import React, {Component} from "react";
import {
  PageHeadings,
  Loading,
  Tab,
  Card,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {setupReduxConnection} from "../../../redux";
import POSModal from "../../../modals/shop/devices/pos-modal";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import DangerBadge from "../../../components/badges/danger-badge";
import {showSuccessNotification} from "../../../utils/notification-helper";

class DevicePage extends Component {
  state = {device: null, events: []};

  async componentDidMount() {
    this.syncData();
  }

  async syncData() {
    const {ID: id} = this.props.router.params;

    const device = await request("partner/device/" + id, "GET");

    this.setState({device, events: device.EVENTS});
  }

  async reactivateDevice() {
    const {location} = this.props.shop;
    const {device} = this.state;

    await request("partner/devices/activate", "POST", {
      DEVICE_ID: device.UNIQUE_ID,
      PUSH_ID: device.PUSH_ID,
      INFO: device.INFO,
      LOCATION_ID: location.ID,
    });

    await this.syncData();
  }
  async deactivateDevice() {
    const {ID: id} = this.state.device;

    const close = await showLoadingConfirmAlert(
      "Delete Device",
      "Are you sure you want to delete this device?"
    );

    await request("device/" + id, "DELETE", null);

    close();

    showSuccessNotification("Device Delete", "We have deleted the device!");
    this.props.router.navigate("/devices");
  }

  render() {
    let {device, events} = this.state;

    if (device === null) {
      return <Loading />;
    }

    return (
      <div>
        <POSModal
          ref={(e) => (this.deviceModal = e)}
          updateInfo={(_, name) => {
            this.setState({device: {...device, NAME: name}});
          }}
        />

        <PageHeadings
          label={
            !!device.STATUS ? (
              device.NAME
            ) : (
              <div className={"flex flex-row items-center"}>
                <div>{device.NAME}</div>
                <DangerBadge className="ml-2 mt-2 h-1/2">Disabled</DangerBadge>
              </div>
            )
          }
          description="View and edit the information about a point of sale device"
          breadcrumbs={[
            {label: "My Shop", url: "/shop"},
            {label: "Devices List", url: "/devices"},
          ]}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: !device.STATUS ? "Reactivate" : "Delete",
                      onClick: () => (!device.STATUS ? this.reactivateDevice() : this.deactivateDevice()),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.deviceModal.open(device),
            },
          ]}
          label="Device Information"
          description="Information about this device"
          data={[
            {
              label: "Name",
              value: device.NAME,
            },
            {label: "Device Info", value: device.INFO},
            {
              label: "Last Used",
              value: moment(device.DATE_UPDATED).format("MM/DD/YY"),
            },
            {
              label: "Date Created",
              value: moment(device.DATE_CREATED).format("MM/DD/YY"),
            },
          ]}
        />

        <Tab
          data={[
            {
              id: "events",
              label: "Employee Events",
            },
          ]}
        >
          {(tab) => {
            if (tab === "events") {
              return (
                <Card
                  label="Employee Events"
                  description="Events that have been carried out by employees on the point of sale"
                >
                  <Table
                    pagination
                    data={events}
                    ref={(e) => (this.tableRef = e)}
                    columns={[
                      {
                        value: "EMPLOYEE_NAME",
                        label: "Name",
                      },
                      {
                        value: "TYPE",
                        label: "Event Type",
                        format: (type) => type.capitalize(),
                      },
                      {
                        value: "DATE_CREATED",
                        label: "Event Time",
                        format: (value) => moment(value).format("hh:mma MM/DD/YY"),
                      },
                    ]}
                  />
                </Card>
              );
            }
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(DevicePage));
