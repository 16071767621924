import React, {Component} from "react";
import {Modal, FormInput, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {Formik} from "formik";
import TemplateCard from "../../features/scheduling/template-card";
import LoadingSpinner from "../../components/loading-spinner";
import * as Yup from "yup";
import {showErrorNotification} from "../../utils/notification-helper";

class TemplatesModal extends Component {
  state = {templates: [], startWeek: null, isSaving: false, isLoading: false};

  async componentDidMount() {
    await this.refresh();
  }

  async open(startWeek) {
    this.setState({startWeek}, () => {
      this.refresh();

      this.formikRef && this.formikRef.resetForm();
      this.slide.open();
    });
  }

  close = () => {
    this.slide.close();
  };

  createTemplate = async ({name}) => {
    const {locationId} = this.props;
    this.setState({isSaving: true});
    let {startWeek} = this.state;
    let allTemplates = [];

    try {
      allTemplates = await request(`scheduling/templates/${startWeek}`, "POST", {
        NAME: name,
        LOCATION_ID: locationId,
      });
    } catch (err) {
      return showErrorNotification(
        "Error Creating Template",
        "Encountered an error trying to create this template. Please try again or contact support for assistance."
      );
    }

    this.setState({templates: allTemplates, isSaving: false});
  };

  refresh = async (loading = true) => {
    const {locationId} = this.props;

    if (!!loading) {
      this.setState({isLoading: true});
    }

    const templates = await request("scheduling/templates/" + locationId, "GET");

    this.setState({isLoading: false, templates});
  };

  renderLoading() {
    return <LoadingSpinner />;
  }

  render() {
    let {templates, startWeek, isSaving, isLoading} = this.state;
    const {locationId} = this.props;

    return (
      <Modal
        label="Week Templates"
        closeLabel="Close"
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.slide = e)}
      >
        {!!isLoading ? (
          this.renderLoading()
        ) : (
          <>
            <div className="block text-sm font-medium text-gray-700">{`${
              templates.length > 0 ? templates.length : "No"
            } Existing Template${templates.length == 1 ? "" : "s"}`}</div>

            <div className="mb-3 mb-5">
              {templates.map((template) => (
                <TemplateCard
                  template={template}
                  startWeek={startWeek}
                  handleUpdate={this.refresh}
                  close={this.close}
                  locationId={locationId}
                  signedInLocationId={this.props.signedInLocationId}
                  refresh={this.props.refresh}
                />
              ))}
            </div>

            <Formik
              validationSchema={Yup.object().shape({
                name: Yup.string().required("Template name required"),
              })}
              onSubmit={this.createTemplate}
              initialValues={{}}
              innerRef={(e) => (this.formikRef = e)}
            >
              {(formikOptions) => {
                let {handleSubmit} = formikOptions;

                return (
                  <form className="space-y-6 mb-2" onSubmit={handleSubmit}>
                    <div className="flex flex-row justify-between items-end">
                      <FormInput
                        name={"name"}
                        label="Create a Template of Current Schedule"
                        placeholder="Template Name"
                        options={formikOptions}
                      />

                      <button
                        type="submit"
                        // onClick={() => this.formikRef.submitForm()}
                        className="justify-center text-center inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                      >
                        {!isSaving && <div>Create</div>}

                        {isSaving && <LoadingSpinner color="white" size={20} />}
                      </button>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        )}
      </Modal>
    );
  }
}

export default TemplatesModal;
