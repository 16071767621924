import React, {Component} from "react";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import moment from "moment-timezone";
import LoadingSpinner from "../../components/loading-spinner";
import ClosingsModal from "../../modals/shop/employee-details-modal";

class HoursOverridesPage extends Component {
  state = {closings: null};

  async componentDidMount() {
    this.syncState();
  }

  syncState = async () => {
    let closings = await request("hours/closings", "GET");

    closings.sort((a, b) => {
      return a.START_DATE - b.END_DATE;
    });

    this.setState({closings});
  };

  render() {
    let {closings} = this.state;

    let actionButtons = [
      {
        label: "Edit",
        onClick: (row) => {
          this.closingsModal.open(row);
        },
      },
    ];

    let columns = [
      {
        label: "Name",
        value: "NAME",
      },
      {
        label: "Date",
        format: (value, row) => {
          let {START_DATE, END_DATE} = row;

          if (START_DATE === END_DATE) {
            return moment(START_DATE).format("MMM Do");
          }

          return `${moment(START_DATE).format("MMM Do")} - ${moment(END_DATE).format("MMM Do")}`;
        },
      },
      {
        label: "Open",
        format: (value, row) => {
          let {TYPE, START_TIME, END_TIME} = row;

          let base = moment();

          if (moment().isDST() !== moment().subtract(1, "day").isDST()) {
            base.add(1, "day");
          }

          let startStr = base.clone().startOf("day").add(START_TIME, "minutes").format("h:mm A");
          let endStr = base.startOf("day").add(END_TIME, "minutes").format("h:mm A");

          if (TYPE === "FULL_DAY_CLOSING") {
            return "Closed All Day";
          } else {
            return `${startStr} - ${endStr}`;
          }
        },
      },
    ];

    return (
      <div>
        <ClosingsModal refresh={this.syncState} ref={(e) => (this.closingsModal = e)} />

        <PageHeadings
          label={"Hours Overrides"}
          description={"With overrides, you can set planned custom hours for holidays, events, etc. "}
        />

        {!closings && (
          <div>
            <LoadingSpinner />
          </div>
        )}

        {closings && (
          <Card
            label="Planned Overrides"
            button={{
              label: "Add Override",
              onClick: () => this.closingsModal.open(),
            }}
          >
            <Table actionButtons={actionButtons} columns={columns} data={closings} />
          </Card>
        )}
      </div>
    );
  }
}

export default HoursOverridesPage;
