import {dayToIsoDay} from "./util";
import {EVENT_TYPES} from "../features/scheduling/scheduler";

export function calculateWeekStartEpochFromMoment(fromMoment, isoWeekStart) {
  const firstIsoDay = dayToIsoDay(isoWeekStart);

  const weekday = fromMoment.isoWeekday();

  const dayOffset = weekday < firstIsoDay ? weekday - firstIsoDay + 7 : weekday - firstIsoDay;

  return fromMoment.startOf("day").add(-dayOffset, "days").valueOf();
}

export function fetchToggleLocations(schedulingLocations, loggedInLocation) {
  if (!schedulingLocations || schedulingLocations.length === 0) {
    return [loggedInLocation.ID];
  }

  return schedulingLocations;
}

export const isCrossLocationShift = (shiftType) => {
  return shiftType === EVENT_TYPES.CROSS_LOCATION_SHIFT;
};

export const checkIsShiftLocations = (updatedLocationId, selectedLocations, loggedInLocation) =>
  fetchToggleLocations(selectedLocations, loggedInLocation).includes(updatedLocationId);

export const handleShiftAfterEdits = (
  selectedLocations,
  loggedInLocation,
  shiftType,
  editedShift,
  upsertShifts,
  upsertCrossLocationShifts,
  deleteShift,
  deleteCrossLocationShift
) => {
  const updatedLocationId = editedShift.LOCATION_ID;

  const isShiftToggledLocation = checkIsShiftLocations(
    updatedLocationId,
    selectedLocations,
    loggedInLocation
  );

  processShiftEdits(editedShift, isShiftToggledLocation, upsertShifts, upsertCrossLocationShifts);

  isCrossLocationShift(shiftType)
    ? processCrossLocation(editedShift, isShiftToggledLocation, deleteCrossLocationShift)
    : processSelectedLocation(editedShift, isShiftToggledLocation, deleteShift);
};

export const processCrossLocation = (editedShift, isShiftToggled, deleteCrossLocationShift) => {
  if (isShiftToggled) {
    deleteCrossLocationShift(editedShift.ID);
  }
};

export const processSelectedLocation = (editedShift, isShiftToggled, deleteShift) => {
  if (!isShiftToggled) {
    deleteShift(editedShift.ID);
  }
};

export const processShiftEdits = (
  editedShift,
  isShiftToggledLocation,
  upsertShifts,
  upsertCrossLocationShifts
) => {
  if (isShiftToggledLocation) {
    upsertShifts([editedShift]);
  } else {
    upsertCrossLocationShifts([editedShift]);
  }
};

export const getEmployeeName = (employeeId, employees) => {
  return employees.find((employee) => employee.ID === employeeId)?.FULL_NAME;
};

export const getLocationName = (locationId, locations) => {
  return locations.find((location) => location.ID === locationId)?.NAME;
};

export const filterShifts = (updatedShifts, selectedLocations) =>
  updatedShifts.reduce(
    (acc, shift) => {
      if (selectedLocations.includes(shift.LOCATION_ID)) {
        acc.selectedShifts.push(shift);
      } else {
        acc.crossLocationShifts.push(shift);
      }

      return acc;
    },
    {selectedShifts: [], crossLocationShifts: []}
  );
