import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {useTranslation} from "react-i18next";
import {LanguageSelectStep} from "../language-select-step";
import Wizard from "../../../../../components/wizard";
import React, {useMemo, useRef} from "react";

import {SensitiveEmployeeInfoStep} from "./steps/sensitive-employee-info-step";
import {EmployeeInfoStep} from "./steps/employee-info-step";
import {EmployeeAttestationStep} from "./steps/employee-attestation-step";
import * as Yup from "yup";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {SignatureForm} from "../signature-form";
import {setI9FormFields} from "./i9-fill-pdf-helper";
import {languageSchema} from "../w4/w4-helper";
import {
  attestationInitial,
  attestationSchema,
  employeeInfoInitial,
  employeeInfoSchema,
  sensitiveEmployeeInfoSchema,
  sensitiveInfoInitial,
} from "./i9-helper";
import {handleSignatureValidation} from "../federal-form-helpers";
import {useSubmitDocument} from "../../../../../hooks/team/onboarding/submission-hooks";

export const I9Part1FormPage = () => {
  const {t} = useTranslation();

  const {DOCUMENT_ASS_ID} = useParams();

  const wizardRef = useRef(null);

  const navigate = useNavigate();

  const location = useLocation();

  const packetAssUId = useMemo(() => location.state?.packetAssignmentUId, [location.state]);

  const {handleSubmit, reviewLoading} = useSubmitDocument(packetAssUId);

  const getI18NextKey = (path) => {
    return "pages.onboarding.onboarding-landing-page." + path;
  };

  const getSignatureKeys = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  const getI9Part1Steps = () => {
    const steps = [];

    steps.push(
      {
        id: 0,
        name: t(getI18NextKey("select-language")),
        initialValues: {
          language: null,
        },
        yup: languageSchema,
        description: t(getI18NextKey("select-language-description")),
        render: (formik) => <LanguageSelectStep formik={formik} />,
      },
      {
        id: 1,
        name: t(getI18NextKey("employee-info")),
        description: t(getI18NextKey("employee-info-description")),
        initialValues: employeeInfoInitial,
        yup: employeeInfoSchema,
        render: (formik) => <EmployeeInfoStep formik={formik} />,
      },
      {
        id: 2,
        name: t(getI18NextKey("employee-sensitive-info")),
        description: t(getI18NextKey("employee-sensitive-info-description")),
        initialValues: sensitiveInfoInitial,
        yup: sensitiveEmployeeInfoSchema,
        render: (formik) => <SensitiveEmployeeInfoStep formik={formik} />,
      },
      {
        id: 3,
        name: t(getI18NextKey("employee-attestation")),
        description: t(getI18NextKey("employee-attestation-description")),
        initialValues: attestationInitial,
        yup: attestationSchema,
        render: (formik) => <EmployeeAttestationStep formik={formik} />,
      },
      {
        id: 4,
        name: "Employee Verification",
        initialValues: {
          signature: null,
        },
        yup: Yup.object().shape({}),
        customValidation: (formik) => handleSignatureValidation(formik),
        render: (formik) => (
          <SignatureForm
            formik={formik}
            label={t(getSignatureKeys("attestationPhrase.label"))}
            subLabel={t(getSignatureKeys("attestationPhrase.subLabel"))}
            className={"w-3/4"}
          />
        ),
      }
    );

    return steps;
  };

  const navigateBack = () => {
    navigate(location.state?.from ?? "/hub/employee-onboarding", {replace: true});
  };

  const handleI9Submit = async (values) => {
    await handleSubmit(DOCUMENT_ASS_ID, values, setI9FormFields, navigateBack);
  };

  return (
    <Card label={"Federal I-9"} noHeaderBorder={true} className={"py-6 px-4 items-center justify-center"}>
      <div className={"my-4 mx-6"}>
        <Wizard
          flexHeader
          className={"mx-20 h-screen"}
          ref={wizardRef}
          steps={getI9Part1Steps()}
          onSubmit={(values) => handleI9Submit(values)}
          submitLabel={t(getI18NextKey("finish"))}
          onQuit={navigateBack}
        />
      </div>
    </Card>
  );
};
