import React, {Component} from "react";
import {Card, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import LoadingSpinner from "../../../../components/loading-spinner";
import {CUSTOM_BENEFIT_TYPES} from "../../../../utils/payroll-constants";
import {withRouter} from "../../../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PayrollAddEmployeeToBenefitModal from "../../../../modals/payroll/benefits/payroll-add-employees-to-benefit-modal";
import DangerBadge from "../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../components/badges/success-badge";

class PayrollCustomBenefitPage extends Component {
  state = {benefit: null};

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    let {ID} = this.props.router.params;

    let benefit = await request(`payroll/benefits/custom/${ID}`);

    this.setState({benefit});
  };

  render() {
    let {benefit} = this.state;

    if (!benefit) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <PayrollAddEmployeeToBenefitModal
          ref={(e) => (this.payrollAddEmployeeToCustomBenefitModal = e)}
          syncState={this.syncState}
        />

        <PageHeadings label={benefit.NAME} description={CUSTOM_BENEFIT_TYPES[benefit.BENEFIT]} />

        <Card
          label={"Enrolled Employees"}
          buttons={[
            {
              label: "Add Employee",
              onClick: () => {
                this.payrollAddEmployeeToCustomBenefitModal.openAdd(benefit.UNIQUE_ID);
              },
            },
          ]}
        >
          <Table
            actionButtons={[
              {
                label: "Edit",
                onClick: (row) => {
                  this.payrollAddEmployeeToCustomBenefitModal.openEdit(row);
                },
              },
            ]}
            data={benefit.EMPLOYEES}
            columns={[
              {
                label: "Name",
                value: "FULL_NAME",
              },
              {
                label: "Status",
                value: "ACTIVE",
                format: (value) => {
                  if (value) {
                    return <SuccessBadge>Active</SuccessBadge>;
                  }

                  return <DangerBadge>Inactive</DangerBadge>;
                },
              },
              {
                label: "Employee Cont. $",
                value: "EMPLOYEE_CONTRIBUTION_AMOUNT",
                format: (value) => toDollars(value, true),
              },
              {
                label: "Company Cont. $",
                value: "COMPANY_CONTRIBUTION_AMOUNT",
                format: (value) => toDollars(value, true),
              },
              {
                label: "Employee Cont. %",
                value: "EMPLOYEE_CONTRIBUTION_PERCENT",
              },
              {
                label: "Company Cont. %",
                value: "COMPANY_CONTRIBUTION_PERCENT",
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default withRouter(PayrollCustomBenefitPage);
