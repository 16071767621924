import React, {Component} from "react";
import {FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/solid";
import {formatShiftString, tradeRequestStatusToBadge} from "../../utils/team-helper";
import moment from "moment-timezone";
import {TRADE_REQUEST_TYPES} from "../../utils/team-constants";

class TradeRequestModal extends Component {
  state = {trade: null, reviewerNotes: ""};

  open(trade) {
    this.setState({trade}, () => {
      this.modal.open();
    });
  }

  blessRequest(isAccepted) {
    let {trade, reviewerNotes} = this.state;

    this.props.blessRequest(trade.ID, isAccepted, reviewerNotes, trade.SHIFT_ID, trade.SWAP_SHIFT_ID);
    this.modal.close();
  }

  renderHeader() {
    let {trade} = this.state;

    return (
      <div className="flex flex-row">
        <div>{`Trade Request`}</div>

        <div className="ml-10">{tradeRequestStatusToBadge(trade?.STATUS)}</div>
      </div>
    );
  }

  render() {
    let {trade} = this.state;

    let actionableRequest = trade?.STATUS === TRADE_REQUEST_TYPES.ready_for_review.value;

    let reviewed =
      trade?.STATUS === TRADE_REQUEST_TYPES.reviewer_approved.value ||
      trade?.STATUS === TRADE_REQUEST_TYPES.reviewer_denied.value;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        label={this.renderHeader()}
        buttonLabel={actionableRequest && "Approve"}
        buttonOnClick={() => this.blessRequest(true)}
        deleteLabel={actionableRequest && "Reject"}
        deleteOnClick={() => this.blessRequest(false)}
      >
        <div className="text-sm">
          <div className="flex flex-row justify-around">
            <div>{trade?.TRADER_NAME}</div>

            <div className="flex flex-row">
              {trade?.SWAP_SHIFT_DATE_START && <ArrowLeftIcon className="h-5 w-5" />}

              <ArrowRightIcon className="h-5 w-5" />
            </div>

            <div>{trade?.TARGET_NAME}</div>
          </div>

          <div className="mt-3">
            <div className="block text-sm font-medium text-gray-700">Shift</div>

            <div className="text-sm">{formatShiftString(trade?.SHIFT_DATE_START, trade?.SHIFT_DATE_END)}</div>

            <div className="text-sm">{trade?.SHIFT_NOTES && <div>Notes: {trade?.SHIFT_NOTES}</div>}</div>
          </div>

          {trade?.SWAP_SHIFT_DATE_START && (
            <div className="mt-3">
              <div className="block text-sm font-medium text-gray-700">Swap Shift</div>

              <div className="text-sm">
                {formatShiftString(trade?.SWAP_SHIFT_DATE_START, trade?.SWAP_SHIFT_DATE_END)}
              </div>

              <div className="text-sm">
                {trade?.SWAP_SHIFT_NOTES && <div>Notes: {trade?.SWAP_SHIFT_NOTES}</div>}
              </div>
            </div>
          )}

          <div className="mt-3">
            <div className="block text-sm font-medium text-gray-700">Trade Created</div>

            <div className="">
              {`${moment(trade?.DATE_CREATED).format("M/DD")} at
              ${moment(trade?.DATE_CREATED).format("h:mm A")}`}
            </div>

            {trade?.TRADE_NOTES && <div>Notes: {trade?.TRADE_NOTES}</div>}
          </div>

          {actionableRequest && (
            <div>
              <div className="h-0.5 w-full my-10 bg-gray-300 mt-3" />

              <FormTextArea
                className="mt-3"
                label="Reviewer's Notes"
                placeholder="Example: Unfortunately, I cannot approve this request because..."
                onChange={(e) => this.setState({reviewerNotes: e.target.value})}
              />
            </div>
          )}

          {reviewed && (
            <div>
              <div className="mt-3">
                <div className="block text-sm font-medium text-gray-700">Reviewed By</div>
                <div>{trade?.REVIEWER_NAME}</div>
              </div>

              {trade?.REVIEWER_NOTES && (
                <div className="mt-3">
                  <div className="block text-sm font-medium text-gray-700">Reviewer Notes</div>
                  <div>{trade?.REVIEWER_NOTES}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default TradeRequestModal;
