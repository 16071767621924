import React, {Component} from "react";
import {FormInput, Modal, FormDate} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import FormRow from "../../components/form-row";
import ImageFormDropZone from "../../components/image-form-drop-zone";
import FormDateTimeSelect from "../../components/form-date-time-select";
import * as Yup from "yup";

class FundraiserDetailsModal extends Component {
  state = {fundraiser: null, freshOne: false};

  open(fundraiser = null) {
    if (fundraiser === null) {
      this.setState({freshOne: true});
    }

    this.setState({fundraiser}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveFundraiser({name, logo, start, end}) {
    let {fundraiser, freshOne} = this.state;

    let fundraiserPayload = {
      NAME: name,
      LOGO: logo,
      DATE_START: start,
      DATE_END: end,
    };
    if (freshOne) {
      fundraiser = await request("marketing/fundraisers/", "POST", fundraiserPayload);
    } else {
      fundraiser = await request("marketing/fundraisers/" + fundraiser.ID, "PATCH", fundraiserPayload);
    }

    this.props.updateState({fundraiser});

    this.modal.close();
  }

  render() {
    const {fundraiser} = this.state;
    return (
      <Modal
        buttonLabel="Save"
        label={fundraiser ? "Edit Fundraiser" : "Create Fundraiser"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.saveFundraiser.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: fundraiser?.NAME,
            logo: fundraiser?.LOGO,
            start: fundraiser?.DATE_START,
            end: fundraiser?.DATE_END,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Please input a name"),
            start: Yup.string().required("Please select a start time"),
            end: Yup.string().required("Please select an end time"),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" options={formikOptions} />
                <ImageFormDropZone label="Icon" name="logo" options={formikOptions} />

                <FormRow>
                  <FormDateTimeSelect label="Fundraiser Start" options={formikOptions} name="start" />

                  <FormDateTimeSelect label="Fundraiser End" options={formikOptions} name="end" />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default FundraiserDetailsModal;
