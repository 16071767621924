import React, {Component} from "react";
import {Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {DRAWER_TYPES} from "../../utils/operations-constants";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {setupReduxConnection} from "../../redux";
import CashEventModal from "../../modals/operations/cash/cash-event-modal";
import {request} from "../../utils/request";

class CashDrawerTable extends Component {
  cashModal = null;
  syncState = () => {
    this.tableRef.refresh();
  };

  async handleVerifyClick(drawerId) {
    let cashDrawer = await request(`cashboxManagement/cash_drawers/${drawerId}`, "GET");
    this.cashModal.open({...cashDrawer, TYPE: "Verify", CASHBOX_ID: drawerId}); // Open modal after state is updated
  }

  render() {
    const {CASH_DRAWER_VERIFICATION_ENABLED} = this.props.shop.settings;

    return (
      <Card>
        <CashEventModal ref={(e) => (this.cashModal = e)} syncState={() => this.syncState()} />
        <Table
          pagination
          route="cashboxManagement/dumb"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate(`/drawer/${row.ID}`),
            },
            {
              label: "Verify",
              onClick: (row) => this.handleVerifyClick(row.ID),
            },
          ]}
          columns={[
            {
              value: "DRAWER_TYPE",
              label: "Type",
              width: 150,
              tooltip:
                "If Employee, then this cash drawer is used by an employee to track their cash throughout a delivery shift. If Cash, then it is a standard cash drawer.",
              format: (value) => {
                return value === DRAWER_TYPES.EMPLOYEE_DRAWER ? "Employee" : "Cash";
              },
            },
            {
              value: "DEVICE_NAME",
              label: "Device/Employee",
              tooltip:
                "This column lists the name of the specific POS device that the corresponding cash drawer was opened on. For an employee drawer, this lists the employee who used this drawer.",
              format: (device, row) => {
                return row.DRAWER_TYPE === DRAWER_TYPES.EMPLOYEE_DRAWER
                  ? row.EMPLOYEE_NAME
                  : device ?? "All Devices";
              },
            },
            {
              value: "DATE_OPEN",
              label: "Opened",
              mobile: "lg:table-cell",
              tooltip: "This column lists the time that the corresponding cash drawer was opened.",
              format: (value) => {
                return moment(value).format("MMM DD, hh:mm A");
              },
            },
            {
              value: "DATE_CLOSE",
              label: "Closed",
              mobile: "lg:table-cell",
              tooltip: "This column lists the time that the corresponding cash drawer was closed.",
              format: (value) => {
                return value ? moment(value).format("MMM DD, hh:mm A") : "Open";
              },
            },
            ...(CASH_DRAWER_VERIFICATION_ENABLED === "1"
              ? [
                  {
                    value: "DATE_VERIFIED",
                    label: "Verified",
                    mobile: "lg:table-cell",
                    tooltip: "This column lists the time that the corresponding cash drawer was verified.",
                    format: (value) => {
                      return value ? moment(value).format("MMM DD, hh:mm A") : "-";
                    },
                  },
                ]
              : []),
            {
              label: "Expected in Drawer",
              value: "AMOUNT_EXPECTED",
              sortable: true,
              tooltip:
                "The value under this column represents the total amount of cash expected to be in the corresponding drawer once it is closed. This value is based on the sum of the corresponding drawer's opening amount, total cash sales, and pay ins, minus any pay outs.",
              format: (value) => {
                return value !== null ? toDollars(value, true) : "-";
              },
            },
            {
              label: "Close Amount",
              value: "AMOUNT_CLOSE",
              sortable: true,
              tooltip:
                "The value under this column represents the actual closing amount of cash recorded in the corresponding drawer once it has been closed.",
              format: (value) => {
                return value !== null ? toDollars(value, true) : "-";
              },
            },
            {
              label: "Close Difference",
              value: "DIFFERENCE",
              sortable: true,
              tooltip:
                "The value under this column represents the difference between the expected amount and the actual closing amount recorded once the corresponding drawer has been closed.",
              format: (value) => {
                return value !== null ? toDollars(value, true) : "-";
              },
            },
            ...(CASH_DRAWER_VERIFICATION_ENABLED === "1"
              ? [
                  {
                    label: "Verified Amount",
                    value: "AMOUNT_VERIFIED",
                    sortable: true,
                    tooltip:
                      "The value under this column represents the verified amount if verification is enabled.",
                    format: (value) => {
                      return value ? toDollars(value, true) : "-";
                    },
                  },
                  {
                    label: "Verified Difference",
                    value: "AMOUNT_VERIFIED",
                    sortable: true,
                    tooltip:
                      "This value represents the difference between the close amount and the verified amount.",
                    format: (value, row) => {
                      const verifiedDifference = row.AMOUNT_VERIFIED - row.AMOUNT_CLOSE;
                      return row.AMOUNT_CLOSE && row.AMOUNT_VERIFIED !== null
                        ? toDollars(verifiedDifference, true)
                        : "-";
                    },
                  },
                  {
                    label: "Verification Notes",
                    value: "VERIFIED_NOTES",
                    sortable: true,
                    tooltip:
                      "This column lists the notes provided on verification if verification is enabled.",
                    format: (value) => {
                      return value ? value : "-";
                    },
                  },
                ]
              : []),
            {
              label: "Deposit",
              value: "AMOUNT_DEPOSIT",
              sortable: true,
              tooltip: "This value consists of the closing amount minus the opening amount.",
              format: (value, row) => {
                if (row.CASH_REMAINING == null || row.AMOUNT_CLOSE == null) {
                  return "-";
                }
                return toDollars(row.AMOUNT_CLOSE - row.CASH_REMAINING, true);
              },
            },
          ]}
          {...this.props}
        />
      </Card>
    );
  }
}

CashDrawerTable.propTypes = {
  drawers: PropTypes.array.isRequired,
};

export default setupReduxConnection(["shop"])(withRouter(CashDrawerTable));
