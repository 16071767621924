import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import Sidebar from "../../../components/navigation/sidebar";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showErrorNotification, showSuccessNotification} from "../../../utils/notification-helper";
import {request} from "../../../utils/request";
import GrayBadge from "../../../components/badges/gray-badge";
import Guide from "../../../components/guide";
import {USER_GUIDES} from "../../../utils/constants";
import {EmployeeRequests} from "../../../utils/request-helpers/employees/employee-requests";

class EmployeeFolderModal extends Component {
  state = {files: [], folders: [], current: null, newFolder: false};

  loadFiles() {
    const {files = [], folders = []} = this.props;

    if (!files) {
      return;
    }

    const transformedFiles = files.map((_file) => {
      return {
        ..._file,
        ICON: _file.FOLDER_ID && <FontAwesomeIcon icon="check-circle" color="green" />,
        uid: _file.ID,
      };
    });

    this.setState({files: transformedFiles, folders});
  }

  open() {
    this.formikRef && this.formikRef.resetForm();

    this.loadFiles();
    this.modal.open();
  }

  saveFile = async (values) => {
    const {current, files} = this.state;
    const {name, folder} = values;

    try {
      await EmployeeRequests.updateDocument({
        UNIQUE_ID: current.UNIQUE_ID,
        DOCUMENT_NAME: name,
        FOLDER_ID: folder,
      });
    } catch (err) {
      return showErrorNotification("", "Error saving file");
    }

    const index = files.findIndex(({ID}) => ID === current.ID);

    if (index !== -1) {
      files[index] = {
        ...files[index],
        NAME: name,
        FOLDER_ID: folder,
        ICON: <FontAwesomeIcon icon="check-circle" color="green" />,
      };
    }

    if (this.props.updateState) {
      this.props.updateState();
    }

    return showSuccessNotification("", "File successfully saved");
  };

  createFolder = async (values) => {
    const {name} = values;
    const {employee} = this.props;
    const {folders} = this.state;

    let serverFolder = null;
    try {
      serverFolder = await request("employees/folder", "POST", {
        EMPLOYEE_ID: employee.ID,
        NAME: name,
      });
    } catch (err) {
      return showErrorNotification("", "Error creating folder");
    }

    if (!!serverFolder) {
      this.setState({folders: [...folders, serverFolder]});
    }

    if (this.props.updateState) {
      this.props.updateState();
    }

    return showSuccessNotification("", "File successfully saved");
  };

  close() {
    this.modal.close();
  }

  renderHeader() {
    const {newFolder} = this.state;

    return (
      <div className="p-4 rounded-t-md font-semibold bg-gray-100">
        {!!newFolder ? "Create Folder" : "Manage File"}
      </div>
    );
  }

  renderModalLabel() {
    return (
      <div className="text-gray-700 text-md">
        Manage Employee Files
        <GrayBadge className="ml-1">Beta</GrayBadge>
      </div>
    );
  }

  renderButton(onClick, label = "Confirm", disabled = false, id = "") {
    return (
      <button
        onClick={() => onClick()}
        className={classNames(
          "inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        )}
        disabled={disabled}
        type="button"
        id={id}
      >
        {label}
      </button>
    );
  }

  renderSubmitGuide() {
    return (
      <Guide
        target={"#submit"}
        label={"Make sure the file has a name, and press confirm to save your changes"}
        showCheckbox
        _key={USER_GUIDES.FILE_CONFIRM}
      />
    );
  }

  renderSidebarGuide() {
    return (
      <Guide
        target={"#sidebar"}
        label={"Select a file from this sidebar to get started"}
        showCheckbox
        _key={USER_GUIDES.FILE_SIDE_BAR}
      />
    );
  }

  renderSideBar() {
    const {files = []} = this.state;

    return (
      <Sidebar
        className="col-span-1 mr-4"
        onChange={(current) => this.setState({current, newFolder: false})}
        stayOpen={true}
        navigation={[
          {
            NAME: "Files",
            CHILDREN: files?.map((file) => ({...file, NAME: file.DOCUMENT_NAME})),
          },
        ]}
      />
    );
  }

  renderManageFile() {
    const {current, folders = []} = this.state;
    const {FILE_CONFIRM} = this.props.userGuides;

    const validationObject = Yup.object().shape({
      name: Yup.string().required("Name is a required field"),
    });

    return (
      <Formik
        onSubmit={(values) => this.saveFile(values)}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={{
          name: current?.FILE_NAME ?? "",
          folder: current?.FOLDER_ID,
        }}
        validationSchema={validationObject}
        enableReinitialize
      >
        {(formikOptions) => {
          const {values, handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <div className="col-span-3">
                <FormInput options={formikOptions} name={"name"} label={"File Name"} />

                <FormSelect
                  options={formikOptions}
                  name={"folder"}
                  hint={"optional"}
                  label={"Select Tag"}
                  data={folders?.map(({ID, NAME}) => ({value: ID, label: NAME}))}
                />

                <div className="mt-4 flex flex-row justify-end">
                  {this.renderButton(handleSubmit, "Confirm", !values.name || !current, "submit")}

                  {!FILE_CONFIRM && this.renderSubmitGuide()}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  renderNewFolder() {
    return (
      <Formik
        onSubmit={(values) => this.createFolder(values)}
        innerRef={(e) => (this.formikRef = e)}
        initialValues={{
          name: "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is a required field"),
        })}
        enableReinitialize
      >
        {(formikOptions) => {
          const {values, handleSubmit} = formikOptions;

          return (
            <form onSubmit={handleSubmit}>
              <div className="col-span-3">
                <FormInput options={formikOptions} name={"name"} label={"Folder Name"} />

                <div className="mt-4 flex flex-row justify-end">
                  {this.renderButton(handleSubmit, "Save", !values.name)}
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  }

  render() {
    const {newFolder} = this.state;
    const {FILE_SIDE_BAR} = this.props.userGuides;

    return (
      <Modal
        xlarge
        noPadding
        label={this.renderModalLabel()}
        ref={(e) => (this.modal = e)}
        buttons={[{label: "New Tag", onClick: () => this.setState({newFolder: true})}]}
        tooltip={{
          data: "Use the side bar to assign folders to files, and press confirm to submit your changes",
        }}
        backgroundClose={false}
      >
        <div className="grid grid-cols-2 h-screen">
          <div className="flex" id={"sidebar"}>
            {this.renderSideBar()}

            {!FILE_SIDE_BAR && this.renderSidebarGuide()}
          </div>

          <div className="shadow-md rounded-md overflow-y-hidden">
            {this.renderHeader()}

            <div className="mx-4">
              {!!newFolder && this.renderNewFolder()}

              {!newFolder && this.renderManageFile()}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

EmployeeFolderModal.propTypes = {
  employee: PropTypes.object.isRequired,
  files: PropTypes.array.isRequired,
  folders: PropTypes.array.isRequired,
};

export default EmployeeFolderModal;
