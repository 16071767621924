import {Rnd} from "react-rnd";
import {ONBOARDING_FIELD_TYPES} from "../../utils/constants";
import React, {useState} from "react";
import {useTransformEffect} from "react-zoom-pan-pinch";

export const FillableFields = ({field, formik, setDisablePan}) => {
  const [scale, setScale] = useState(1);

  useTransformEffect(({state, instance}) => {
    setScale(state.scale);
  });

  const fieldType = field?.TYPE;

  const fieldName = field?.ID;

  const fieldError = formik.errors[fieldName] && formik.touched[fieldName];

  const getPlaceholderText = () => {
    switch (fieldType) {
      case ONBOARDING_FIELD_TYPES.SIGNATURE:
        return "Click to here to sign";
      case ONBOARDING_FIELD_TYPES.INITIALS:
        return "Click here to initial";
      case ONBOARDING_FIELD_TYPES.DATE:
        return "Click to type date";
      case ONBOARDING_FIELD_TYPES.TEXT:
        return "Click to type";
      case ONBOARDING_FIELD_TYPES.CHECKBOX:
        return "";
      default:
        return "";
    }
  };

  const getInputType = () => {
    if (fieldType === ONBOARDING_FIELD_TYPES.CHECKBOX) {
      return "checkbox";
    }

    return "text";
  };

  const getStyles = () => {
    let colors;

    if (fieldError) {
      colors = "bg-red-500 border-red-500 focus:border-red-700 ";
    } else if (field.IS_REVIEWER) {
      colors = "bg-green-700 border-green-700 focus:border-green-900 ";
    } else {
      colors = "bg-indigo-500 border-indigo-500 focus:border-indigo-700 ";
    }

    const fonts =
      field.TYPE === ONBOARDING_FIELD_TYPES.SIGNATURE ? " font-signature placeholder:font-sans " : " ";

    return (
      colors +
      fonts +
      "flex flex-row text-black justify-between w-full h-full items-center text-xs text-center border bg-opacity-25 hover:bg-opacity-20 focus:border-[1.5px] focus:outline-none focus:ring-0 rounded-md px-3 py-[6px] overflow-hidden whitespace-nowrap"
    );
  };

  const handleFocus = () => setDisablePan(true);
  const handleBlur = () => setDisablePan(false);

  return (
    <Rnd
      key={field.ID}
      default={{
        x: field.X_COORDINATE ?? 0,
        y: field.Y_COORDINATE ?? 0,
        width: field.WIDTH ?? 100,
        height: field.HEIGHT ?? 50,
      }}
      disableDragging={true}
      enableResizing={false}
      className={"hover:cursor-pointer"}
      scale={scale}
      style={{zIndex: 10}}
    >
      <input
        name={fieldName}
        type={getInputType()}
        placeholder={getPlaceholderText()}
        className={getStyles()}
        style={{zIndex: 11}}
        {...(field.TYPE === ONBOARDING_FIELD_TYPES.CHECKBOX
          ? {checked: formik.values[fieldName]}
          : {value: formik.values[fieldName]})}
        onChange={formik.handleChange}
        onBlur={(e) => {
          formik.handleBlur(e);

          handleBlur();
        }}
        onFocus={handleFocus}
      />
    </Rnd>
  );
};
