import React, {Component} from "react";
import {request} from "../../../../utils/request";
import {withRouter} from "../../../../utils/navigation";

import {Card, Loading, PageHeadings, Tab, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import TicketTypeDetailsModal from "../../../../modals/sales/types/ticket-type-details-modal";
import TicketNotificationsDetailsModal from "../../../../modals/sales/types/ticket-notifications-details-modal";
import TicketVerbiageModal from "../../../../modals/sales/types/ticket-verbiage-modal";
import {getVerbiageArray} from "../../../../utils/sales-helper";
import {showErrorAlert, showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import DangerBadge from "../../../../components/badges/danger-badge";

class TicketTypePage extends Component {
  state = {type: null, verbiages: null, settings: null, notificationsArray: null};

  async componentDidMount() {
    await this.syncState();
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;

    let type = await request("types/order/" + id, "GET", null);

    this.setState({
      type: type,
      verbiages: type.VERBIAGES,
      settings: type.SETTINGS,
      notificationsArray: this.getNotificationsFromSettings(type.SETTINGS),
    });
  };

  async deleteTicketType() {
    const {type} = this.state;

    const close = await showLoadingConfirmAlert(
      "Delete Ticket Type",
      "Are you sure you want to delete this ticket type?"
    );

    try {
      await request("types/order/" + type.ID, "DELETE", {});

      this.props.router.navigate("/flow/tickets");
    } catch (e) {
      if (e.error === "ORDER_TYPE_REQUIRED") {
        setTimeout(
          () =>
            showErrorAlert(
              "Cannot Archive Order Type",
              "You must have at least one order type on the point of sale. Please enable a different order type to archive this one."
            ),
          500
        );
      }
    } finally {
      close();

      this.modal.close();
    }
  }

  getNotificationsFromSettings(settings) {
    return [
      {
        label: "Custom Message When Order Placed",
        value: settings.NOTIFY_ON_PLACEMENT === "1" ? settings.PLACEMENT_MESSAGE_CONTENT : <i>Disabled</i>,
        span: true,
      },
      {
        label: "Custom Message When Order Completed",
        value: settings.NOTIFY_ON_COMPLETION === "1" ? settings.COMPLETION_MESSAGE_CONTENT : <i>Disabled</i>,
        span: true,
      },
    ];
  }

  render() {
    const {type, verbiages, settings, notificationsArray} = this.state;

    if (type === null) {
      return <Loading />;
    }

    return (
      <div>
        <TicketTypeDetailsModal
          ref={(e) => (this.ticketTypeDetailsModal = e)}
          updateState={(e) => this.setState(e)}
        />

        <TicketNotificationsDetailsModal
          ref={(e) => (this.ticketNotificationsDetailsModal = e)}
          handleUpdate={this.syncState}
        />

        <TicketVerbiageModal ref={(e) => (this.ticketVerbiageModal = e)} handleUpdate={this.syncState} />

        <PageHeadings
          label={
            type.TAX_DISABLED === "1" || type.TAX_DISABLED === 1 ? (
              <div className={"flex flex-row items-center"}>
                <div>{type.NAME}</div>
                <DangerBadge className="ml-2 mt-2 h-1/2" yellow>
                  Taxes Disabled
                </DangerBadge>
              </div>
            ) : (
              type.NAME
            )
          }
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Duplicate",
                      onClick: () => this.duplicateModal.open(),
                    },
                  ],
                },
                {
                  items: [{label: "Archive", onClick: () => this.deleteTicketType()}],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Checkout Flow", url: "/flow/tickets"},
            {label: "Ticket Types Page", url: "/flow/tickets"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.ticketTypeDetailsModal.open(type),
            },
          ]}
          label="Ticket Type Details"
          description="Configure information about the ticket type"
          data={[
            {label: "Name", value: type.NAME},
            {label: "Type", value: type.TYPE},
            {label: "Enabled", value: "" + type.ENABLED, type: "bool"},
            {
              label: "Mobile Enabled",
              value: "" + type.MOBILE_ENABLED,
              type: "bool",
            },
            {
              label: "Requires Expo Completion",
              tooltip: "Tickets won't be fully completed until the expo ticket screen completes the ticket",
              type: "bool",
              value: type.EXPO_ENABLED,
            },
            {label: "Color", type: "color", value: type.COLOR},
            {label: "Date Created", type: "datetime", value: type.DATE_CREATED},
            {
              label: "Tip Splitting",
              value: type.SPLIT_TIPS,
              type: "bool",
            },
            {label: "Tax Disabled", value: type.TAX_DISABLED, type: "bool"},
            {
              label: "Auto Complete Tickets",
              value: type.AUTO_COMPLETE,
              type: "bool",
            },
            {
              label: "Begin Ticket in Progress",
              value: type.BEGIN_IN_PROGRESS,
              type: "bool",
            },
            {
              label: "Customer Fulfillment Instructions",
              tooltip:
                "These instructions tell a customer how to pick up their order at the shop. They display on the receipt screen after mobile checkout.",
              value: type.FULFILLMENT_INSTRUCTIONS ? type.FULFILLMENT_INSTRUCTIONS : "None",
              span: type.FULFILLMENT_INSTRUCTIONS,
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Notifications", id: "notifications"},
            {label: "Status Indicator", id: "status"},
          ]}
        >
          {(id) => {
            if (id === "notifications") {
              return (
                <TwoColumnList
                  buttons={[
                    {
                      label: "Edit Information",
                      onClick: () => this.ticketNotificationsDetailsModal.open(type, settings),
                    },
                  ]}
                  label="Ticket Notifications"
                  description="Configure custom text messages to be sent to customers for orders of this type"
                  data={notificationsArray}
                />
              );
            }

            if (id === "status") {
              let verbiageArray = getVerbiageArray(type, verbiages);

              let displayArray = [];

              for (let entryIndex in verbiageArray) {
                displayArray.push({
                  label: (
                    <div>
                      <div className="font-semibold">Status {parseInt(entryIndex) + 1}</div>
                      <div className="font-normal">Label</div>
                    </div>
                  ),
                  value: verbiageArray[entryIndex].LABEL,
                });

                displayArray.push({
                  label: <div className="mt-6">Description</div>,
                  value: "Your order " + verbiageArray[entryIndex].DESCRIPTION,
                });
              }

              return (
                <TwoColumnList
                  buttons={[
                    {
                      label: "Edit Statuses",
                      onClick: () => this.ticketVerbiageModal.open(type, verbiages),
                    },
                  ]}
                  label="Status Indicator Labels"
                  description="Customize the text displayed for each ticket step"
                  data={displayArray}
                />
              );
            }

            return <Card />;
          }}
        </Tab>
      </div>
    );
  }
}

export default withTranslation()(withRouter(TicketTypePage));
