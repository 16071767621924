import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";
import {usePopper} from "react-popper";
import React, {useEffect, useRef, useState} from "react";
import moment from "moment-timezone";
import Calendar from "../features/reporting/reporting-filter/calendar";
import ReactDOM from "react-dom";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const FormDateTimeSelect = (props) => {
  const {options, name, hideTime, hideDate, buttonText, flex, clearable} = props;

  const id = "cd_" + randomString(24);

  const formElementRef = useRef(null);

  const [showCalendar, setShowCalendar] = useState(false);

  const buttonRef = useRef(null);

  const popperElement = useRef(null);

  const {styles, attributes} = usePopper(buttonRef.current, popperElement.current, {
    placement: "bottom-start",
  });

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        formElementRef.current &&
        !formElementRef.current.contains(event.target) &&
        popperElement.current &&
        !popperElement.current.contains(event.target)
      ) {
        setShowCalendar(false);
      }
    }

    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCalendar]);

  const format = hideTime ? "M/D/YY" : hideDate ? "h:mm A" : "M/D/YY, h:mm A";

  const getButtonText = (value) => {
    return buttonText ? buttonText(value) : value ? moment(value).format(format) : "Click to select time";
  };

  const getCurrentMinutes = (value) => {
    return value && !hideTime ? moment(value).diff(moment(value).startOf("day"), "minutes") : 0;
  };

  const handleClear = () => {
    options.setFieldValue(name, null);

    setShowCalendar(false);
  };

  return (
    <div id={id} ref={formElementRef} className={flex && "flex-1"}>
      <FormElement {...props} ignoreShadow>
        {(value, error, onChange, onBlur, extraStyle, onChangeSoft) => {
          return (
            <>
              <button
                ref={buttonRef}
                type="button"
                onClick={() => setShowCalendar((prevState) => !prevState)}
                className={classNames(
                  flex && "w-full",
                  "inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                )}
              >
                {getButtonText(value)}
              </button>

              {!!clearable && (
                <button
                  className={
                    "inline-flex font-bold text-center mx-2 p-2  text-red-500 hover:text-red-700 focus:text-red-700 focus:ring-1 focus:ring-red-700 rounded"
                  }
                  onClick={handleClear}
                  type="button"
                >
                  <FontAwesomeIcon icon={"x"} />
                </button>
              )}

              {showCalendar &&
                ReactDOM.createPortal(
                  <div
                    ref={popperElement}
                    style={styles.popper}
                    {...attributes.popper}
                    className={"mt-1 h-72 z-50 bg-white flex flex-row"}
                  >
                    {!hideDate && (
                      <Calendar
                        className="pt-4"
                        initialEpochs={{
                          startEpoch: value,
                          endEpoch: value,
                        }}
                        singleDay
                        small
                        onChange={({startEpoch}) => {
                          const newEpoch = moment(startEpoch)
                            .startOf("day")
                            .add(getCurrentMinutes(value), "minutes")
                            .valueOf();

                          onChangeSoft && onChangeSoft(newEpoch);
                          if (options) {
                            options.setFieldValue(name, newEpoch);
                          } else {
                            onChange(newEpoch);
                          }
                        }}
                      />
                    )}

                    {!hideTime && (
                      <CalendarTimes
                        currentMinutes={getCurrentMinutes(value)}
                        value={value}
                        name={name}
                        options={options}
                        onChange={onChange}
                        onChangeSoft={onChangeSoft}
                        closeCalendar={() => setShowCalendar(false)}
                      />
                    )}
                  </div>,
                  formElementRef.current
                )}
            </>
          );
        }}
      </FormElement>
    </div>
  );
};

const CalendarTimes = ({currentMinutes, value, name, options, onChange, onChangeSoft, closeCalendar}) => {
  const populateTimes = () => {
    const MINUTES = [0, 15, 30, 45];

    const times = [];

    for (let hour = 0; hour < 24; hour++) {
      for (let minute of MINUTES) {
        times.push({hour, minute});
      }
    }

    times.push({hour: 23, minute: 59});

    return times;
  };

  const populatedTimes = populateTimes();

  const getMappedMinutes = ({hour, minute}) => {
    return hour * 60 + minute;
  };

  const getTimeDisplay = ({hour, minute}) => {
    const displayHour = hour % 12 || 12;

    const displayMinute = minute.toString().padStart(2, "0");

    const isAM = hour < 12;

    return `${displayHour}:${displayMinute} ${isAM ? "AM" : "PM"}`;
  };

  const handleTimeSelection = ({hour, minute}) => {
    const baseMoment = moment(value || undefined).startOf("day");

    const mappedMinutes = hour * 60 + minute;

    baseMoment.add(mappedMinutes, "minutes");

    onChangeSoft && onChangeSoft(baseMoment.valueOf());

    if (options) {
      options.setFieldValue(name, baseMoment.valueOf());
    } else {
      onChange(baseMoment.valueOf());
    }

    closeCalendar();
  };

  return (
    <div className="border-l w-24">
      <div className="mt-4 pb-2 w-full flex justify-center fixed sticky font-semibold border-b">Time</div>

      <div className="h-52 overflow-y-scroll flex flex-col cursor-pointer">
        {populatedTimes.map((time) => (
          <div
            key={`${time.hour}-${time.minute}`}
            onClick={() => handleTimeSelection(time)}
            className={classNames(
              "text-center text-sm py-0.5 px-2",
              currentMinutes === getMappedMinutes(time) ? "bg-indigo-500 text-white" : ""
            )}
          >
            {getTimeDisplay(time)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FormDateTimeSelect;
