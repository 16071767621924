import {Button, FormInput, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {PdfViewer} from "../../../../../components/pdf/pdf-viewer";
import React, {useEffect, useMemo, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useLocation, useNavigate} from "react-router-dom";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Row} from "../../../../../v2/components/shared";
import LoadingSpinner from "../../../../../components/loading-spinner";
import {DocumentAcknowledge} from "../../../../../forms/team/onboarding/form-document-acknowledge";
import {FormRadioSelect} from "../../../../../components/form-elements/form-radio-select";
import {DocumentFields} from "../../../../../forms/team/onboarding/form-document-fields";
import {BackButton} from "../BackButton";
import {useDocumentEdits, useDocumentSignedUrl} from "../../../../../hooks/team/onboarding/onboarding-hooks";
import {EDIT_TYPES, parseFetchedFields} from "./documents-helper";
import {
  useCreateDocument,
  useUpdateDocument,
} from "../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

export const EditDocumentPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const existingDocument = useMemo(() => location.state?.document, [location.state]);
  const uploadedFile = useMemo(() => location.state?.uploadedFile, [location.state]);

  const [currentPage, setCurrentPage] = useState(1);

  const {
    isLoading: isUrlLoading,
    error: isUrlError,
    data: signedUrl,
  } = useDocumentSignedUrl(existingDocument);

  const {
    isLoading: isEditsLoading,
    error: isEditsError,
    data: fetchedDocumentEdits,
  } = useDocumentEdits(existingDocument);

  const [addedFields, setAddedFields] = useState([]);

  const parseExistingDocumentName = () => {
    if (existingDocument) {
      return existingDocument?.NAME?.replace(/\.[^/.]+$/, "") ?? "";
    }

    return uploadedFile?.filename?.replace(/\.[^/.]+$/, "") ?? "";
  };

  const navigateBack = () => {
    if (!location.state?.origin) {
      return navigate(location.state?.from ?? "/onboarding/company-document");
    }
    navigate(location.state?.origin ?? "/onboarding/company-documents");
  };

  const navigatePostSubmit = () => {
    if (!location.state?.origin) {
      return navigate("/onboarding/company-documents");
    }

    navigate(location.state?.from ?? "/onboarding/company-documents", {
      state: {origin: location.state?.origin},
      replace: true,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      documentName: parseExistingDocumentName() ?? "",
      documentEdit: null,
      ackText: "I acknowledge that I have read and understand this document.",
    },
    validationSchema: Yup.object().shape({
      documentName: Yup.string()
        .required("Please enter a valid file name")
        .matches(
          /^[a-zA-Z0-9 _-]+$/,
          "File name can only contain letters, numbers, spaces, underscores, and hyphens, with no special characters or periods"
        ),
      documentEdit: Yup.string()
        .required("Please configure your document edits")
        .typeError("Please select a document configuration type"),
    }),
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    if (fetchedDocumentEdits) {
      formik.setFieldValue("documentEdit", fetchedDocumentEdits.EDIT_TYPE);

      if (fetchedDocumentEdits.EDIT_TYPE === EDIT_TYPES.ACKNOWLEDGE) {
        formik.setFieldValue("ackText", fetchedDocumentEdits.ACK_TEXT);
      } else if (fetchedDocumentEdits.EDIT_TYPE === EDIT_TYPES.FIELDS) {
        const formattedFields = fetchedDocumentEdits.FIELDS?.map((fetchedField) =>
          parseFetchedFields(fetchedField)
        );

        setAddedFields(formattedFields);
      }
    }
  }, [fetchedDocumentEdits]);

  const prepareEdits = (documentEdit, ackText) => {
    if (documentEdit === EDIT_TYPES.ACKNOWLEDGE) {
      return {ACK_TEXT: ackText};
    }

    if (documentEdit === EDIT_TYPES.FIELDS) {
      return {
        FIELDS: addedFields.map((field) => ({
          TYPE: field.TYPE,
          X_COORDINATE: field.X_COORDINATE,
          Y_COORDINATE: field.Y_COORDINATE,
          HEIGHT: field.HEIGHT,
          WIDTH: field.WIDTH,
          PAGE: field.PAGE,
          IS_REVIEWER: field.IS_REVIEWER ?? false,
        })),
      };
    }

    return {};
  };

  const {mutate: createDocument, isLoading: createMutationLoading} = useCreateDocument();
  const {mutate: updateDocument, isLoading: updateMutationLoading} = useUpdateDocument();

  const handleSubmit = async (values) => {
    const edits = prepareEdits(values.documentEdit, values.ackText);

    const payload = {
      DOCUMENT_NAME: values.documentName,
      TYPE: values.documentEdit,
      EDITS: edits,
      ...(existingDocument ? {DOCUMENT_ID: existingDocument.ID} : {URL: uploadedFile.url}),
    };

    if (existingDocument) {
      updateDocument({...payload, DOCUMENT_TYPE: existingDocument.TYPE});
    } else {
      createDocument(payload);
    }

    navigatePostSubmit();
  };

  const addField = (id, updatedFields) => {
    setAddedFields((prevFields) =>
      prevFields.map((field) => (field.ID === id ? {...field, ...updatedFields} : field))
    );
  };

  const updateFields = (field) => {
    const freshField = {
      ID: randomString(24),
      ...field,
      PAGE: currentPage,
      X: 0,
      Y: 0,
      HEIGHT: 33,
      WIDTH: 100,
    };

    setAddedFields((prevFields) => [...prevFields, freshField]);
  };

  const removeField = (id) => {
    setAddedFields((prevFields) => prevFields.filter((field) => field.ID !== id));
  };

  const getEditTypeOptions = () => {
    const editType = formik.values.documentEdit;

    switch (editType) {
      case EDIT_TYPES.ACKNOWLEDGE:
        return <DocumentAcknowledge name={"ackText"} label={"Acknowledgement Text"} options={formik} />;
      case EDIT_TYPES.FIELDS:
        return (
          <>
            <DocumentFields updateFields={updateFields} />

            <DocumentFields updateFields={updateFields} isReviewer={true} />
          </>
        );

      default:
        return <></>;
    }
  };

  const displayPdf = () => {
    if (!uploadedFile && isUrlLoading) {
      return (
        <div className={"flex items-center justify-center h-[70vh]"}>
          <LoadingSpinner />
        </div>
      );
    }

    if (!uploadedFile && isUrlError) {
      return (
        <div className="flex items-center justify-center h-[70vh]">
          <p>Error displaying the PDF. Please refresh your page to try again.</p>
        </div>
      );
    }

    if (uploadedFile || signedUrl) {
      return (
        <>
          <div className={"flex relative items-center justify-center"}>
            <div className={"inline-block overflow-y-auto overflow-x-auto"}>
              <PdfViewer
                url={uploadedFile?.signed_url ?? signedUrl}
                fields={addedFields ?? []}
                type={formik.values?.documentEdit}
                addField={addField}
                removeField={removeField}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <PageHeadings
        label={"Edit Document"}
        description={
          "Edit the document title, configure fields (e.g., signatures, dates), and add acknowledgment checkboxes. Editing will increment the document version, and update related packets with the latest version."
        }
        className={"py-4"}
      />

      <div className="mt-4 bg-white p-8 border border-gray-200 rounded-md">
        <div className={"flex items-stretch justify-between space-x-4"}>
          <div className={"w-3/4"}>{displayPdf()}</div>

          <form onSubmit={formik.handleSubmit} className="w-1/4 flex flex-col">
            <div
              className={
                "flex-grow h-full px-4 py-5 sm:px-6 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-15 rounded-md flex flex-col"
              }
            >
              <FormInput label="Document Name" name="documentName" options={formik} />

              <FormRadioSelect
                formik={formik}
                label={"Document Configuration"}
                sublabel={"Choose one of the following"}
                name="documentEdit"
                data={[
                  {
                    label: "Acknowledge Only",
                    value: EDIT_TYPES.ACKNOWLEDGE,
                    description:
                      "An acknowledgment checkbox will be provided at the end of the document that the employee can check to signify that they have read and agree to the document.",
                  },
                  {
                    label: "Form Fields",
                    value: EDIT_TYPES.FIELDS,
                    description:
                      "Name, data, signature, and text fields can be placed on the document to create an interactive form that the onboarding employee must fill out.",
                  },
                ]}
              />

              <div className={"mt-4"}>{getEditTypeOptions()}</div>
            </div>

            <Row className={"mt-2 flex flex-row w-full space-x-1"}>
              <BackButton className={"flex-1"} goBack={navigateBack} />

              <Button
                className={"flex-1 justify-center text-indigo-600 hover:text-white cursor-pointer"}
                label={"Save Changes"}
                type="submit"
                disabled={formik.isSubmitting || createMutationLoading || updateMutationLoading}
              />
            </Row>
          </form>
        </div>
      </div>
    </>
  );
};
