import {OnboardingDocumentsHeading} from "./onboarding-documents-heading";
import {Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import CustomDocumentsTab from "./tabs/custom-documents-tab";
import DefaultDocumentsTab from "./tabs/default-documents-tab";
import ArchivedDocumentsTab from "./tabs/archived-documents-tab";
import {useLocation, useNavigate} from "react-router-dom";

function OnboardingDocuments() {
  const navigate = useNavigate();
  const location = useLocation();

  const TAB_NAMES = {
    CUSTOM: "Custom",
    DEFAULT: "Default",
    ARCHIVED: "Archived",
  };

  const TABS = [
    {
      id: TAB_NAMES.CUSTOM,
      label: TAB_NAMES.CUSTOM,
    },
    {
      id: TAB_NAMES.DEFAULT,
      label: TAB_NAMES.DEFAULT,
    },
    {
      id: TAB_NAMES.ARCHIVED,
      label: TAB_NAMES.ARCHIVED,
    },
  ];

  const selectTab = (id) => {
    switch (id) {
      case TAB_NAMES.CUSTOM:
        return <CustomDocumentsTab />;
      case TAB_NAMES.DEFAULT:
        return <DefaultDocumentsTab />;
      case TAB_NAMES.ARCHIVED:
        return <ArchivedDocumentsTab />;
      default:
        return <CustomDocumentsTab />;
    }
  };

  const handleAddModalOpen = () => {
    navigate("/onboarding/create-document", {state: {from: location.pathname}});
  };

  return (
    <>
      <OnboardingDocumentsHeading handleAddDocument={handleAddModalOpen} />

      <Tab data={TABS}>{(id) => selectTab(id)}</Tab>
    </>
  );
}

export default OnboardingDocuments;
