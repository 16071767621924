import {useLocation, useNavigate} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {useEmployeeInReview} from "../../../hooks/team/onboarding/onboarding-hub-hooks";
import {Button, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import LoadingSpinner from "../../../components/loading-spinner";
import {showErrorNotification} from "../../../utils/notification-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PdfViewer} from "../../../components/pdf/pdf-viewer";
import {Row} from "../../../v2/components/shared";
import {BackButton} from "../../team/onboarding/functional/BackButton";
import {
  DOCUMENT_FED_CATEGORY,
  DOCUMENT_TYPE,
} from "../../team/onboarding/functional/documents/documents-helper";
import {useRegenerateDocument} from "../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {DOCUMENT_ERROR} from "../../team/onboarding/functional/error-messages";
import {ConfirmationModal} from "../../../modals/general/confirmation-modal";

export const InReviewDocumentPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const documentName = useMemo(() => location.state?.documentName, [location.state]);

  const documentUID = useMemo(() => location.state?.documentUID, [location.state]);

  const document = useMemo(() => location.state?.document, [location.state]);

  const inReview = useMemo(() => location.state?.inReview, [location.state]);

  const packetAssUId = useMemo(() => location.state?.packetAssignmentUId, [location.state]);

  const {isLoading, error, data} = useEmployeeInReview(document.DOCUMENT_ASS_UID);

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const navigateBack = () => {
    navigate(location.state?.from, {replace: true});
  };

  const {mutate: regenerateDocument, isLoading: regenerateLoading} = useRegenerateDocument(
    packetAssUId,
    document.DOCUMENT_ID
  );

  const handleRegenerateDocument = async () => {
    regenerateDocument(document.DOCUMENT_ASS_UID);

    let regeneratePath = `${document.DOCUMENT_ASS_UID}`;

    if (document.DOCUMENT_TYPE === DOCUMENT_TYPE.FEDERAL) {
      if (document.DOCUMENT_CATEGORY === DOCUMENT_FED_CATEGORY.I9) {
        regeneratePath = `federal/I9/${document.DOCUMENT_ASS_UID}`;
      }

      if (document.DOCUMENT_CATEGORY === DOCUMENT_FED_CATEGORY.W4) {
        regeneratePath = `federal/W4/${document.DOCUMENT_ASS_UID}`;
      }
    }

    setTimeout(() => {
      navigate(
        `/hub/employee-onboarding/packet/form/${regeneratePath}`,
        {
          state: {
            from: location.state?.from,
            documentName: documentName,
            documentUID: documentUID,
            packetAssignmentUId: packetAssUId,
          },
        },
        {replace: true}
      );
    }, 500);
  };

  const displayInstructions = () => {
    return (
      <div
        className={
          "flex flex-col h-full sm:px-6 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-15 rounded-md items-center justify-center gap-y-4"
        }
      >
        {inReview ? (
          <>
            <FontAwesomeIcon className={"text-yellow-400 h-8 w-8"} icon={"far fa-hourglass-half"} />

            <p className={"block text-lg font-semibold text-gray-700"}>In Review</p>
          </>
        ) : (
          <>
            <FontAwesomeIcon className={"text-green-600 h-8 w-8"} icon={"fas fa-check-circle"} />

            <p className={"block text-lg font-semibold text-gray-700"}>Completed</p>
          </>
        )}
      </div>
    );
  };

  const handlePdfDisplay = () => {
    if (isLoading) {
      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      showErrorNotification(DOCUMENT_ERROR, "Couldn't load document. Please refresh to try again.");

      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <FontAwesomeIcon icon="far fa-circle-exclamation" className={"mr-2 text-red-600"} />

          <span className={"text-red-600"}>Unable to load document</span>
        </div>
      );
    }

    if (data) {
      return (
        <div className={"flex items-stretch justify-between space-x-4"}>
          <div className={"w-3/4 flex flex-col items-center justify-center"}>
            <div className={"inline-block overflow-x-auto overflow-y-auto"}>
              <PdfViewer url={data} fields={[]} userView={true} />
            </div>
          </div>

          <div className={"flex flex-col w-1/4 gap-y-2"}>
            {displayInstructions()}

            <Row className={"mt-2 flex flex-row w-full space-x-1 "}>
              <BackButton className={"flex-1"} label="Back" goBack={navigateBack} />

              <Button
                className={"flex-1 justify-center"}
                type={"button"}
                label={"Regenerate Document"}
                onClick={() => setIsConfirmationModalOpen(true)}
                disabled={regenerateLoading}
              />
            </Row>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleModalClose={() => {
          setIsConfirmationModalOpen(false);
        }}
        modalLabel={"Regenerate Document Confirmation"}
        tooltip={"Regenerating the document will reset all fields and revert its status to 'Incomplete'."}
        confirmationAction={handleRegenerateDocument}
        confirmationMessage={
          "Are you sure you want to proceed? Regenerating the document will require it to be completed again."
        }
        isLoading={regenerateLoading}
      />

      <Card label={documentName} noHeaderBorder={true} className={" py-6 px-4"}>
        {handlePdfDisplay}
      </Card>
    </>
  );
};
