import {Card, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";
import {formatPacketStatus} from "../../team/onboarding/functional/dashboard/dashboard-helper";
import {useEmployeePackets} from "../../../hooks/team/onboarding/onboarding-hub-hooks";
import {useSelector} from "react-redux";
import {formatDate} from "../../team/onboarding/functional/documents/documents-helper";
import {getTableData} from "../../team/onboarding/functional/onboarding-helper";
import {useNavigate} from "react-router-dom";

export const OnboardingDashboardPage = () => {
  const navigate = useNavigate();

  const {account} = useSelector((state) => state.user);

  const {isLoading, error, data} = useEmployeePackets(account);

  console.log("dash data!!", data);

  const COLUMNS = [
    {
      label: "Packet Name",
      value: "PACKET_NAME",
    },
    {
      label: "Version",
      value: "PACKET_VERSION",
    },
    {
      label: "Status",
      value: "PACKET_STATUS",
      format: (status) => formatPacketStatus(status),
    },
    {
      label: "Due Date",
      value: "DUE_DATE",
      format: (value) => formatDate(value),
    },
  ];

  const displayAssignedPackets = (id) => {
    const filteredData = data?.filter((item) => item.COMPANY_UID === id)?.[0]?.PACKETS;

    return (
      <Card
        label={"Assigned Packets"}
        description={"Click on an assigned packet to view and complete documents within it"}
        className={"mt-6 border rounded-md"}
      >
        <Table
          pagination
          columns={COLUMNS}
          data={getTableData(isLoading, error, filteredData)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => {
                navigate(`/hub/employee-onboarding/packet/${row.UNIQUE_ID}`);
              },
            },
          ]}
        />
      </Card>
    );
  };

  const displayTabs = () => {
    const tabs = data?.map((item) => ({
      id: item.COMPANY_UID,
      label: item.COMPANY_NAME,
      badge: item.PACKETS?.length ?? 0,
    }));

    return <Tab data={tabs}>{(id) => displayAssignedPackets(id)}</Tab>;
  };

  return (
    <>
      <PageHeadings label={`Onboarding Dashboard`} description={""} />

      {data?.length > 1 ? displayTabs() : displayAssignedPackets(data?.[0]?.COMPANY_UID)}
    </>
  );
};
