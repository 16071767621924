import {faEnvelopeOpenDollar, faTableColumns, faFile} from "@fortawesome/pro-light-svg-icons";

export const EMPLOYEE_HEADER_MENU = [
  {
    label: "Payroll",
    routes: [
      {
        live: true,
        name: "Dashboard",
        description: "Update your information and see future paydays",
        url: "/hub/payroll",
        icon: faTableColumns,
      },
      {
        live: true,
        name: "Paystubs",
        description: "View all your paystubs",
        url: "/hub/payroll/paystubs",
        icon: faEnvelopeOpenDollar,
      },
      {
        live: true,
        name: "Tax Documents",
        description: "View all your tax documents",
        url: "/hub/payroll/tax-documents",
        icon: faEnvelopeOpenDollar,
      },
    ],
  },
  {
    label: "Onboarding",
    routes: [
      {
        live: true,
        name: "Dashboard",
        description: "View documents and packets that have been assigned to you",
        url: "/hub/employee-onboarding",
        icon: faTableColumns,
      },
      {
        live: true,
        name: "Documents",
        description: "View all your onboarding documents",
        url: "/hub/employee-onboarding/documents",
        icon: faFile,
      },
    ],
  },
];
