import {Button, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {useState} from "react";
import {useFormik} from "formik";
import {useLocation, useNavigate} from "react-router-dom";
import EmployeeFormDropZone from "../../../../../components/employee-form-drop-zone";
import {FileDropZoneWrapper} from "./file-drop-zone-wrapper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Row} from "../../../../../v2/components/shared";
import {BackButton} from "../BackButton";
import {fileUploadValidation} from "./documents-helper";

export const AddDocumentPage = () => {
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const location = useLocation();

  const onStartEditing = async () => {
    navigate("/onboarding/edit-document", {
      state: {from: location.state?.from, origin: location.state?.origin, uploadedFile: file.fileDetails},
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fileUpload: null,
      filename: "",
    },
    validationSchema: fileUploadValidation,
    onSubmit: onStartEditing,
  });

  const onDeleteFile = () => {
    formik.setFieldValue("fileUpload", null);
    formik.setFieldValue("filename", "");
    setFile(null);
  };

  const goBack = () => {
    navigate(location.state?.from ?? "/onboarding/company-documents", {
      state: {origin: location.state?.origin},
    });
  };

  return (
    <>
      <PageHeadings
        className={"py-4"}
        label={"Add Document"}
        description={"Start by uploading a company file and then continue to pdf editor"}
      />

      <form onSubmit={formik.handleSubmit}>
        <EmployeeFileUploader
          formik={formik}
          setFile={(file) => setFile(file)}
          onDeleteFile={onDeleteFile}
          endpoint={"onboarding/documents/custom/upload/custom"}
        />

        <Row className={"mt-4 justify-end"}>
          <BackButton goBack={goBack} />

          {file && (
            <Button
              label={"Start Editing"}
              type="submit"
              className="text-indigo-600 hover:text-white cursor-pointer"
              disabled={formik.isSubmitting}
            />
          )}
        </Row>
      </form>
    </>
  );
};

export const EmployeeFileUploader = ({formik, setFile, onDeleteFile, endpoint}) => {
  return (
    <div className="mt-4 bg-white p-12 border rounded-md border-gray-200">
      <div className={"w-3/5 mx-auto"}>
        {!formik.values.fileUpload ? (
          <EmployeeFormDropZone
            name="fileUpload"
            label={""}
            type={"PDF_ONLY"}
            endpoint={endpoint}
            onChangeSoft={(file) => {
              formik.setFieldValue("fileUpload", file.fileDetails);
              formik.setFieldValue("filename", file.name);
              setFile(file);
            }}
          />
        ) : (
          <div>
            <span className={"block font-medium text-lg text-indigo-500 pl-2 pr-5"}>
              Your file is uploaded!
            </span>

            <FileDropZoneWrapper>
              <FontAwesomeIcon icon="fa-regular fa-file-check" />

              <span className={"pl-2 pr-5"}>{formik.values.filename}</span>

              <button
                type="button"
                onClick={onDeleteFile}
                className={
                  "w-7 h-7 items-center justify-center hover:bg-gray-200 hover:rounded-full hover:shadow-sm"
                }
              >
                <FontAwesomeIcon icon="fa-solid fa-trash" />
              </button>
            </FileDropZoneWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
