import React, {Component} from "react";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import moment from "moment-timezone";
import {GUSTO_HARD_CODED_MAPS} from "../constants";
import {CSVLink} from "react-csv";

class DownloadGustoImport extends Component {
  state = {csvData: null};

  componentDidMount() {
    this.processImport();
  }

  processImport() {
    let {checkTemplateData, pastProvider} = this.props.payroll.importData;
    let importedPaystubs = [];

    if (pastProvider === "gusto-summary") {
      importedPaystubs = this.processSummary();
    } else {
      importedPaystubs = this.processPaystubs();
    }

    let {data} = checkTemplateData;

    let csvHeaders = Object.keys(data[0]);

    this.setState({csvData: importedPaystubs, csvHeaders});
  }

  processSummary() {
    let {exportData} = this.props.payroll.importData;
    let importedPaystubs = [];

    for (let row = 2; row < exportData.length; row++) {
      importedPaystubs.push(this.processPaystub(row, "", "", ""));
    }

    return importedPaystubs;
  }

  processPaystubs() {
    let {exportData} = this.props.payroll.importData;

    const importedPaystubs = [];

    for (let row = 2; row < exportData.length; row++) {
      if (exportData[row][0] === "Pay day") {
        importedPaystubs.push(...this.processPayroll(row));
      }
    }

    return importedPaystubs;
  }

  processPayroll(row) {
    let {exportData} = this.props.payroll.importData;

    let toReturn = [];

    let payday = moment(exportData[row][1]).format("YYYY-MM-DD");
    let periodStart = payday;
    let periodEnd = payday;

    try {
      if (exportData[row - 1][0] === "Payroll period") {
        const periodBreakdown = exportData[row - 1][1].split(" - ");

        periodStart = moment(periodBreakdown[0]).format("YYYY-MM-DD");
        periodEnd = moment(periodBreakdown[1]).format("YYYY-MM-DD");
      }

      row += 2;

      while (exportData[row][0].length > 0 && !PAYROLL_END_INDICATORS.includes(exportData[row][0])) {
        toReturn.push(this.processPaystub(row++, periodStart, periodEnd, payday));
      }
    } catch (e) {
      console.log("error ", row);
    }
    return toReturn;
  }

  processPaystub(row, periodStart, periodEnd, payday) {
    let {exportData, paycheckToCheckMapping, matchedEmployees, matchedWorkplaces} =
      this.props.payroll.importData;
    let entry = {
      "Period Start": periodStart,
      "Period End": periodEnd,
      Payday: payday,
    };

    entry["Employee ID"] = matchedEmployees[`${exportData[row][0]}, ${exportData[row][1]}`] ?? "";
    entry["Workplace ID"] = matchedWorkplaces[exportData[row][3]?.split(",")[0]] ?? "";

    for (let key of Object.keys(GUSTO_HARD_CODED_MAPS)) {
      entry[key] = GUSTO_HARD_CODED_MAPS[key](exportData[row]);
    }

    for (let mapKey of Object.keys(paycheckToCheckMapping)) {
      entry[mapKey] = exportData[row][paycheckToCheckMapping[mapKey].colOffset];
    }

    return entry;
  }

  render() {
    let {companyName} = this.props.payroll.importData;
    let {csvHeaders, csvData} = this.state;

    return (
      <div>
        {csvData && (
          <CSVLink filename={`${companyName}-import`} data={csvData} headers={csvHeaders}>
            <button
              type="button"
              className="ml-6 mb-2 inline-flex flex flex-col items-center px-2.5 py-1 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-600 bg-white hover:bg-gray-50"
              // onClick={this.downloadImport}
            >
              Download
            </button>
          </CSVLink>
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(DownloadGustoImport));

const PAYROLL_START_INDICATORS = ["Pay day", ""];
const PAYROLL_END_INDICATORS = ["Payroll Totals", ""];
