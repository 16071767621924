import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {OnboardingHeadingLabel} from "../onboarding-heading-label";

function OnboardingDashboardHeading({handleAssignPacket, handleAddDocument, handleCreatePacket}) {
  return (
    <PageHeadings
      label={<OnboardingHeadingLabel label={"Dashboard"} />}
      description={
        "Create onboarding packets and documents to assign to employees for hiring or training. Manage assigned packets and track progress."
      }
      buttons={[
        {
          theme: "primary",
          label: "New",
          icon: "plus",
          type: "list",
          onChange: ({id}) => {
            if (id === "document") {
              handleAddDocument();
            }

            if (id === "packet") {
              handleCreatePacket();
            }
          },
          items: [
            {
              id: "document",
              label: "Add Document",
              description: "Create a custom document for use in an onboarding packet",
            },
            {
              id: "packet",
              label: "Create Packet",
              description: "Create a custom onboarding packet for employees",
            },
          ],
        },
        {
          label: "Assign Packet",
          onClick: handleAssignPacket,
        },
      ]}
    />
  );
}

export default OnboardingDashboardHeading;
