import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";

import moment from "moment-timezone";

import {Loading, PageHeadings, TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import FundraiserDetailsModal from "../../../modals/marketing/fundraiser-details-modal";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class FundraiserPage extends Component {
  state = {isLoading: true, fundraiser: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;
    this.setState({isLoading: true});

    let fundraisers = await request("marketing/fundraisers", "GET");
    fundraisers = fundraisers.filter((item) => {
      return item.UNIQUE_ID === id;
    });

    if (fundraisers.length !== 1) {
      console.log("Houston, we have a problem");
      return;
    }

    const fundraiser = fundraisers[0];

    this.setState({isLoading: false, fundraiser});
  }

  render() {
    const {isLoading, fundraiser} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <FundraiserDetailsModal
          ref={(e) => {
            this.editModal = e;
          }}
          updateState={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label={fundraiser.NAME}
          description={`Information about the "${fundraiser.NAME}" fundraiser`}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.editModal.open(fundraiser);
              },
            },
          ]}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Fundraisers Page", url: "/fundraisers"},
          ]}
        />

        <TwoColumnList
          data={[
            {label: "Name", value: fundraiser.NAME},
            {
              label: "Amount Raised",
              value: `$${toDollars(fundraiser.AMOUNT_RAISED)}`,
            },
            {
              label: "Date Started",
              value: moment(fundraiser.DATE_START).format("MM/DD/YY"),
            },
            {
              label: "Date Ended",
              value: moment(fundraiser.DATE_END).format("MM/DD/YY"),
            },
            {
              label: "Icon",
              value: (
                <img
                  className="h-20 w-20 mt-4"
                  src={getObjectImage(fundraiser, "LOGO", "appicon.png")}
                  alt=""
                />
              ),
            },
          ]}
        />
      </div>
    );
  }
}

export default withTranslation()(withRouter(FundraiserPage));
