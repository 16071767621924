import {useReviewDocument, useSendForReview} from "./onboarding-mutate-hooks";
import {loadPDFDocument, saveFormsToPdf} from "../../../pages/hub/onboarding/federal/fillable-pdf-helpers";
import {showErrorNotification} from "../../../utils/notification-helper";
import {
  DOCUMENT_ERROR,
  DOCUMENT_SUBMISSION_ERROR_MESSAGE,
} from "../../../pages/team/onboarding/functional/error-messages";

const genericHandleSubmit = async (
  documentAssignmentId,
  values,
  setFields,
  navigateBack,
  reviewFn,
  isApproved = false
) => {
  const {pdf, form, font} = await loadPDFDocument(documentAssignmentId);

  if (form) {
    setFields(values, form, font, pdf.getPages()[0]);
    const pdfBytes = await saveFormsToPdf(pdf);

    await reviewFn({
      id: documentAssignmentId,
      payload: {
        FILE: pdfBytes,
        ...(isApproved && {IS_APPROVED: isApproved}),
      },
    });
  } else {
    showErrorNotification(DOCUMENT_ERROR, DOCUMENT_SUBMISSION_ERROR_MESSAGE);
  }

  navigateBack();
};

export const useSubmitDocument = (packetAssignmentUId) => {
  const {mutateAsync: sendForReview, isLoading: reviewLoading} = useSendForReview(packetAssignmentUId);

  const handleSubmit = async (documentAssignmentId, values, setFields, navigateBack) => {
    await genericHandleSubmit(documentAssignmentId, values, setFields, navigateBack, sendForReview);
  };

  return {handleSubmit, reviewLoading};
};

export const useVerificationSubmit = (documentAssignmentId) => {
  const {mutateAsync: reviewDocument, isLoading: reviewLoading} = useReviewDocument();

  const handleSubmit = async (documentAssignmentId, values, setFields, navigateBack) => {
    await genericHandleSubmit(documentAssignmentId, values, setFields, navigateBack, reviewDocument, true);
  };

  return {handleSubmit, reviewLoading};
};
