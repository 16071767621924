import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";
import {OnboardingHeadingLabel} from "../onboarding-heading-label";

export const OnboardingDocumentsHeading = ({handleAddDocument}) => {
  return (
    <PageHeadings
      label={<OnboardingHeadingLabel label={"Company Documents"} />}
      description={
        "Upload new files, manage existing documents, and stay organized by viewing, editing, or archiving your company records"
      }
      buttons={[
        {
          label: "Add Document",
          onClick: () => handleAddDocument(),
        },
      ]}
    />
  );
};
