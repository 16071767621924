import React, {Component} from "react";
import {
  FormBoolean,
  FormEmail,
  FormInput,
  FormPhone,
  FormSelect,
  Modal,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import LocationDropdown from "../../../dropdowns/team/locations-dropdown";
import {
  decimalToDollars,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import RoleDropdown from "../../../dropdowns/team/role-dropdown";
import * as Yup from "yup";
import {COMPENSATION_TYPES} from "../../../utils/team-constants";
import DepartmentDropdown from "../../../dropdowns/team/department-dropdown";
import EmployeeDropdown from "../../../dropdowns/team/employee-dropdown";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import moment from "moment-timezone";
import FormRow from "../../../components/form-row";
import Accordion from "../../../components/form-elements/accordion";
import {setupReduxConnection} from "../../../redux";
import {getIsShowBackDate} from "../../../utils/messaging/pay-helper";

class EmployeeDetailsModal extends Component {
  state = {employee: null};

  open(employee) {
    this.setState({employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveEmployee({
    name,
    email,
    location,
    locations,
    scheduleLocations,
    notificationLocations,
    pin,
    start,
    manager,
    managerName,
    managerUnique,
    department,
    departmentName,
    departmentUnique,
    compensation,
    salaryAmount,
    defaultHourlyRate,
    hourlyMethod,
    backdate,
    backdateDate,
  }) {
    const {employee} = this.state;

    const dataLocation = this.locationRef.fetchLocation();

    if (compensation === "HOUR" && hourlyMethod === HOURLY_METHOD_TYPES.PAY_RATES) {
      defaultHourlyRate = null;
    }

    if (!name) {
      name = "";
    }

    if (!email) {
      email = null;
    }

    const employeePayload = {
      FULL_NAME: name,
      EMAIL: email,
      ACTIVE: employee.ACTIVE,
      ROLE_ID: employee.ROLE_ID,
      MANAGER_EMPLOYEE_ID: manager,
      LOCATION_ID: parseInt(location),
      PIN: "" + pin,
      DEPARTMENT_ID: department,
      SCHEDULE_LOCATIONS: scheduleLocations,
      NOTIFICATION_LOCATIONS: notificationLocations,
      LOCATIONS: locations,
      DATE_STARTED: start,
      TYPE: compensation,
      RATE: null,
      BACKDATE: parseInt(backdate),
      BACKDATE_DATE: backdateDate,
    };

    let serverEmployee;
    let rate;

    if (compensation === COMPENSATION_TYPES.SAL.VALUE) {
      rate = decimalToDollars(salaryAmount);
      employeePayload.RATE = rate;
    } else {
      rate = decimalToDollars(defaultHourlyRate);
      employeePayload.RATE = rate;
    }

    try {
      serverEmployee = await request("employees/" + employee.ID, "PUT", employeePayload);
    } catch ({error}) {
      if (error.data?.message === "PIN_NOT_UNIQUE_TO_COMPANY") {
        // await SweetAlert.fire({
        //   title: "Duplicate Pin #",
        //   text: "An employee in your company with this pin number already exists.",
        //   icon: "warning",
        // });
        alert(`Employee found with duplicate PIN: ${error.data?.employee?.FULL_NAME}`);
      } else {
        alert("Error Saving Employee. Please try again or contact support.");
      }

      return this.modal.stopLoading();
    }

    this.props.updateState({
      ...employee,
      ...employeePayload,
      MANAGER_EMPLOYEE_UNIQUE_ID: managerUnique,
      MANAGER_EMPLOYEE_NAME: managerName,
      DEPARTMENT_UNIQUE_ID: departmentUnique,
      DEPARTMENT_NAME: departmentName,
      LOCATION_NAME: dataLocation.label,
      LOCATION_ID: parseInt(location),
      LOCATIONS: serverEmployee.LOCATIONS,
      SCHEDULE_LOCATIONS: serverEmployee.SCHEDULE_LOCATIONS,
      NOTIFICATION_LOCATIONS: serverEmployee.NOTIFICATION_LOCATIONS,
      PIN: pin,
      TYPE: compensation,
      RATE: rate,
    });

    this.modal.close();
  }

  async createEmployee({
    name,
    start,
    role,
    phone,
    email,
    pin,
    manager,
    department,
    compensation,
    salaryAmount,
    defaultHourlyRate,
    hourlyMethod,
    location,
    locations,
    scheduleLocations,
    notificationLocations,
  }) {
    phone = "1" + phone.replaceAll(" ", "");

    if (compensation === "HOUR" && hourlyMethod === HOURLY_METHOD_TYPES.PAY_RATES) {
      defaultHourlyRate = null;
    }

    let employeePayload = {
      PIN: "" + pin,
      FULL_NAME: name,
      ROLE_ID: role,
      EMAIL: email,
      PHONE: phone,
      DATE_STARTED: start,
      MANAGER_EMPLOYEE_ID: manager,
      DEPARTMENT_ID: department,
      TYPE: compensation,
      RATE: null,
      LOCATION_ID: location,
      SCHEDULE_LOCATIONS: scheduleLocations,
      NOTIFICATION_LOCATIONS: notificationLocations,
      LOCATIONS: locations,
    };

    let serverEmployee;
    let rate;

    if (compensation === COMPENSATION_TYPES.SAL.VALUE) {
      rate = decimalToDollars(salaryAmount);
      employeePayload.RATE = rate;
    } else {
      rate = decimalToDollars(defaultHourlyRate);
      employeePayload.RATE = rate;
    }

    try {
      serverEmployee = await request("employees", "POST", employeePayload);
    } catch ({error}) {
      if (error.data?.message === "PIN_NOT_UNIQUE_TO_COMPANY") {
        alert(`Employee found with duplicate PIN: ${error.data?.employee?.FULL_NAME}`);
      } else {
        alert("Error Saving Employee. Please try again or contact support.");
      }

      return this.modal.stopLoading();
    }

    this.props.addState(serverEmployee);
    this.modal && this.modal.close();
  }

  render() {
    const {employee} = this.state;
    const {location} = this.props.shop;

    const initialHourlyMethod = !employee?.RATE ? HOURLY_METHOD_TYPES.PAY_RATES : HOURLY_METHOD_TYPES.DEFAULT;

    let initialValues = {
      name: employee?.FULL_NAME ?? "",
      phone: employee?.PHONE.slice(1) ?? "",
      email: employee?.EMAIL ?? "",
      location: employee?.LOCATION_ID ?? location?.ID,
      locations:
        employee?.LOCATIONS?.filter(({LOCATION_ID}) => LOCATION_ID !== employee?.LOCATION_ID).map(
          (_location) => _location.LOCATION_ID
        ) ?? [],
      scheduleLocations:
        employee?.SCHEDULE_LOCATIONS?.filter(({LOCATION_ID}) => LOCATION_ID !== employee?.LOCATION_ID).map(
          (_location) => _location.LOCATION_ID
        ) ?? [],
      notificationLocations:
        employee?.NOTIFICATION_LOCATIONS?.filter(
          ({LOCATION_ID}) => LOCATION_ID !== employee?.LOCATION_ID
        ).map((_location) => _location.LOCATION_ID) ?? [],
      start: employee?.DATE_STARTED,
      role: employee?.ROLE_ID ?? null,
      pin: employee?.PIN ?? "",
      compensation: employee?.TYPE ?? COMPENSATION_TYPES.HOUR.VALUE,
      manager: employee?.MANAGER_EMPLOYEE_ID ?? null,
      managerName: employee?.MANAGER_EMPLOYEE_NAME,
      managerUnique: employee?.MANAGER_EMPLOYEE_UNIQUE_ID,
      department: employee?.DEPARTMENT_ID ?? null,
      departmentName: employee?.DEPARTMENT_NAME,
      departmentUnique: employee?.DEPARTMENT_UNIQUE_ID,
      hourlyMethod: initialHourlyMethod,
      backdate: "0",
    };
    if (employee?.TYPE === COMPENSATION_TYPES.SAL.VALUE && employee?.RATE) {
      initialValues.salaryAmount = toDollars(employee?.RATE);
    } else if (employee?.TYPE === COMPENSATION_TYPES.HOUR.VALUE && employee?.RATE) {
      initialValues.defaultHourlyRate = toDollars(employee?.RATE);
    }

    let validationShape = {
      name: Yup.string().required("Name is required"),
      phone: Yup.number().required("Phone is required"),
      email: Yup.string().required("Email is required"),
      pin: Yup.string()
        .required("Pin is required")
        .matches(/^\d{4,8}$/, "Pin must be between 4 and 8 digits"),
      salaryAmount: Yup.string().when(["compensation"], {
        is: (compensation) => compensation === COMPENSATION_TYPES.SAL.VALUE,
        then: Yup.string()
          .test("len", "Salary must be greater than $1,000", (val) => decimalToDollars(val) > 100000)
          .required("Salary amount is required"),
      }),
      defaultHourlyRate: Yup.string().when(["compensation", "hourlyMethod"], {
        is: (compensation, hourlyMethod) =>
          compensation === COMPENSATION_TYPES.HOUR.VALUE && hourlyMethod === HOURLY_METHOD_TYPES.DEFAULT,
        then: Yup.string().required("Default hourly rate is required"),
      }),
      backdateDate: Yup.number()
        .nullable()
        .test("", "Please enter a valid date", (val, ctx) => {
          return ctx.parent.backdate === "0" ? true : !isNaN(val);
        }),
    };

    if (!employee) {
      validationShape.role = Yup.number().typeError("Role is required").required("Role is required");
    }

    return (
      <Modal
        buttonLabel={employee ? "Save" : "Add"}
        label={employee ? "Edit Employee" : "Create Employee"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        large
      >
        <Formik
          onSubmit={employee ? this.saveEmployee.bind(this) : this.createEmployee.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationShape)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue, values} = formikOptions;
            let {compensation, hourlyMethod, backdate, defaultHourlyRate, salaryAmount} =
              formikOptions.values;

            const isShowBackDate = getIsShowBackDate(
              employee,
              compensation,
              hourlyMethod,
              defaultHourlyRate,
              salaryAmount
            );

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name="name" placeholder="Donald Draper" options={formikOptions} />

                <FormRow>
                  <FormPhone
                    label="Phone"
                    name="phone"
                    placeholder="(781) 583-3699"
                    options={formikOptions}
                    disabled={!!employee}
                    tooltip={{
                      data: "Please contact Dripos support to change an employee's phone number!",
                    }}
                    flex
                  />

                  <FormEmail
                    label="Email"
                    name="email"
                    placeholder="draper@dripos.com"
                    options={formikOptions}
                    flex
                  />
                </FormRow>

                <FormInput
                  label="Pin"
                  name="pin"
                  placeholder="1234"
                  type={"text"}
                  tooltip={{
                    label: "PIN",
                    data: "A unique PIN given to each employee that uniquely identifies them.",
                  }}
                  options={formikOptions}
                  buttonHint={{
                    label: "Random Pin",
                    onClick: () => {
                      setFieldValue("pin", randomString(4, "1234567890"));
                    },
                  }}
                />

                {!employee && <RoleDropdown label="Default Role" options={formikOptions} name="role" />}

                <Accordion label="Locations" className="mt-4">
                  <LocationDropdown
                    label="Home Location"
                    name="location"
                    ref={(e) => (this.locationRef = e)}
                    options={formikOptions}
                    flex
                  />

                  <LocationDropdown
                    label="Additional Home Locations"
                    name="locations"
                    hint="optional"
                    // ref={(e) => (this.locationRef = e)}
                    options={formikOptions}
                    tooltip={
                      "Gives employees permissions to the following locations, in addition to their home location."
                    }
                    multi
                    showNonStores={true}
                    flex
                    exclude={[values.location]}
                  />

                  <LocationDropdown
                    label="Additional Scheduling Locations"
                    name="scheduleLocations"
                    hint="optional"
                    // ref={(e) => (this.locationRef = e)}
                    options={formikOptions}
                    tooltip={"Allows employee to be scheduled on the following locations."}
                    multi
                    flex
                    exclude={[values.location]}
                  />

                  <LocationDropdown
                    label="Notification Locations"
                    name="notificationLocations"
                    hint="optional"
                    // ref={(e) => (this.locationRef = e)}
                    options={formikOptions}
                    tooltip={
                      "Sends employee notifications from the following locations (trade requests and late clock in reminders)."
                    }
                    multi
                    flex
                    exclude={[values.location]}
                  />
                </Accordion>

                <Accordion label="Compensation" className="mt-4">
                  <FormSelect
                    label="Compensation Type"
                    name="compensation"
                    data={[
                      {
                        label: "Hourly",
                        value: "HOUR",
                      },
                      {
                        label: "Salary",
                        value: "SAL",
                      },
                    ]}
                    options={formikOptions}
                    flex
                  />

                  {compensation === COMPENSATION_TYPES.SAL.VALUE && (
                    <FormInput
                      label="Yearly Salary Amount (in $)"
                      name={"salaryAmount"}
                      options={formikOptions}
                      flex
                    />
                  )}

                  {compensation === COMPENSATION_TYPES.HOUR.VALUE && (
                    <FormSelect
                      label={"Hourly Pay Calculation"}
                      name={"hourlyMethod"}
                      options={formikOptions}
                      data={[
                        {
                          label: "Role Based Rate",
                          value: HOURLY_METHOD_TYPES.PAY_RATES,
                        },
                        {
                          label: "Employee Specific Rate",
                          value: HOURLY_METHOD_TYPES.DEFAULT,
                        },
                      ]}
                      flex
                    />
                  )}

                  {compensation === COMPENSATION_TYPES.HOUR.VALUE &&
                    hourlyMethod === HOURLY_METHOD_TYPES.DEFAULT && (
                      <FormInput
                        label={"Default Hourly Rate (in $)"}
                        name="defaultHourlyRate"
                        options={formikOptions}
                        tooltip={{
                          label: "Default Rate",
                          data: "Employee's hourly rate that will be used to calculate pay",
                        }}
                      />
                    )}

                  {employee && isShowBackDate && (
                    <FormBoolean
                      label={"Backdate Pay"}
                      name="backdate"
                      options={formikOptions}
                      tooltip={
                        "Recalculates existing time card pay based on pay rate changes, starting from a specified date"
                      }
                      flex
                    />
                  )}

                  {employee && isShowBackDate && backdate === "1" && (
                    <FormDateTimeSelect
                      label={"Backdate Start Date"}
                      name="backdateDate"
                      hideTime
                      flex
                      options={formikOptions}
                    />
                  )}
                </Accordion>

                <Accordion label="Optional Settings" className="mt-4">
                  <FormDateTimeSelect
                    options={formikOptions}
                    label="Start Date"
                    buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                    name="start"
                    hideTime
                    flex
                  />

                  <DepartmentDropdown
                    allowNull
                    hint="optional"
                    placeholder="Select a department"
                    options={formikOptions}
                    name="department"
                    onChangeSoft={({label, unique}) => {
                      setFieldValue("departmentName", label);
                      setFieldValue("departmentUnique", unique);
                    }}
                    flex
                  />

                  <EmployeeDropdown
                    allowNull
                    hint="optional"
                    name="manager"
                    label="Manager"
                    options={formikOptions}
                    onChangeSoft={({label, unique}) => {
                      setFieldValue("managerName", label);
                      setFieldValue("managerUnique", unique);
                    }}
                    flex
                  />
                </Accordion>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export const HOURLY_METHOD_TYPES = {
  DEFAULT: "DEFAULT",
  PAY_RATES: "PAY_RATES",
};

export default setupReduxConnection(["shop"])(EmployeeDetailsModal);
