import React, {Component} from "react";
import {Card, PageHeadings, Table, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import PostTaxDeductionModal from "../../../modals/payroll/post-tax-modal";
import LoadingSpinner from "../../../components/loading-spinner";
import moment from "moment-timezone";
import GrayBadge from "../../../components/badges/gray-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {PayrollRequests} from "../../../utils/request-helpers/payroll/payroll-requests";

class PostTaxDeductionsPage extends Component {
  state = {postTaxDeductions: null, isLoading: true};

  async componentDidMount() {
    await this.syncState();
  }

  _formatDeduction(deduction) {
    const startDate = moment(deduction.start_date).startOf("day");
    const currentDate = moment();

    if (deduction.description === deductionTypeToString(deduction.type)) {
      deduction.description = null;
    }
    deduction.active = true;

    if (deduction.miscellaneous) {
      if (!deduction.effective_end) {
        deduction.miscellaneous.frequency = "recurring_indefinitely";
      } else {
        const effectiveEndDate = moment(deduction.effective_end).endOf("day");
        const deductionDuration = deduction.effective_end - deduction.effective_start;
        if (!currentDate.isBetween(startDate, effectiveEndDate) || deductionDuration < 86400000) {
          deduction.active = false;
        }
        deduction.miscellaneous.frequency = deductionDuration < 86400000 ? "one_time" : "recurring";
      }
    }

    return deduction;
  }

  syncState = async () => {
    let postTaxDeductions = await PayrollRequests.fetchPostTaxDeductions();
    postTaxDeductions = postTaxDeductions.map((deduction) => this._formatDeduction(deduction));
    this.setState({postTaxDeductions, isLoading: false});
  };

  renderTable = (data) => {
    return (
      <Filter
        className={"-m-0"}
        defaultFilters={[{filter: "active", id: true}]}
        data={[
          {
            id: "active",
            label: "Status",
            type: "check",
            onFilter: (options, data) => {
              return data.filter((item) => options.includes(item.active));
            },
            options: [
              {label: "Active", id: true},
              {label: "Inactive", id: false},
            ],
          },
        ]}
      >
        {(filters) => {
          return (
            <Table
              className={"-m-0"}
              ref={(e) => (this.tableRef = e)}
              actionButtons={[
                {
                  label: "View",
                  onClick: (row) => {
                    this.addPostTaxModal.openEdit(row);
                  },
                },
              ]}
              pagination
              data={data}
              filters={filters}
              columns={[
                {
                  label: "Employee",
                  value: "employee_name",
                },
                {
                  label: "Type",
                  value: "type",
                  format: (value) => deductionTypeToString(value),
                },
                {
                  label: "Start Date",
                  value: "effective_start",
                  format: (value) => moment(value, "YYYY-MM-DD").format("MMM DD, YYYY"),
                },
                {
                  label: "Description",
                  value: "description",
                },
                {
                  label: "Active",
                  value: "active",
                  format: (value) => {
                    if (value) {
                      return (
                        <SuccessBadge darkGrey className={"ml-3"}>
                          Active
                        </SuccessBadge>
                      );
                    }
                    return (
                      <GrayBadge darkGrey className={"ml-3"}>
                        Inactive
                      </GrayBadge>
                    );
                  },
                },
              ]}
            />
          );
        }}
      </Filter>
    );
  };

  render() {
    let {postTaxDeductions, isLoading} = this.state;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <PostTaxDeductionModal refresh={this.syncState} ref={(e) => (this.addPostTaxModal = e)} />

        <PageHeadings label={"Post Tax Deductions"} />

        <Card
          label={"Post Tax Deductions"}
          description={
            "Earnings withheld from an employee's paycheck after all applicable taxes have been subtracted."
          }
          buttons={[
            {
              label: "Add",
              onClick: () => this.addPostTaxModal.openAdd(null),
            },
          ]}
        >
          {this.renderTable(postTaxDeductions)}
        </Card>
      </div>
    );
  }
}

export default withRouter(PostTaxDeductionsPage);

export function deductionTypeToString(type) {
  return type
    .split("_")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}
