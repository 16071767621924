import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";
import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import {SearchableTable} from "../../../v2/components/Table";
import {showErrorNotification} from "../../../utils/notification-helper";

const convertToDisplayUnitQuantity = (ingredient, quantity = 0) => {
  const displayUnitId = ingredient.DISPLAY_UNIT_ID;

  if (displayUnitId !== ingredient.UNIT_ID) {
    const conversion = ingredient.UNIT?.CONVERSIONS?.find((_conversion) => {
      return _conversion.TO_UNIT === displayUnitId;
    });

    return (quantity * conversion?.RATE).toFixed(2);
  }

  return quantity;
};

class SupplyChainInventoryTable extends Component {
  columns = (allCategories) => [
    {
      key: "NAME",
      label: "Ingredient",
      headerClassName: "w-64",
      formatRow: (row) => {
        const outOfStock = row.DISPLAY_STOCK <= 0;
        return (
          <>
            {outOfStock && <FontAwesomeIcon icon="fa-solid fa-circle-exclamation" />} {row.NAME}
          </>
        );
      },
      csvFormat: (row) => row.NAME,
    },
    {
      key: "DISPLAY_STOCK",
      label: "Current Inventory",
      headerClassName: "w-32",
      formatRow: (row) => {
        return `${row.DISPLAY_STOCK} ${row.DISPLAY_UNIT?.NAME ?? row.UNIT?.NAME ?? ""}`;
      },
      filterOptions: [
        {value: 1, label: "In Stock"},
        {value: 0, label: "Out of Stock"},
      ],
      filterFunction: (row, value) => {
        if (value === 1) {
          return row.DISPLAY_STOCK > 0;
        }
        return row.DISPLAY_STOCK <= 0;
      },
    },
    {
      key: "COST",
      label: "Default Cost",
      headerClassName: "w-32",
      type: "dollars",
      editable: true,
      onChange: (val, row, thing) => this.handleCostChange(val, row, thing),
      formatRow: (row) => `$${row.COST} per ${row?.UNIT?.NAME ?? "Unit"}`,
    },
    {
      key: "CATEGORIES",
      label: "Groups",
      headerClassName: "w-32",
      format: (categories = []) => {
        return categories.length === 0 ? "-" : categories.map((category) => category.NAME).join(", ");
      },
      filterOptions: allCategories.map(({NAME, ID}) => ({value: NAME, label: NAME})),
      filterFunction: (row, value) => {
        return row.CATEGORIES.some((category) => category.NAME === value);
      },
    },
    {
      key: "DATE_UPDATED",
      label: "Edited On",
      headerClassName: "w-32",
      format: (val) => moment(val).format("MMM Do, YYYY"),
      sortable: true,
    },
  ];

  handleCostChange(ingredient, key, value) {
    if (ingredient.CHILDREN.length > 0) {
      showErrorNotification(
        "Ingredient default cost is not configurable",
        ingredient.NAME + "'s default cost is calculated based off its sub-recipes."
      );
      return;
    }
    const {updateState} = this.props;
    updateState({[ingredient.ID]: {COST: value}});
  }

  render() {
    const {data: ingredients, actionButtons} = this.props;
    const {categories} = this.props.supply;
    return (
      <SearchableTable
        columns={this.columns(categories)}
        data={ingredients}
        searchableColumns={["NAME"]}
        exportFile={`Supply Chain Report ${new Date().toLocaleDateString()}`}
        buttons={actionButtons}
      />
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(SupplyChainInventoryTable));
