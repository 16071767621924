import {request} from "../utils/request";
import {getStore} from "./index";

const defaultState = {
  otherPayments: [],
  categories: [],
  orderTypes: [],
  products: [],
  activeProduct: null,
};

const UPDATE_MENU = "UPDATE_MENU";
const UPDATE_PRODUCTS = "UPDATE_PRODUCTS";
const UPDATE_ORDER_TYPES = "UPDATE_ORDER_TYPES";
const UPDATE_ACTIVE_PRODUCT = "UPDATE_ACTIVE_PRODUCT";

export function loadMenu() {
  return async (dispatch) => {
    dispatch(updateProducts());
    dispatch(updateOrderTypes());
  };
}

export function updateMenu(payload) {
  return {type: UPDATE_MENU, payload};
}

export function updateProducts() {
  return async (dispatch) => {
    const products = await request("products/", "GET");
    dispatch(setProducts(products));
  };
}

export function updateOrderTypes() {
  return async (dispatch) => {
    const orderTypes = await request("types/order", "GET");
    dispatch(setOrderTypes(orderTypes));
  };
}

export function setProducts(payload) {
  return {type: UPDATE_PRODUCTS, payload};
}

export function updateActiveProduct() {
  return async (dispatch) => {
    const activeProduct = getStore().getState().menu.activeProduct;
    const product = await request("product/" + activeProduct.ID, "GET");
    dispatch(setProduct(product));
  };
}

export function setProduct(payload) {
  return {type: UPDATE_ACTIVE_PRODUCT, payload};
}

export function setOrderTypes(payload) {
  return {type: UPDATE_ORDER_TYPES, payload};
}

export const menuReducer = (state = defaultState, action) => {
  let {type, payload} = action;

  switch (type) {
    default:
      return state;
    case UPDATE_ORDER_TYPES:
      return {...state, orderTypes: payload};
    case UPDATE_PRODUCTS:
      return {...state, products: payload};
    case UPDATE_ACTIVE_PRODUCT:
      return {...state, activeProduct: payload};
    case UPDATE_MENU:
      return {...state, ...payload};
  }
};
