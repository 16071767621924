import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import {withRouter} from "../../utils/navigation";
import {POLICY_TYPE} from "../../utils/constants";
import {getPolicyWorkedHours} from "../../utils/time-off-helper";

class TimeOffCard extends Component {
  render() {
    const {policy, onClick} = this.props;
    const actionButtons = [];
    const policyData = [];

    if (onClick) {
      actionButtons.push({
        label: "Edit Information",
        onClick: onClick,
      });
    }

    policy.CATEGORIES.sort((a, b) => {
      return a.SEQ - b.SEQ;
    });

    policyData.push(
      {label: "Name", value: policy.NAME},
      {label: "Type", value: POLICY_TYPE[policy.TYPE]},
      {
        label: "Request Categories",
        value: policy.CATEGORIES.map((item) => {
          return item.NAME + (item.ADVANCED_NOTICE > 0 ? " (" + item.ADVANCED_NOTICE + " day notice)" : "");
        }).join(", "),
      },
      {label: "Request Approvals", value: policy.REVIEW_REQUESTS, type: "bool"},
      {label: "Paid Time Off", value: policy.PAID, type: "bool"},
      {label: "Paid Dismissal", value: policy.PAID_DISMISSAL, type: "bool"},
      {label: "Unlimited Hours", value: policy.UNLIMITED, type: "bool"},
      {label: "Accrual Method", value: policy.METHOD.capitalize()}
    );

    if (parseInt(policy.UNLIMITED) === 0) {
      if (policy.METHOD === "FIXED") {
        policyData.push({label: "Yearly Allotted Hours", value: policy.AMOUNT});
      } else {
        const earned = 1;
        const worked = getPolicyWorkedHours(policy.AMOUNT);

        policyData.push({
          label: "Hours Allocation",
          value: earned + " hour earned every " + worked + " hours worked",
        });

        policyData.push(
          {
            label: "Maximum Hours Tracked",
            tooltip: {
              data: [
                {
                  label: "Maximum Hours",
                  data: "The maximum hours in a calendar year that will look at when allocating policy hours",
                },
                {
                  label: "An Example",
                  data: "If the maximum was set at 1000 hours, after the employee has worked 1000 hours they will not be awarded any more policy hours on any hours after the 1000.",
                },
              ],
            },
            value: policy.MAX_HOURS ? policy.MAX_HOURS + " hours" : "None",
          },
          {
            label: "Maximum Hour Balance",
            tooltip: "The maximum number of hours an employee can have at a time",
            value: policy.MAX_BALANCE ? policy.MAX_BALANCE + " hours" : "None",
          },
          {
            label: "Maximum Carry Over",
            tooltip: "The maximum number of hours an employee can have at the start of the year",
            value: policy.MAX_CARRY_OVER ? policy.MAX_CARRY_OVER + " hours" : "None",
          },
          {
            label: "Earning Waiting Period",
            tooltip: "How long do employees have to wait until they can start earning hours",
            value: policy.WAITING_PERIOD_ACCRUE + " days",
          },
          {
            label: "Request Waiting Period",
            tooltip: "How long do employees have to wait until they can start requesting time off",
            value: policy.WAITING_PERIOD_REQUEST + " days",
          }
        );
      }
    }

    if (policy.DESCRIPTION) {
      policyData.push({
        label: "Description",
        value: policy.DESCRIPTION,
        span: true,
      });
    }

    return (
      <TwoColumnList
        label="Time Off Policy"
        description={`Overview of the ${policy.NAME} policy's details and rules`}
        buttons={actionButtons}
        data={policyData}
      />
    );
  }
}

TimeOffCard.propTypes = {
  policy: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

export default withRouter(TimeOffCard);
