import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {withRouter} from "../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {REDEEM_RULE_TYPES} from "../../utils/loyalty-constants";

class RedeemsTable extends Component {
  render() {
    const {redeems} = this.props;

    return (
      <Table
        pagination
        data={redeems}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/ticket/" + row.TICKET_UNIQUE_ID),
          },
        ]}
        columns={[
          {
            value: "PRODUCT_NAME",
            label: "Product",
            format: (val, row) =>
              val || (row.ADV_RULE_TYPE === REDEEM_RULE_TYPES.TICKET ? "Ticket Discount" : ""),
          },
          {
            value: "LOCATION_NAME",
            label: "Location",
          },
          {
            width: 1,
            value: "PRICE",
            label: "Price",
            format: (val) => "$" + toDollars(val),
          },
          {
            width: 1,
            value: "DATE_REDEEMED",
            label: "Date Redeemed",
            format: (value) => {
              return moment(value).format("MM/DD/YY");
            },
          },
        ]}
      />
    );
  }
}

RedeemsTable.propTypes = {
  redeems: PropTypes.array.isRequired,
};

export default withRouter(RedeemsTable);
