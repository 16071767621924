import {formatDate, formatDocumentStatus, formatType} from "../documents/documents-helper";
import {PACKET_STATUS} from "../packets/packets-helper";
import DangerBadge from "../../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../../components/badges/success-badge";
import * as Yup from "yup";

export const assignPacketValidation = Yup.object({
  selectedPacket: Yup.number()
    .required("Packet is required")
    .typeError("Please select a valid packet")
    .min(1, "Packet select a packet"),
  selectedEmployees: Yup.array()
    .min(1, "Please select at least one employee")
    .required("Employees are required"),
  dueDate: Yup.string()
    .nullable()
    .optional()
    .test("is-future", "Due date must be in the future", (value) => {
      return value == null || value > new Date();
    }),
});

export const COLUMNS = [
  {
    value: "EMPLOYEE_NAME",
    label: "Employee Name",
  },
  {
    value: "LOCATION_NAME",
    label: "Location",
  },
  {
    value: "PACKET_NAME",
    label: "Packet Name",
  },
  {
    value: "VERSION",
    label: "Version",
  },
  {
    value: "DUE_DATE",
    label: "Due Date",
    format: (value) => formatDate(value),
  },
];

export const SUBTABLE_COLUMNS = [
  {
    key: "NAME",
    label: "Document Name",
  },
  {
    key: "TYPE",
    label: "Type",
    format: (value) => formatType(value),
  },
  {
    key: "VERSION",
    label: "Version",
  },
  {
    key: "STATUS",
    label: "Status",
    format: (value) => formatDocumentStatus(value),
  },
];

export const formatPacketStatus = (status) => {
  switch (status) {
    case PACKET_STATUS.INCOMPLETE:
      return (
        <DangerBadge blue className={"whitespace-nowrap"}>
          Incomplete
        </DangerBadge>
      );
    case PACKET_STATUS.REJECTED:
      return <DangerBadge className={"whitespace-nowrap"}>Rejected - Resubmission Required</DangerBadge>;
    case PACKET_STATUS.PENDING_REVIEW:
      return (
        <DangerBadge yellow className={"whitespace-nowrap"}>
          Needs Review
        </DangerBadge>
      );
    case PACKET_STATUS.COMPLETED:
      return <SuccessBadge className={"whitespace-nowrap"}>Completed</SuccessBadge>;
    default:
      return "";
  }
};

export const DATE_MASK = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
export const EIN_MASK = [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
