import React, {Component} from "react";
import {FormBoolean, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import MultiSelectCombobox from "../../../components/multi-select-combobox";
import {request} from "../../../utils/request";
import PropTypes from "prop-types";
import {
  PIN_ENTRY_TYPE_TRANSLATIONS,
  PIN_ENTRY_TYPES,
} from "../../../pages/operations/checklists/checklist-page";
import RolesDropdown from "../../../dropdowns/team/roles-dropdown";
import FormRow from "../../../components/form-row";

class ChecklistModal extends Component {
  state = {checklist: null};

  open(checklist) {
    this.setState({checklist}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createChecklist({
    durationMinutes,
    durationHours,
    durationDays,
    employee,
    name,
    pin,
    role,
    pinType,
    targetRoles,
    validateRole,
    locations,
  }) {
    const duration = parseInt(durationMinutes) + parseInt(durationHours * 60) + parseInt(durationDays * 1440);

    const checklistPayload = {
      NAME: name,
      DURATION: duration,
      EMPLOYEE_ID: employee,
      REQUIRE_PIN: pin,
      ROLE_ID: role,
      PIN_TYPE: pinType,
      TARGET_ROLES: targetRoles,
      VALIDATE_ROLE: validateRole,
      LOCATIONS: locations,
    };

    const serverChecklist = await request("checklists/", "POST", checklistPayload);

    this.props.addState(serverChecklist);
    this.modal.close();
  }

  async saveChecklist({
    pin,
    durationMinutes,
    durationHours,
    durationDays,
    employee,
    status,
    name,
    role,
    pinType,
    targetRoles,
    validateRole,
    locations,
  }) {
    const {checklist} = this.state;
    const duration = parseInt(durationMinutes) + parseInt(durationHours * 60) + parseInt(durationDays * 1440);

    const checklistPayload = {
      NAME: name,
      DURATION: duration,
      ENABLED: status,
      EMPLOYEE_ID: employee,
      REQUIRE_PIN: pin,
      ROLE_ID: role,
      PIN_TYPE: pinType,
      TARGET_ROLES: targetRoles,
      VALIDATE_ROLE: validateRole,
      LOCATIONS: locations,
    };

    const serverChecklist = await request("checklists/" + checklist.UNIQUE_ID, "PATCH", checklistPayload);

    this.props.updateState(serverChecklist, serverChecklist.TARGET_ROLES);
    this.modal.close();
  }

  render() {
    const {checklist} = this.state;
    const {locations, location} = this.props.shop;

    const dropDownData = [
      {
        data: locations.map((location) => ({
          name: location.NAME,
          label: location.NAME,
          id: location.ID,
        })),
      },
    ];
    return (
      <Modal
        large
        label={checklist ? "Edit Checklist" : "Create Checklist"}
        buttonLabel={checklist ? "Save" : "Create"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={checklist ? this.saveChecklist.bind(this) : this.createChecklist.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: checklist?.NAME ?? "",
            durationMinutes: checklist?.DURATION ? checklist?.DURATION % 60 : 0,
            durationHours: checklist?.DURATION ? Math.floor((checklist?.DURATION % 1440) / 60) : 0,
            durationDays: checklist?.DURATION ? Math.floor(checklist?.DURATION / 1440) : 0,
            status: checklist?.ENABLED ?? "1",
            employee: checklist?.EMPLOYEE_ID,
            employeeName: checklist?.EMPLOYEE_NAME,
            role: checklist?.ROLE_ID,
            roleName: checklist?.ROLE_NAME,
            pin: checklist?.REQUIRE_PIN ?? 0,
            pinType: checklist?.PIN_TYPE ?? PIN_ENTRY_TYPES.BY_ITEM,
            targetRoles: checklist?.TARGET_ROLES.map((_role) => _role.ROLE_ID) ?? [],
            validateRole: checklist?.VALIDATE_ROLE ?? 0,
            locations: checklist?.LOCATIONS.map((_location) => _location.LOCATION_ID) ?? [location.ID],
          }}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue, values} = formikOptions;
            const {pin, pinType, targetRoles} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput
                    className="w-full"
                    placeholder="Avery's Checklist"
                    options={formikOptions}
                    label="Name"
                    name="name"
                  />

                  <FormInput
                    className="w-full"
                    tooltip="How long do employees have to complete the checklist. Will add up all set durations."
                    label="Duration (minutes)"
                    name="durationMinutes"
                    options={formikOptions}
                  />
                </FormRow>

                <FormRow>
                  <FormInput
                    className="w-full"
                    tooltip="How long do employees have to complete the checklist. Will add up all set durations."
                    label="Duration (hours)"
                    name="durationHours"
                    options={formikOptions}
                  />

                  <FormInput
                    className="w-full"
                    tooltip="How long do employees have to complete the checklist. Will add up all set durations."
                    label="Duration (days)"
                    name="durationDays"
                    options={formikOptions}
                  />
                </FormRow>

                <FormRow>
                  <FormBoolean
                    className="w-full"
                    name="pin"
                    label="Require Employee Pin"
                    options={formikOptions}
                  />

                  {!!pin && (
                    <FormSelect
                      className="w-full"
                      label="Pin Entry Type"
                      name="pinType"
                      data={[
                        {
                          value: PIN_ENTRY_TYPES.BY_ITEM,
                          label: PIN_ENTRY_TYPE_TRANSLATIONS[PIN_ENTRY_TYPES.BY_ITEM],
                        },
                        {
                          value: PIN_ENTRY_TYPES.BY_LIST,
                          label: PIN_ENTRY_TYPE_TRANSLATIONS[PIN_ENTRY_TYPES.BY_LIST],
                        },
                      ]}
                      options={formikOptions}
                    />
                  )}
                </FormRow>

                <FormRow>
                  <RolesDropdown
                    className="w-full"
                    tooltip="Select which employee roles get notified about this checklist when clocking in."
                    label="Target Roles"
                    name="targetRoles"
                    options={formikOptions}
                    multi
                  />

                  {!!pin && !!targetRoles.length > 0 && (
                    <FormBoolean
                      className="w-full"
                      name="validateRole"
                      label="Validate Role on Pin Entry"
                      tooltip="Only employees with the target roles will be able to complete this checklist."
                      options={formikOptions}
                    />
                  )}
                </FormRow>

                <FormRow className={"flex space-x-4 justify-between"}>
                  {checklist && (
                    <FormBoolean className="w-1/2" label="Active" name="status" options={formikOptions} />
                  )}

                  <MultiSelectCombobox
                    className="w-1/2"
                    label="Assigned Locations"
                    name="locations"
                    options={formikOptions}
                    multi
                    maxLen={1}
                    data={dropDownData}
                  />
                </FormRow>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChecklistModal.propTypes = {
  updateState: PropTypes.func.isRequired,
};

export default setupReduxConnection(["shop"])(ChecklistModal);
