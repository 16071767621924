import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const SignatureForm = ({formik, label = "", subLabel = "", className = ""}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  return (
    <form className={"my-8 " + className}>
      <div className="text-sm font-medium text-gray-700 mt-10 mb-2">
        {t(getI18NextKey("signature.prompt"))}
      </div>

      <div
        className={
          "flex items-center justify-center border border-indigo-500 border-opacity-25 rounded-md bg-indigo-500 bg-opacity-10 p-8 py-16"
        }
      >
        <div className={"flex items-center space-x-2 w-1/2"}>
          <FontAwesomeIcon icon={"fas fa-pencil"} />

          <input
            id="signature"
            name="signature"
            type="text"
            placeholder={t(getI18NextKey("signature.placeholder"))}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.signature}
            className="font-signature placeholder:font-sans border-0 border-b-2 bg-transparent focus:outline-none focus:border-indigo-500 focus:ring-0 w-full text-center text-xl appearance-none"
          />
        </div>
      </div>

      {formik.errors["signature"] && (
        <div className="text-sm text-red-500 mt-2 text-left">{formik.errors["signature"]}</div>
      )}

      <div className="text-sm font-medium text-gray-700 mt-4 mb-4">
        <p className={"mb-2"}>{label}</p>

        <p className={"mb-2"}>{subLabel}</p>
      </div>
    </form>
  );
};
