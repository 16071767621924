import React from "react";
import {useTranslation} from "react-i18next";
import FormRow from "../../../../../../components/form-row";
import {FormElement, FormEmail, FormInput, FormPhone} from "@frostbyte-technologies/frostbyte-tailwind";
import InputMask from "react-input-mask";
import {DATE_MASK} from "../../../../../team/onboarding/functional/dashboard/dashboard-helper";

export const SensitiveEmployeeInfoStep = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-contact-page." + path;
  };

  return (
    <form className={"mt-4 mb-3"}>
      <FormRow>
        <FormInput
          className={"mt-0"}
          flex
          name={"dateOfBirth"}
          label={t(getI18NextKey("dateOfBirth.label"))}
          onChange={formik.handleChange}
          mask={DATE_MASK}
          placeholder={"MM/DD/YYYY"}
          options={formik}
        />

        <FormElement flex options={formik} label={t(getI18NextKey("ssn.label"))} name={"ssn"}>
          {(value, error, onChange) => {
            return (
              <InputMask
                mask={"999-99-9999"}
                name={"ssn"}
                type={"text"}
                maskChar={null}
                placeholder={"XXX-XX-XXXX"}
                value={value}
                flex
                options={formik}
                onChange={onChange}
                aria-describedby="email-error"
                aria-invalid="true"
                className={
                  "focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                }
              />
            );
          }}
        </FormElement>
      </FormRow>

      <FormRow>
        <FormEmail flex name={"email"} label={t(getI18NextKey("email.label"))} options={formik} />

        <FormPhone flex name={"phone"} label={t(getI18NextKey("phone.label"))} options={formik} />
      </FormRow>
    </form>
  );
};
