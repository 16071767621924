import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import ProductRecipePage from "../../../../../pages/operations/supply-chain/recipes/product-recipe-page";
import {getStore} from "../../../../../redux";
import {setProduct, updateProducts} from "../../../../../redux/menu";
import {showConfirmAlert} from "../../../../../utils/alert-helper";
import {withRouter} from "../../../../../utils/navigation";

class SetupProductIngredientsModal extends Component {
  state = {
    productId: null,
  };

  open(productId) {
    this.setState({productId}, () => this.modal.open());
  }

  handleClose() {
    const {pathname} = window.location;
    window.history.pushState({}, "", pathname);
    getStore().dispatch(setProduct(null));
    getStore().dispatch(updateProducts());
  }

  render() {
    const {productId} = this.state;

    return (
      <Modal ref={(e) => (this.modal = e)} full hideClose={true}>
        <ProductRecipePage
          productId={productId}
          closeParent={() => {
            this.handleClose();
            this.modal.close();
          }}
        />
      </Modal>
    );
  }
}

export default withRouter(SetupProductIngredientsModal);
