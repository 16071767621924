import React, {Component} from "react";
import {request} from "../../../utils/request";
import {Card, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import PayrollInformationComponent from "../../../features/payroll/payroll-information-component";
import {saveAs} from "file-saver";
import {showErrorAlert} from "../../../utils/alert-helper";
import PayrollWhitelistWrapper from "../../../features/payroll/payroll-whitelist-wrapper";

class PastPayrollPage extends Component {
  state = {payrollData: null, isLoading: true};

  async componentDidMount() {
    const {ID} = this.props.router.params;

    let payrollData = await request("payroll/payrolls/" + ID, "GET", null);
    this.setState({payrollData, isLoading: false});
  }

  renderSummary() {
    let {PAYDAY, PAYROLL} = this.state.payrollData;

    let {AMOUNT_SUBTOTAL, AMOUNT_DEBIT, AMOUNT_CHECK} = PAYROLL.TOTALS;

    let summaryArr = [
      {label: "Total Payroll", value: toDollars(AMOUNT_SUBTOTAL, true)},
      {label: "Debit Amount", value: toDollars(AMOUNT_DEBIT, true)},
      {label: "Physical Check Amount", value: toDollars(AMOUNT_CHECK, true)},
      {label: "Employee Pay Date", value: moment(PAYDAY).format("MM/DD/yyyy")},
    ];

    return (
      <div className="flex flex-row">
        {summaryArr.map((item) => {
          let {label, value} = item;

          return (
            <div className={"flex flex-col mr-3"}>
              <span>{label}</span>
              <span className="font-bold">{value}</span>
            </div>
          );
        })}
      </div>
    );
  }

  render() {
    let {isLoading, payrollData} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    let buttons = [];

    if (payrollData.PAYROLL.STATUS === "pending" || payrollData.PAYROLL.STATUS === "approved") {
      buttons.push({
        label: "Reopen",
        onClick: async () => {
          try {
            await request(`payroll/payrolls/${payrollData.PAYROLL.ID}/reopen`, "POST");

            this.props.router.navigate("/payroll");
          } catch (e) {
            showErrorAlert(
              "Error Reopening",
              "Payroll could not re-opened. Please reach out to support for more information",
              "Ok"
            );
          }
        },
      });
    }

    if (payrollData.PAYROLL.CHECKS_LINK) {
      buttons.push({
        label: "Download Physical Checks",
        onClick: () => {
          saveAs(payrollData.PAYROLL.CHECKS_LINK, "pdf");
        },
      });
    }

    let typeStr = payrollData.TYPE === PAYROLL_TYPES.REGULAR ? "Regular" : "Off-Cycle";

    return (
      <PayrollWhitelistWrapper router={this.props.router}>
        <Card label={`${typeStr} Payroll Summary`} buttons={buttons}>
          <div className={"m-6"}>
            {this.renderSummary()}

            <PayrollInformationComponent className="mt-3" payroll={payrollData.PAYROLL} />
          </div>
        </Card>
      </PayrollWhitelistWrapper>
    );
  }
}

const PAYROLL_TYPES = {
  REGULAR: "regular",
  OFF_CYCLE: "off_cycle",
};

export default withRouter(PastPayrollPage);
