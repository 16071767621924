import io from "socket.io-client";
import {getShortURL} from "../utils/request";
import {setupMessageListener} from "./message-listener";

let socketRef = null;

export function setupSocketHandler() {
  if (socketRef) {
    socketRef.disconnect();
  }

  let socket = (socketRef = io(getShortURL(), {
    transports: ["websocket"],
    upgrade: false,
  }));

  socket.on("connect", () => {
    const token = window.localStorage["TOKEN"];

    socket.emit("token", {
      TOKEN: token,
      LOCATION: window.localStorage["CURRENT_LOCATION"],
    });

    socket.emit("authentication", {
      TOKEN: token,
    });
  });

  socket.on("init", () => {});

  socket.on("disconnect", () => {});

  setupMessageListener(socket);
}
