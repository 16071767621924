import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {showConfirmAlert} from "../../../utils/alert-helper";
import NewChatModal from "../../../modals/operations/chats/new-chat-modal";

class ChatsPage extends Component {
  state = {
    isLoading: true,
    chats: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let chats = await request("support/chats", "GET", {});

    this.setState({isLoading: false, chats});
  }

  closeAll() {
    showConfirmAlert("Are you sure you want to close all chats?").then(async () => {
      await request("support/chats", "DELETE", {});

      let {chats} = this.state;

      for (let chat of chats) {
        if (chat.IS_OPEN) {
          chat.IS_OPEN = false;
        }
      }

      this.setState({chats});
    });
  }

  render() {
    const {isLoading, chats} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const openChats = chats.filter((item) => {
      return item.IS_OPEN === true;
    });

    const closedChats = chats.filter((item) => {
      return item.IS_OPEN === false;
    });

    let buttons = [{label: "New Message", onClick: () => this.newChatModal.open()}];

    if (openChats.length > 0) {
      buttons.unshift({label: "Close All", onClick: () => this.closeAll()});
    }

    return (
      <div>
        <NewChatModal router={this.props.router} ref={(e) => (this.newChatModal = e)} />

        <PageHeadings
          label="Customer Chats"
          description="View and respond to customer feedback"
          buttons={buttons}
        />

        <Tab
          data={[
            {id: "open", label: "Open", badge: "" + openChats.length},
            {id: "closed", label: "Closed", badge: "" + closedChats.length},
          ]}
        >
          {(id) => {
            const data = id === "open" ? openChats : closedChats;

            return (
              <Table
                pagination
                data={data}
                className="mt-4"
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => this.props.router.navigate("/chat/" + row.PHONE),
                  },
                ]}
                columns={[
                  {
                    value: "FULL_NAME",
                    label: "Customer Name",
                    width: 200,
                  },
                  {
                    value: "TEXTS",
                    label: "Last Message",
                    format: (texts) => {
                      return texts[0].CONTENT;
                    },
                  },
                  {
                    value: "TEXTS",
                    label: "Number of Texts",
                    format: (texts) => {
                      return texts.length;
                    },
                    width: 200,
                  },
                  {
                    value: "LAST_TEXT",
                    label: "Last Response",
                    format: (value) => {
                      return moment(value).format("MM/DD/YY hh:mma");
                    },
                  },
                ]}
              />
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(ChatsPage));
