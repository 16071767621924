import React, {Component} from "react";
import {
  FormBoolean,
  Modal,
  FormSelect,
  FormInput,
  FormTextArea,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {Formik} from "formik";
import FormRow from "../../../components/form-row";
import * as Yup from "yup";
import ContactPaymentModal from "./contact-payment-modal";
import {showSuccessAlert} from "../../../utils/alert-helper";
import {decimalToDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {getPaymentMethodVerbiageByType, INVOICE_CONTACT_PAYMENT_METHODS} from "../../../utils/invoice-helper";
import {InvoiceRequests} from "../../../utils/request-helpers/invoices/invoice-requests";

class PayInvoiceModal extends Component {
  state = {invoice: null, methods: null};

  constructor(props) {
    super(props);
    this.formikRef = React.createRef(null);
    this.modalRef = React.createRef(null);
    this.paymentModalRef = React.createRef(null);
  }

  open(invoice = null) {
    this.setState({invoice, methods: invoice.CONTACT.PAYMENT_METHODS}, () => {
      this.modalRef.current.open();
    });
  }

  getPaymentMethodData() {
    const {methods = []} = this.state;

    return methods.map((_method) => ({
      label: getPaymentMethodVerbiageByType(_method),
      value: _method.id,
    }));
  }

  async payInvoice(values, formikRef) {
    const {number, notes, type, receipt, method, tip, checkNumber} = values;
    const {invoice} = this.state;

    if (type === "METHOD") {
      if (method === null) {
        formikRef.setFieldError("method", "Please select or create a payment method");

        return this.modalRef.current.fetchModalButton().stopLoading();
      }

      try {
        const invoicePayload = {
          INVOICE_NUMBER: number,
          NOTES: notes,
          PAYMENT_METHOD_ID: method,
          AMOUNT_TIP: tip ? decimalToDollars(tip) : 0,
          RECEIPT: receipt,
        };
        await InvoiceRequests.payInvoiceMethod(invoice.ID, invoicePayload);

        setTimeout(
          () => request("invoices/" + invoice.ID, "GET").then((invoice) => this.props.updateState(invoice)),
          2000
        );
      } catch ({err}) {
        if (err?.raw?.message) {
          formikRef.setFieldError("method", err.raw.message);
        } else {
          formikRef.setFieldError("method", "There was an error processing this card. Try again later.");
        }

        return this.modalRef.current.fetchModalButton().stopLoading();
      }

      this.modalRef.current.close();

      return setTimeout(
        () =>
          showSuccessAlert(
            "Payment Successful",
            "Invoice payment went through but it might take a few minutes for invoice status to update.",
            "Continue",
            {
              buttonClick: () => {
                this.props.resyncPage();
              },
            }
          ),
        250
      );
    }

    let serverInvoice = null;
    try {
      const invoicePayload = {
        RECEIPT: receipt,
        INVOICE_NUMBER: number,
        NOTES: notes,
        SOURCE: {
          TYPE: type,
          CONTENT: type === "CHECK" ? checkNumber : null,
          AMOUNT: invoice.TICKET.PAYMENT_INTENT.TOTAL + (tip ? decimalToDollars(tip) : 0),
          AMOUNT_TIP: tip ? decimalToDollars(tip) : 0,
        },
      };

      serverInvoice = await InvoiceRequests.payInvoice(invoice.ID, invoicePayload);
    } catch ({error}) {
      if (error?.raw?.message) {
        formikRef.setFieldError("checkNumber", error.raw.message);
      } else {
        formikRef.setFieldError(
          "checkNumber",
          "There was an error processing this check number. Try again later."
        );
      }
      return this.modalRef.current.fetchModalButton().stopLoading();
    }

    this.props.updateState(serverInvoice);
    this.props.resyncPage();
    this.modalRef.current.close();
  }

  fetchInitialValues() {
    const {methods, invoice} = this.state;

    const initialValues = {
      number: invoice?.INVOICE_NUMBER ?? "000000",
      notes: invoice?.TICKET?.NOTES ?? "",
      method: methods?.length > 0 ? methods[0].id : null,
      checkNumber: null,
      type: "METHOD",
      receipt: "1",
      tip: 0,
    };

    return initialValues;
  }

  renderFormPaymentMethod(formikOptions, values) {
    if (values.type === "METHOD")
      return (
        <FormSelect
          data={this.getPaymentMethodData()}
          buttonHint={{
            label: "Add Method",
            onClick: () => this.paymentModalRef.current.open(this.state.invoice.CONTACT),
          }}
          options={formikOptions}
          label="Payment Method"
          name="method"
        />
      );

    if (values.type === "CHECK")
      return <FormInput options={formikOptions} label="Check Number" hint={"Optional"} name="checkNumber" />;
  }

  renderForm(formikOptions) {
    const {invoice} = this.state;
    const {values} = formikOptions;
    return (
      <>
        <FormInput options={formikOptions} label="Invoice Number" name="number" />

        <FormSelect
          data={[
            {label: "Contact Payment Method", value: "METHOD"},
            {label: "Check", value: "CHECK"},
            {label: "Cash", value: "CASH"},
          ]}
          options={formikOptions}
          label="Payment Type"
          name="type"
        />
        {this.renderFormPaymentMethod(formikOptions, values)}
        <FormBoolean options={formikOptions} label="Send Receipt" name="receipt" />
        {!!invoice.REQUEST_TIP && <FormInput options={formikOptions} label="Tip" name="tip" />}
        <FormTextArea
          options={formikOptions}
          tooltip="These notes will display on invoice emails, pdfs, and receipts."
          label="Invoice Notes"
          hint="Optional"
          name="notes"
        />
      </>
    );
  }

  renderFormik() {
    const validationSchema = Yup.object({
      type: Yup.string().required("Payment type is required"),
      checkNumber: Yup.number().typeError("Please enter a valid check number").nullable(),
    });

    return (
      <Formik
        onSubmit={(values) => this.payInvoice(values, this.formikRef.current)}
        innerRef={this.formikRef}
        enableReinitialize
        validationSchema={validationSchema}
        initialValues={this.fetchInitialValues()}
      >
        {(formikOptions) => {
          const {handleSubmit, values} = formikOptions;

          return <form onSubmit={handleSubmit}>{this.renderForm(formikOptions, values)}</form>;
        }}
      </Formik>
    );
  }

  render() {
    const {methods, invoice} = this.state;
    return (
      <Modal
        buttonLabel="Pay"
        label="Pay Invoice"
        ref={this.modalRef}
        formikOnClick={() => this.formikRef.current}
      >
        <ContactPaymentModal
          addState={(card) =>
            this.setState({methods: [...methods, card]}, () => {
              invoice.CONTACT.PAYMENT_METHODS.push(card);

              this.formikRef.current.setFieldValue("method", card.id);
            })
          }
          ref={this.paymentModalRef}
        />
        {this.renderFormik()}
      </Modal>
    );
  }
}

export default PayInvoiceModal;
