import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {withRouter} from "../../../utils/navigation";
import {AdvancedFilter, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TicketsExportModal from "../../../modals/sales/tickets/tickets-export-modal";
import {showErrorAlert} from "../../../utils/alert-helper";

class TicketsPage extends Component {
  state = {
    type: null,
    types: [],
    otherPayments: [],
    isLoading: true,
    stripePayouts: [{label: "None", value: null}],
  };

  componentDidMount() {
    this.syncState();
    this.fetchPayouts();
  }

  fetchPayouts = async () => {
    let stripePayouts = (await request("payouts", "GET")).reverse().map((payout) => ({
      label: `${moment(payout.DATE_ARRIVED).format("MM/DD/YY")} - ${toDollars(payout.TOTAL, true)}`,
      value: payout.UNIQUE_ID,
    }));

    stripePayouts.unshift({label: "None", value: null});

    this.setState({stripePayouts});
  };

  async syncState() {
    let types = await request("types/order", "GET");
    let otherPayments = await request("other/payments", "GET");

    this.setState({isLoading: false, types, otherPayments});
  }

  render() {
    let {isLoading, types, otherPayments, stripePayouts} = this.state;
    const {NAME} = this.props.shop.location;

    if (isLoading) {
      return <Loading />;
    }

    const formatDownloadData = (downloadData) => {
      return downloadData.reduce((accum, ticket) => {
        const ticketPayload = {...ticket};
        let amount = 0;
        let amountTip = 0;
        let amountTax = 0;
        let amountFees = 0;
        let amountDiscount = 0;
        let amountRefund = 0;
        let amountProcessing = 0;
        let total = 0;

        for (let item of ticketPayload.PAYMENT_INTENT.TRANSACTIONS.filter(
          (item) => item.TYPE === "PAYMENT"
        )) {
          amount += item.AMOUNT;
          amountTip += item.AMOUNT_TIP;
          amountTax += item.AMOUNT_TAX;
          amountFees += item.AMOUNT_FEES;
          amountDiscount += item.AMOUNT_DISCOUNT;
          amountRefund += item.AMOUNT_REFUNDED;
          amountProcessing += item.AMOUNT_PROCESSING;
          total += item.TOTAL;
        }

        amountRefund = ticketPayload.PAYMENT_INTENT.TRANSACTIONS.filter(({TYPE}) => TYPE === "REFUND").reduce(
          (total, {AMOUNT_REFUNDED}) => total + AMOUNT_REFUNDED,
          0
        );

        ticketPayload.AMOUNT = "$" + toDollars(amount);
        ticketPayload.AMOUNT_TIP = "$" + toDollars(amountTip);
        ticketPayload.AMOUNT_TAX = "$" + toDollars(amountTax);
        ticketPayload.AMOUNT_FEES = "$" + toDollars(amountFees);
        ticketPayload.AMOUNT_DISCOUNT = "$" + toDollars(amountDiscount);
        ticketPayload.AMOUNT_REFUNDED = "$" + toDollars(amountRefund);
        ticketPayload.AMOUNT_PROCESSING = "$" + toDollars(amountProcessing);
        ticketPayload.TOTAL = "$" + toDollars(total);

        ticketPayload.DATE_CREATED = ticket.DATE_CREATED
          ? moment(ticket.DATE_CREATED).format("hh:mma MM/DD/YY")
          : "";

        ticketPayload.DATE_STARTED = ticket.DATE_STARTED
          ? moment(ticket.DATE_STARTED).format("hh:mma MM/DD/YY")
          : "";

        ticketPayload.DATE_COMPLETED = ticket.DATE_COMPLETED
          ? moment(ticket.DATE_COMPLETED).format("hh:mma MM/DD/YY")
          : "";

        ticketPayload.DATE_SENT = ticket.DATE_SENT ? moment(ticket.DATE_SENT).format("hh:mma MM/DD/YY") : "";

        ticketPayload.DATE_REFUNDED = ticket.PAYMENT_INTENT.DATE_REFUNDED
          ? moment(ticket.PAYMENT_INTENT.DATE_REFUNDED).format("hh:mma MM/DD/YY")
          : "";

        ticketPayload.DATE_VOIDED = ticket.PAYMENT_INTENT.DATE_VOIDED
          ? moment(ticket.PAYMENT_INTENT.DATE_VOIDED).format("hh:mma MM/DD/YY")
          : "";

        ticketPayload.ITEMS = ticket.ITEMS.length;

        accum.push(ticketPayload);

        // for (let item of ticket.ITEMS) {
        //   accum.push({
        //     ITEM_NAME: item.NAME ? item.NAME.replaceAll('"', "") : "",
        //     ITEM_AMOUNT: toDollars(item.AMOUNT),
        //     ITEM_AMOUNT_TAX: toDollars(item.AMOUNT_TAX),
        //     ITEM_TOTAL: toDollars(item.TOTAL),
        //     ITEM_QUANTITY: item.QUANTITY,
        //   });

        // if (Array.isArray(item.SELECTIONS)) {
        //   for (let selection of item.SELECTIONS) {
        //     accum.push({
        //       MODIFIER_NAME: selection.MODIFIER_NAME ? selection.MODIFIER_NAME.replaceAll('"', "") : "",
        //       OPTION_NAME: selection.OPTION_NAME ? selection.OPTION_NAME.replaceAll('"', "") : "",
        //       SELECTION_AMOUNT: toDollars(selection.AMOUNT),
        //       SELECTION_AMOUNT_TAX: toDollars(selection.AMOUNT_TAX),
        //       SELECTION_TOTAL: toDollars(selection.TOTAL),
        //       SELECTION_QUANTITY: selection.QUANTITY,
        //     });
        //   }
        // }
        // }

        return accum;
      }, []);
    };

    return (
      <div>
        <TicketsExportModal ref={(e) => (this.exportModal = e)} />

        <PageHeadings label="Tickets" description="Filter to find current and future tickets" />

        <AdvancedFilter
          className="mt-2"
          defaultOpen
          data={[
            {
              type: "array",
              data: [
                {
                  label: "Search by Name, Phone, Ticket Number, Last 4, $ Amount",
                  placeholder: "Search",
                  type: "search",
                  id: "search",
                },
                {label: "Start Date", type: "datetime", id: "start"},
                {label: "End Date", type: "datetime", id: "end"},
                {
                  label: "Payout",
                  type: "select",
                  data: stripePayouts,
                  id: "payout",
                },
              ],
            },
            {
              id: "payment",
              type: "check",
              label: "Payment Types",
              data: [
                {label: "Cash", value: "CASH"},
                {label: "Card Present", value: "CARD_PRESENT"},
                {label: "Card Not Present", value: "CARD_NOT_PRESENT"},
                {label: "Physical Gift Card", value: "GIFT_CARD"},
                {label: "Digital Gift Card", value: "E_GIFT_CARD"},
                {label: "Reward", value: "REWARD"},
                {label: "Dripos Balance", value: "BALANCE"},
                {label: "Billing Account", value: "BILLING"},
                ...otherPayments.map((item) => {
                  return {label: item.NAME, value: item.ID};
                }),
              ],
            },
            {
              id: "type",
              type: "check",
              label: "Ticket Types",
              data: types.map((item) => {
                return {label: item.NAME, value: item.ID};
              }),
            },
            {
              type: "check",
              id: "platform",
              label: "Platforms",
              data: [
                {label: "Mobile", value: "mobile"},
                {label: "Web", value: "web"},
                {
                  label: "Point of Sale",
                  value: "pos",
                },
                {label: "Kiosk", value: "kiosk"},
              ],
            },
            {
              type: "check",
              id: "status",
              label: "Status",
              data: [
                {label: "Refunded", value: "DATE_REFUNDED"},
                {label: "Voided", value: "DATE_VOIDED"},
              ],
            },
          ]}
          csv={{
            onDownloadError: () =>
              showErrorAlert(
                "Export Failed",
                "We only allow exporting 10,000 tickets at a time. Please narrow your filters to export your tickets!"
              ),
            file: `tickets-${NAME}`,
            route: "tickets/dumb",
            formatDownloadData,
            headers: [
              {label: "Ticket Number", key: "TICKET_NUMBER"},
              {label: "Name", key: "NAME"},
              {label: "Phone", key: "PHONE"},
              {label: "Email", key: "EMAIL"},
              {label: "Ticket Type", key: "TICKET_TYPE_NAME"},
              {label: "Platform", key: "PLATFORM"},
              {label: "Employee Name", key: "EMPLOYEE_NAME"},
              {label: "Number of Items", key: "ITEMS"},
              {label: "Subtotal", key: "AMOUNT"},
              {label: "Tip", key: "AMOUNT_TIP"},
              {label: "Tax", key: "AMOUNT_TAX"},
              {label: "Fees", key: "AMOUNT_FEES"},
              {label: "Discounted", key: "AMOUNT_DISCOUNT"},
              {label: "Refunded", key: "AMOUNT_REFUNDED"},
              {label: "Total", key: "TOTAL"},
              {label: "Created", key: "DATE_CREATED"},
              {label: "Sent to KDS", key: "DATE_SENT"},
              {label: "Started on KDS", key: "DATE_STARTED"},
              {label: "Completed on KDS", key: "DATE_COMPLETED"},
              {label: "Date Refunded", key: "DATE_REFUNDED"},
              {label: "Date Voided", key: "DATE_VOIDED"},
              {label: "Device Name", key: "DEVICE_NAME"},
              {label: "Notes", key: "NOTES"},
              {label: "Internal Notes", key: "INTERNAL_NOTES"},
            ],
          }}
        >
          {(filters, search) => {
            return (
              <div className="p-6">
                <Table
                  route="tickets/dumb"
                  search={search}
                  filters={filters}
                  actionButtons={[
                    {
                      label: "View",
                      onClick: (ticket) => this.props.router.navigate("/ticket/" + ticket.UNIQUE_ID),
                    },
                    {
                      label: <FontAwesomeIcon icon="fa-solid fa-arrow-up-right-from-square" />,
                      onClick: (ticket) => this.props.router.navigateNewTab("/ticket/" + ticket.UNIQUE_ID),
                    },
                  ]}
                  columns={[
                    {
                      label: "Ticket Number",
                      value: "TICKET_NUMBER",
                    },
                    {
                      label: "Amount",
                      value: "PAYMENT_INTENT",
                      format: (payment) => "$" + toDollars(payment.TOTAL),
                    },
                    {label: "Name", value: "NAME"},
                    {
                      label: "Ticket & Platform",
                      value: "TICKET_TYPE_NAME",
                      format: (name, row) => {
                        return `${name} - ${row.PLATFORM ? row.PLATFORM.capitalize() : "POS"}`;
                      },
                    },
                    {
                      label: "Date Created",
                      value: "DATE_CREATED",
                      format: (val) => moment(val).format("h:mma ddd MMM Do"),
                    },
                  ]}
                  pagination
                />
              </div>
            );
          }}
        </AdvancedFilter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop"])(withRouter(TicketsPage));
