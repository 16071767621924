import {Button, FormInput, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {useFormik} from "formik";
import {useCustomDocuments, useDefaultDocuments} from "../../../../../hooks/team/onboarding/onboarding-hooks";
import {MultiCheckbox} from "../../../../../components/form-elements/form-multi-checkbox";
import {DOCUMENT_TYPE} from "../documents/documents-helper";
import React from "react";
import {AddButton} from "../../../../../forms/team/onboarding/form-add-button";
import {Row} from "../../../../../v2/components/shared";
import {useLocation, useNavigate} from "react-router-dom";
import {BackButton} from "../BackButton";
import {packetValidation} from "./packets-helper";
import {useCreatePacket, useUpdatePacket} from "../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

export const ManagePacketPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const sessionStorageKey = "packetFormikValues";
  const sessionStoragePacketKey = "packet";

  const packet = location.state?.packet || JSON.parse(sessionStorage.getItem(sessionStoragePacketKey));

  const {isLoading: isDefaultLoading, error: isDefaultError, data: defaultDocuments} = useDefaultDocuments();
  const {isLoading: isCustomLoading, error: isCustomError, data: customDocuments} = useCustomDocuments();

  const initialValues = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {
    packetName: packet?.NAME ?? "",
    selectedDocuments: packet?.DOCUMENTS?.map((document) => document.ID) ?? [],
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: packetValidation,
    onSubmit: (values) => handleSubmit(values),
  });

  const {mutate: createPacket, isLoading: packetCreationLoading} = useCreatePacket();
  const {mutate: updatePacket, isLoading: packetUpdateLoading} = useUpdatePacket();

  const handlePacketCreation = (packetName, selectedDocuments) => {
    const payload = {
      PACKET_NAME: packetName,
      DOCUMENT_IDS: selectedDocuments,
    };

    createPacket(payload);

    navigatePostSubmit();
  };

  const handlePacketEdits = (packetName, selectedDocuments) => {
    const payload = {
      PACKET_ID: packet.ID,
      UPDATED_DOCUMENT_IDS: selectedDocuments,
      PACKET_NAME: packetName ?? packet.NAME,
    };

    updatePacket(payload);

    navigatePostSubmit();
  };

  const handleSubmit = (values) => {
    const {packetName, selectedDocuments} = values;

    if (packet) {
      handlePacketEdits(packetName, selectedDocuments);
    } else {
      handlePacketCreation(packetName, selectedDocuments);
    }
  };

  const documentCheckboxToggle = (options, document) => {
    const {selectedDocuments} = options.values;

    if (selectedDocuments?.includes(document.ID)) {
      const updatedDocuments = selectedDocuments.filter((item) => item !== document.ID);

      options.setFieldValue("selectedDocuments", updatedDocuments);
    } else {
      options.setFieldValue("selectedDocuments", [...selectedDocuments, document.ID]);
    }
  };

  const navigateBack = () => {
    sessionStorage.removeItem(sessionStorageKey);
    sessionStorage.removeItem(sessionStoragePacketKey);

    if (!location.state?.origin) {
      return navigate(location.state?.from ?? "/onboarding/company-packets");
    }
    navigate(location.state?.origin ?? "/onboarding/company-packets");
  };

  const navigatePostSubmit = () => {
    sessionStorage.removeItem(sessionStorageKey);
    sessionStorage.removeItem(sessionStoragePacketKey);

    navigate(location.state?.origin ?? "/onboarding/company-packets");
  };

  const formatCheckboxLabel = (document) => {
    return document.NAME;
  };

  const goToAddDocument = () => {
    const formikValues = JSON.stringify(formik.values);

    sessionStorage.setItem(sessionStorageKey, formikValues);
    sessionStorage.setItem(sessionStoragePacketKey, JSON.stringify(packet));

    navigate("/onboarding/create-document", {
      state: {
        from: location.pathname,
        origin: location.state?.origin ?? "/onboarding/company-packets",
        packet: packet,
      },
    });
  };

  return (
    <>
      <PageHeadings
        className={"py-4"}
        label={packet ? "Edit Packet" : "Create Packet"}
        description={
          packet
            ? "Edit your existing packet name and documents"
            : "Create a packet using existing files or add new company documents to it"
        }
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="mt-4 bg-white px-6 py-4 border rounded-md border-gray-200">
          <FormInput
            label="Packet Name"
            name="packetName"
            placeholder="My Onboarding Packet"
            options={formik}
          />

          <MultiCheckbox
            options={formik}
            data={defaultDocuments?.filter((document) => document.TYPE === DOCUMENT_TYPE.FEDERAL)}
            title={"Include federal documents?"}
            tooltip={
              "These federal documents are defaults that cannot be customized. If your company uses Dripos payroll, employee W-4s do not need to be included here, as they are part of the payroll-specific onboarding process."
            }
            name={"selectedDocuments"}
            onChange={(document) => documentCheckboxToggle(formik, document)}
            formatLabel={(document) => formatCheckboxLabel(document)}
            getSelectedValue={(document) => document.ID}
          />

          <MultiCheckbox
            options={formik}
            data={customDocuments}
            title={"Include custom documents?"}
            name={"selectedDocuments"}
            onChange={(document) => documentCheckboxToggle(formik, document)}
            formatLabel={(document) => formatCheckboxLabel(document)}
            getSelectedValue={(document) => document.ID}
            tooltip={
              "Custom documents are specific to your company and are customizable. Select an existing document for inclusion in the packet, or add a new custom document below."
            }
            noDataMessage={"No custom documents created yet. Add one below or in Company Documents tab."}
          />

          <AddButton label="Add Document" onClick={goToAddDocument}>
            <svg
              className="h-6 w-6 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </AddButton>
        </div>

        <Row className={"mt-4 justify-end"}>
          <BackButton goBack={navigateBack} />

          <Button
            label={packet ? "Save Changes" : "Create Packet"}
            type="submit"
            className="text-indigo-600 hover:text-white cursor-pointer"
            disabled={formik.isSubmitting || packetCreationLoading || packetUpdateLoading}
          />
        </Row>
      </form>
    </>
  );
};
