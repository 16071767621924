import React, {Component} from "react";
import {Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {RecurringInvoiceRequests} from "../../../utils/request-helpers/invoices/recurring-invoice-requests";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class RecurringInvoicesPages extends Component {
  state = {schedules: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let schedules = await RecurringInvoiceRequests.fetchAll();

    schedules.reverse();

    this.setState({schedules});
  }

  fetchNextBillingDateFormat(invoice, schedule) {
    if (!schedule.DRAFT_INVOICE_ID) {
      return <div className="text-sm font-medium text-gray-900 text-center">None</div>;
    }

    return (
      <div>
        <div className="text-sm font-medium text-gray-900 text-center">
          {moment(invoice.DATE_CREATED).format("MM/DD/YY")}
        </div>

        <div className="text-sm text-gray-500">
          {schedule.FREQUENCY} {schedule.INTERVAL_TYPE.capitalize()} Interval
        </div>
      </div>
    );
  }

  render() {
    const {schedules} = this.state;

    if (schedules === null) {
      return <Loading />;
    }

    const statusCategories = {
      All: schedules,
      Active: schedules.filter((item) => item.STATUS === "ACTIVE"),
      Expired: schedules.filter((item) => item.STATUS === "EXPIRED"),
      Paused: schedules.filter((item) => item.STATUS === "PAUSED"),
      Cancelled: schedules.filter((item) => item.STATUS === "CANCELLED"),
    };

    return (
      <div>
        <PageHeadings
          label="Recurring Invoices"
          description="Track and view your recurring invoices"
          button={{
            label: "Create Recurring Invoice",
            onClick: () => {
              this.props.router.navigate("/recurring-invoice");
            },
          }}
        />

        <Tab
          data={Object.keys(statusCategories).map((key) => {
            return {label: key + " (" + statusCategories[key].length + ")", id: key};
          })}
        >
          {(tab) => {
            let data = tab ? statusCategories[tab] : schedules;

            return (
              <Table
                pagination
                data={data}
                columns={[
                  {label: "ID", value: "ID", width: 1},
                  {
                    label: "Contact",
                    value: "INVOICE",
                    format: (invoice) => {
                      return invoice?.CONTACT?.FIRST_NAME + " " + invoice?.CONTACT.LAST_NAME;
                    },
                  },
                  {
                    label: "Status",
                    value: "STATUS",
                  },
                  {
                    label: "Amount",
                    value: "INVOICE",
                    format: (invoice) => toDollars(invoice.TICKET.PAYMENT_INTENT.TOTAL),
                  },
                  {
                    width: 1,
                    label: "Next Billing Date",
                    value: "INVOICE",
                    format: this.fetchNextBillingDateFormat,
                  },
                ]}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => this.props.router.navigate("/recurring-invoice/" + row.UNIQUE_ID),
                  },
                ]}
              />
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(RecurringInvoicesPages);
