import {FormBoolean, FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import TicketTypeDropdown from "../../dropdowns/sales/ticket-type-dropdown";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import {showConfirmAlert} from "../../utils/alert-helper";

class TaxWithholdingSettingsModal extends Component {
  open() {
    this.setState({}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {taxWithholding} = values;
    const {TAX_WITHHOLDING} = this.props.shop.settings;

    const payload = {ENABLED: taxWithholding === "1"};

    if (taxWithholding === "0" && TAX_WITHHOLDING === "1") {
      await showConfirmAlert(
        "Are you sure you want to proceed?",
        "Are you sure you want to proceed? Please note that turning off tax withholding will automatically payout your current tax withholding balance with the regular daily payout."
      ).catch((e) => {
        this.modal.close();
      });
    }

    await request("tax-withholding/set", "PUT", payload);

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});
    this.modal.close();
  };

  render() {
    let {TAX_WITHHOLDING} = this.props.shop.settings;

    return (
      <Modal
        label="Tax Withholding Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            taxWithholding: TAX_WITHHOLDING,
          }}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormBoolean
                  options={formikOptions}
                  name="taxWithholding"
                  label="Withhold tax from payouts"
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TaxWithholdingSettingsModal);
