import moment from "moment-timezone";

import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {Document, Page, View} from "@react-pdf/renderer";

import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

const PurchaseOrderPreview = (props) => {
  const {purchaseOrder} = props;
  const {location} = props.shop;

  let poNumber = "" + purchaseOrder.PO_NUMBER;
  poNumber = "#" + poNumber.padStart(7 - poNumber.length, "0");

  function fetchPurchaseOrderHtml() {
    return (
      <div className="bg-white mx-auto mt-3 h-[1054px] w-[816px]">
        <div className="pt-10"></div>

        <div
          style={{
            borderRadius: 4,
            border: "1px solid #F0F0F6",
            marginLeft: 40,
            marginRight: 40,
            marginBottom: 24,
            marginTop: 24,
            padding: 24,
          }}
        >
          <div style={{display: "flex", flexDirection: "row"}}>
            <div>
              <img
                src={getObjectImage(location, "ICON", "appicon.png")}
                style={{
                  width: 146,
                  height: 146,
                  borderRadius: 4,
                  objectFit: "contain",
                  maxWidth: 10000,
                }}
              />
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <div
                  style={{
                    fontSize: 24,
                    textAlign: "right",
                    marginBottom: 4,
                  }}
                >
                  Purchase Order
                </div>
                <div style={{fontSize: 12, textAlign: "right"}}>
                  Date: {moment().format("dddd, MMM Do, YYYY")}
                </div>
                <div style={{fontSize: 12, textAlign: "right"}}>Purchase Order: {poNumber}</div>
              </div>
            </div>
          </div>

          <table style={{marginTop: 10, width: "100%"}}>
            <tr
              style={{
                width: "100%",
              }}
            >
              {!!purchaseOrder.VENDOR && (
                <td>
                  <div>Vendor Information:</div>

                  <div style={{fontSize: 14, marginTop: 8}}>{purchaseOrder.VENDOR.NAME}</div>

                  <div style={{fontSize: 14}}>{purchaseOrder.VENDOR.ADDRESS}</div>

                  <div style={{fontSize: 14}}>
                    {purchaseOrder.VENDOR.CITY}, {purchaseOrder.VENDOR.STATE}, {purchaseOrder.VENDOR.ZIPCODE}
                  </div>
                </td>
              )}

              <td>
                <div>Ship To:</div>

                <div style={{fontSize: 14, marginTop: 8}}>{location.NAME}</div>

                <div style={{fontSize: 14}}>{location.ADDRESS}</div>

                <div style={{fontSize: 14}}>
                  {location.CITY}, {location.STATE}, {location.ZIPCODE}
                </div>
              </td>
            </tr>
          </table>

          <table style={{marginTop: 48}}>
            <tr
              style={{
                width: "100%",
              }}
            >
              <td style={{width: "60%"}}>
                <div style={{fontWeight: 14, marginBottom: 16}}>Items</div>
              </td>

              <td style={{width: "50%"}}>
                <div style={{fontWeight: 14, marginBottom: 16}}>Quantity</div>
              </td>

              <td style={{width: "25%"}}>
                <div style={{fontWeight: 14, marginBottom: 16}}>Total</div>
              </td>
            </tr>

            <tr style={{width: "100%"}}>
              <td colSpan={4}>
                <div style={{borderBottom: "2px solid #F0F0F6"}} />
              </td>
            </tr>

            {purchaseOrder.ITEMS.map((item, index) => (
              // <div key={item.ID || index} style={{width: "100%"}} className={"border-2 max-w-full"}>
              //   {index > 0 && (
              //     <tr style={{width: "100%"}}>
              //       <td colSpan={4}>
              //         <div style={{borderBottom: "1px solid #F0F0F6"}} />
              //       </td>
              //     </tr>
              //   )}

              <tr
                key={item.ID || index}
                style={{
                  width: "100%",
                }}
              >
                <td style={{width: "60%"}}>
                  <div
                    style={{
                      fontWeight: 14,
                      marginTop: 14,
                      marginBottom: 16,
                    }}
                  >
                    {item.NAME}
                  </div>
                </td>

                <td style={{width: "50%"}}>
                  <div
                    style={{
                      fontWeight: 14,
                      marginTop: 14,
                      marginBottom: 16,
                    }}
                  >
                    {item.QUANTITY}
                  </div>
                </td>

                <td style={{width: "25%"}}>
                  <div
                    style={{
                      fontWeight: 14,
                      marginTop: 14,
                      marginBottom: 16,
                    }}
                  >
                    ${toDollars(item.AMOUNT)}
                  </div>
                </td>
              </tr>
              // </div>
            ))}

            <tr style={{width: "100%"}}>
              <td colSpan={4}>
                <div style={{borderBottom: "2px solid #F0F0F6"}} />
              </td>
            </tr>
          </table>

          {purchaseOrder.NOTES && (
            <div
              style={{
                marginTop: 32,
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                NOTES
              </div>

              <div
                style={{
                  marginTop: 24,
                  borderRadius: 4,
                  border: "1px solid #F0F0F6",
                  padding: 24,
                  width: "100%",
                }}
              >
                <table>
                  <tr style={{width: "100%"}}>
                    <td>
                      <div
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {purchaseOrder.NOTES}
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          )}

          <table style={{marginTop: 24}}>
            <tr style={{width: "100%"}}>
              <td style={{width: "99%"}}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                  }}
                >
                  Subtotal
                </div>
              </td>

              <td>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: "600",
                    textAlign: "right",
                  }}
                >
                  ${toDollars(purchaseOrder.AMOUNT)}
                </div>
              </td>
            </tr>

            <tr style={{width: "100%"}}>
              <td style={{width: "99%"}}>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 12,
                    fontWeight: "600",
                  }}
                >
                  Taxes
                </div>
              </td>

              <td>
                <div
                  style={{
                    fontSize: 16,
                    marginTop: 12,
                    fontWeight: "600",
                    textAlign: "right",
                  }}
                >
                  ${toDollars(purchaseOrder.AMOUNT_TAX)}
                </div>
              </td>
            </tr>

            <tr style={{width: "100%"}}>
              <td style={{width: "99%"}}>
                <div
                  style={{
                    fontSize: 24,
                    marginTop: 12,
                    fontWeight: "600",
                  }}
                >
                  Total
                </div>
              </td>

              <td>
                <div
                  style={{
                    fontSize: 24,
                    marginTop: 12,
                    fontWeight: "600",
                  }}
                >
                  ${toDollars(purchaseOrder.TOTAL)}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }

  return (
    <Document>
      <Page>
        <View>{fetchPurchaseOrderHtml()}</View>
      </Page>
    </Document>
  );
};

export default PurchaseOrderPreview;
