import {forwardRef, useState} from "react";

const FunctionalCheckbox = forwardRef(
  ({label, name, formikOptions = null, onChange, initialValue, ...props}, ref) => {
    const [checked, setChecked] = useState(initialValue ?? false);

    return (
      <div className={"flex-row"}>
        <input
          className={
            "form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-indigo-600 transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
          }
          type="checkbox"
          name={name}
          checked={checked}
          onChange={onChange}
          {...props}
        />

        <label className={"pl-4"}>{label}</label>
      </div>
    );
  }
);

export default FunctionalCheckbox;
