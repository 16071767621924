import React, {Component} from "react";

import TestReceipt from "../../../pages/receipt.json";
import {parseIdDict, randomString, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import BlockEditor from "../../../pages/receipt/block-editor";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import {FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";

import RewardAndCard from "../../../pages/ticket.json";
import RegularReceipt from "../../../assets/tickets/receipts/regular-ticket.json";
import UnpaidTicket from "../../../assets/tickets/receipts/regular-ticket-unpaid.json";
import GiftCardReceipt from "../../../assets/tickets/receipts/gift-card-processing.json";
import {PAYMENT_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/ticket-constants";
import {objectDot} from "../../../utils/util";

const RECEIPT_DATA = {
  DEFAULT: RegularReceipt,
  PROCESSING: GiftCardReceipt,
  PAYMENTS: RewardAndCard,
  UNPAID: UnpaidTicket,
};

class ReceiptEditor extends Component {
  state = {
    receipts: null,
    currentBlock: null,
    currentContext: null,
    ticketTemplate: "DEFAULT",
    ticket: RegularReceipt,
    receipt: {},
    currentStone: {},
    past: [],
  };

  componentDidMount() {
    let {receipt} = this.props;

    if (!receipt) {
      receipt = TestReceipt;
    }

    this.setState({
      currentStone: this.assignIDS(receipt),
      receipt: JSON.parse(JSON.stringify(this.assignIDS(receipt))),
      past: [],
    });
  }

  fetchReceipt() {
    return this.state.receipt;
  }

  selectBlock(currentBlock) {
    this.setState({currentBlock});
  }

  undoAction() {
    const {past} = this.state;

    const lastState = past.pop();

    if (past.length === 0) {
      this.props.setUndo(false);
    }

    this.setState({
      receipt: lastState,
      currentStone: JSON.parse(JSON.stringify(lastState)),
    });
  }

  getPaymentTypeText(paymentObject) {
    switch (paymentObject.TYPE) {
      case PAYMENT_TYPES.CUSTOM_TYPE:
        return "Custom Payment";
      case PAYMENT_TYPES.CASH: {
        try {
          return `Cash${
            paymentObject.CONTENT - paymentObject.AMOUNT > 0
              ? ` (Change Due ${toDollars(paymentObject.CONTENT - paymentObject.AMOUNT, true)})`
              : ""
          }`;
        } catch (_) {}
        return "Cash";
      }
      case PAYMENT_TYPES.CHECK:
        return "Check";
      case PAYMENT_TYPES.BILLING:
        // TODO account number/name
        return "Billing Account";
      case PAYMENT_TYPES.E_GIFT_CARD:
      case PAYMENT_TYPES.GIFT_CARD:
        // TODO gift card number
        return "Gift Card";
      case PAYMENT_TYPES.CARD_PRESENT:
      case PAYMENT_TYPES.CARD_NOT_PRESENT: {
        try {
          return (
            paymentObject.CARD.BRAND.toUpperCase() +
            " " +
            paymentObject.CARD.FUNDING.toUpperCase() +
            " *" +
            paymentObject.CARD.LAST_FOUR
          );
        } catch (_) {}
        return "Card";
      }
      case PAYMENT_TYPES.REWARD:
        return "Reward";
      case PAYMENT_TYPES.BALANCE:
        return "Balance";
      default:
        return "Payment";
    }
  }
  assignIDS(block) {
    block.ID = "block_" + randomString(24);

    if (block.CHILDREN) {
      block.CHILDREN = block.CHILDREN.map((item) => {
        item.PARENT = block.ID;

        return this.assignIDS(item);
      });
    }

    return block;
  }

  renderAddButton() {
    return (
      <div className={"border-indigo-600"}>
        <FontAwesomeIcon
          onClick={() => this.addBlock()}
          icon={"plus"}
          color={"rgb(79 70 229)"}
          className={"w-5 h-5"}
        />
      </div>
    );
  }

  openBlockEditor(event, {block, context}) {
    const {viewOnly} = this.props;

    if (viewOnly) return;

    this.setState({currentBlock: block.ID, currentContext: context});
    event.stopPropagation();
  }

  addBlock() {
    const {receipt} = this.state;
    const blockId = "blk" + randomString(32);

    //TODO default block params
    const blockPayload = {
      ID: blockId,
      TYPE: "TEXT",
      TEXT: {
        CONTENT: "HELLO",
      },
    };

    let receiptChildren = receipt.CHILDREN;
    receiptChildren.push(blockPayload);

    this.setState({currentBlock: blockPayload.ID, receipt});
  }

  updateBlock(blockId, payload) {
    const {receipt, currentStone, past} = this.state;

    this.props.setUndo(true);

    this.setState({past: [...past, currentStone]});

    const availableBlocks = {};
    const doBlock = (block) => {
      availableBlocks[block.ID] = block;

      if (block.CHILDREN) {
        for (let child of block.CHILDREN) {
          doBlock(child);
        }

        const blockIndex = block.CHILDREN.findIndex((item) => {
          return item.ID === blockId;
        });

        if (blockIndex !== -1 && payload === null) {
          block.CHILDREN.splice(blockIndex, 1);
        }
      }
    };

    doBlock(receipt);

    if (availableBlocks[blockId]) {
      if (payload !== null) {
        for (let key of Object.keys(payload)) {
          availableBlocks[blockId][key] = payload[key];
        }
      }
    }

    this.setState({currentStone: JSON.parse(JSON.stringify(receipt))});

    this.forceUpdate();
  }

  renderContent(block, content) {
    if (block.TYPE === "IMAGE") {
      const {SOURCE, WIDTH, HEIGHT} = block.IMAGE;

      return <img src={SOURCE} style={{width: WIDTH + "px", height: HEIGHT + "px"}} alt="" />;
    }

    if (block.TYPE === "TEXT") {
      let textContainer = {fontSize: 12};
      let finalText = block.CONTENT;

      if (block.TEXT) {
        if (block.TEXT.CONTENT) {
          finalText = block.TEXT.CONTENT;
        }

        if (block.TEXT.FONT_WEIGHT) {
          textContainer.fontWeight = block.TEXT.FONT_WEIGHT;
        }

        if (block.TEXT.FONT_SIZE) {
          textContainer.fontSize = block.TEXT.FONT_SIZE;
        }

        if (block.TEXT.COLOR) {
          textContainer.color = block.TEXT.COLOR;
        }

        if (block.TEXT.TEXT_ALIGN) {
          textContainer.textAlign = block.TEXT.TEXT_ALIGN.toLowerCase();
        }

        for (let key of Object.keys(content)) {
          if (typeof content[key] === "string" || typeof content[key] === "number" || content[key] === null) {
            let replaceText = content[key];

            if (block.TEXT.FORMAT) {
              const formatDict = parseIdDict(block.TEXT.FORMAT, "KEY");

              if (formatDict[key]) {
                if (formatDict[key].TYPE === "DOLLARS") {
                  replaceText = "$" + toDollars(replaceText);
                } else if (formatDict[key].TYPE === "DATE") {
                  replaceText = moment(replaceText).format(formatDict[key].CONTENT);
                }
              }
            }

            finalText = ("" + finalText).replaceAll(`{{${key}}}`, replaceText || "");
          }
        }
      }

      return <div style={textContainer}>{finalText}</div>;
    }
  }

  renderBlock(block, context) {
    const {currentBlock} = this.state;

    if (block.TYPE === "NEW_LINE") {
      return "\n";
    }

    if (block.TYPE === "PAYMENTS") {
      return (
        <div>
          {context.PAYMENT_INTENT.PAYMENTS.map((item) => {
            return this.renderBlock(
              {...block, TYPE: "CONTAINER"},
              {...item, TYPE: this.getPaymentTypeText(item)}
            );
          })}
        </div>
      );
    }

    if (block.TYPE === "LINE_ITEMS") {
      return (
        <div>
          {context.ITEMS.filter((item) => item.TYPE === "PRODUCT" || item.TYPE === "CUSTOM_PRODUCT").map(
            (item) => {
              return this.renderBlock({...block, TYPE: "CONTAINER"}, item);
            }
          )}
        </div>
      );
    }

    if (block.TYPE === "OPTIONS") {
      return (
        <div style={{display: "flex", flexDirection: "row"}}>
          {context?.OPTIONS?.map((item, index) => {
            return (
              <>
                {index > 0 && block.JOIN ? this.renderBlock(block.JOIN, context) : <div />}

                {this.renderBlock({...block, TYPE: "CONTAINER"}, item)}
              </>
            );
          }) ?? <></>}
        </div>
      );
    }

    if (block.TYPE === "SELECTIONS") {
      let customizations = context.SELECTIONS.filter((item) => {
        return item.MODIFIER_TYPE !== 6;
      }).reduce((dict, item) => {
        if (item.MODIFIER_ID in dict) {
          dict[item.MODIFIER_ID].push({...item});
        } else {
          dict[item.MODIFIER_ID] = [{...item}];
        }

        return dict;
      }, {});

      return (
        <div
          style={{
            display: "flex",
            flexDirection: block.JOIN?.TYPE === "NEW_LINE" ? "column" : "row",
          }}
        >
          {Object.values(customizations)
            .reduce((accum, item) => {
              return [...accum, {...item[0], OPTIONS: item}];
            }, [])
            .map((item, index) => {
              if (block.JOIN?.TYPE === "NEW_LINE") {
                return <div>{this.renderBlock({...block, TYPE: "CONTAINER", SHRINK: true}, item)}</div>;
              }

              return (
                <>
                  {index > 0 && block.JOIN ? this.renderBlock(block.JOIN, context) : <div />}

                  {this.renderBlock({...block, TYPE: "CONTAINER", SHRINK: true}, item)}
                </>
              );
            })}
        </div>
      );
    }

    let containerStyle = {
      flexDirection: "column",
      display: "flex",
      flex: "1 1",
    };

    if (block.CONDITIONS) {
      for (let condition of block.CONDITIONS) {
        const values = Array.isArray(condition.VALUE) ? condition.VALUE : [condition.VALUE];

        let hasFailingCondition = false;
        if (condition.OPERATOR === "<") {
          for (let value of values) {
            if (context[condition.KEY] >= parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === "<=") {
          for (let value of values) {
            if (context[condition.KEY] > parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === "NULL") {
          // eslint-disable-next-line no-unused-vars
          for (let value of values) {
            if (context[condition.KEY] !== null && context[condition.KEY] !== "") {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === "SET") {
          // eslint-disable-next-line no-unused-vars
          for (let value of values) {
            // This gets the value of context at "xyz.abc.123" since context can be a nested object
            const contextValue = objectDot(context, condition.KEY);

            if (
              contextValue === null ||
              contextValue === "" ||
              (Array.isArray(contextValue) && contextValue.length === 0)
            ) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === ">") {
          for (let value of values) {
            if (context[condition.KEY] <= parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === ">=") {
          for (let value of values) {
            if (context[condition.KEY] <= parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === "!=") {
          for (let value of values) {
            if (context[condition.KEY] === parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (condition.OPERATOR === "=") {
          for (let value of values) {
            if (context[condition.KEY] !== parseInt(value)) {
              hasFailingCondition = true;
            }
          }
        }

        if (hasFailingCondition) {
          containerStyle.backgroundColor = "#FF7276";

          return <div />;
        }
      }
    }

    if (block.TYPE === "ROW" || block.DIRECTION === "ROW") {
      containerStyle.flexDirection = "row";
    }

    if (block.FLEX) {
      containerStyle.flex = 1;
    }

    if (block.PADDING) {
      const padding = block.PADDING.split(" ");

      if (padding.length === 1) {
        containerStyle.padding = parseInt(padding);
      } else if (padding.length === 4) {
        containerStyle.paddingTop = parseInt(padding[0]);
        containerStyle.paddingRight = parseInt(padding[1]);
        containerStyle.paddingBottom = parseInt(padding[2]);
        containerStyle.paddingLeft = parseInt(padding[3]);
      }
    }

    if (block.MARGIN) {
      const margin = block.MARGIN.split(" ");

      if (margin.length === 1) {
        containerStyle.margin = parseInt(margin[0]);
      } else if (margin.length === 4) {
        containerStyle.marginTop = parseInt(margin[0]);
        containerStyle.marginRight = parseInt(margin[1]);
        containerStyle.marginBottom = parseInt(margin[2]);
        containerStyle.marginLeft = parseInt(margin[3]);
      }
    }

    if (block.BORDER) {
      containerStyle.borderColor = "gray";
      containerStyle.borderStyle = "solid";

      const border = block.BORDER.split(" ");

      if (border.length === 1) {
        containerStyle.border = parseInt(border[0]);
      } else if (border.length === 4) {
        containerStyle.borderTopWidth = parseInt(border[0]);
        containerStyle.borderRightWidth = parseInt(border[1]);
        containerStyle.borderBottomWidth = parseInt(border[2]);
        containerStyle.borderLeftWidth = parseInt(border[3]);
      }
    }

    if (block.BACKGROUND) {
      containerStyle.backgroundColor = block.BACKGROUND;
    }

    if (block.RADIUS) {
      const radius = block.RADIUS.split(" ");

      if (radius.length === 1) {
        containerStyle.borderRadius = parseInt(block.RADIUS);
      }

      if (radius.length > 1) {
        containerStyle.borderRadius = block.RADIUS;
      }
    }

    if (block.HORIZONTAL_ALIGN) {
      if (containerStyle.flexDirection === "row") {
        containerStyle.justifyContent = block.HORIZONTAL_ALIGN.toLowerCase();
      } else {
        containerStyle.alignItems = block.HORIZONTAL_ALIGN.toLowerCase();
      }
    }

    if (block.VERTICAL_ALIGN) {
      if (containerStyle.flexDirection === "row") {
        containerStyle.alignItems = block.VERTICAL_ALIGN.toLowerCase();
      } else {
        containerStyle.justifyContent = block.VERTICAL_ALIGN.toLowerCase();
      }
    }

    if (block.TYPE === "TEXT" || block.TYPE === "IMAGE" || block.TYPE === "SELECTIONS" || block.SHRINK) {
      if (!block.FLEX) {
        delete containerStyle.flex;
      }
    }

    const hasCurrent = block?.CHILDREN?.some((item) => {
      return item.ID === currentBlock;
    });

    if (hasCurrent) {
      containerStyle.backgroundColor = "lightgray";
    }

    if (currentBlock === block.ID) {
      containerStyle.backgroundColor = "lightblue";
    }

    return (
      <div onClick={(event) => this.openBlockEditor(event, {block, context})} style={containerStyle}>
        {this.renderContent(block, context)}

        {block.CHILDREN &&
          block.CHILDREN.map((item) => {
            return this.renderBlock(item, context);
          })}
      </div>
    );
  }

  render() {
    const {currentBlock, currentContext, receipt, ticketTemplate, ticket} = this.state;
    const {serverReceipt, viewOnly} = this.props;
    const {location} = this.props.shop;

    const availableBlocks = {};
    const doBlock = (block) => {
      availableBlocks[block.ID] = block;

      if (block.CHILDREN) {
        for (let child of block.CHILDREN) {
          doBlock(child);
        }
      }
    };

    doBlock(receipt);

    const actualItems = ticket.ITEMS.filter((item) => ["PRODUCT"].includes(item.TYPE));

    const extraData = {
      LOCATION_NAME: location.NAME,
      LOCATION_ADDRESS: location.ADDRESS,
      LOCATION_CITY: location.CITY,
      LOCATION_STATE: location.STATE,
      LOCATION_ZIPCODE: location.ZIPCODE,
    };

    const ticketPayload = {
      ITEM_NUMBER: 1,
      ITEM_TOTAL: 1,
      ...extraData,
      ...ticket.PAYMENT_INTENT,
      ...ticket,
    };

    ticketPayload.AMOUNT -= ticketPayload.AMOUNT_FEES + ticketPayload.AMOUNT_TIP;

    return (
      <div style={{display: "flex"}}>
        <div style={{flex: 1}}>
          {serverReceipt?.ITEM_SPECIFIC === 1 ? (
            actualItems.map((item, index) => {
              return (
                <div
                  style={{
                    width: 285,
                    display: "flex",
                    backgroundColor: "gray",
                    flexDirection: "column",
                    marginBottom: 24,
                  }}
                >
                  {this.renderBlock(receipt, {
                    ...ticketPayload,
                    ITEMS: [item],
                    ITEM_NUMBER: index + 1,
                    ITEM_TOTAL: actualItems.length,
                  })}
                </div>
              );
            })
          ) : (
            <div
              style={{
                width: 285,
                display: "flex",
                backgroundColor: "gray",
                flexDirection: "column",
              }}
            >
              {this.renderBlock(receipt, ticketPayload)}
            </div>
          )}

          {!viewOnly && (
            <>
              <div>
                <FormSelect
                  flex
                  label="Ticket Examples"
                  data={[
                    {label: "Default", value: "DEFAULT"},
                    {
                      label: "Multi Item w/ Processing Fees",
                      value: "PROCESSING",
                    },
                    {
                      label: "Card and Reward Payment Types",
                      value: "PAYMENTS",
                    },
                    {
                      label: "Unpaid Ticket",
                      value: "UNPAID",
                    },
                  ]}
                  value={ticketTemplate}
                  onChange={(type) => {
                    this.setState({
                      ticketTemplate: type,
                      ticket: JSON.parse(JSON.stringify(RECEIPT_DATA[type])),
                    });
                  }}
                />
              </div>

              <div>
                Color Key:
                <div>Blue: Selected Block</div>
                <div>Gray: Parent Block</div>
                <div>Red: Block condition is failing</div>
                Ticket Key:
                <div>NAME - Order Name</div>
                <div>ORDER_NANE - Order Name</div>
              </div>

              {this.renderAddButton()}
            </>
          )}
        </div>

        <div style={{margin: viewOnly ? 0 : 24, display: "flex"}}>
          {currentBlock && (
            <BlockEditor
              blocks={availableBlocks}
              parentBlock={
                availableBlocks[currentBlock] ? availableBlocks[availableBlocks[currentBlock].PARENT] : null
              }
              block={availableBlocks[currentBlock]}
              context={currentContext}
              selectBlock={(block) => {
                this.setState({currentBlock: block});
              }}
              updateBlock={(blockId, payload) => {
                this.updateBlock(blockId, payload);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

ReceiptEditor.propTypes = {
  TYPE: PropTypes.oneOf(["CONTAINER", "TEXT", "LINE_ITEMS", "SELECTIONS", "IMAGE"]),
  VERTICAL_ALIGN: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
  HORIZONTAL_ALIGN: PropTypes.oneOf(["flex-start", "center", "flex-end"]),

  PADDING: PropTypes.string,
  MARGIN: PropTypes.string,

  TEXT: PropTypes.objectOf({
    CONTENT: PropTypes.string,
    TEXT_ALIGN: PropTypes.oneOf(["LEFT", "CENTER", "RIGHT"]),
    FONT_WEIGHT: PropTypes.oneOf(["400", "500", "600", "700"]),
  }),

  CONDITIONS: PropTypes.arrayOf(
    PropTypes.objectOf({
      KEY: PropTypes.string,
      OPERATOR: PropTypes.oneOf([">", ">=", "<", "<=", "=", "!="]),
      VALUE: PropTypes.any,
    })
  ),

  IMAGE: PropTypes.objectOf({
    SOURCE: PropTypes.string,
    HEIGHT: PropTypes.number,
    WIDTH: PropTypes.number,
  }),

  JOIN: PropTypes.objectOf({}),

  CHILDREN: PropTypes.array,
};

// Line Items -> Selections (optional) -> Options (optional)

export default setupReduxConnection(["shop"])(ReceiptEditor);
