export const getDescription = (t, getI18NextKey, i9 = false) => {
  const name = i9 ? "referPrompt.i9" : "referPrompt.w4";

  const url = i9
    ? "https://www.uscis.gov/sites/default/files/document/forms/i-9.pdf"
    : "https://www.irs.gov/pub/irs-pdf/fw4.pdf";

  return (
    <p className={"mb-2"}>
      {t(getI18NextKey("referPrompt.part1"))}
      <a
        className={"text-indigo-500 hover:text-indigo-800 font-semibold"}
        href={url}
        target="_blank"
        rel={"noopener noreferrer"}
      >
        {t(getI18NextKey(name))}
      </a>
      {t(getI18NextKey("referPrompt.part2"))}
    </p>
  );
};

export const handleSignatureValidation = (formik) => {
  const isValidSignature = formik?.values?.signature?.trim()?.length > 0;

  if (!isValidSignature) {
    formik.setFieldError("signature", "Signature is required to submit");
  }

  return isValidSignature;
};
