import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {Modal, FormInput, FormSelect, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import FormStateSelect from "../../components/form-state-select";
import {request} from "../../utils/request";
import {TIMEZONE_LABELS} from "../../utils/settings-constants";

class LocationModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async ({
    NAME,
    DESCRIPTION,
    INSTRUCTIONS,
    ADDRESS,
    CITY,
    STATE,
    ZIPCODE,
    LATITUDE,
    LONGITUDE,
    TIMEZONE,
  }) => {
    let locationPayload = {
      NAME,
      DESCRIPTION,
      INSTRUCTIONS,
      ADDRESS,
      CITY,
      STATE,
      ZIPCODE,
      LATITUDE,
      LONGITUDE,
    };

    let settingsPayload = {
      TIMEZONE,
    };

    await Promise.all([
      request("location", "PATCH", locationPayload),
      request("partner/settings/v2", "PATCH", {SETTINGS: settingsPayload}),
    ]);

    let {location} = this.props.shop;
    location = {...location, ...locationPayload};

    let settings = await request("settings/shop", "GET");

    this.props.updateLocation(location);
    this.props.updateShop({settings});

    this.modal.close();
  };

  render() {
    let {location} = this.props.shop;
    let {TIMEZONE} = this.props.shop.settings;

    return (
      <Modal
        label="Shop Settings"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          initialValues={{...location, TIMEZONE}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput label="Name" name={"NAME"} options={formikOptions} />
                <FormTextArea label="Description" name={"DESCRIPTION"} options={formikOptions} />
                <FormTextArea label="Instructions" name={"INSTRUCTIONS"} options={formikOptions} />

                <FormInput label="Address" name={"ADDRESS"} options={formikOptions} />

                <div className="flex flex-row justify-between">
                  <FormInput label="City" name="CITY" options={formikOptions} />
                  <FormStateSelect className={"mx-3"} label="State" name="STATE" options={formikOptions} />

                  <FormInput className="w-24" label="Zipcode" name="ZIPCODE" options={formikOptions} />
                </div>

                <div className="flex flex-row justify-between">
                  <FormInput label="Latitude" name={"LATITUDE"} options={formikOptions} />

                  <FormInput label="Longitude" name={"LONGITUDE"} options={formikOptions} />
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(LocationModal);
