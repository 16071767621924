import {request} from "../../request";

export const EmployeeRequests = {
  fetchEmployee: async (employeeId) => {
    return request("employees/" + employeeId, "GET", null);
  },
  fetchEmployeePayRates: async (employeeId) => {
    return request("pay/rates/employee/" + employeeId, "GET");
  },
  fetchEmployeeTimeOffPolicies: async (employeeId) => {
    return request("timeoff/policies/employee/" + employeeId, "GET");
  },
  fetchEmployeeRoles: async (employeeId) => {
    return request("employeeroles/employee/" + employeeId, "GET");
  },
  fetchEmployeeReports: async (employeeId) => {
    return request("employees/" + employeeId + "/reports", "GET");
  },
  fetchEmployeeSchedule: async (employeeId) => {
    return request("scheduling/schedule/employee/" + employeeId, "GET");
  },
  fetchEmployeeTermination: async (employeeId) => {
    return request("termination/employee/" + employeeId, "GET");
  },
  fetchEmployeeUnavailabilities: async (employeeId) => {
    return request("scheduling/preferences/v2/" + employeeId, "GET");
  },
  fetchEmployeeTimeOffRequests: async (employeeId) => {
    return request("timeoff/requests/employee/" + employeeId, "GET");
  },
  fetchEmployeeNotes: async (employeeId) => {
    return request("employees/notes/" + employeeId, "GET");
  },
  fetchEmployeeFiles: async (employeeId) => {
    return request("employees/documents/" + employeeId, "GET");
  },
  fetchEmployeeFolders: async (employeeId) => {
    return request("employees/folder/" + employeeId, "GET");
  },
  fetchEmployeeFranchiseLocations: async () => {
    return request("partner/franchise/locations", "GET");
  },
  fetchAllEmployees: async () => {
    return request("employees", "GET");
  },
  fetchLocations: async () => {
    return request("employees/locations", "GET");
  },
  uploadDocument: async (params) => {
    return request("employees/aws/document/", "POST", params);
  },
  createDocument: async (params) => {
    return request("employees/document/", "POST", params);
  },
  updateDocument: async (params) => {
    return request("employees/document", "PATCH", params);
  },
  archiveDocument: async (uniqueId) => {
    return request(`employees/document/${uniqueId}/archive`, "PATCH");
  },
};

export const CompanyFolders = {
  create: async (params) => {
    return request("company/files/folders", "POST", params);
  },
  fetch: async () => {
    return request("company/files/folders", "GET");
  },
  update: async (params, uniqueID) => {
    return request("company/files/folders" + uniqueID, "PATCH", params);
  },
  delete: async (uniqueID) => {
    return request("company/files/folders" + uniqueID, "DELETE");
  },
};
