import moment from "moment-timezone";
import PropTypes from "prop-types";
import React, {Component} from "react";
import {CSVLink} from "react-csv";
import {Download} from "react-feather";
import {DownloadIcon} from "@heroicons/react/solid";

/*
 * Designed to be placed in a <CardHeader>
 */
class CSVDownloadButton extends Component {
  getDateRangeString = () => {
    let {startEpoch, endEpoch} = this.props;

    if (startEpoch && !endEpoch) {
      return moment(startEpoch).format("MM-DD");
    }

    if (!startEpoch || !endEpoch) {
      return "";
    }
    return moment(startEpoch).format("MM-DD") + "--" + moment(endEpoch).format("MM-DD");
  };

  /*
   * Translates react-data-table-component (data, columns) props into downloadable CSV.
   * - Executes columns.format function if it exists. (return value must be literal (string, number, boolean)
   * - Uses selectors as the KEY/VALUE pairing for downloads
   */
  formatForDataTable = () => {
    let {dataTableData, dataTableColumns} = this.props;
    let headers = [];
    let data = [];

    for (const columnRecord of dataTableColumns) {
      headers.push({
        label: columnRecord.name,
        key: columnRecord.selector,
      });
    }

    for (const record of dataTableData) {
      let newDictionary = {};

      for (const column of dataTableColumns) {
        if (column.format) {
          newDictionary[column.selector] = column.format(record);
        } else if (column.selector in record) {
          newDictionary[column.selector] = record[column.selector];
        }
      }

      if (newDictionary !== {}) {
        data.push(newDictionary);
      }
    }

    return {data, headers};
  };

  render() {
    let {dataOverride, headersOverride, dataTableColumns, dataTableData, filenamePrefix} = this.props;

    if (!((dataOverride && headersOverride) || (dataTableColumns && dataTableData)) || !filenamePrefix) {
      alert("CSV Download Missing Required Props");
      return null;
    }

    if (dataTableData.length === 0) {
      // Hide Download on Empty Dataset
      return null;
    }

    let formattedData = null;
    if (!(dataOverride && headersOverride)) {
      formattedData = this.formatForDataTable();
    }

    return (
      <CSVLink
        data={dataOverride ? dataOverride : formattedData.data}
        headers={headersOverride ? headersOverride : formattedData.headers}
        filename={filenamePrefix + this.getDateRangeString() + ".csv"}
        style={{float: "right"}}
        className="m-t-5 m-b-10"
      >
        <button
          type="button"
          className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-gray-700 border-b bg-gray-50 hover:bg-gray-100"
        >
          <DownloadIcon className="h-5 w-5" />
        </button>
      </CSVLink>
    );
  }
}

CSVDownloadButton.propTypes = {
  dataTableColumns: PropTypes.any,
  dataTableData: PropTypes.any,
  dataOverride: PropTypes.any,
  headersOverride: PropTypes.any,
  endEpoch: PropTypes.number.isRequired,
  filenamePrefix: PropTypes.string.isRequired,
  startEpoch: PropTypes.number.isRequired,
};

export default CSVDownloadButton;
