import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {getStore, setupReduxConnection} from "../../../../redux";
import {showSuccessNotification} from "../../../../utils/notification-helper";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {removeIngredient, upsertIngredient, upsertUnit} from "../../../../redux/supply";
import {
  IngredientRequests,
  UnitRequests,
} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import IngredientForm from "../../../../forms/operations/supply/ingredients/ingredient-form";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import * as Yup from "yup";
import {Formik} from "formik";

export const CALCULATION_TYPES = {
  USE_CHILDREN: "USE_CHILDREN",
  STANDARD: "STANDARD",
};

export const CALCULATION_TYPE_DISPLAYS = {
  [CALCULATION_TYPES.USE_CHILDREN]: "Consume Sub-Recipes on Sale",
  [CALCULATION_TYPES.STANDARD]: "Standard",
};

export const INGREDIENT_TYPES = {
  STANDARD: "STANDARD",
};

export const INGREDIENT_TYPE_DISPLAYS = {
  [INGREDIENT_TYPES.STANDARD]: "Ingredient",
};

class SupplyChainIngredientModal extends Component {
  state = {ingredient: null};
  resolve = null;

  open(ingredient = null) {
    this.setState({ingredient}, () => this.modal.open());

    return new Promise((resolve) => {
      this.resolve = resolve;
    });
  }

  async constructPayload(values) {
    let {
      name,
      unit,
      unitName,
      cost,
      displayUnit,
      calculation,
      type,
      expirationTime,
      pricing,
      par,
      locations,
      categories,
    } = values;

    if (unit === null && unitName) {
      const payload = {NAME: unitName};
      unit = await UnitRequests.createUnit(payload);
      getStore().dispatch(upsertUnit(unit));
      unit = unit.ID;
      displayUnit = unit;
    }

    const parsedPar = parseInt(par);
    const PAR = isNaN(parsedPar) ? null : parsedPar;

    return {
      NAME: name,
      PAR,
      UNIT_ID: unit,
      COST_PER_UNIT: decimalToDollars(cost),
      DISPLAY_UNIT_ID: displayUnit,
      CALCULATION_TYPE: calculation,
      TIME_TO_EXPIRATION: expirationTime,
      TYPE: type,
      LOCATIONS: locations,
      CATEGORIES: categories,
      VENDOR_ITEMS: pricing.map((_pricing) => ({
        ID: _pricing.id,
        PRODUCT_SKU: _pricing.sku,
        NAME: _pricing.name,
        VENDOR_ID: _pricing.vendor,
        PRICE_PER_CASE: decimalToDollars(_pricing.price),
        CASE_SIZE: _pricing.caseSize,
        UNIT_ID: _pricing.caseUnit === -1 ? unit : _pricing.caseUnit,
        IS_DEFAULT: _pricing.isDefault,
      })),
    };
  }

  async saveIngredient(values) {
    const {ingredient} = this.state;
    const payload = await this.constructPayload(values);

    const serverIngredient = await IngredientRequests.updateIngredient(ingredient.ID, payload);

    getStore().dispatch(upsertIngredient(serverIngredient));
    this.modal.close();
  }

  async createIngredient(values) {
    const payload = await this.constructPayload(values);
    const serverIngredient = await IngredientRequests.createIngredient(payload);

    if (values.unitName) {
      const serverUnit = await UnitRequests.updateUnit(payload.UNIT_ID, {
        INGREDIENT_ID: serverIngredient.ID,
      });

      getStore().dispatch(upsertUnit(serverUnit));
    }

    getStore().dispatch(upsertIngredient(serverIngredient));
    this.resolve && this.resolve(serverIngredient);
    this.modal.close();
  }

  async deleteIngredient() {
    const {ingredient} = this.state;

    await showConfirmAlert("Are you sure you want to delete this ingredient?");

    try {
      await IngredientRequests.deleteIngredient(ingredient.ID);
      getStore().dispatch(removeIngredient(ingredient.ID));

      showSuccessNotification(
        "Ingredient Deleted Successfully.",
        `${ingredient.NAME} was archived successfully.`
      );
    } finally {
      this.props.syncState && (await this.props.syncState());
      this.modal.close();
    }
  }

  fetchInitialValues(ingredient, locations) {
    return {
      name: ingredient?.NAME,
      unit: ingredient?.UNIT_ID,
      cost: toDollars(ingredient?.COST_PER_UNIT ?? 0),
      displayUnit: ingredient?.DISPLAY_UNIT_ID,
      calculation: ingredient?.CALCULATION_TYPE ?? CALCULATION_TYPES.STANDARD,
      locations:
        ingredient?.LOCATION_COUNTS?.filter((l) => l.ENABLED).map((l) => l.LOCATION_ID) ??
        locations.map((l) => l.ID) ??
        [],
      categories: ingredient?.CATEGORIES?.map((category) => category.ID) ?? [],
      type: INGREDIENT_TYPES.STANDARD,
      par: ingredient?.PAR,
      pricing:
        ingredient?.VENDOR_ITEMS?.map((vendorItem) => ({
          id: vendorItem.ID,
          isDefault: vendorItem.IS_DEFAULT,
          sku: vendorItem.PRODUCT_SKU,
          name: vendorItem.NAME,
          vendor: vendorItem.VENDOR_ID,
          price: toDollars(vendorItem.PRICE_PER_CASE),
          caseSize: vendorItem.CASE_SIZE,
          caseUnit: vendorItem.UNIT_ID,
        })) ?? [],
    };
  }

  fetchValidationSchema() {
    return Yup.object().shape({
      name: Yup.string().nullable().required("Please enter a name for the ingredient."),
      unit: Yup.number()
        .nullable()
        .integer()
        .test("unit", "Please select a unit.", (value, ctx) => {
          return value || ctx.parent.unitName;
        }),
      cost: Yup.number().optional(),
      displayUnit: Yup.number()
        .nullable()
        .integer()
        .test("displayUnit", (value, ctx) => {
          return value || ctx.parent.unitName;
        }),
      unitName: Yup.string().nullable(),
      calculation: Yup.string().nullable().required(),
      type: Yup.string().nullable().required(),
      expirationTime: Yup.number().nullable(),
      pricing: Yup.array()
        .of(
          Yup.object().shape({
            sku: Yup.string().nullable(),
            name: Yup.string().nullable().required("Please enter in a name for this vendor item."),
            vendor: Yup.string().nullable().required("Please select a vendor."),
            isDefault: Yup.boolean().default(false).required(),
            price: Yup.string().nullable().required("Please enter the price of the product."),
            caseSize: Yup.number().nullable().required("Please enter the number of units in this product."),
            caseUnit: Yup.number()
              .nullable()
              .required("Please select the unit that this product is measured in."),
          })
        )
        .test("atMostOneDefault", "At most one default", (value) => {
          const defaultCount = value.filter((item) => item.isDefault).length;
          return defaultCount <= 1;
        }),
      categories: Yup.array().of(Yup.number().integer()),
    });
  }

  render() {
    const {ingredient} = this.state;
    const {companyLocations: locations} = this.props.shop;

    return (
      <Modal
        buttonLabel={ingredient?.ID ? "Save" : "Add"}
        label={ingredient?.ID ? `Edit Ingredient` : "Create Ingredient"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        deleteOnClick={() => this.deleteIngredient()}
        deleteLabel={ingredient?.ID ? "Delete" : ""}
        xlarge
      >
        <Formik
          onSubmit={
            ingredient?.ID
              ? (values) => this.saveIngredient(values)
              : (values) => this.createIngredient(values)
          }
          validateOnChange
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={this.fetchInitialValues(ingredient, locations)}
          validationSchema={this.fetchValidationSchema()}
        >
          {(formikOptions) => {
            return (
              <IngredientForm
                ingredient={ingredient}
                formikOptions={formikOptions}
                onSubmit={() => this.modal.close()}
              />
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(SupplyChainIngredientModal);
