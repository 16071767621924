import React, {Component} from "react";
import ComboBox from "../../../components/combobox";
import {withRouter} from "../../../utils/navigation";
import MultiSelectCombobox from "../../../components/multi-select-combobox";
import {setupReduxConnection} from "../../../redux";

class IngredientDropdown extends Component {
  state = {data: []};

  render() {
    const {data} = this.state;
    const {ingredients, multi} = this.props;
    const {ingredients: reduxIngredients} = this.props.supply;

    const comboData = ingredients ?? reduxIngredients;

    if (multi) {
      return (
        <MultiSelectCombobox
          data={[
            {
              data: comboData.map((ingredient) => ({
                ingredient,
                name: ingredient.NAME,
                id: ingredient.ID,
                label: ingredient.NAME,
              })),
            },
          ]}
          popOver
          {...this.props}
        />
      );
    }

    return (
      <ComboBox
        wrap={true}
        data={comboData.map((ingredient) => ({
          ingredient,
          name: ingredient.NAME,
          id: ingredient.ID,
          label: ingredient.NAME,
        }))}
        {...this.props}
      />
    );
  }
}

export default setupReduxConnection(["supply"])(withRouter(IngredientDropdown));
