import {Button, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {useEmployeeDocument} from "../../../hooks/team/onboarding/onboarding-hub-hooks";
import LoadingSpinner from "../../../components/loading-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {showErrorNotification} from "../../../utils/notification-helper";
import {PdfViewer} from "../../../components/pdf/pdf-viewer";
import {parseFetchedFields} from "../../team/onboarding/functional/documents/documents-helper";
import {Row} from "../../../v2/components/shared";
import {BackButton} from "../../team/onboarding/functional/BackButton";
import * as Yup from "yup";
import {ONBOARDING_FIELD_TYPES as types} from "../../../utils/constants";
import {useFormik} from "formik";
import {saveDrawableFieldsToPdf} from "./federal/fillable-pdf-helpers";
import FormCheckbox from "../../../components/form-elements/form-checkbox";
import {
  useAckDocument,
  useReviewHubDocument,
  useSendForReview,
} from "../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {
  DOCUMENT_ERROR,
  DOCUMENT_SUBMISSION_ERROR_MESSAGE,
} from "../../team/onboarding/functional/error-messages";

export const CustomDocumentPage = () => {
  const {DOCUMENT_ASS_UID} = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const documentName = useMemo(() => location.state?.documentName, [location.state]);

  const documentUID = useMemo(() => location.state?.documentUID, [location.state]);

  const packetAssignmentUId = useMemo(() => location.state?.packetAssignmentUId, [location.state]);

  const {isLoading, error, data} = useEmployeeDocument(documentUID, DOCUMENT_ASS_UID);

  const [userFields, setUserFields] = useState([]);

  const {mutateAsync: sendForReview, isLoading: sendForReviewLoading} = useSendForReview(packetAssignmentUId);

  const {mutateAsync: markAsComplete, isLoading: reviewLoading} = useReviewHubDocument(packetAssignmentUId);

  useEffect(() => {
    if (data?.fields?.FIELDS) {
      setUserFields(data.fields.FIELDS.filter((field) => field.IS_REVIEWER === 0));
    }
  }, [data]);

  const [fetchedFields, setFetchedFields] = useState({});

  const [validationSchema, setValidationSchema] = useState(null);

  const getAckInitialValues = () => {
    return {
      acknowledgment: false,
    };
  };

  const getAckValidation = () => {
    return {
      acknowledgment: Yup.boolean()
        .oneOf([true], "You must acknowledge to continue")
        .required("Acknowledgment is required"),
    };
  };

  const getFieldsInitialValues = () => {
    return userFields.reduce((acc, field) => {
      acc[field.ID] = field.FIELD_TYPE === types.CHECKBOX ? false : "";
      return acc;
    }, {});
  };

  const getFieldsValidation = () => {
    return userFields.reduce((acc, field) => {
      acc[field.ID] =
        field.FIELD_TYPE === types.CHECKBOX ? Yup.boolean().optional() : Yup.string().required();

      return acc;
    }, {});
  };

  useEffect(() => {
    if (!data) return;

    const isFieldsEdit = data.fields?.EDIT_TYPE === "FIELDS";

    const initialValues = isFieldsEdit ? getFieldsInitialValues() : getAckInitialValues();

    const schema = isFieldsEdit ? getFieldsValidation() : getAckValidation();

    setFetchedFields(initialValues);

    setValidationSchema(Yup.object().shape(schema));
  }, [userFields, data]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fetchedFields,
    validationSchema: validationSchema || Yup.object().shape({}),
    onSubmit: (values) => {
      if (data?.fields?.EDIT_TYPE === "FIELDS") {
        handleSubmit(values);
      } else {
        handleAck(values);
      }
    },
    validateOnChange: false,
  });

  const {mutate: ackDocument, isLoading: ackLoading} = useAckDocument(packetAssignmentUId);

  const handleAck = (values) => {
    ackDocument(DOCUMENT_ASS_UID);

    navigateBack();
  };

  const handleSubmit = async (values) => {
    try {
      const requiresReview = data?.fields?.FIELDS.some((field) => field.IS_REVIEWER === 1);

      const pdfBytes = await saveDrawableFieldsToPdf(data?.loadedPdf, userFields, values, !requiresReview);

      if (requiresReview) {
        await sendForReview({id: DOCUMENT_ASS_UID, payload: {FILE: pdfBytes}});
      } else {
        await markAsComplete({id: DOCUMENT_ASS_UID, payload: {FILE: pdfBytes, IS_APPROVED: true}});
      }
    } catch (error) {
      showErrorNotification(DOCUMENT_ERROR, DOCUMENT_SUBMISSION_ERROR_MESSAGE);
    }

    navigateBack();
  };

  const displayAcknowledgement = () => {
    return (
      <div
        className={
          "flex flex-col flex-grow px-4 py-5 sm:px-6 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-15 rounded-md"
        }
      >
        <p className={"block text-sm font-semibold text-gray-700"}>Acknowledgement</p>

        <div
          className={
            "mt-4 items-start flex flex-row justify-center bg-white border border-gray-200 rounded-md p-4"
          }
        >
          <FormCheckbox label={""} options={formik} name={"acknowledgment"} />

          <p className={"mt-2 mb-2 block text-sm font-medium text-gray-700"}>
            {data?.fields?.ACK_TEXT ?? ""}
          </p>
        </div>
      </div>
    );
  };

  const displayFieldsInstructions = () => {
    return (
      <div
        className={
          "flex flex-col flex-grow px-4 py-5 sm:px-6 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-15 rounded-md"
        }
      >
        <p className={"block text-sm font-semibold text-gray-700"}>Instructions</p>

        <p className={"mt-4 block text-sm font-medium text-gray-700"}>
          1. Review the document carefully and complete all required fields, including your name, signature,
          and any relevant dates.
        </p>

        <p className={"mt-4 block text-sm font-medium text-gray-700"}>
          2. Once all fields are filled out, click Submit to send the document for review if necessary.{" "}
        </p>

        <p className={"mt-4 block text-sm font-medium text-gray-700"}>
          3. If you need a fresh, blank document, click Regenerate to start over. Please note that this action
          will erase your current entries.{" "}
        </p>
      </div>
    );
  };

  const displayErrorMessage = () => {
    const isError = Object.keys(formik.touched).some((touchedKey) =>
      Object.keys(formik.errors).includes(touchedKey)
    );

    return (
      <>
        {isError && (
          <div className="text-sm text-red-500 mt-1">
            * The fields highlighted in red are required and must be filled in to submit document
          </div>
        )}
      </>
    );
  };

  const navigateBack = () => {
    navigate(location.state?.from, {replace: true});
  };

  const setSideBarType = () => {
    const editType = data.fields?.EDIT_TYPE;

    if (editType === "FIELDS") {
      return displayFieldsInstructions();
    } else if (editType === "ACKNOWLEDGE") {
      return displayAcknowledgement();
    } else {
      return <></>;
    }
  };

  const handlePdfDisplay = () => {
    if (isLoading) {
      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      showErrorNotification(
        "Document Error",
        "An error occurred while trying to load this document. Please refresh to try again."
      );

      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <FontAwesomeIcon icon="far fa-circle-exclamation" className={"mr-2 text-red-600"} />

          <span className={"text-red-600"}>Unable to load document</span>
        </div>
      );
    }

    if (data) {
      const formattedFields = userFields.map((fetchedField) => parseFetchedFields(fetchedField)) ?? [];

      return (
        <form onSubmit={formik.handleSubmit} className={"flex items-stretch justify-between space-x-4"}>
          <div className={"w-3/4 flex flex-col items-center justify-center"}>
            <div className={"inline-block overflow-x-auto overflow-y-auto"}>
              <PdfViewer
                url={data.signedUrl}
                type={data.fields?.EDIT_TYPE}
                fields={formattedFields}
                userView={true}
                formik={formik}
              />
            </div>
          </div>

          <div className={"flex flex-col w-1/4 gap-y-2"}>
            {setSideBarType()}

            <Row className={"mt-2 flex flex-row w-full space-x-1"}>
              <BackButton
                className={"flex-1"}
                label="Back"
                goBack={navigateBack}
                disabled={formik.isSubmitting || sendForReviewLoading || reviewLoading || ackLoading}
              />

              <Button
                className={"flex-1 justify-center"}
                type={"submit"}
                label={"Submit"}
                autoLoad={true}
                disabled={formik.isSubmitting || reviewLoading || sendForReviewLoading || ackLoading}
              />
            </Row>

            {displayErrorMessage()}
          </div>
        </form>
      );
    }
  };

  return (
    <Card label={documentName} noHeaderBorder={true} className={"py-6 px-4"}>
      {handlePdfDisplay}
    </Card>
  );
};
