import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import React from "react";

export const FileDropZoneWrapper = ({small, children}) => {
  return (
    <div className="mt-1 sm:mt-0 sm:col-span-2">
      <div
        className={classNames(
          "flex justify-center items-center px-6 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer hover:border-indigo-300",
          !small && "pt-5"
        )}
      >
        <div className={classNames(small ? "h-10" : "h-24", "flex justify-center items-center")}>
          <div className="space-y-1 text-center">{children}</div>
        </div>
      </div>
    </div>
  );
};
