import {request} from "../../request";
import {ONBOARDING_AWS_BUCKET} from "../../../pages/team/onboarding/functional/documents/documents-helper";

export const OnboardingHubRequests = {
  fetchPackets: async () => {
    return request("onboarding/hub/packets", "GET");
  },
  fetchAccountDocuments: async () => {
    return request("onboarding/hub/documents", "GET");
  },
  fetchPacketDocuments: async (packetAssignmentUId) => {
    return request(`onboarding/hub/packet/${packetAssignmentUId}/documents`, "GET");
  },
  fetchDocumentFields: async (documentId) => {
    return request(`onboarding/hub/document/${documentId}/fields`, "GET");
  },
  fetchSignedUrl: async (documentAssignmentId) => {
    return request(`onboarding/hub/assignment/${documentAssignmentId}/url`, "GET");
  },
  sendDocumentForReview: async (documentAssignmentId, params) => {
    return request(`onboarding/hub/assignment/${documentAssignmentId}/submission`, "PATCH", params);
  },
  acknowledgeDocument: async (documentAssignmentId) => {
    return request(`onboarding/hub/assignment/${documentAssignmentId}/acknowledgment`, "PATCH");
  },
  regenerateDocument: async (documentAssignmentId) => {
    return request(`onboarding/hub/assignment/${documentAssignmentId}/regenerate`, "PATCH");
  },
};

export const EmployeeDocumentRequests = {
  upload: async (params) => {
    return request("onboarding/hub/employee/document", "POST", params);
  },
  fetchSignedUrl: async (url, bucket = ONBOARDING_AWS_BUCKET) => {
    return request("onboarding/hub/employee/document/url", "PATCH", {URL: url, BUCKET: bucket});
  },
};
