import {useReactQuery} from "../../use-query-wrapper";
import {
  EmployeeDocumentRequests,
  OnboardingHubRequests,
} from "../../../utils/request-helpers/onboarding/onboarding-hub-requests";
import {loadPdf} from "../../../pages/hub/onboarding/federal/fillable-pdf-helpers";
import {useMutation} from "@tanstack/react-query";
import {showErrorNotification} from "../../../utils/notification-helper";
import {ONBOARDING_AWS_BUCKET} from "../../../pages/team/onboarding/functional/documents/documents-helper";

export const useEmployeePackets = (account) => {
  return useReactQuery(
    ["employee-packets", account?.ID],
    () => OnboardingHubRequests.fetchPackets(account?.ID),
    {
      enabled: !!account,
    }
  );
};

export const usePacketDocuments = (packetAssignmentUID) => {
  return useReactQuery(
    ["packet-documents", packetAssignmentUID],
    () => OnboardingHubRequests.fetchPacketDocuments(packetAssignmentUID),
    {
      enabled: !!packetAssignmentUID,
    }
  );
};

const fetchEmployeeDocument = async (documentUID, assignmentUID) => {
  const [signedUrl, fields] = await Promise.all([
    OnboardingHubRequests.fetchSignedUrl(assignmentUID),
    OnboardingHubRequests.fetchDocumentFields(documentUID),
  ]);

  const loadedPdf = await loadPdf(signedUrl);

  return {signedUrl, fields, loadedPdf};
};

export const useEmployeeDocument = (documentUID, assignmentUID) => {
  return useReactQuery(
    ["employee-document", documentUID],
    () => fetchEmployeeDocument(documentUID, assignmentUID),
    {
      enabled: !!documentUID && !!assignmentUID,
    },
    0,
    0
  );
};

export const useEmployeeInReview = (documentAssignmentUId) => {
  return useReactQuery(
    ["employee-document-in-review", documentAssignmentUId],
    () => OnboardingHubRequests.fetchSignedUrl(documentAssignmentUId),
    {
      enabled: !!documentAssignmentUId,
    },
    0,
    0
  );
};

export const useAccountDocuments = () => {
  return useReactQuery(["account-documents"], OnboardingHubRequests.fetchAccountDocuments);
};

export const useEmployeeDocumentSignedUrl = () => {
  const mutation = useMutation(
    async ({url, bucket}) => {
      return EmployeeDocumentRequests.fetchSignedUrl(url, bucket);
    },
    {
      onSuccess: (url) => {
        window.open(url, "_blank");
      },
      onError: (error) => {
        showErrorNotification("Document Error", "Unable to get the URL for this document. Please try again.");
      },
    }
  );

  const openDocument = (url, bucket = ONBOARDING_AWS_BUCKET) => {
    mutation.mutate({url, bucket});
  };

  return {
    openDocument,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};
