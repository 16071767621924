import React from "react";

export const AddButton = ({label, onClick, children}) => {
  return (
    <button
      className="my-4 p-4  bg-white max-h-48 overflow-y-auto border focus:border-primary-border border-neutral-border block w-full focus:outline-none sm:text-sm rounded-md shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      onClick={onClick}
    >
      <div className="flex flex-1 items-center justify-center">
        <span className="block text-sm font-medium text-gray-700 mr-1">{label}</span>

        {children}
      </div>
    </button>
  );
};
