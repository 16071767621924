import {FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import LoadingSpinner from "../../components/loading-spinner";
import {Payroll_Type_Labels} from "../../pages/success/groups-tab";
import {PayrollGroupsRequests} from "../../utils/request-helpers/admin/payroll-groups-requests";
import Banner from "../../components/banner";
import {setupReduxConnection} from "../../redux";
import PayrollWorkplacesComponent from "../../features/payroll/onboard/group/payroll-workplaces-component";
import {showConfirmAlert, showErrorAlert} from "../../utils/alert-helper";
import FilterDropdown from "../../components/filter-dropdown";

class EditGroupModal extends Component {
  state = {
    isLoading: true,
    isSubmitting: false,
    locations: [],
    payrollGroupType: null,
    group: null,
    errorText: null,
    selectedLocations: [],
  };

  clearState() {
    this.setState({
      isLoading: true,
      isSubmitting: false,
      locations: [],
      payrollGroupType: null,
      group: null,
      errorText: null,
      selectedLocations: [],
    });
  }

  async open(group) {
    this.slide.open();
    const {GROUP_LOCATIONS: groupLocations, COMPANY_LOCATIONS: companyLocations} =
      await PayrollGroupsRequests.getLocations(group.ID);

    this.setState({locationsDropdown: null});
    this.setState({
      group,
      locations: companyLocations,
      selectedLocations: groupLocations.map(({ID}) => ID),
      payrollGroupType: group.TYPE,
      isLoading: false,
    });
  }

  handleError = (error) => {
    if (error.code === errorTypes["checkAddressError"]) {
      this.setState({
        errorText:
          error.message +
          " encountered an error creating a Check workplace. Please double-check your addresses.",
      });
      setTimeout(() => this.setState({errorText: null}), 5000);
    } else if (error.code === errorTypes["editGroupError"]) {
      showErrorAlert("Error Editing Group", error.message);
    } else {
      showErrorAlert("Unknown Error", error.message);
    }
  };

  handleSubmit = async ({type}) => {
    let {group, selectedLocations} = this.state;

    this.setState({errorText: null, isSubmitting: true});

    try {
      await PayrollGroupsRequests.convertGroup(group.ID, selectedLocations, type);
      this.props.loadPayrollGroups();
      this.clearState();
      this.slide.close();
    } catch (err) {
      this.handleError(err);
    } finally {
      this.setState({isSubmitting: false});
    }
  };

  handleLocationChange = (selectedLocations) => {
    this.setState({selectedLocations}, () => {
      const typeOptions = this.generateTypeOptions(selectedLocations.length);
      this.setState({typeOptions, selectedLocations});
    });
  };

  generateTypeOptions = (locationCount) => {
    return Object.keys(Payroll_Type_Labels)
      .filter((key) => {
        if (locationCount === 1) return key === "SINGLE" || key === "INDEPENDENT";
        if (locationCount > 1) return key === "INDEPENDENT" || key === "MULTI";
        return true;
      })
      .map((key) => ({
        label: Payroll_Type_Labels[key],
        value: key,
      }));
  };

  archiveGroup = async () => {
    try {
      await showConfirmAlert(
        "Are you sure?",
        "Archiving a group will clear all associated information including tax forms from the web dashboard."
      );
      await PayrollGroupsRequests.archiveGroup(this.state.group.ID);
      this.clearState();
      this.slide.close();
    } catch (error) {}
  };

  render() {
    const {isLoading, payrollGroupType, locations, group, errorText, selectedLocations} = this.state;

    const locsWithoutWorkplace = locations.filter(
      ({ID, CHECK_WORKPLACE_ID, TYPE}) => !CHECK_WORKPLACE_ID && TYPE === "STORE"
    );

    const typeOptions = this.generateTypeOptions(selectedLocations.length);

    return (
      <Modal
        large
        ref={(e) => (this.slide = e)}
        label="Edit Payroll Group"
        disableButtonLoading={true}
        onClose={() => this.clearState()}
        buttonLabel={"Submit"}
        buttonOnClick={() => this.formikRef.submitForm()}
        buttons={[
          {
            label: "Archive",
            onClick: this.archiveGroup,
            type: "error",
          },
        ]}
      >
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Formik
            enableReinitialize
            initialValues={{type: payrollGroupType}}
            innerRef={(e) => (this.formikRef = e)}
            onSubmit={this.handleSubmit}
          >
            {(formikOptions) => (
              <div className="min-w-64">
                <div className="text-black text-lg font-semibold">{group.NAME}</div>

                <FormSelect name="type" data={typeOptions} label="Type" options={formikOptions} />

                {locations.length > 1 && (
                  <FilterDropdown
                    className="py-6"
                    selectAll={false}
                    noMl={true}
                    cannotBeEmpty={true}
                    initialText={"Select Locations"}
                    enableReinitialize={true}
                    initialValues={selectedLocations}
                    defaultValue={selectedLocations}
                    plural="Locations"
                    data={locations.map(({NAME: name, ID: value}) => ({name, value}))}
                    onChange={this.handleLocationChange}
                  />
                )}

                {locsWithoutWorkplace.length > 0 && (
                  <PayrollWorkplacesComponent selectedLocations={locsWithoutWorkplace} />
                )}

                {errorText && (
                  <Banner
                    className={
                      "fixed bottom-16 left-4 right-4 px-4 py-2 rounded-lg shadow-md text-sm font-medium flex items-center justify-center"
                    }
                    label={errorText}
                  />
                )}
              </div>
            )}
          </Formik>
        )}
      </Modal>
    );
  }
}

export default setupReduxConnection(["admin"])(EditGroupModal);

const errorTypes = {
  editGroupError: "ERR_EDITING_GROUP",
  checkAddressError: "INCORRECT_ADDRESS",
};
