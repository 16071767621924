import moment from "moment-timezone";
import * as Yup from "yup";
import DangerBadge from "../../../../../components/badges/danger-badge";
import SuccessBadge from "../../../../../components/badges/success-badge";
import {ONBOARDING_FORM_FIELDS} from "../../../../../utils/constants";

export const fileUploadValidation = Yup.object().shape({
  fileUpload: Yup.object()
    .shape({
      filename: Yup.string().nullable().required("The file name is required."),
      url: Yup.string().nullable().required("The file url is required."),
    })
    .nullable()
    .required("Please select a file."),
});

export const formatType = (type) => {
  if (!type) return "";
  return type.charAt(0) + type.slice(1).toLowerCase();
};

export const COLUMNS = [
  {
    value: "NAME",
    label: "Name",
  },
  {
    value: "TYPE",
    label: "Type",
    sortable: false,
    format: (value) => formatType(value),
  },
  {
    value: "VERSION",
    label: "Version",
  },
  {
    value: "DATE_CREATED",
    label: "Date Created",
    format: (value) => formatDate(value),
  },
  {
    value: "DATE_UPDATED",
    label: "Date Updated",
    format: (value) => formatDate(value),
  },
];

export const formatDate = (value) => {
  if (moment(value).isValid()) {
    return moment(value).format("M/D/YYYY");
  }

  return "None";
};

export const sortByType = (documents, order) => {
  return (
    documents?.sort((a, b) => {
      return order.indexOf(a.TYPE) - order.indexOf(b.TYPE);
    }) ?? []
  );
};

export const ONBOARDING_AWS_BUCKET = "dripos-onboarding-documents";

export const CUSTOM_DOCUMENT_STATUS = {
  ARCHIVED: "ARCHIVED",
};

export const DOCUMENT_STATUS = {
  PENDING_EMPLOYEE: "PENDING_EMPLOYEE",
  PENDING_REVIEW: "PENDING_REVIEW",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
};

export const DOCUMENT_TYPE = {
  FEDERAL: "FEDERAL",
  STATE: "STATE",
  CUSTOM: "CUSTOM",
};

export const DOCUMENT_FED_CATEGORY = {
  I9: "I-9",
  W4: "W-4",
};

export const EDIT_TYPES = {
  ACKNOWLEDGE: "ACKNOWLEDGE",
  FIELDS: "FIELDS",
};

export const formatDocumentStatus = (status) => {
  switch (status) {
    case DOCUMENT_STATUS.PENDING_EMPLOYEE:
      return (
        <DangerBadge blue className={"whitespace-nowrap"}>
          Waiting for employee
        </DangerBadge>
      );
    case DOCUMENT_STATUS.REJECTED:
      return (
        <DangerBadge red className={"whitespace-nowrap"}>
          Rejected - Resubmission Required
        </DangerBadge>
      );
    case DOCUMENT_STATUS.PENDING_REVIEW:
      return (
        <DangerBadge yellow className={"whitespace-nowrap"}>
          Needs Review
        </DangerBadge>
      );
    case DOCUMENT_STATUS.COMPLETED:
      return <SuccessBadge className={"whitespace-nowrap"}>Completed</SuccessBadge>;
    default:
      return "";
  }
};

export const parseFetchedFields = (field) => {
  return (
    {
      ...ONBOARDING_FORM_FIELDS.find((formField) => formField.TYPE === field.FIELD_TYPE),
      ID: field.ID,
      X_COORDINATE: field.X_COORDINATE,
      Y_COORDINATE: field.Y_COORDINATE,
      HEIGHT: field.HEIGHT,
      WIDTH: field.WIDTH,
      PAGE: field.PAGE,
      IS_REVIEWER: field.IS_REVIEWER,
    } ?? []
  );
};
