import React, {Component} from "react";
import {FormSelect, FormInput, FormTextArea, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../../utils/request";
import LocationsDropdown from "../../../dropdowns/team/locations-dropdown";
import FormRow from "../../../components/form-row";
import FormDropZone from "../../../components/form-drop-zone";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import moment from "moment-timezone";
import ProductDropdown from "../../../dropdowns/sales/product-dropdown";
import FormSpacer from "../../../components/form-spacer";
import CategoryDropdown from "../../../dropdowns/sales/category-dropdown";
import ImageFormDropZone from "../../../components/image-form-drop-zone";

class TaskModal extends Component {
  state = {task: null};

  open(task = null) {
    this.setState({task}, () => {
      this.formikRef && this.formikRef.resetForm({});
      this.modal.open();
    });
  }

  async createTask({name, start, end, type, content, quantity, location, description, range, logo}) {
    const taskPayload = {
      NAME: name,
      DATE_END: end,
      DATE_START: start,
      TYPE: type,
      CONTENT: content,
      QUANTITY: quantity,
      LOCATION_ID: location,
      DESCRIPTION: description,
      LOGO: logo,
    };

    if (range === "one") {
      taskPayload.DATE_START = null;
      taskPayload.DATE_END = null;
    }

    const serverTask = await request("rewards/tasks", "POST", taskPayload);

    this.props.addState(serverTask);
    this.modal.close();
  }

  async saveTask({name, start, end, type, content, quantity, location, description, range, logo}) {
    const {task} = this.state;

    const taskPayload = {
      NAME: name,
      DATE_END: end,
      DATE_START: start,
      TYPE: type,
      CONTENT: content,
      QUANTITY: quantity,
      LOCATION_ID: location,
      DESCRIPTION: description,
      LOGO: logo,
    };

    if (range === "one") {
      taskPayload.DATE_START = null;
      taskPayload.DATE_END = null;
    }

    const serverTask = await request("rewards/tasks/" + task.ID, "PATCH", taskPayload);

    this.props.updateState(serverTask);
    this.modal.close();
  }

  renderContent(formikOptions) {
    const {values} = formikOptions;

    if (values.type === "PRODUCT") {
      return <ProductDropdown options={formikOptions} label="Task Product" name="content" unique flex />;
    }

    if (values.type === "CATEGORY") {
      return <CategoryDropdown options={formikOptions} label="Task Category" name="content" unique flex />;
    }

    return <FormSpacer />;
  }

  render() {
    const {task} = this.state;

    return (
      <Modal
        large
        ref={(e) => (this.modal = e)}
        buttonLabel={task ? "Save" : "Create"}
        label={task ? "Edit Task" : "Create Task"}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={task ? this.saveTask.bind(this) : this.createTask.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            name: task?.NAME ?? "",
            logo: task?.LOGO ?? "",
            type: task?.TYPE ?? "TICKET",
            content: task?.CONTENT ?? null,
            quantity: task?.QUANTITY ?? "",
            location: task?.LOCATION_ID ?? null,
            range: task ? (task.DATE_START ? "range" : "one") : "one",
            start: task?.DATE_START ?? Date.now(),
            end: task?.DATE_END ?? Date.now(),
            description: task?.DESCRIPTION ?? "",
          }}
        >
          {(formikOptions) => {
            const {values, setFieldValue, handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormRow>
                  <FormInput name="name" label="Name" options={formikOptions} flex />

                  <FormSelect
                    flex
                    name="range"
                    label="Range"
                    options={formikOptions}
                    data={[
                      {label: "One-Time Task", value: "one"},
                      {label: "Time Range Challenge", value: "range"},
                    ]}
                  />
                </FormRow>

                {values.range === "range" && (
                  <FormRow>
                    <FormDateTimeSelect
                      hideTime
                      name="start"
                      label="Challenge Start"
                      buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                      options={formikOptions}
                      flex
                    />

                    <FormDateTimeSelect
                      hideTime
                      name="end"
                      label="Challenge End"
                      buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                      options={formikOptions}
                      flex
                    />
                  </FormRow>
                )}

                <FormRow>
                  <LocationsDropdown options={formikOptions} name="location" allowNull flex />

                  <FormSelect
                    flex
                    name="type"
                    label="Task Type"
                    options={formikOptions}
                    onChangeSoft={() => setFieldValue("content", null)}
                    data={[
                      {label: "Number of Orders", value: "TICKET"},
                      {label: "Product Orders", value: "PRODUCT"},
                      {label: "Category Orders", value: "CATEGORY"},
                    ]}
                  />
                </FormRow>

                <FormRow>
                  <FormInput options={formikOptions} label="Task Quantity" name="quantity" flex />

                  {this.renderContent(formikOptions)}
                </FormRow>

                <ImageFormDropZone options={formikOptions} label="Task Logo" hint="optional" name="logo" />

                <FormTextArea options={formikOptions} label="Description" name="description" />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default TaskModal;
