import React, {useRef} from "react";
import EmployeeFileModal from "../../modals/team/employee/employee-file-modal";
import moment from "moment-timezone";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useEmployeeDocumentSignedUrl} from "../../hooks/team/onboarding/onboarding-hub-hooks";
import {hasPermission} from "../../redux/user";
import {showErrorNotification} from "../../utils/notification-helper";

export const EmployeeFilesTable = ({employee, filters = [], files = [], folders = [], loadFiles}) => {
  const employeeModal = useRef(null);

  const {openDocument} = useEmployeeDocumentSignedUrl();

  const handleViewDocument = async (document) => {
    if (hasPermission("ONBOARDING_REVIEW_FULL")) {
      openDocument(document.URL, document.BUCKET);
    } else {
      showErrorNotification(
        "Permission Denied",
        "Unable to view file because you do not have the required permissions."
      );
    }
  };

  return (
    <>
      <EmployeeFileModal ref={employeeModal} employee={employee} syncState={loadFiles} folders={folders} />

      <Table
        pagination
        actionButtons={[
          {
            label: "View",
            onClick: (document) => handleViewDocument(document),
          },
          {
            label: "Edit",
            onClick: (row) => employeeModal.current?.open(employee, row),
          },
        ]}
        columns={[
          {
            value: "DOCUMENT_NAME",
            label: "File Name",
          },
          {
            value: "FOLDER_NAME",
            label: "Folder",
            format: (val) => val ?? "-",
            tooltip:
              "Organize files with company-level folders. Click ‘Edit’ to assign a file to the appropriate folder.",
          },
          {
            value: "DATE_CREATED",
            label: "Effective Date",
            format: (end) => moment(parseInt(end)).format("MMM DD, h:mm A"),
          },
          {
            value: "DATE_ARCHIVED",
            label: "Status",
            format: (value) => {
              if (value) {
                return (
                  <DangerBadge red className={"whitespace-nowrap w-[68px]"}>
                    Archived
                  </DangerBadge>
                );
              }

              return (
                <SuccessBadge className={"whitespace-nowrap w-[68px] justify-center"}>Active</SuccessBadge>
              );
            },
          },
        ]}
        data={files}
        filters={filters}
      />
    </>
  );
};
