import OnboardingDashboardHeading from "./onboarding-dashboard-heading";
import {PendingEmployeeTab} from "./tabs/pending-employee-tab";
import {PendingReviewTab} from "./tabs/pending-review-tab";
import {CompletedTab} from "./tabs/completed-tab";
import {Tab} from "@frostbyte-technologies/frostbyte-tailwind";
import {useLocation, useNavigate} from "react-router-dom";
import {useDashboardCount} from "../../../../../hooks/team/onboarding/onboarding-hooks";
import {hasPermission} from "../../../../../redux/user";

function OnboardingDashboard() {
  const navigate = useNavigate();
  const location = useLocation();

  const {data} = useDashboardCount();

  const TAB_NAMES = {
    PENDING_EMPLOYEE: "Pending Employee",
    PENDING_REVIEW: "Pending Review",
    COMPLETED: "Completed",
  };

  const TABS = [
    {
      id: TAB_NAMES.PENDING_EMPLOYEE,
      label: TAB_NAMES.PENDING_EMPLOYEE,
      badge: data?.incompleteCount ?? 0,
    },
    hasPermission("ONBOARDING_REVIEW_FULL") && {
      id: TAB_NAMES.PENDING_REVIEW,
      label: TAB_NAMES.PENDING_REVIEW,
      badge: data?.reviewCount ?? 0,
    },
    {
      id: TAB_NAMES.COMPLETED,
      label: TAB_NAMES.COMPLETED,
    },
  ].filter(Boolean);

  const selectTab = (id) => {
    switch (id) {
      case TAB_NAMES.PENDING_EMPLOYEE:
        return <PendingEmployeeTab />;
      case TAB_NAMES.PENDING_REVIEW:
        return <PendingReviewTab />;
      case TAB_NAMES.COMPLETED:
        return <CompletedTab />;
      default:
        return <PendingEmployeeTab />;
    }
  };

  const handleAddDocument = () => {
    navigate("/onboarding/create-document", {
      state: {from: location.pathname, origin: null},
    });
  };

  const handleAssignPacket = () => {
    navigate("/onboarding/assign-packet", {state: {from: location.pathname}});
  };

  const handleCreatePacket = () => {
    navigate("/onboarding/manage-packet", {
      state: {from: location.pathname, origin: null, packet: null},
    });
  };

  return (
    <>
      <OnboardingDashboardHeading
        handleAddDocument={handleAddDocument}
        handleCreatePacket={handleCreatePacket}
        handleAssignPacket={handleAssignPacket}
      />

      <Tab data={TABS}>{(id) => selectTab(id)}</Tab>
    </>
  );
}

export default OnboardingDashboard;
