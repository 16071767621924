import {Card, Filter, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {useState} from "react";
import {
  useAccountDocuments,
  useEmployeeDocumentSignedUrl,
} from "../../../hooks/team/onboarding/onboarding-hub-hooks";
import {getTableData} from "../../team/onboarding/functional/onboarding-helper";
import {UploadDocumentModal} from "../../../modals/general/upload-document-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import SuccessBadge from "../../../components/badges/success-badge";
import {formatDate} from "../../team/onboarding/functional/documents/documents-helper";

export const EmployeeDocumentsPage = () => {
  const COLUMNS = [
    {
      label: "Name",
      value: "DOCUMENT_NAME",
    },
    {
      label: "Effective Date",
      value: "DATE_CREATED",
      format: (value) => formatDate(value),
    },
    {
      value: "DATE_ARCHIVED",
      label: "Status",
      format: (value) => {
        if (value) {
          return (
            <DangerBadge red className={"whitespace-nowrap w-[68px]"}>
              Archived
            </DangerBadge>
          );
        }

        return <SuccessBadge className={"whitespace-nowrap w-[68px] justify-center"}>Active</SuccessBadge>;
      },
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);

  const {openDocument} = useEmployeeDocumentSignedUrl();

  const {isLoading, error, data} = useAccountDocuments();

  const handleViewDocument = async (document) => {
    openDocument(document.URL);
  };

  const displayDocuments = (id) => {
    const companyDetails = data?.filter((item) => item.COMPANY_UID === id)?.[0];

    const filteredData = companyDetails?.DOCUMENTS;

    return (
      <Card
        label={"Documents"}
        className={"mt-6 border rounded-md"}
        description={
          "Documents that have been completed as part of an onboarding packet or manually uploaded."
        }
        button={{
          label: "Upload Document",
          onClick: () => {
            setSelectedEmployeeId(companyDetails.EMPLOYEE_ID);
            setIsModalOpen(true);
          },
        }}
      >
        <Filter
          defaultFilters={[{filter: "active", id: true}]}
          data={[
            {
              id: "active",
              label: "Filter Status",
              onFilter: (options, data) =>
                data.filter((item) => options.includes(item.DATE_ARCHIVED === null)),
              options: [
                {label: "Active", id: true},
                {label: "Archived", id: false},
              ],
            },
          ]}
        >
          {(filters, _) => {
            return (
              <Table
                sortable
                pagination
                columns={COLUMNS}
                data={getTableData(isLoading, error, filteredData)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (document) => handleViewDocument(document),
                  },
                ]}
                filters={filters}
              />
            );
          }}
        </Filter>
      </Card>
    );
  };

  const displayTabs = () => {
    const tabs = data?.map((item) => ({
      id: item.COMPANY_UID,
      label: item.COMPANY_NAME,
      badge: item.DOCUMENTS?.length ?? 0,
    }));

    return <Tab data={tabs}>{(id) => displayDocuments(id)}</Tab>;
  };

  return (
    <>
      <UploadDocumentModal
        isOpen={isModalOpen}
        modalLabel={"Upload Document"}
        buttonLabel={"Upload"}
        selectedEmployeeId={selectedEmployeeId}
        handleModalClose={() => {
          setSelectedEmployeeId(null);
          setIsModalOpen(false);
        }}
      />

      <PageHeadings label={`Employee Documents`} />

      {data?.length > 1 ? displayTabs() : displayDocuments(data?.[0]?.COMPANY_UID)}
    </>
  );
};
