import React, {Component} from "react";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {Card, Loading, Table} from "@frostbyte-technologies/frostbyte-tailwind";

class ProductTrackerPage extends Component {
  state = {
    pulls: [],
    things: [],
    complete: [],
    deployment: [],
    development: [],
    people: null,

    isLoading: true,
  };

  componentDidMount() {
    this.loadData();

    this.resetInterval = setInterval(() => {
      this.loadData();
    }, 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.resetInterval);
  }

  async loadData() {
    request("notion/product", "GET", {
      DATE_START: moment().subtract(7, "days").valueOf(),
      DATE_END: moment().valueOf(),
    }).then(({things, complete, development, people, deployment, pulls}) => {
      this.setState({
        isLoading: false,
        development,
        deployment,
        complete,
        things,
        people,
        pulls,
      });
    });
  }

  render() {
    const {isLoading, complete, things, development, people, deployment, pulls} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const supportTasks = things.filter((item) => item.properties.Type.select?.name === "Support Task");
    const features = things.filter((item) => item.properties.Type.select?.name === "Feature");
    const bugs = things.filter((item) => item.properties.Type.select?.name === "Bug");

    return (
      <div className="p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">General Stats</h3>

          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Things Created</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{things.length}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">In Development</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{development.length}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Ready for Deployment</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{deployment.length}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Completed Things</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{complete.length}</dd>
            </div>
          </dl>
        </div>

        <Card>
          <Table
            data={pulls}
            columns={[
              {
                width: 1,
                value: "status",
                label: "Date Opened",
                className: "font-medium text-gray-900",
                format: (_, pull) =>
                  moment(pull.properties["Date Opened"]?.date?.start).format("hh:mma MM/DD/YY"),
              },
              {
                width: 1,
                value: "author",
                label: "Author",
                className: "font-medium text-gray-900",
                format: (_, pull) => pull.properties.Author.people[0].name,
              },
              {
                width: 1,
                value: "status",
                label: "Status",
                className: "font-medium text-gray-900",
                format: (_, pull) => pull.properties.Status.select.name,
              },
              {
                value: "name",
                label: "Name",
                format: (_, pull) => pull.properties.Name.title[0].plain_text,
              },
            ]}
          />
        </Card>

        <Card label="Developer Status">
          <Table
            data={people}
            columns={[
              {
                width: 1,
                value: "name",
                label: "Name",
                className: "font-medium text-gray-900",
              },
              {
                value: "things",
                label: "In Development",
                className: "font-medium text-gray-900",
                format: (things) => {
                  return things.length > 0
                    ? things.map((item) => item.properties.Things.title[0].plain_text).join("\n")
                    : "None in development";
                },
              },
              {
                value: "deployment",
                label: "Ready for Deployment",
                format: (things) => {
                  return things.length > 0
                    ? things.map((item) => item.properties.Things.title[0].plain_text).join(", ")
                    : "None ready for deployment";
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default ProductTrackerPage;
