import React, {Component, Fragment} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../utils/request";
import moment from "moment-timezone";
import LoadingSpinner from "../../../components/loading-spinner";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {numberWithCommas} from "../../../utils/util";

class PayrollTrackerPage extends Component {
  state = {paySchedules: null, isLoading: true, totalProcessed: null};

  async componentDidMount() {
    let {PAY_SCHEDULES, TOTAL_PROCESSED} = await request("admin/dashboard/payroll", "GET");

    PAY_SCHEDULES.sort((a, b) => {
      return moment(a.payday).valueOf() - moment(b.payday).valueOf();
    });

    this.setState({isLoading: false, paySchedules: PAY_SCHEDULES, totalProcessed: TOTAL_PROCESSED});
  }

  render() {
    let {paySchedules, totalProcessed} = this.state;

    if (!paySchedules) {
      return <LoadingSpinner />;
    }

    return (
      <div className="p-6">
        <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
          <dt className="text-sm font-medium text-gray-500 truncate">Total Processed</dt>
          <dd className="mt-1 text-3xl font-semibold text-gray-900">{toDollars(totalProcessed, true)}</dd>
        </div>

        <Table
          columns={[
            {
              label: "Location",
              value: "NAME",
            },
            {
              label: "Pay Period",
              format: (value, row) => {
                let {period_start, period_end} = row;

                return `${moment(period_start).format("M/DD")} - ${moment(period_end).format("M/DD")}`;
              },
            },
            {
              label: "Payday",
              format: (value, row) => {
                let {payday} = row;

                return `${moment(payday).format("M/DD")}`;
              },
            },
          ]}
          data={paySchedules}
        />
      </div>
    );
  }
}

export default PayrollTrackerPage;
