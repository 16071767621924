import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {withRouter} from "../../utils/navigation";
import LabelerModal from "../../modals/shop/devices/labeler-modal";

class LabelersTable extends Component {
  render() {
    const {labelers} = this.props;

    return (
      <>
        <LabelerModal
          ref={(e) => (this.labelerModal = e)}
          updateInfo={(labeler) => {
            this.props.updateState(labeler);
          }}
        />

        <Table
          pagination
          className="mt-4"
          data={labelers}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.labelerModal.open(row),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value, row) => {
                return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
              },
            },
          ]}
        />
      </>
    );
  }
}

LabelersTable.propTypes = {
  labelers: PropTypes.array.isRequired,
};

export default withRouter(LabelersTable);
