import React, {Component} from "react";
import {AdvancedFilter, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import BillModal from "../../../modals/accounting/bills/bill-modal";
import {setupReduxConnection} from "../../../redux";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class BillsPage extends Component {
  componentDidMount() {
    this.props.loadLedgers();

    this.syncState();
  }

  syncState = async () => {
    await this.tableRef.refresh(true);
  };
  render() {
    const {vendors} = this.props.accounting;

    return (
      <div>
        <BillModal ref={(e) => (this.billModal = e)} syncState={this.syncState} />

        <PageHeadings
          label={"Bills"}
          breadcrumbs={[{label: "Accounting", url: "/accounting"}]}
          button={{label: "+ Add Bill", onClick: () => this.billModal.open()}}
        />

        <Tab
          data={[
            {label: "Unpaid", id: "unpaid"},
            {label: "Paid", id: "paid"},
          ]}
        >
          {(id) => (
            <div className={"bg-white"}>
              <AdvancedFilter
                data={[
                  {
                    label: "Vendor",
                    type: "select",
                    data: vendors.map(({ID, NAME}) => ({
                      label: NAME,
                      value: ID,
                    })),
                    id: "vendor",
                  },
                  {label: "Start Date", type: "datetime", id: "start"},
                  {label: "End Date", type: "datetime", id: "end"},
                ]}
              >
                {(filters, search) => (
                  <Table
                    ref={(e) => (this.tableRef = e)}
                    route={"accounting/bills/dumb"}
                    columns={[
                      {
                        label: "Vendor",
                        value: "VENDOR_NAME",
                      },
                      {
                        label: "Issue Date",
                        value: "DATE_ISSUED",
                        format: (date) => moment(date).format("MM/DD/YYYY"),
                      },
                      {
                        label: "Due Date",
                        value: "DATE_DUE",
                        format: (date) => moment(date).format("MM/DD/YYYY"),
                      },
                      {
                        label: "Amount",
                        value: "AMOUNT",
                        format: (amount) => toDollars(amount, true),
                      },
                    ]}
                    actionButtons={[
                      {
                        label: "View",
                        onClick: ({UNIQUE_ID}) =>
                          this.props.router.navigate(`/accounting/bills/${UNIQUE_ID}`),
                      },
                    ]}
                    pagination
                  />
                )}
              </AdvancedFilter>
            </div>
          )}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["accounting"])(withRouter(BillsPage));
