import React, {Component} from "react";
import PropTypes from "prop-types";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import PercentageBadge from "../percentage-badge";

class ReportingSalesGraph extends Component {
  state = {
    datasets: this.props.datasets.map((dataset) => ({
      ...dataset,
      toggled: true,
      displayStroke: dataset.stroke,
    })),
  };

  getRechartValues(key) {
    const {datasets} = this.state;

    const toReturn = [];

    const minLength = Math.min(...datasets.map((_dataset) => _dataset.rawData[key]?.length));

    for (let index = 0; index < minLength; index++) {
      const primaryMoment = moment(datasets[0].rawData[key][index][key]);

      const dateFormat = key === "HOUR" ? "h A" : "MMM D, YYYY";

      const payload = {
        index: index,
        primaryEpoch: primaryMoment.valueOf(),
      };

      for (const {id, rawData, stopNow, dataKey, countKey, extraKeys = []} of datasets) {
        payload[`${id}Date`] = moment(rawData[key][index][key]).format(dateFormat);
        payload[`${id}Value`] =
          !stopNow || primaryMoment.valueOf() < moment().valueOf() ? rawData[key][index][dataKey] : undefined;
        payload[`${id}Count`] =
          !stopNow || primaryMoment.valueOf() < moment().valueOf()
            ? rawData[key][index][countKey]
            : undefined;

        for (const {keyName, keyValue} of extraKeys) {
          payload[`${keyName}`] =
            !stopNow || primaryMoment.valueOf() < moment().valueOf()
              ? rawData[key][index][keyValue]
              : undefined;
        }
      }

      toReturn.push(payload);
    }

    return toReturn;
  }

  getTimelineMode = () => {
    const {datasets} = this.state;
    const {ignoreWeekdayLabels} = this.props;

    const hourArr = datasets[0].rawData.HOUR;
    const dayArr = datasets[0]?.rawData?.DAY;

    let startEpoch = hourArr[0].HOUR;
    let endEpoch = hourArr[hourArr.length - 1].HOUR;

    if (dayArr && dayArr.length > 1) {
      startEpoch = moment(dayArr[0]).startOf("day").valueOf();
      endEpoch = moment(dayArr[dayArr.length - 1])
        .startOf("day")
        .valueOf();
    }

    let mode = TIMELINE_MODES.DATE;

    if (
      moment(startEpoch).startOf("day").valueOf() ===
      moment(endEpoch).subtract(1, "minute").startOf("day").valueOf()
    ) {
      mode = TIMELINE_MODES.HOUR;
    } else if (!ignoreWeekdayLabels && moment(startEpoch).diff(moment(endEpoch), "days") < 7) {
      mode = TIMELINE_MODES.DAY;
    } else if (moment(endEpoch).diff(startEpoch, "days") > 90) {
      mode = TIMELINE_MODES.MONTH;
    }

    return mode;
  };

  renderDot = ({cx, cy, payload}) => {
    const {primaryEpoch} = payload;

    const mode = this.getTimelineMode();

    if (
      (mode === TIMELINE_MODES.HOUR &&
        moment().startOf("hour").valueOf() <= primaryEpoch &&
        primaryEpoch <= moment().endOf("hour").valueOf()) ||
      ([TIMELINE_MODES.DAY, TIMELINE_MODES.DATE, TIMELINE_MODES.MONTH].includes(mode) &&
        moment().startOf("day").valueOf() <= primaryEpoch &&
        primaryEpoch <= moment().endOf("day").valueOf())
    ) {
      return <circle cx={cx} cy={cy} r="5" fill="#4e46e5" />;
    }

    return <div></div>;
  };

  renderCustomTooltip = ({payload}) => {
    const {datasets} = this.state;
    const {displayAsDollars, tooltipLabel = "Gross Sales"} = this.props;

    if (payload[0]) {
      const {baseValue, compareValue} = payload[0].payload;

      return (
        <div className={"bg-white border border-solid border-gray-300 p-2 rounded-sm"}>
          <div className={"flex flex-row justify-between"}>
            <div className={"font-semibold text-sm"}>{tooltipLabel}</div>

            {datasets.length === 2 && <PercentageBadge current={baseValue} past={compareValue} />}
          </div>

          <div className="w-full my-2 h-0.5 bg-gray-300" />

          <div className={"flex flex-col space-y-2"}>
            {datasets.map(({id, stroke, stopNow}) => {
              const toDisplayValue = displayAsDollars
                ? toDollars(payload[0].payload[`${id}Value`], true)
                : payload[0].payload[`${id}Value`];

              return (
                <div style={{minWidth: 150}} className={"w-44 flex flex-row items-center justify-between"}>
                  <div className={"flex flex-row items-center"}>
                    <div className={"h-3 w-3 mr-1"} style={{backgroundColor: stroke}} />

                    <div className={"text-xs text-gray-700 font-semibold"}>
                      {payload[0].payload[`${id}Date`]}
                    </div>
                  </div>

                  <div className={"text-xs font-semibold"}>
                    {!stopNow || payload[0].payload[`${id}Value`] ? toDisplayValue : undefined}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    return <div></div>;
  };

  getLeftMargin = () => {
    const {datasets} = this.state;

    return (
      datasets.reduce(
        (mostCharacters, value) => Math.max(mostCharacters, this.yTickFormatter(value).length),
        0
      ) * LEFT_MARGIN_MULTIPLIER
    );
  };

  yTickFormatter = (value) => {
    const {displayAsDollars, displayAsPercentage} = this.props;

    if (displayAsDollars) {
      return toDollars(value, true);
    }

    if (displayAsPercentage) {
      return Math.round(value * 100) + "%";
    }

    return value;
  };

  toggleDataset = (id) => {
    const {datasets} = this.state;
    const dataset = datasets.find((d) => d.id === id);

    if (dataset) {
      dataset.toggled = !dataset.toggled;
      dataset.stroke = dataset.toggled ? dataset.displayStroke : null;
    }
    this.setState({datasets});
  };

  legendFormatter = (value) => {
    const {datasets, toggleDatasets} = this.props;
    const dataset = datasets.find((d) => `${d.name}` === value);

    if (dataset && toggleDatasets) {
      return (
        <span
          onClick={() => this.toggleDataset(dataset.id)}
          className={`${dataset.toggled ? "" : "text-black"} cursor-pointer`}
        >
          {dataset.name}
        </span>
      );
    }
    return value;
  };

  render() {
    const {datasets} = this.state;
    const {height, type, tooltip, showLegend} = this.props;

    const {reportKey, domainExtension, tickFormatter, getTickProps} = this.getTimelineMode();

    const data = this.getRechartValues(reportKey);

    const {graphComponent, childComponent} = REPORTING_GRAPH_TYPES[type];

    const GraphComponent = graphComponent;
    const ChildComponent = childComponent;

    return (
      <div>
        <ResponsiveContainer width="100%" height={height}>
          <GraphComponent
            height={height}
            width={"100%"}
            data={data}
            margin={{left: this.getLeftMargin(), right: 35, top: 10}}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey={"index"}
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              axisLine={{stroke: "#cccccc"}}
              type={"number"}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              tickFormatter={(value) => tickFormatter(value, data)}
              domain={["dataMin", `dataMax ${domainExtension}`]}
              interval={"preserveStartEnd"}
              {...getTickProps(data)}
            />

            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              tickFormatter={this.yTickFormatter}
              axisLine={{stroke: "#cccccc"}}
              tickLine={{stroke: "#cccccc"}}
            />

            <Tooltip content={tooltip} />
            {showLegend && <Legend formatter={this.legendFormatter} />}

            {datasets.map((_dataset) => (
              <ChildComponent
                {..._dataset}
                strokeWidth={_dataset.strokeWidth}
                dataKey={`${_dataset.id}Value`}
                dot={_dataset.stopNow ? this.renderDot : false}
              />
            ))}
          </GraphComponent>
        </ResponsiveContainer>
      </div>
    );
  }
}

ReportingSalesGraph.propTypes = {
  height: PropTypes.number,
  type: PropTypes.string,
  ignoreWeekdayLabels: PropTypes.bool,
  datasets: PropTypes.array,
};

export default ReportingSalesGraph;

const MILLIS_IN_HOUR = 3600000;
const MILLIS_IN_DAY = 86400000;

const LEFT_MARGIN_MULTIPLIER = 5;

const TIMELINE_MODES = {
  HOUR: {
    key: "hour",
    reportKey: "HOUR",
    domainExtension: "+1",
    tickFormatter: (index, data) => {
      return moment(data[0].primaryEpoch + index * MILLIS_IN_HOUR).format("hA");
    },
    getTickProps: (data) => {
      return {
        tickCount: Math.min(24, data.length),
      };
    },
  },
  DAY: {
    key: "relativeDay",
    reportKey: "DAY",
    tickFormatter: (index, data) => {
      return moment(data[0].primaryEpoch + index * MILLIS_IN_DAY).format("dddd");
    },
    getTickProps: (data) => {
      return {
        tickCount: Math.min(12, data.length),
      };
    },
  },
  DATE: {
    key: "date",
    reportKey: "DAY",
    tickFormatter: (index, data) => {
      return moment(data[0].primaryEpoch + index * MILLIS_IN_DAY).format("M/D");
    },
    getTickProps: (data) => {
      return {
        tickCount: Math.min(12, data.length),
      };
    },
  },
  MONTH: {
    key: "date",
    reportKey: "DAY",
    tickFormatter: (index, data) => {
      if (index === 0 || index === data.length - 1) {
        return moment(data[index].primaryEpoch).format("MMM D");
      }

      return moment(data[index].primaryEpoch).format("MMM");
    },
    getTickProps: (data) => {
      const toReturn = [];

      for (let i = 0; i < data.length; i++) {
        if (
          moment(data[i].primaryEpoch).startOf("day").valueOf() ===
          moment(data[i].primaryEpoch).startOf("month").valueOf()
        ) {
          toReturn.push(i);
        }
      }

      toReturn.push(data.length - 1);

      return {
        ticks: toReturn,
      };
    },
  },
};

export const REPORTING_GRAPH_TYPES = {
  LINE: {
    id: "LINE",
    graphComponent: LineChart,
    childComponent: Line,
  },
  AREA: {
    id: "AREA",
    graphComponent: AreaChart,
    childComponent: Area,
  },
};
