import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {formatDate, formatType} from "../documents-helper";
import {useArchivedDocuments} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {useSignedUrl} from "../../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

export default function ArchivedDocumentsTab() {
  const {isLoading, error, data} = useArchivedDocuments();

  const {openDocument} = useSignedUrl();

  const handleViewDocument = (document) => {
    openDocument(document.ID);
  };

  const COLUMNS = [
    {
      value: "NAME",
      label: "Name",
    },
    {
      value: "TYPE",
      label: "Type",
      sortable: false,
      format: (value) => formatType(value),
    },
    {
      value: "VERSION",
      label: "Version",
    },
    {
      value: "DATE_ARCHIVED",
      label: "Date Archived",
      format: (value) => formatDate(value),
    },
  ];

  return (
    <Card
      label={"Archived Documents"}
      description={
        "This is the list of company documents that have been archived, meaning they are no longer able to be assigned or added to packets."
      }
    >
      <Table
        sortable
        data={getTableData(isLoading, error, data)}
        isLoading={isLoading}
        pagination={true}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => handleViewDocument(row),
          },
        ]}
        columns={COLUMNS}
      />
    </Card>
  );
}
