import {W4_FIELDS} from "./constants";
import {setCheckboxFieldValue, setFieldValue} from "../fillable-pdf-helpers";
import moment from "moment-timezone";

// W-4 EMPLOYEE FIELDS
export const W4_EMPLOYEE_FIELDS_MAP = {
  firstName: W4_FIELDS.FIRST_NAME,
  lastName: W4_FIELDS.LAST_NAME,
  address: W4_FIELDS.ADDRESS,
  ssn: W4_FIELDS.SSN,
  dependentsClaim: W4_FIELDS.DEPENDENTS,
  otherIncome: W4_FIELDS.OTHER_INCOME,
  deductions: W4_FIELDS.DEDUCTIONS,
  additionalWithholding: W4_FIELDS.ADDITIONAL_AMOUNT,
};

export const W4_EMPLOYEE_EXEMPTION_MAP = {
  withholdingExemption: W4_FIELDS.EXEMPT,
};

export const W4_EMPLOYEE_CHECKBOX_MAP = {
  exactlyTwoJobs: W4_FIELDS.HAS_TWO_JOBS,
};

export const W4_EMPLOYEE_LOCATION_MAP = {
  cityStateZip: W4_FIELDS.CITY_STATE_ZIP,
};

export const W4_EMPLOYEE_SIGNATURE_MAP = {
  signature: W4_FIELDS.EMPLOYEE_SIGNATURE,
  signatureDate: W4_FIELDS.EMPLOYEE_SIGNATURE_DATE,
};

export const FILING_STATUS = {
  SINGLE: "single",
  MARRIED: "married",
  HOUSEHOLD: "household",
};

export const W4_FILING_MAP = {
  [FILING_STATUS.SINGLE]: W4_FIELDS.FILING_STATUS_SINGLE,
  [FILING_STATUS.MARRIED]: W4_FIELDS.FILING_STATUS_MARRIED,
  [FILING_STATUS.HOUSEHOLD]: W4_FIELDS.FILING_STATUS_HEAD_OF_HOUSEHOLD,
};

// W-4 VERIFICATION FIELDS
export const W4_VERIFICATION_MAP = {
  employerName: W4_FIELDS.COMPANY_NAME,
  employerAddress: W4_FIELDS.COMPANY_ADDRESS,
  employeeStartDate: W4_FIELDS.EMPLOYEE_START_DATE,
  employerEIN: W4_FIELDS.COMPANY_FEDERAL_EIN,
};

export const W4_EMPLOYER_LOCATION_MAP = {
  employerCityStateZip: W4_FIELDS.COMPANY_CITY_STATE_ZIP,
};

// W-4 Form Fill Helper Methods
const formatCityStateZip = (city, state, zip) => {
  return `${city}, ${state} ${zip}`;
};

const handleEmployeeExemption = (form, withholdingExemption) => {
  if (withholdingExemption) {
    setFieldValue(form, W4_EMPLOYEE_EXEMPTION_MAP.withholdingExemption, "Exempt");
  }
};

const handleSignature = (form, signature, font) => {
  setFieldValue(form, W4_EMPLOYEE_SIGNATURE_MAP.signatureDate, moment().format("MM/DD/YYYY"));

  setFieldValue(form, W4_EMPLOYEE_SIGNATURE_MAP.signature, signature, font);
};

const handleW4Location = (form, map, city, state, zip) => {
  const cityStateZip = formatCityStateZip(city, state, zip);

  setFieldValue(form, map, cityStateZip);
};

const handleEmployeeJobs = (form, exactlyTwoJobs) => {
  if (exactlyTwoJobs) {
    setCheckboxFieldValue(form, W4_EMPLOYEE_CHECKBOX_MAP.exactlyTwoJobs, true);
  }
};

const handleEmployeeFiling = (form, filingStatus) => {
  setCheckboxFieldValue(form, W4_FILING_MAP[filingStatus], true);
};

export const setW4FormFields = (values, form, font) => {
  Object.entries(W4_EMPLOYEE_FIELDS_MAP).forEach(([key, value]) => {
    if (values[key] !== null) {
      setFieldValue(form, value, values[key]);
    }
  });

  handleW4Location(form, W4_EMPLOYEE_LOCATION_MAP.cityStateZip, values.cityOrTown, values.state, values.zip);

  handleSignature(form, values.signature, font);

  if (values.withholdingExemption) {
    handleEmployeeExemption(form, values.withholdingExemption);
  } else {
    handleEmployeeJobs(form, values.exactlyTwoJobs);

    handleEmployeeFiling(form, values.filingStatus);
  }
};

export const setW4VerificationFields = (values, form, font) => {
  Object.entries(W4_VERIFICATION_MAP).forEach(([key, value]) => {
    setFieldValue(form, value, values[key]);
  });

  handleW4Location(
    form,
    W4_EMPLOYER_LOCATION_MAP.employerCityStateZip,
    values.employerCity,
    values.employerState,
    values.employerZip
  );
};
