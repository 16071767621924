import {useTranslation} from "react-i18next";

export const useI9ListA = () => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  return [
    {label: "-", value: null},
    {type: "listA", label: t(getI18NextKey("listA.usPassport")), value: "usPassport"},
    {type: "listA", label: t(getI18NextKey("listA.permanentResidentCard")), value: "permanentResidentCard"},
    {type: "listA", label: t(getI18NextKey("listA.foreignPassportI551")), value: "foreignPassportI551"},
    {
      type: "listA",
      label: t(getI18NextKey("listA.employmentAuthorization")),
      value: "employmentAuthorization",
    },
  ];
};

export const useI9ListB = () => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  return [
    {type: "listB", label: "-", value: null},
    {type: "listB", label: t(getI18NextKey("listB.driversLicense")), value: "driversLicense"},
    {type: "listB", label: t(getI18NextKey("listB.stateIssuedID")), value: "stateIssuedID"},
    {type: "listB", label: t(getI18NextKey("listB.birthCertificate")), value: "birthCertificate"},
    {type: "listB", label: t(getI18NextKey("listB.schoolID")), value: "schoolID"},
    {type: "listB", label: t(getI18NextKey("listB.votersRegistration")), value: "votersRegistration"},
    {type: "listB", label: t(getI18NextKey("listB.militaryRecord")), value: "militaryRecord"},
    {type: "listB", label: t(getI18NextKey("listB.militaryDependantID")), value: "militaryDependantID"},
    {type: "listB", label: t(getI18NextKey("listB.coastGuardCard")), value: "coastGuardCard"},
    {type: "listB", label: t(getI18NextKey("listB.nativeAmericanTribal")), value: "nativeAmericanTribal"},
    {type: "listB", label: t(getI18NextKey("listB.canadianLicense")), value: "canadianLicense"},
  ];
};

export const useI9ListC = () => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  return [
    {type: "listC", label: "-", value: null},
    {type: "listC", label: t(getI18NextKey("listC.socialSecurityCard")), value: "socialSecurityCard"},
    {type: "listC", label: t(getI18NextKey("listC.birthCertificateCopy")), value: "birthCertificateCopy"},
    {type: "listC", label: t(getI18NextKey("listC.usCitizenID")), value: "usCitizenID"},
    {type: "listC", label: t(getI18NextKey("listC.residentCitizenID")), value: "residentCitizenID"},
    {
      type: "listC",
      label: t(getI18NextKey("listC.employmentAuthorizationDHS")),
      value: "employmentAuthorizationDHS",
    },
    {type: "listC", label: t(getI18NextKey("listC.schoolRecordMinors")), value: "schoolRecordMinors"},
    {type: "listC", label: t(getI18NextKey("listC.doctorHospitalRecord")), value: "doctorHospitalRecord"},
    {type: "listC", label: t(getI18NextKey("listC.daycareRecord")), value: "daycareRecord"},
    {type: "listC", label: t(getI18NextKey("listC.fsmRmiPassport")), value: "fsmRmiPassport"},
  ];
};
