import React, {useState} from "react";
import {Rnd} from "react-rnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DOCUMENT_FIELD_DEFAULTS} from "../../utils/constants";
import {useTransformEffect} from "react-zoom-pan-pinch";

export const DraggableField = ({field, addField, removeField, page, setDisablePan}) => {
  const [scale, setScale] = useState(1);

  useTransformEffect(({state, instance}) => {
    setScale(state.scale);
  });

  const minWidth = 10;
  const minHeight = 10;

  return (
    <Rnd
      key={`${page}-${field.ID}`}
      bounds="parent"
      className={"hover:cursor-pointer"}
      default={{
        x: field.X_COORDINATE ?? 0,
        y: field.Y_COORDINATE ?? 0,
        width: field?.WIDTH ?? DOCUMENT_FIELD_DEFAULTS.WIDTH,
        height: field?.HEIGHT ?? DOCUMENT_FIELD_DEFAULTS.HEIGHT,
      }}
      position={{
        x: field?.X_COORDINATE ?? 0,
        y: field?.Y_COORDINATE ?? 0,
      }}
      scale={scale}
      size={{
        width: Math.max(field?.WIDTH ?? DOCUMENT_FIELD_DEFAULTS.WIDTH, minWidth),
        height: Math.max(field?.HEIGHT ?? DOCUMENT_FIELD_DEFAULTS.HEIGHT, minHeight),
      }}
      minWidth={minWidth}
      minHeight={minHeight}
      onDragStart={() => setDisablePan(true)}
      onDragStop={(e, d) => {
        addField(field.ID, {X_COORDINATE: d.x, Y_COORDINATE: d.y});
        setDisablePan(false);
      }}
      onResizeStart={() => setDisablePan(true)}
      onResizeStop={(e, direction, ref, delta, position) => {
        addField(field.ID, {
          X_COORDINATE: position.x,
          Y_COORDINATE: position.y,
          WIDTH: Math.max(field.WIDTH + delta.width, minWidth), // Enforce min width
          HEIGHT: Math.max(field.HEIGHT + delta.height, minHeight), // Enforce min height
        });

        setDisablePan(false);
      }}
      dragHandleClassName="cursor-move"
    >
      <div
        className={
          (field.IS_REVIEWER
            ? "bg-green-700 text-green-700 border-green-700 "
            : "bg-indigo-500 text-indigo-500 border-indigo-500 ") +
          "flex flex-row justify-between h-full items-center text-sm text-center border bg-opacity-25 rounded-md px-1 cursor-move"
        }
      >
        <div className={"cursor-move z-10"}>{field.ICON}</div>

        <button className={"text-pink-600 hover:text-pink-800 z-10"} onClick={() => removeField(field.ID)}>
          <FontAwesomeIcon icon={"fa-trash"} />
        </button>
      </div>
    </Rnd>
  );
};
