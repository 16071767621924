import React, {Component} from "react";
import {Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";

class CouponsTable extends Component {
  state = {tab: null};

  componentDidUpdate(prevProps) {
    const {currentTab} = this.props;

    if (prevProps.currentTab && currentTab !== prevProps.currentTab) {
      this.setState({tab: currentTab});
    }
  }

  render() {
    const {tab} = this.state;

    return (
      <Filter className="ml-4" searchable searchPlaceholder={"Search a Code..."} noBorder>
        {(filters, search) => {
          filters = [{filter: tab}];

          return (
            <Table
              {...this.props}
              pagination
              route={`promotions/coupons/dumb/?tab=${tab}`}
              search={search}
              filters={filters}
              actionButtons={[
                {
                  label: "View",
                  onClick: (row) => {
                    this.props.router.navigate("/coupon/" + row.UNIQUE_ID);
                  },
                },
              ]}
              focus={false}
              columns={[
                {
                  value: "NAME",
                  label: "Promotion Name",
                  format: (_, coupon) => coupon?.PROMOTION?.NAME,
                },
                {
                  value: "CODE",
                  label: "Code",
                },
                {
                  width: 1,
                  value: "DATE_CREATED",
                  label: "Created",
                  format: (value, row) => {
                    return moment(value).isValid() ? moment(value).format("MM/DD/YY") : "-";
                  },
                },
              ]}
            />
          );
        }}
      </Filter>
    );
  }
}

export default withRouter(CouponsTable);
