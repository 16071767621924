import {useMutation, useQueryClient} from "@tanstack/react-query";
import {showErrorNotification, showSuccessNotification} from "../utils/notification-helper";

export const useOnboardingMutation = (
  mutationFn,
  {onSuccess, successLabel, successMessage, queryKeys = [], errorLabel, errorMessage, onError}
) => {
  const queryClient = useQueryClient();

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      if (onSuccess) {
        onSuccess(data);
      }

      if (successLabel && successMessage) {
        showSuccessNotification(successLabel, successMessage);
      }

      queryKeys.forEach((key) => queryClient.invalidateQueries({queryKey: key}));
    },
    onError: (error) => {
      if (errorLabel && errorMessage) {
        showErrorNotification(errorLabel, errorMessage);
      }

      if (onError) {
        onError(error);
      }
    },
  });
};
