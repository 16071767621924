import {Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";

export const FormRadioSelect = ({name, data, label, sublabel, formik, tooltip}) => {
  return (
    <div className={"mt-8"}>
      <div className={"flex flex-row items-center space-x-2"}>
        <span className={"block text-sm font-medium text-gray-700"}>{label}</span>
        {tooltip && <Tooltip data={tooltip} />}
      </div>

      <span className={"text-sm text-gray-500 mb-2"}>{sublabel}</span>
      {data.map((item, index) => (
        <div key={index} className={"flex flex-row items-center mt-2"}>
          <label className={"text-sm font-medium text-gray-500 mr-2"}>
            <input
              type="radio"
              name={name}
              value={item.value}
              onChange={formik.handleChange}
              checked={formik.values[name] === item.value}
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 mr-2"
            />
            {item.label}
          </label>
          {item.description && <Tooltip data={item.description} />}
        </div>
      ))}

      {formik.touched[name] && formik.errors[name] && (
        <div className="text-sm text-red-500 mt-1">{formik.errors[name]}</div>
      )}
    </div>
  );
};
