import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../redux";
import {Modal, FormInput, FormSelect, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {TIMEZONE_LABELS} from "../../utils/settings-constants";

class TimezoneModal extends Component {
  open() {
    this.formikRef && this.formikRef.resetForm();
    this.modal.open();
  }

  handleSubmit = async ({TIMEZONE}) => {
    let settingsPayload = {
      TIMEZONE,
    };

    await request("partner/settings/v2", "PATCH", {SETTINGS: settingsPayload});

    let settings = await request("settings/shop", "GET");

    this.props.updateShop({settings});

    await this.props.handleUpdate();
    this.modal.close();
  };

  render() {
    let {location} = this.props.shop;
    let {TIMEZONE} = this.props.shop.settings;

    return (
      <Modal
        label="Set Timezone"
        buttonLabel={"Save"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize={true}
          onSubmit={this.handleSubmit}
          initialValues={{...location, TIMEZONE}}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormSelect
                  label="Timezone"
                  name="TIMEZONE"
                  data={Object.keys(TIMEZONE_LABELS).map((key) => ({
                    label: TIMEZONE_LABELS[key],
                    value: key,
                  }))}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"], null, {forwardRef: true})(TimezoneModal);
