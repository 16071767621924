import {
  CustomDocumentRequests,
  DefaultDocumentRequests,
  DocumentAssignments,
  DocumentRequests,
  PacketAssignments,
  PacketRequests,
} from "../../../utils/request-helpers/onboarding/onboarding-requests";
import {useReactQuery} from "../../use-query-wrapper";

export const usePackets = () => {
  return useReactQuery(["packets"], PacketRequests.fetchActive);
};

export const useIncompletePackets = () => {
  return useReactQuery(["incomplete"], PacketAssignments.fetchIncomplete);
};

export const useDashboardCount = () => {
  return useReactQuery(["dashboard-count"], PacketAssignments.getCount);
};

export const useDocumentsInReview = () => {
  return useReactQuery(["documentsInReview"], DocumentAssignments.fetchInReview);
};

export const usePacketsInReview = () => {
  return useReactQuery(["packetsInReview"], PacketAssignments.fetchInReview);
};

export const useApprovedPackets = () => {
  return useReactQuery(["approvedPackets"], PacketAssignments.fetchCompleted);
};

export const useDefaultDocuments = () => {
  return useReactQuery(["default"], DefaultDocumentRequests.fetchActive);
};

export const useCustomDocuments = () => {
  return useReactQuery(["custom"], CustomDocumentRequests.fetch);
};

export const useArchivedDocuments = () => {
  return useReactQuery(["archived"], CustomDocumentRequests.fetchArchived);
};

export const useDocumentEdits = (existingDocument) => {
  return useReactQuery(
    ["document-edits", existingDocument?.ID],
    () => CustomDocumentRequests.fetchEdits(existingDocument?.ID),
    {enabled: !!existingDocument, keepPreviousData: false},
    0,
    0
  );
};

export const useDocumentSignedUrl = (existingDocument) => {
  return useReactQuery(
    ["documents-signed-url", existingDocument?.ID],
    () => DocumentRequests.fetchMetadata(existingDocument?.ID),
    {
      enabled: !!existingDocument,
    }
  );
};
