import {Card, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {ReadOnlyTable} from "../../../../../../v2/components/shared";
import {formatDate, formatType} from "../../documents/documents-helper";
import {useApprovedPackets} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {useEmployeeDocumentSignedUrl} from "../../../../../../hooks/team/onboarding/onboarding-hub-hooks";
import {getFilterData} from "../../table-helpers";

export const CompletedTab = () => {
  const {isLoading, error, data} = useApprovedPackets();

  const {openDocument} = useEmployeeDocumentSignedUrl();

  const COLUMNS = [
    {
      value: "EMPLOYEE_NAME",
      label: "Employee Name",
    },
    {
      value: "LOCATION_NAME",
      label: "Location",
    },
    {
      value: "PACKET_NAME",
      label: "Packet Name",
    },
    {
      value: "DATE_COMPLETED",
      label: "Date Completed",
      format: (value) => formatDate(value),
    },
  ];

  const handleViewDocument = async (document) => {
    openDocument(document.ASSIGNMENT_URL ?? document.URL);
  };

  return (
    <Card
      label={"Approved Packets"}
      description={
        "The following packets have been fully completed by the onboarding employee and approved by a reviewer."
      }
    >
      <Filter defaultFilters={[]} data={getFilterData(data)}>
        {(filters, _) => (
          <Table
            sortable
            columns={COLUMNS}
            data={getTableData(isLoading, error, data)}
            pagination={true}
            filters={filters}
            actionButtons={[]}
            expandable={(packet) => (
              <ReadOnlyTable
                className={"m-2"}
                columns={[
                  {
                    key: "NAME",
                    label: "Document Name",
                  },
                  {
                    key: "TYPE",
                    label: "Type",
                    format: (value) => formatType(value),
                  },
                  {
                    key: "VERSION",
                    label: "Version",
                  },
                ]}
                data={packet.DOCUMENTS}
                buttons={[
                  {
                    label: "View",
                    onClick: (document) => handleViewDocument(document),
                  },
                ]}
              />
            )}
          />
        )}
      </Filter>
    </Card>
  );
};
