import React, {Component} from "react";
import SignatureModal from "../../../../../modals/team/employee/onboarding/signature-modal";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Document, Page} from "react-pdf";
import {PDFDocument} from "pdf-lib";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../../utils/request";
import SignatureField from "../../../../../components/pdf-editor/signature-field";
import {I9_PREFILL_FIELDS, I9_SPANISH_PREFILL_FIELDS} from "../../../../../utils/constants";
import moment from "moment-timezone";
import ReviewAndConfirmFileModal from "../../../../../modals/team/employee/onboarding/review-and-confirm-file-modal";
import i18next from "i18next";
import {cloneDeep} from "lodash";

class I9SignPage extends Component {
  state = {
    document: null,
    field: {
      WIDTH: i18next.language === "en" ? 243 : 117,
      HEIGHT: i18next.language === "en" ? 24 : 26,
      X_COORDINATE: i18next.language === "en" ? 126 : 114,
      Y_COORDINATE: i18next.language === "en" ? 514 : 372,
      ID: -1,
    },
  };

  componentDidMount() {
    const language = i18next.language;

    request("company-documents/i9/part1/" + language, "GET").then(async (document) => {
      document = await this.applyAnnotations(document);

      this.setState({document});
    });
  }

  getI18NextKey(path) {
    return "pages.onboarding.landing-page-types.i9-part1.i-9-sign-page." + path;
  }

  async submitDocument(document) {
    const {employee, details} = this.props;
    await request("employees/file/" + employee.ID, "POST", document);

    await request(
      "checklists/instances/" + details.CHECKLIST_INSTANCE_ID + "/item/" + details.ID + "/resolve",
      "POST"
    );
  }

  async handleSubmit() {
    const {document, field} = this.state;
    const documentCopy = cloneDeep(document);

    documentCopy.FILE_NAME = "I9_Part_1.pdf";
    documentCopy.DATA.data = await this.writeSignature(field, documentCopy);

    const i9Document = await this.confirmModal.open(documentCopy);

    if (!!i9Document) {
      this.submitDocument({
        KEY: i9Document.KEY,
        FILE_NAME: "I9_Part1.pdf",
        BUCKET: "onboarding-company-documents",
      });

      this.props.options.setFieldValue("i9Document", i9Document);
    }

    return !!i9Document;
  }

  async writeSignature(field, document) {
    const editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    const {employee} = this.props;

    const imageBytes = await fetch(field.VALUE).then((data) => data.arrayBuffer());

    const pngImage = await editablePDF.embedPng(imageBytes);
    const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    page.drawImage(pngImage, {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT,
      width: field.WIDTH,
      height: field.HEIGHT,
    });

    request("company-documents/signature/image", "POST", {
      FILE: Array.from(new Uint8Array(imageBytes)),
    }).then(({url}) => {
      request("company-documents/signatures", "POST", {
        DATE_SIGNED: Date.now(),
        DOCUMENT_FIELD_ID: null,
        SIGNATURE_IMAGE_URL: url,
        ACCOUNT_ID: employee.ACCOUNT_ID,
      });
    });

    return editablePDF.save();
  }

  async applySignature() {
    const {field} = this.state;

    field.VALUE = await this.signatureModal.open(field);

    if (!field.VALUE) {
      return false;
    }

    this.setState({field});
    return true;
  }

  checkValidationType(editablePdf, page, annotations) {
    const validationType = annotations["validationType"];
    const FIELDS = i18next.language === "en" ? I9_PREFILL_FIELDS : I9_SPANISH_PREFILL_FIELDS;

    const validationField = FIELDS[validationType];

    page.drawText("x", {
      x: validationField.X_COORDINATE,
      y: page.getHeight() - validationField.Y_COORDINATE - validationField.HEIGHT,
      width: validationField.WIDTH,
      height: validationField.HEIGHT,
      size: 18,
    });
  }

  fillSocialSecurity(page, annotations) {
    const FIELDS = i18next.language === "en" ? I9_PREFILL_FIELDS : I9_SPANISH_PREFILL_FIELDS;

    const ssn = annotations["ssn"];
    const ssn1Field = FIELDS["ssn1"];
    const ssn2Field = FIELDS["ssn2"];
    const ssn3Field = FIELDS["ssn3"];
    const ssnSplit = ssn.split(" ");

    page.drawText(ssnSplit[0], {
      x: ssn1Field.X_COORDINATE,
      y: page.getHeight() - ssn1Field.Y_COORDINATE - ssn1Field.HEIGHT,
      width: ssn1Field.WIDTH,
      height: ssn1Field.HEIGHT,
      size: 16,
    });

    page.drawText(ssnSplit[1], {
      x: ssn2Field.X_COORDINATE,
      y: page.getHeight() - ssn2Field.Y_COORDINATE - ssn2Field.HEIGHT,
      width: ssn2Field.WIDTH,
      height: ssn2Field.HEIGHT,
      size: 16,
    });

    page.drawText(ssnSplit[2], {
      x: ssn3Field.X_COORDINATE,
      y: page.getHeight() - ssn3Field.Y_COORDINATE - ssn3Field.HEIGHT,
      width: ssn3Field.WIDTH,
      height: ssn3Field.HEIGHT,
      size: 16,
    });
  }

  writeTodaysDate(page) {
    if (i18next.language === "en") {
      page.drawText(moment().format("M/D/YYYY"), {
        x: 475,
        y: page.getHeight() - 535,
        width: 214,
        height: 22,
        size: 12,
      });
    } else {
      page.drawText(moment().format("M/D/YYYY"), {
        x: 366,
        y: page.getHeight() - 392,
        width: 174,
        height: 26,
        size: 12,
      });
    }
  }

  checkTranslator(page) {
    if (i18next.language === "en") {
      page.drawText("x", {
        x: 38,
        y: page.getHeight() - 568,
        width: 10,
        height: 10,
        size: 18,
      });
    }
  }

  async applyAnnotations(document) {
    const {values} = this.props.options;

    const editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    for (const field of editablePDF.getForm().getFields()) {
      editablePDF.getForm().removeField(field);
    }

    const language = i18next.language;
    const PREFILL_FIELDS = language === "en" ? I9_PREFILL_FIELDS : I9_SPANISH_PREFILL_FIELDS;
    const page = editablePDF.getPages()[0];

    Object.keys(PREFILL_FIELDS).forEach((field) => {
      const fieldValue = values[field];

      if (!fieldValue) {
        return;
      }

      const fieldDetails = PREFILL_FIELDS[field];

      page.drawText(fieldValue, {
        x: fieldDetails.X_COORDINATE + 5,
        y: page.getHeight() - fieldDetails.Y_COORDINATE - fieldDetails.HEIGHT + 5,
        width: fieldDetails.WIDTH,
        height: fieldDetails.HEIGHT,
        size: 12,
      });
    });

    this.checkValidationType(editablePDF, page, values);
    this.fillSocialSecurity(page, values);
    this.writeTodaysDate(page);
    this.checkTranslator(page);

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  renderFields() {
    const {field} = this.state;

    return (
      <SignatureField
        field={field}
        onClick={async () => {
          const result = await this.applySignature();
          this.props.options.setFieldValue("i9DocumentSigned", !!result);
        }}
        selected={false}
        key={"SIGNATURE"}
        completed={!!field.VALUE}
        message={i18next.t(this.getI18NextKey("signature.label"))}
      />
    );
  }

  render() {
    const {document} = this.state;
    const {employee} = this.props;

    if (!document) {
      return <Loading />;
    }

    return (
      <>
        <ReviewAndConfirmFileModal
          ref={(e) => (this.confirmModal = e)}
          employee={employee}
          key={randomString(24)}
        />

        <SignatureModal
          key={randomString(24)}
          ref={(e) => (this.signatureModal = e)}
          document={document}
          employee={employee}
        />

        <div className={"relative w-full flex flex-col justify-center align-middle items-center"}>
          <div className={"h-[32rem] w-full sm:w-auto relative overflow-y-scroll overflow-x-scroll"}>
            <Document file={document.DATA}>
              <Page pageNumber={1} />
              {this.renderFields()}
            </Document>
          </div>
        </div>
      </>
    );
  }
}

export default I9SignPage;
