import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {withRouter} from "../../../utils/navigation";

class PromotionsTable extends Component {
  render() {
    const {promotions} = this.props;

    return (
      <Table
        {...this.props}
        pagination
        data={promotions}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/promotion/" + row.UNIQUE_ID),
          },
        ]}
        columns={[
          {
            value: "NAME",
            label: "Name",
          },
          {
            value: "DATE_CREATED",
            label: "Created",
            mobile: "lg:table-cell",
            format: (value, row) => {
              return moment(value).format("MM/DD/YY");
            },
          },
          {
            value: "DATE_UPDATED",
            label: "Updated",
            mobile: "lg:table-cell",
            format: (value) => {
              return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
            },
          },
        ]}
      />
    );
  }
}

PromotionsTable.propTypes = {
  promotions: PropTypes.array.isRequired,
};

export default withRouter(PromotionsTable);
