import React, {Component} from "react";
import {CheckIcon, ChevronDownIcon, ChevronRightIcon} from "@heroicons/react/solid";
import {TrashIcon} from "@heroicons/react/outline";
import {request} from "../../utils/request";
import {Check} from "react-feather";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../components/loading-spinner";

class TemplateCard extends Component {
  state = {
    expanded: false,
    applied: false,
    loadingApply: false,
    loadingDelete: false,
  };

  applyTemplate = async () => {
    this.setState({loadingApply: true});
    let {startWeek, signedInLocationId} = this.props;
    let {ID} = this.props.template;
    const {locationId} = this.props;

    this.setState({applied: true});

    await request(`scheduling/templates/${ID}/${startWeek}`, "POST", {
      LOCATION_ID: locationId,
    });

    if (locationId !== signedInLocationId && this.props.refresh) {
      this.props.refresh(false);
    }

    this.setState({loadingApply: false});
    this.props.close();
  };

  deleteTemplate = async () => {
    this.setState({loadingDelete: true});
    let {ID} = this.props.template;

    await request(`scheduling/templates/${ID}`, "DELETE");

    await this.props.handleUpdate(false);

    this.setState({loadingDelete: false});
  };

  render() {
    let {NAME, TOTAL_HOURS, TOTAL_COST} = this.props.template;
    let {expanded, applied, loadingApply, loadingDelete} = this.state;

    return (
      <div className="bg-white mt-1 p-2 w-full border ">
        <div className="flex flex-row justify-between">
          <div
            className="flex flex-row"
            onClick={() =>
              this.setState((prevState) => ({
                expanded: !prevState.expanded,
              }))
            }
          >
            {!expanded && <ChevronRightIcon className="h-5 w-5 mr-1 text-color-gray-700 cursor-pointer" />}

            {expanded && <ChevronDownIcon className="h-5 w-5 mr-1 text-color-gray-700 cursor-pointer" />}

            <div className="text-sm">{NAME}</div>
          </div>

          <div className="flex flex-row items-center">
            {!applied && (
              <div
                className="text-indigo-700 font-medium mr-4 cursor-pointer text-sm"
                onClick={this.applyTemplate}
              >
                Apply
              </div>
            )}

            {loadingApply ? (
              <div className="mr-6">
                <LoadingSpinner size={20} />
              </div>
            ) : (
              <div>{applied && <CheckIcon className="h-5 w-5 text-green-700 mr-6" />}</div>
            )}

            {loadingDelete ? (
              <LoadingSpinner size={20} />
            ) : (
              <TrashIcon className="h-5 w-5 text-gray-600 cursor-pointer" onClick={this.deleteTemplate} />
            )}
          </div>
        </div>

        {expanded && (
          <div className="mt-2 text-xs">
            <div>Total Cost: {toDollars(TOTAL_COST, true)}</div>
            <div>Total Hours: {TOTAL_HOURS}</div>
          </div>
        )}
      </div>
    );
  }
}

export default TemplateCard;
