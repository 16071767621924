export const getFilterData = (data) => {
  const locationOptions = Array.from(new Set(data?.map((packet) => packet.LOCATION_NAME) ?? [])).map(
    (location) => ({label: location, id: location})
  );

  const packetOptions = Array.from(new Set(data?.map((packet) => packet.PACKET_NAME) ?? [])).map(
    (packet) => ({label: packet, id: packet})
  );

  return [
    {
      id: "location",
      label: "Filter Location",
      onFilter: (options, data) => data?.filter((item) => options.includes(item.LOCATION_NAME)),
      options: locationOptions,
    },
    {
      id: "packet",
      label: "Filter Packet",
      onFilter: (options, data) => data?.filter((item) => options.includes(item.PACKET_NAME)),
      options: packetOptions,
    },
  ];
};
