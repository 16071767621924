import {useTranslation} from "react-i18next";
import FormRow from "../../../../../../components/form-row";
import {FormElement, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";
import FormStateSelect from "../../../../../../components/form-state-select";
import InputMask from "react-input-mask";

export const W4EmployeeInfoStep = ({formik}) => {
  const {t, i18n} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-info-page." + path;
  };

  const getGeneralKey = (path) => {
    return "pages.onboarding.general.forms.fields." + path;
  };

  return (
    <form className={"flex flex-col gap-y-4 my-8"}>
      <FormRow>
        <FormInput
          name={"firstName"}
          label={t(getI18NextKey("firstNameMiddle.label"))}
          flex
          options={formik}
        />

        <FormInput name={"lastName"} label={t(getI18NextKey("lastName.label"))} flex options={formik} />
      </FormRow>

      <FormInput name={"address"} label={t(getI18NextKey("address.label"))} options={formik} />

      <FormRow>
        <FormInput name={"cityOrTown"} label={t(getI18NextKey("city.label"))} flex options={formik} />

        <FormStateSelect name={"state"} label={t(getI18NextKey("state.label"))} flex options={formik} />

        <FormInput name={"zip"} label={t(getI18NextKey("zip.label"))} flex options={formik} />
      </FormRow>

      <FormRow>
        <FormElement
          onChange={formik.handleChange}
          options={formik}
          label={t(getGeneralKey("ssn"))}
          name={"ssn"}
        >
          {(value, error, onChange) => {
            return (
              <InputMask
                mask={"999-99-9999"}
                name={"ssn"}
                type={"text"}
                maskChar={null}
                placeholder={"XXX-XX-XXXX"}
                pattern="\d{3}-\d{2}-\d{4}"
                value={value}
                flex
                options={formik}
                onChange={onChange}
                aria-describedby="email-error"
                aria-invalid="true"
                className={
                  "focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                }
              />
            );
          }}
        </FormElement>
      </FormRow>
    </form>
  );
};
