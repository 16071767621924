import {COLUMNS} from "../documents-helper";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useLocation, useNavigate} from "react-router-dom";
import {useCustomDocuments} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {useState} from "react";
import {ConfirmationModal} from "../../../../../../modals/general/confirmation-modal";
import {
  useArchiveDocument,
  useSignedUrl,
} from "../../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

export default function CustomDocumentsTab() {
  const navigate = useNavigate();
  const location = useLocation();

  const {isLoading, error, data} = useCustomDocuments();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(null);

  const {openDocument} = useSignedUrl();

  const handleViewDocument = (document) => {
    openDocument(document.ID);
  };

  const {mutate: archiveDocument, isLoading: mutationLoading} = useArchiveDocument();

  const handleArchiveDocument = () => {
    archiveDocument(selectedDocument.ID);
  };

  const onEdit = (document) => {
    navigate("/onboarding/edit-document", {state: {from: location.pathname, document: document}});
  };

  const onArchive = async (document) => {
    setSelectedDocument(document);
    setIsConfirmationModalOpen(true);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleModalClose={() => {
          setIsConfirmationModalOpen(false);
          setSelectedDocument(null);
        }}
        modalLabel={"Archive Document Confirmation"}
        tooltip={
          "Archiving this document will remove it from all active packets. If an employee has already completed the packet, the document will remain visible in their file"
        }
        confirmationAction={handleArchiveDocument}
        confirmationMessage={
          "Are you sure you want to proceed? Once archived, a document cannot be unarchived."
        }
        isLoading={mutationLoading}
      />

      <Card
        label={"Custom Documents"}
        description={"This is the list of custom, company documents that have been uploaded and edited."}
      >
        <Table
          sortable
          data={getTableData(isLoading, error, data)}
          isLoading={isLoading}
          pagination={true}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => handleViewDocument(row),
            },
            {
              label: "Edit",
              onClick: (row) => onEdit(row),
            },
            {
              label: "Archive",
              onClick: (row) => onArchive(row),
            },
          ]}
          columns={COLUMNS}
        />
      </Card>
    </>
  );
}
