import React, {Component} from "react";
import FullCalendar from "@fullcalendar/react";
import moment from "moment-timezone";
import SchedulerResource from "./scheduler-resource";
import OpenShiftSchedulerResource from "./open-shift-scheduler-resource";
import SchedulerEvent from "./scheduler-event";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import interactionPlugin from "@fullcalendar/interaction";
import adaptivePlugin from "@fullcalendar/adaptive";
import ResourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {setupReduxConnection} from "../../redux";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import "./scheduler.scss";
import ShiftModal from "../../modals/scheduling/shift-modal";
import CopyWeekScheduleModal from "../../modals/scheduling/copy-week-schedule-modal";
import {getShortURL, request} from "../../utils/request";
import EventSchedulerResource from "./event-scheduler-resource";
import EventModal from "../../modals/scheduling/event-modal";
import io from "socket.io-client";
import OpenShiftModal from "../../modals/scheduling/open-shift-modal";
import PropTypes from "prop-types";
import PreferredEvent from "./preferred-event";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EmployeeModal from "../../modals/scheduling/employee-modal";
import {withRouter} from "../../utils/navigation";
import TimeOffModal from "../../modals/team/time-off-modal";
import {dayToIsoDay} from "../../utils/util";
import {createSearchParams} from "react-router-dom";
import StatsContainer from "./stats-container";
import LaborSalesDisplay from "./labor-sales-display";
import {showErrorAlert, showLoadingConfirmAlert} from "../../utils/alert-helper";
import UnavailabilityModal from "../../modals/scheduling/unavailability-modal";
import PreferredModal from "../../modals/scheduling/preferred-modal";
import {
  getShiftLabel,
  getTimeOffDescription,
  isShiftInTimeOffRange,
  isShiftInUnavailabilityRange,
} from "../../utils/scheduler-helper";
import {showErrorNotification} from "../../utils/notification-helper";

class Scheduler extends Component {
  calendarRef = React.createRef();

  componentDidMount() {
    this.setupSocket();

    const params = new URLSearchParams(document.location.search);

    this.setSchedulerParams(params);

    request("scheduling/weather", "GET").then((weather) => {
      this.props.setScheduler({weather});
    });

    if (window.innerWidth <= MOBILE_BREAKPOINT) {
      this.handleCurrentViewChange(VIEWS.AGENDA);
    }
  }

  componentWillUnmount() {
    this.setSchedulerParams();

    this.socket.disconnect();
  }

  setSchedulerParams(params = null) {
    const view = params?.get("view");
    const date = params?.get("date");

    const {permissions: userPermissions} = this.props.user;
    const {SCHEDULER_DEFAULT_VIEW, TIMEZONE} = this.props.shop.settings;
    const shiftView = params?.get("shiftView") ?? SCHEDULER_DEFAULT_VIEW;

    let payViewPermission =
      userPermissions?.includes("ADMIN_FULL") || userPermissions?.includes("PAY_RATES_FULL");

    this.props.updateCurrentViewString(this.calendarRef.current.getApi().currentDataManager.data.viewTitle);

    this.props.setScheduler({
      payViewPermission,
      shiftView,
      currentDateString: moment().format("YYYY-MM-DD"),
      timezone: TIMEZONE,
    });

    if (params) {
      if (date) {
        this.fetchInitialSchedule(view, parseInt(date));
      } else {
        this.updateCalendarEpoch(parseInt(moment().startOf("day").valueOf()));
      }
    }
  }

  setupSocket() {
    this.socket = io(getShortURL(), {
      transports: ["websocket"],
      upgrade: false,
    });

    this.socket.on("connect", () => {
      this.socket.emit("token", {
        TOKEN: window.localStorage["TOKEN"],
        LOCATION: window.localStorage["CURRENT_LOCATION"],
      });
    });

    this.socket.on("SCHEDULE_UPDATED_DAY", (data) => {
      let {start, schedule} = data;

      if (this.isToggledLocation()) {
        return;
      }

      if (
        this.props.scheduling.currentView === VIEWS.DAY &&
        start === this.calendarRef.current?.getApi().view.activeStart.valueOf()
      ) {
        this.props.setScheduler({
          schedule,
          waitingForLoadEvent: {
            id: null,
            type: null,
          },
          waitingForLoad: false,
        });
      }
    });

    this.socket.on("SCHEDULE_UPDATED_MONTH", (data) => {
      let {start, schedule} = data;

      if (this.isToggledLocation()) {
        return;
      }

      if (
        this.props.scheduling.currentView === VIEWS.MONTH &&
        start === this.calendarRef.current?.getApi().view.activeStart.valueOf()
      ) {
        this.props.setScheduler({
          schedule,
          waitingForLoadEvent: {
            id: null,
            type: null,
          },
          waitingForLoad: false,
        });
      }
    });

    this.socket.on("SCHEDULE_UPDATED", (data) => {
      let {start, schedule} = data;

      if (this.isToggledLocation()) {
        return;
      }

      if (
        this.props.scheduling.currentView === VIEWS.WEEK &&
        start === this.calendarRef.current.getApi().view.activeStart.valueOf()
      ) {
        this.props.setScheduler({
          schedule,
          waitingForLoadEvent: {
            id: null,
            type: null,
          },
          waitingForLoad: false,
        });
      }
    });

    this.socket.on("EMPLOYEE_CLOCK_IN", (data) => {
      this.props.insertCard(data);
    });
  }

  isToggledLocation() {
    const {locationArr: locationIdArr} = this.props.scheduling;

    if (locationIdArr === null || locationIdArr.length === 0) {
      return false;
    }

    if (
      Array.isArray(locationIdArr) &&
      locationIdArr.length === 1 &&
      locationIdArr[0] === this.props.shop.location.ID
    ) {
      return false;
    }

    return true;
  }

  clearWaitingForLoad() {
    this.props.setScheduler({
      waitingForLoadEvent: {
        id: null,
        type: null,
      },
      waitingForLoad: false,
    });
  }

  handleEventCreation = ({start, end, resource, view}) => {
    this.shiftModal.openForCreation(
      moment(start.getTime ? start.getTime() : start)
        .startOf("day")
        .valueOf(),
      resource?.extendedProps?.item?.ID ? parseInt(resource.extendedProps.item.ID) : null,
      resource?.extendedProps?.item?.EMPLOYEE_ROLE_ID
        ? parseInt(resource.extendedProps.item.EMPLOYEE_ROLE_ID)
        : null,
      start ? start : null,
      end ? end : null,
      this.props.scheduling.currentView
    );
  };

  handleEventEventCreation = ({start, end, type}) => {
    this.eventModal.openWithDates(start, end, type === "customWeek");
  };

  handleOpenShiftCreation = ({start, end, view}) => {
    this.openShiftModal.openForCreation(moment(start).startOf("day").valueOf(), start, end);
  };

  handleEventClick = ({event}) => {
    if (
      event.extendedProps.eventType === EVENT_TYPES.SHIFT ||
      event.extendedProps.eventType === EVENT_TYPES.CROSS_LOCATION_SHIFT
    ) {
      this.shiftModal.openForEdit(
        event.extendedProps.item,
        this.props.scheduling.currentView,
        event.extendedProps.eventType
      );
    } else if (event.extendedProps.eventType === EVENT_TYPES.EVENT) {
      const {item: scheduleEvent} = event.extendedProps;
      this.eventModal.open(scheduleEvent);
    } else if (event.extendedProps.eventType === EVENT_TYPES.OPEN_SHIFT) {
      this.openShiftModal.openForEdit(event.extendedProps.item);
    } else if (event.extendedProps.eventType === EVENT_TYPES.UNAVAILABILITY) {
      const {item: unavailability} = event.extendedProps;
      this.unavailabilityModal.open(unavailability);
    } else if (event.extendedProps.eventType === EVENT_TYPES.PREFERRED) {
      this.preferredModal.open(event.extendedProps.item);
    } else if (event.extendedProps.eventType === EVENT_TYPES.TIME_OFF_REQUEST) {
      const timeOffObj = {
        EMPLOYEE_NAME: event.extendedProps.item.FULL_NAME,
        ...event.extendedProps.item,
      };
      this.timeoffModal.open(timeOffObj);
    } else {
      this.shiftModal.openForCreation(
        moment(event.start).startOf("day").valueOf(),
        event.extendedProps.item.EMPLOYEE_ID,
        event.extendedProps.item.EMPLOYEE_ROLE_ID
      );
    }
  };

  handleFetchSchedule = async (start, end, newView) => {
    const {locationIdArr} = this.props;

    let viewPayload = {};

    if (newView) {
      viewPayload = {currentView: newView};
    }

    try {
      request("scheduling/locations/schedule?DATE_START=" + start + "&DATE_END=" + end, "POST", {
        LOCATION_ID_ARR: locationIdArr,
      }).then((schedule) => {
        this.props.setScheduler({
          schedule,
          currentViewString: this.calendarRef.current?.getApi().currentDataManager.data.viewTitle,
          firstLoad: false,
          ...viewPayload,
        });

        this.props.updateLoading(false);
      });
    } catch (err) {
      console.log("Failed to retrieve schedule!");
      console.log(err);
    }
  };

  handleFetchingCurrentCalendarDatesSchedule = async (loading = true) => {
    if (!!loading) {
      this.props.updateLoading(true);
    }

    let start = moment(this.calendarRef.current.getApi().view.activeStart).valueOf();
    let end = moment(this.calendarRef.current.getApi().view.activeEnd).valueOf();

    this.props.setCurrentViewString(this.calendarRef.current.getApi().currentDataManager.data.viewTitle);

    await this.handleFetchSchedule(start, end);
  };

  handleCopyWeek = async () => {
    this.scheduleCopyModal.open();
  };

  handleToggleShiftOnly = () => {
    this.props.setScheduler({
      showShiftsOnly: !this.props.scheduling.showShiftsOnly,
    });
  };

  handleToggleUnavailability = () => {
    const {showUnavailability} = this.props.scheduling;

    this.props.setScheduler({
      showUnavailability: !showUnavailability,
    });
  };

  handleToggleShowEmployeesWithShifts = () => {
    const {showEmployeesWithShifts} = this.props.scheduling;

    this.props.setScheduler({
      showEmployeesWithShifts: !showEmployeesWithShifts,
    });
  };

  handleToggleShowLaborCost = () => {
    const {showLaborCost} = this.props.scheduling;

    this.props.setScheduler({showLaborCost: !showLaborCost});
  };

  formatEvents = () => {
    const {SCHEDULER_HINTS, SCHEDULER_NO_SHOW_THRESHOLD, SCHEDULER_LATE_THRESHOLD} = this.props.shop.settings;
    let {shiftView, showUnavailability, currentView, sortBy} = this.props.scheduling;
    let {events} = this.props.scheduling.schedule;
    const isDayView = currentView === VIEWS.DAY;

    const {isToggledLocation} = this.props;

    if (this.calendarRef.current) {
      let finalEvents = [];

      // Filter out the null Employee IDS, i.e. the open shifts
      let shifts = this.props.scheduling.schedule.shifts
        .filter((item) => {
          return item.EMPLOYEE_ID;
        })
        .map((item) => {
          let resourceId;
          let title;

          if (isDayView && sortBy === SORT_BY.SHIFT) {
            resourceId = "shift_" + item.ID;
            title = item.ROLE_NAME;
          } else {
            switch (shiftView) {
              case SHIFT_VIEWS.GROUP:
                resourceId = item.EMPLOYEE_ROLE_ID;
                title = item.FULL_NAME;
                break;
              case SHIFT_VIEWS.EMPLOYEES:
                resourceId = item.EMPLOYEE_ID;
                title = item.ROLE_NAME ?? "Archived Role";
                break;
              case SHIFT_VIEWS.ROLES:
                resourceId = item.ROLE_ID;
                title = item.FULL_NAME;
                break;
            }
          }

          let timeText = `${moment(item.DATE_START).format("h:mmA")} - ${moment(item.DATE_END).format(
            "h:mmA"
          )}`;

          return {
            eventType: EVENT_TYPES.SHIFT,
            item,
            resourceId,
            title: title,
            start: moment(item.DATE_START).toDate(),
            end: moment(item.DATE_END).toDate(),
            color: item.DRAFT ? LightenDarkenColor(`#${item.SHIFT_COLOR}`, 80) : `#${item.SHIFT_COLOR}`,
            textColor: item.DRAFT ? EVENT_TEXT_COLORS.SHIFT_DRAFT : EVENT_TEXT_COLORS.SHIFT,
            borderColor: item.DRAFT ? `#${item.SHIFT_COLOR}` : `#${item.SHIFT_COLOR}`,
            timeText,
            ...(SCHEDULER_HINTS === "1" && {
              label: getShiftLabel(item, SCHEDULER_NO_SHOW_THRESHOLD, SCHEDULER_LATE_THRESHOLD),
            }),
          };
        });

      const testShifts = shifts.filter((item) => item.item.EMPLOYEE_ID === 969);

      // Deal with open shifts
      let openShifts = this.props.scheduling.schedule.shifts
        .filter((item) => {
          return item.EMPLOYEE_ID === null;
        })
        .map((item) => {
          let resourceId = RESOURCE_IDS.OPEN_SHIFT;
          let title = item.ROLE_NAME;

          let timeText = `${moment(item.DATE_START).format("h:mmA")} - ${moment(item.DATE_END).format(
            "h:mmA"
          )}`;

          return {
            eventType: EVENT_TYPES.OPEN_SHIFT,
            item,
            resourceId,
            title: title,
            start: moment(item.DATE_START).toDate(),
            end: moment(item.DATE_END).toDate(),
            color: item.DRAFT ? EVENT_COLORS.OPEN_SHIFT_DRAFT : EVENT_COLORS.OPEN_SHIFT,
            textColor: item.DRAFT ? EVENT_TEXT_COLORS.OPEN_SHIFT_DRAFT : EVENT_TEXT_COLORS.OPEN_SHIFT,
            borderColor: item.DRAFT ? EVENT_BORDER_COLORS.OPEN_SHIFT_DRAFT : EVENT_BORDER_COLORS.OPEN_SHIFT,
            timeText,
          };
        });

      let scheduleEvents = events.map((item) => {
        let resourceId = RESOURCE_IDS.EVENT;

        return {
          eventType: EVENT_TYPES.EVENT,
          item,
          resourceId,
          allDay: !item.DATE_END,
          title: item.NAME,
          description: item.DESCRIPTION,
          start: moment(item.DATE_START).toDate(),
          end: item.DATE_END ? moment(item.DATE_END).toDate() : null,
          color: EVENT_COLORS.EVENT,
          textColor: EVENT_TEXT_COLORS.EVENT,
          borderColor: EVENT_BORDER_COLORS.EVENT,
        };
      });

      let crossLocationShifts = this.props.scheduling.schedule.crossLocationShifts.map((item) => {
        let resourceId;

        switch (shiftView) {
          case SHIFT_VIEWS.GROUP:
            resourceId = item.EMPLOYEE_ROLE_ID;
            break;
          case SHIFT_VIEWS.EMPLOYEES:
            resourceId = item.EMPLOYEE_ID;
            break;
          case SHIFT_VIEWS.ROLES:
            resourceId = item.ROLE_ID;
            break;
        }

        const timeText = `${moment(item.DATE_START).format("h:mmA")} - ${moment(item.DATE_END).format(
          "h:mmA"
        )}`;

        return {
          eventType: EVENT_TYPES.CROSS_LOCATION_SHIFT,
          item,
          resourceId,
          title: `at ${item.LOCATION_NAME}`,
          start: moment(item.DATE_START).toDate(),
          end: moment(item.DATE_END).toDate(),
          color: EVENT_COLORS.CROSS_SHIFT,
          timeText,
        };
      });

      let unavailability =
        this.props.scheduling.schedule.unavailability
          ?.filter((item) => item.TYPE === 1)
          ?.map((item) => {
            let resourceId;

            switch (shiftView) {
              case SHIFT_VIEWS.GROUP:
                resourceId = item.EMPLOYEE_ROLE_ID;
                break;
              case SHIFT_VIEWS.EMPLOYEES:
                resourceId = item.EMPLOYEE_ID;
                break;
            }

            let timeText = `${moment(item.DATE_START).format("h:mmA")} - ${moment(item.DATE_END).format(
              "h:mmA"
            )}`;

            return {
              eventType: EVENT_TYPES.UNAVAILABILITY,
              item,
              resourceId,
              title: "Unavailable",
              allDay: item.ALL_DAY,
              start: moment(item.DATE_START).valueOf(),
              end: moment(item.DATE_START).valueOf(),
              color: EVENT_COLORS.UNAVAILABILITY,
              textColor: EVENT_TEXT_COLORS.UNAVAILABILITY,
              borderColor: EVENT_BORDER_COLORS.UNAVAILABILITY,
              timeText,
            };
          }) ?? [];

      let preferred =
        this.props.scheduling.schedule.unavailability
          ?.filter((item) => item.TYPE === 0)
          ?.map((item) => {
            let resourceId;

            switch (shiftView) {
              case SHIFT_VIEWS.GROUP:
                resourceId = item.EMPLOYEE_ROLE_ID;
                break;
              case SHIFT_VIEWS.EMPLOYEES:
                resourceId = item.EMPLOYEE_ID;
                break;
            }

            let timeText = `${moment(item.DATE_START).format("h:mmA")} - ${moment(item.DATE_END).format(
              "h:mmA"
            )}`;

            return {
              eventType: EVENT_TYPES.PREFERRED,
              item,
              resourceId,
              title: "Preferred",
              allDay: item.ALL_DAY,
              start: moment(item.DATE_START).valueOf(),
              end: moment(item.DATE_END).valueOf(),
              color: EVENT_COLORS.PREFERRED,
              textColor: EVENT_TEXT_COLORS.PREFERRED,
              borderColor: EVENT_BORDER_COLORS.PREFERRED,
              isVisible: false,
              timeText,
            };
          }) ?? [];

      let timeOff = [];

      for (let item of this.props.scheduling?.schedule?.timeOff ?? []) {
        let resourceId;

        switch (shiftView) {
          case SHIFT_VIEWS.GROUP:
            resourceId = item.EMPLOYEE_ROLE_ID;
            break;
          case SHIFT_VIEWS.EMPLOYEES:
            resourceId = item.EMPLOYEE_ID;
            break;
        }

        item?.DAYS?.sort();

        const isCutOff =
          item.DATE_START <= moment(this.calendarRef.current.getApi().view.activeStart).valueOf() ||
          item.DATE_END >= moment(this.calendarRef.current.getApi().view.activeEnd).valueOf();

        if (item.DAYS.length * 8 === item.AMOUNT && !isCutOff) {
          timeOff.push({
            eventType: EVENT_TYPES.TIME_OFF_REQUEST,
            item,
            resourceId,
            customEvent: true,
            title: item.POLICY_NAME + " - " + item.STATUS.capitalize(),
            description: item.DAYS.length === 1 ? "All Day" : item.DAYS.length + " Days",
            start: moment(item.DATE_START).toDate(),
            end: moment(item.DATE_END).endOf("day").toDate(),
            color: EVENT_COLORS.UNAVAILABILITY,
            textColor: EVENT_TEXT_COLORS.UNAVAILABILITY,
            borderColor: EVENT_BORDER_COLORS.UNAVAILABILITY,
          });

          continue;
        }

        for (let day of item.DAYS) {
          timeOff.push({
            eventType: EVENT_TYPES.TIME_OFF_REQUEST,
            item,
            resourceId,
            dayId: day.ID,
            customEvent: true,
            title: item.POLICY_NAME + " - " + item.STATUS.capitalize(),
            description: getTimeOffDescription(day),
            start: moment(day.CONTENT, "MM/DD/YY").toDate(),
            end: moment(day.CONTENT, "MM/DD/YY").toDate(),
            color: EVENT_COLORS.UNAVAILABILITY,
            textColor: EVENT_TEXT_COLORS.UNAVAILABILITY,
            borderColor: EVENT_BORDER_COLORS.UNAVAILABILITY,
          });
        }
      }

      if (shiftView === SHIFT_VIEWS.EMPLOYEES) {
        preferred = removeDuplicates(preferred);
        unavailability = removeDuplicates(unavailability);
        timeOff = removeTimeOffDuplicates(timeOff);
        crossLocationShifts = removeDuplicates(crossLocationShifts);
      }

      if (shiftView === SHIFT_VIEWS.ROLES) {
        preferred = [];
        unavailability = [];
        timeOff = [];
      }

      let currentView = this.calendarRef.current.getApi().view.type;
      if (
        currentView === "dayGridMonth" ||
        currentView === "listWeek" ||
        this.props.scheduling.showShiftsOnly
      ) {
        return shifts;
      } else {
        if (!showUnavailability) {
          finalEvents = shifts.concat(crossLocationShifts, timeOff, scheduleEvents, openShifts);
        } else {
          finalEvents = shifts.concat(
            crossLocationShifts,
            unavailability,
            preferred,
            timeOff,
            scheduleEvents,
            openShifts
          );
        }

        return finalEvents;
      }
    }
  };

  renderDayWeather(date) {
    let {weather} = this.props.scheduling;

    if (weather[moment(date).format("MM/DD/YYYY")]) {
      let {MAIN, TEMP} = weather[moment(date).format("MM/DD/YYYY")];

      return (
        <div className="flex flex-row items-center">
          {(MAIN === "Clouds" || MAIN === "Mist" || MAIN === "Haze") && (
            <FontAwesomeIcon icon={"cloud"} className="h-3 w-3" />
          )}
          {MAIN === "Rain" && <FontAwesomeIcon icon={"cloud-showers"} className="h-3 w-3" />}
          {MAIN === "Snow" && <FontAwesomeIcon icon={"snowflakes"} className="h-3 w-3" />}
          {MAIN === "Clear" && <FontAwesomeIcon icon={"sun"} className="h-3 w-3 text-gray-800" />}

          <div className="ml-1 text-xs">{TEMP}°</div>
        </div>
      );
    }
  }

  renderLaborSalesRatioToolTip(laborSalesRatio) {
    return (
      <div>
        <div>Labor to Sales Ratio</div>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between font-normal">
            <div>Labor Cost</div>
            <div>{toDollars(laborSalesRatio?.COST, true)}</div>
          </div>

          <div className="flex flex-row justify-between font-normal">
            <div>Projected Sales</div>
            <div className="ml-3">{toDollars(laborSalesRatio?.PROJECTED_SALES, true)}</div>
          </div>

          <div className="flex flex-row justify-between font-normal border-t border-gray-300">
            <div>Ratio</div>
            <div className="ml-3">
              {((laborSalesRatio?.COST / laborSalesRatio?.PROJECTED_SALES) * 100).toFixed(1)}%
            </div>
          </div>
        </div>
      </div>
    );
  }

  formatSlotLabel = (resourceGroupContext) => {
    const {payViewPermission, showLaborCost} = this.props.scheduling;
    let {locationShiftStats} = this.props.scheduling.schedule;
    let currentDay = moment(resourceGroupContext.date).isoWeekday() - 1;

    return (
      <div className="mt-2 mb-1 flex flex-1 flex-col pl-2 text-left">
        <div className="flex-row justify-between flex content-end mb-1.5">
          <div className="flex text-lg">{resourceGroupContext.text}</div>

          <div className="flex text-gray-600 mr-1">{this.renderDayWeather(resourceGroupContext.date)}</div>
        </div>

        {showLaborCost && (
          <div className="flex-row flex justify-between">
            <div className="flex text-xs font-normal text-gray-600">
              {locationShiftStats.dailyStats[currentDay].hours.toFixed(1)}
              {" Hrs "}
              {payViewPermission ? `- $${toDollars(locationShiftStats.dailyStats[currentDay].cost)}` : ""}
            </div>

            {payViewPermission && (
              <LaborSalesDisplay cost={locationShiftStats.dailyStats[currentDay].cost} day={currentDay} />
            )}
          </div>
        )}
      </div>
    );
  };

  handleCurrentViewChange = async (newView) => {
    if (newView === "resourceTimelineDay") {
      await this.props.setScheduler({shiftView: "EMPLOYEE"});
    }
    this.calendarRef.current.getApi().changeView(newView);

    this.props.updateCurrentViewString(this.calendarRef.current.getApi().currentDataManager.data.viewTitle);

    this.updateView(newView);

    this.handleFetchRouteParams(newView, null);
  };

  handleFetchRouteParams = (currentViewInput = null, shiftViewInput = null) => {
    const {currentView, shiftView} = this.props.scheduling;

    const params = {
      view: currentViewInput ?? currentView,
      shiftView: shiftViewInput ?? shiftView,
      date: moment(this.calendarRef.current.getApi().view.activeStart).valueOf(),
    };

    this.props.router.navigate({
      pathname: "/scheduling",
      search: `?${createSearchParams(params)}`,
    });
  };

  fetchDate = () => {
    return this.calendarRef.current.getApi().getDate();
  };

  handleSetAlphabetical = (order) => {
    this.props.setScheduler({
      sortByAlphabetical: order,
    });
  };

  handleSortAlphabetical = () => {
    const {sortByAlphabetical} = this.props.scheduling;
    let {employees, employeesWithoutRoles} = this.props.scheduling.schedule;
    if (sortByAlphabetical === SORT_BY_ALPHABETICAL.FIRST) {
      employees.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));
      for (let i = 1; i <= employees.length; i++) {
        employees[i - 1].SEQ = i;
      }
      employeesWithoutRoles.sort((a, b) => a.FULL_NAME.localeCompare(b.FULL_NAME));
      for (let i = 1; i <= employeesWithoutRoles.length; i++) {
        employeesWithoutRoles[i - 1].SEQ = i;
      }
    } else if (sortByAlphabetical === SORT_BY_ALPHABETICAL.LAST) {
      employees.sort((a, b) => this.fetchLast(a.FULL_NAME).localeCompare(this.fetchLast(b.FULL_NAME)));
      for (let i = 1; i <= employees.length; i++) {
        employees[i - 1].SEQ = i;
      }
      employeesWithoutRoles.sort((a, b) =>
        this.fetchLast(a.FULL_NAME).localeCompare(this.fetchLast(b.FULL_NAME))
      );
      for (let i = 1; i <= employeesWithoutRoles.length; i++) {
        employeesWithoutRoles[i - 1].SEQ = i;
      }
    }

    return {employees, employeesWithoutRoles};
  };

  handleShowEmployeesWithShiftsOnly = () => {
    let {shifts, employeesWithoutRoles, employees} = this.props.scheduling.schedule;

    const shiftEmployeeIds = shifts ? shifts.map((shift) => shift.EMPLOYEE_ID) : [];
    const shiftEmployeeRoleIds = shifts ? shifts.map((shift) => shift.EMPLOYEE_ROLE_ID) : [];

    employeesWithoutRoles = employeesWithoutRoles.filter((employee) =>
      shiftEmployeeIds.includes(employee.ID)
    );
    employees = employees.filter(
      (employee) =>
        shiftEmployeeIds.includes(employee.ID) && shiftEmployeeRoleIds.includes(employee.EMPLOYEE_ROLE_ID)
    );

    return {employeesWithoutRoles, employees};
  };

  eventDropHasConflict(id, start, end, employeeId, timezone) {
    const {timeOff, unavailability} = this.props.scheduling.schedule;

    const timeOffHasConflict = timeOff.reduce(
      (hasConflict, _time) =>
        (_time.EMPLOYEE_ID === employeeId &&
          _time.STATUS === "APPROVED" &&
          isShiftInTimeOffRange(start, end, _time.DATE_STARt, _time.DATE_END, timezone)) ||
        hasConflict,
      false
    );

    const unavailHasConflict = unavailability.reduce(
      (hasConflict, _unavail) =>
        (_unavail.EMPLOYEE_ID === employeeId &&
          _unavail.TYPE === 1 &&
          isShiftInUnavailabilityRange(start, end, _unavail.DATE_START, _unavail.DATE_END)) ||
        hasConflict,
      false
    );

    return timeOffHasConflict || unavailHasConflict;
  }

  backInTime = () => {
    let {isLoading, currentView, currentDateString} = this.props.scheduling;

    if (isLoading) {
      return;
    }

    let currentMoment = moment(currentDateString);

    switch (currentView) {
      case VIEWS.DAY:
        currentMoment.subtract(1, "day");
        break;
      case VIEWS.MONTH:
        currentMoment.subtract(1, "month");
        break;
      default:
        currentMoment.subtract(1, "week");
        break;
    }

    this.updateCalendarEpoch(currentMoment.valueOf());
  };

  forwardInTime = () => {
    let {currentView, currentDateString} = this.props.scheduling;

    let currentMoment = moment(currentDateString);

    switch (currentView) {
      case VIEWS.DAY:
        currentMoment.add(1, "day");
        break;
      case VIEWS.MONTH:
        currentMoment.add(1, "month");
        break;
      default:
        currentMoment.add(1, "week");
        break;
    }

    this.updateCalendarEpoch(currentMoment.valueOf());
  };

  fetchInitialSchedule = async (view, date) => {
    this.props.updateLoading(true);

    if (view) {
      if (view === "resourceTimelineDay") {
        await this.props.setScheduler({shiftView: "EMPLOYEE"});
      }

      this.calendarRef.current.getApi().changeView(view);
    }

    this.calendarRef.current.getApi().gotoDate(moment(date).startOf("day").add(12, "hours").valueOf());

    this.props.setScheduler({
      currentDateString: moment(date).format("YYYY-MM-DD"),
    });

    this.props.updateCurrentViewString(this.calendarRef.current.getApi().currentDataManager.data.viewTitle);

    this.updateView();

    this.props.updateLoading(false);
  };

  updateCalendarEpoch = (newEpoch) => {
    this.props.updateLoading(true);
    this.calendarRef.current.getApi().gotoDate(moment(newEpoch).startOf("day").add(12, "hours").valueOf());
    this.props.setCurrentViewString(this.calendarRef.current.getApi().currentDataManager.data.viewTitle);

    this.props.setScheduler({
      currentDateString: moment(newEpoch).format("YYYY-MM-DD"),
    });

    this.updateView();
  };

  updateView = (newView) => {
    this.handleFetchSchedule(
      moment(this.calendarRef.current.getApi().view.activeStart).startOf("day").valueOf(),
      moment(this.calendarRef.current.getApi().view.activeEnd).startOf("day").valueOf(),
      newView
    );

    this.handleFetchRouteParams();
  };

  getCalendarWeekStartEpoch = () => {
    const {SCHEDULER_FIRST_DAY} = this.props.shop.settings;
    const {currentDateString} = this.props.scheduling;

    const firstIsoDay = dayToIsoDay(SCHEDULER_FIRST_DAY);

    let currentMoment = moment(currentDateString);
    const weekday = moment(currentDateString).isoWeekday();

    const dayOffset = weekday < firstIsoDay ? weekday - firstIsoDay + 7 : weekday - firstIsoDay;

    return currentMoment.startOf("day").add(-dayOffset, "days").valueOf();
  };

  clearDrafts = async () => {
    let {currentView, currentDateString, rolesToView, employeesToView} = this.props.scheduling;
    this.props.updateLoading(true);
    const {locationIdArr} = this.props;

    let startEpoch, endEpoch;

    switch (currentView) {
      case VIEWS.DAY:
        startEpoch = moment(currentDateString).valueOf();
        endEpoch = moment(startEpoch).endOf("day").valueOf();
        break;
      default:
        startEpoch = this.getCalendarWeekStartEpoch();
        endEpoch = moment(startEpoch).add(1, "week").valueOf();
        break;
    }

    showLoadingConfirmAlert("Clear Drafts", "Clearing drafts will apply to existing filter criteria only")
      .then(async (close) => {
        try {
          const shiftIdsToDelete = await request("scheduling/locations/clear", "POST", {
            START_EPOCH: startEpoch,
            END_EPOCH: endEpoch,
            FILTERS: {
              ...(rolesToView && rolesToView.length > 0 && {roles: rolesToView}),
              ...(employeesToView && employeesToView.length > 0 && {employees: employeesToView}),
            },
            LOCATION_ID_ARR: locationIdArr,
          }).catch((err) => {
            return showErrorAlert(
              "Clear Draft Error",
              "Problem clearing drafts. Please refresh the scheduler and try again.",
              "Ok"
            );
          });

          if (this.isToggledLocation() && shiftIdsToDelete?.length > 0) {
            this.props.deleteShifts(shiftIdsToDelete);
          }
        } catch (err) {
          console.log(err);
          return showErrorNotification(
            "Error Clearing Shifts",
            "Encountered an error trying to clear draft shifts, please refresh the scheduler and try again."
          );
        }

        close();
      })
      .catch(() => {});

    this.props.updateLoading(false);
  };

  clearOpenShiftDrafts = () => {
    const {schedule, currentView} = this.props.scheduling;
    const {shifts} = schedule;

    let currentMoment = moment(this.calendarRef.current.getApi().currentDataManager.data.currentDate);

    let start;
    let end;

    switch (currentView) {
      case VIEWS.DAY:
        // Delete Open Shift Drafts for that day
        start = currentMoment.startOf("day").valueOf();
        end = currentMoment.endOf("day").valueOf();
        break;
      case VIEWS.MONTH:
        start = currentMoment.startOf("month").valueOf();
        end = currentMoment.endOf("month").valueOf();
        break;
      default:
        start = this.getCalendarWeekStartEpoch;
        end = moment(start).add(7, "days").valueOf();
        break;
    }

    const draftShiftsToDelete = shifts
      .filter((item) => {
        return item.DRAFT;
      })
      .filter((item) => {
        return item.DATE_START.valueOf() > start && item.DATE_START.valueOf() < end;
      });

    this.props.setScheduler({isLoading: true});

    const promiseArr = [];

    for (const shift of draftShiftsToDelete) {
      const {ID: id} = shift;
      try {
        const a = request("scheduling/v2/shift/" + id, "DELETE", null);
        promiseArr.push(a);
      } catch (e) {
        console.log(e, "Could not delete.");
      }
    }

    Promise.all(promiseArr)
      .then(() => this.handleFetchingCurrentCalendarDatesSchedule())
      .catch((err) => {
        console.log(err, "Error");
        this.handleFetchingCurrentCalendarDatesSchedule();
      });
  };

  fetchLast(str) {
    const lastIndex = str.lastIndexOf(" ");
    if (lastIndex === -1) {
      return str;
    }
    return str.slice(lastIndex + 1);
  }

  fetchFilteredRoles(res) {
    const {rolesToView} = this.props.scheduling;
    let returnRoles = [];

    returnRoles = res.reduce((prev, curr) => {
      if (prev.findIndex((item) => curr.item.ROLE_ID === item.value) === -1) {
        return [
          ...prev,
          {
            value: curr.item.ROLE_ID,
            name: curr.item.ROLE_NAME,
            ROLE_SEQ: curr.item.ROLE_SEQ,
          },
        ];
      }
      return prev;
    }, []);

    returnRoles.sort((a, b) => a.ROLE_SEQ - b.ROLE_SEQ);

    if (rolesToView && rolesToView.length > 0) {
      res = res.filter((item) => rolesToView.indexOf(item.item.ROLE_ID) !== -1);
    }

    return {res, returnRoles};
  }

  fetchFilteredEmployees(res) {
    const {employeesToView} = this.props.scheduling;
    let returnEmployees = [];

    returnEmployees = res.reduce((prev, curr) => {
      if (prev.findIndex((item) => curr.item.ACCOUNT_ID === item.value) === -1) {
        return [
          ...prev,
          {
            value: curr.item.ACCOUNT_ID,
            name: curr.item.FULL_NAME,
            SEQ: curr.item.SEQ,
          },
        ];
      }
      return prev;
    }, []);

    returnEmployees.sort((a, b) => a.SEQ - b.SEQ);

    if (employeesToView && employeesToView.length > 0) {
      res = res.filter((item) => employeesToView.indexOf(item.item.ACCOUNT_ID) !== -1);
    }

    return {res, returnEmployees};
  }

  fetchResources() {
    let {employeesWithoutRoles, shifts, employees, shiftStatsByShift} = this.props.scheduling.schedule;
    const {shiftView, currentView, sortBy, showEmployeesWithShifts, sortByAlphabetical} =
      this.props.scheduling;
    let isDayView = currentView === VIEWS.DAY;
    let resourcesArr, resourceGroupField, resourceOrder;

    if (sortByAlphabetical !== 0) {
      const sortedEmployees = this.handleSortAlphabetical();
      employees = sortedEmployees?.employees;
      employeesWithoutRoles = sortedEmployees?.employeesWithoutRoles;
    }

    if (showEmployeesWithShifts) {
      let filtered = this.handleShowEmployeesWithShiftsOnly();
      employees = filtered.employees;
      employeesWithoutRoles = filtered.employeesWithoutRoles;
    }

    if (isDayView && sortBy === SORT_BY.SHIFT) {
      resourcesArr = shiftStatsByShift;
      resourceGroupField = "";
      resourceOrder = "start,seq";
    } else if (shiftView === SHIFT_VIEWS.EMPLOYEES) {
      resourcesArr = employeesWithoutRoles;
      resourceGroupField = "";
      resourceOrder = "seq";
    } else if (shiftView === SHIFT_VIEWS.GROUP) {
      resourcesArr = employees;
      resourceGroupField = "role";
      resourceOrder = "role,seq";
    } else {
      resourcesArr = employees.reduce((prev, curr) => {
        const idx = prev.findIndex((item) => curr.ROLE_ID === item.ROLE_ID);
        if (idx === -1) {
          return [
            ...prev,
            {
              ROLE_ID: curr.ROLE_ID,
              ROLE_NAME: curr.ROLE_NAME,
              FULL_NAME: curr.ROLE_NAME,
              ROLE_SEQ: curr.ROLE_SEQ,
              shift_stats: {...curr.shift_stats},
            },
          ];
        } else {
          prev[idx].shift_stats.hours += curr.shift_stats.hours;
          prev[idx].shift_stats.cost += curr.shift_stats.cost;
          return [...prev];
        }
      }, []);
      resourceGroupField = "";
      resourceOrder = "role,seq";
    }

    return {
      resourcesArr,
      resourceGroupField,
      resourceOrder,
    };
  }

  render() {
    let {isLoading, shiftView, waitingForLoad, payViewPermission, sortBy, loadingStats, showLaborCost} =
      this.props.scheduling;
    let {employees, unavailability, timeOff} = this.props.scheduling.schedule;
    const {SCHEDULER_FIRST_DAY} = this.props.shop.settings;
    const firstDay = parseInt(SCHEDULER_FIRST_DAY);

    let isGroupView = shiftView === SHIFT_VIEWS.GROUP;
    let isRoleView = shiftView === SHIFT_VIEWS.ROLES;
    let isEmployeeView = shiftView === SHIFT_VIEWS.EMPLOYEES;
    let {resourcesArr, resourceGroupField, resourceOrder} = this.fetchResources();

    let resources = [];
    let currentRoles = [];
    let currentEmployees = [];

    // There's a bug in FullCalendar where you cannot set the resource order to something other than the group field,
    // so in order to hack together a solution we prepend x "a" characters to the role name where x is the seq of
    // that role. We then strip that when displaying
    resources = resourcesArr.map((item) => {
      let roleName = "_" + item.ROLE_NAME;
      for (let i = 0; i < item.ROLE_SEQ + 1; i++) {
        roleName = "a" + roleName;
      }

      return {
        item,
        id: isGroupView
          ? item.EMPLOYEE_ROLE_ID
          : shiftView === SHIFT_VIEWS.EMPLOYEES || sortBy === SORT_BY.SHIFT
          ? item.ID
          : item.ROLE_ID,
        title: item.FULL_NAME,
        role: roleName,
        seq: item.SEQ ? item.SEQ : 10000,
        ...(item.START && {start: item.START}),
      };
    });

    resources.sort((a, b) => a.SEQ - b.SEQ);

    if (isGroupView || isRoleView) {
      const {res, returnRoles} = this.fetchFilteredRoles(resources);
      currentRoles = returnRoles;
      resources = res;
    } else if (isEmployeeView) {
      const {res, returnEmployees} = this.fetchFilteredEmployees(resources);
      currentEmployees = returnEmployees;
      resources = res;
    }

    const {TIMEZONE: timezone} = this.props.shop.settings;

    if (resources.length > 0) {
      resources.unshift({
        title: "__Events",
        role: "__Events",
        id: RESOURCE_IDS.EVENT,
        item: {
          EMPLOYEE_ROLE_ID: RESOURCE_IDS.EVENT,
          FULL_NAME: "Events",
          ROLE_NAME: "Events",
        },
        seq: -2,
        start: -2,
      });

      resources.unshift({
        title: "__Open Shifts",
        role: "__Open Shifts",
        id: RESOURCE_IDS.OPEN_SHIFT,
        item: {
          EMPLOYEE_ROLE_ID: null,
          FULL_NAME: "Open Shifts",
          ROLE_NAME: "Open Shifts",
        },
        seq: -1,
        start: -1,
      });
    }

    return (
      <>
        <StatsContainer />

        <EventModal ref={(e) => (this.eventModal = e)} />
        <EmployeeModal
          ref={(e) => {
            this.employeeModal = e;
          }}
          navigateToEmployee={(emp) => {
            this.props.router.navigate(emp);
          }}
        />
        <ShiftModal
          ref={(e) => (this.shiftModal = e)}
          startDay={dayToIsoDay(this.props.shop.settings.SCHEDULER_FIRST_DAY)}
          refresh={this.handleFetchingCurrentCalendarDatesSchedule}
          isToggledLocation={this.isToggledLocation()}
          deleteShift={this.props.deleteShift}
          {...this.props}
        />
        <OpenShiftModal
          ref={(e) => (this.openShiftModal = e)}
          locations={this.props.locations}
          startDay={dayToIsoDay(this.props.shop.settings.SCHEDULER_FIRST_DAY)}
          isToggledLocation={this.isToggledLocation()}
          locationIdArr={this.props.locationIdArr}
          deleteShift={this.props.deleteShift}
          upsertShifts={this.props.upsertShifts}
        />
        <CopyWeekScheduleModal
          ref={(e) => (this.scheduleCopyModal = e)}
          locationIdArr={this.props.locationIdArr}
          afterUpdate={this.updateCalendarEpoch}
        />
        <UnavailabilityModal ref={(e) => (this.unavailabilityModal = e)} />
        <PreferredModal ref={(e) => (this.preferredModal = e)} />
        <TimeOffModal
          ref={(e) => (this.timeoffModal = e)}
          blessRequest={async (requestId, isAccepted, reviewerNotes) => {
            const blessPayload = {
              NOTES: reviewerNotes,
            };

            if (isAccepted) {
              await request("timeoff/requests/" + requestId + "/approve", "POST", blessPayload);
            } else {
              await request("timeoff/requests/" + requestId + "/deny", "POST", blessPayload);
            }

            await this.handleFetchingCurrentCalendarDatesSchedule();
          }}
        />
        <div>
          <div className={classNames(isLoading ? "hidden" : "")}>
            <FullCalendar
              rerenderDelay={1000}
              timeZone={timezone}
              viewClassNames={isLoading ? "fc_hide_view" : ""}
              ref={this.calendarRef}
              plugins={[
                interactionPlugin,
                dayGridPlugin,
                ResourceTimelinePlugin,
                timeGridPlugin,
                listPlugin,
                momentTimezonePlugin,
                adaptivePlugin,
              ]}
              initialDate={
                this.props.initialDate ? moment(this.props.initialDate).format("YYYY-MM-DD") : null
              }
              initialView={"customWeek"}
              editable={!waitingForLoad}
              eventResizableFromStart={false}
              eventResize={(eventResizeInfo) => {
                eventResizeInfo.revert();
              }}
              eventAllow={(dropInfo, draggedEvent) => {
                // Determine which events can and cannot be dragged
                const {eventType} = draggedEvent.extendedProps;
                const {resource} = dropInfo;
                const {item: resourceItem} = resource.extendedProps;

                // Can never drag Preferred or unavailability slots
                if (eventType === EVENT_TYPES.PREFERRED || eventType === EVENT_TYPES.UNAVAILABILITY) {
                  return false;
                }

                // Can't drag events to shifts
                if (eventType === EVENT_TYPES.EVENT && resourceItem.EMPLOYEE_ROLE_ID !== RESOURCE_IDS.EVENT) {
                  return false;
                }

                // Can't Drag non-events to events
                if (resourceItem.EMPLOYEE_ROLE_ID === RESOURCE_IDS.EVENT && eventType !== EVENT_TYPES.EVENT) {
                  return false;
                }

                // Can't Drag cross location shifts
                return eventType !== EVENT_TYPES.CROSS_LOCATION_SHIFT;
              }}
              nextDayThreshold={"9:00:00"}
              eventDrop={async (eventDropInfo) => {
                const {event, oldEvent, oldResource, newResource, revert} = eventDropInfo;

                let hasConflict = false;

                //dragging to open shift does not need check conflict
                if (parseInt(newResource?.id) !== RESOURCE_IDS.OPEN_SHIFT) {
                  const employeeId =
                    newResource?.extendedProps?.item?.ID ?? event.extendedProps.item.EMPLOYEE_ID;

                  hasConflict = await this.eventDropHasConflict(
                    event.extendedProps.item.ID,
                    event.start,
                    event.end,
                    employeeId,
                    timezone
                  );
                }

                if (hasConflict) {
                  await showLoadingConfirmAlert(
                    "Scheduling Conflict",
                    "The updated shift conflicts with one or more employee unavailabilities. Are you sure you want to proceed?"
                  )
                    .then(async (close) => {
                      close();
                    })
                    .catch((err) => {
                      revert();
                      throw new Error("User terminated gesture.");
                    });
                }

                if (oldResource === newResource) {
                  // If the resource is the same before and after the drop then it should be a time change
                  const {start, end, extendedProps} = event;
                  const {item, eventType} = extendedProps;

                  item.DATE_START = moment(start).valueOf();
                  item.DATE_END = end ? moment(end).valueOf() : null;

                  event.setExtendedProp("item", item);

                  // Send request to database to change the event
                  switch (eventType) {
                    case EVENT_TYPES.EVENT:
                      // Send request to events endpoint
                      const {ID: eventEventId} = item;

                      this.props.setScheduler({
                        waitingForLoadEvent: {
                          id: eventEventId,
                          type: eventType,
                        },
                        waitingForLoad: true,
                      });

                      try {
                        await request("scheduling/event/" + eventEventId, "PATCH", {
                          DATE_START: item.DATE_START,
                          DATE_END: item.DATE_END,
                        });
                      } catch (err) {
                        console.log("ERROR!!!!", err);
                        revert();
                      }

                      break;

                    case EVENT_TYPES.OPEN_SHIFT:
                    case EVENT_TYPES.SHIFT:
                      const {ID: shiftId} = item;

                      this.props.setScheduler({
                        waitingForLoadEvent: {
                          id: event.extendedProps.item.ID,
                          type: event.extendedProps.eventType,
                        },
                        waitingForLoad: true,
                      });

                      try {
                        await request("scheduling/v2/shift/" + shiftId, "PATCH", {
                          DATE_START: item.DATE_START,
                          DATE_END: item.DATE_END,
                          DRAFT: 1,
                          ACKNOWLEDGED: 0,
                        });
                      } catch (err) {
                        console.log("ERROR!!!!", err);
                        revert();
                      }

                      this.clearWaitingForLoad();

                      break;
                    default:
                      revert();
                      break;
                  }
                  return;
                }

                // Next step is to deal with dragging to and from Open shifts
                // Moving an open shift
                if (parseInt(oldResource.id) === RESOURCE_IDS.OPEN_SHIFT) {
                  // If we're in schedule role view then don't allow dragging of open shifts.
                  if (shiftView === SHIFT_VIEWS.SCHEDULE_ROLE) {
                    revert();

                    this.openShiftModal.openForEdit(event.extendedProps.item);

                    return;
                  }

                  // Check if new person can take on that role
                  const eventRoleId = event.extendedProps.item.ROLE_ID;
                  const personRoleId = newResource.extendedProps.item.ROLE_ID;

                  // ID's match
                  if (eventRoleId === personRoleId) {
                    const {item} = event.extendedProps;

                    item.DATE_START = moment(event.start).valueOf();
                    item.DATE_END = moment(event.end).valueOf();

                    item.FULL_NAME = newResource.extendedProps.item.FULL_NAME;
                    item.ROLE_ID = newResource.extendedProps.item.ROLE_ID;
                    item.EMPLOYEE_ID = newResource.extendedProps.item.ID;
                    item.EMPLOYEE_ROLE_ID = newResource.extendedProps.item.EMPLOYEE_ROLE_ID;
                    item.DRAFT = 1;

                    const shiftPayload = {
                      EMPLOYEE_ID: newResource.extendedProps.item.ID,
                      EMPLOYEE_ROLE_ID: newResource.extendedProps.item.EMPLOYEE_ROLE_ID,
                      ROLE_ID: personRoleId,
                      DATE_START: item.DATE_START,
                      DATE_END: item.DATE_END,
                      DRAFT: 1,
                      ACKNOWLEDGED: 0,
                    };

                    event.setExtendedProp("item", item);
                    event.setExtendedProp("eventType", EVENT_TYPES.SHIFT);

                    event.setProp("title", newResource.extendedProps.item.FULL_NAME);

                    this.props.setScheduler({
                      waitingForLoadEvent: {
                        id: event.extendedProps.item.ID,
                        type: event.extendedProps.eventType,
                      },
                      waitingForLoad: true,
                    });

                    try {
                      await request(
                        `scheduling/v2/shift/${event.extendedProps.item.ID}`,
                        "PATCH",
                        shiftPayload
                      );
                    } catch (err) {
                      console.log("HOUSTON (pronounced HOW-STAHN) we have a problem!", err);
                      revert();
                    }

                    this.clearWaitingForLoad();

                    return;
                  }

                  if (this.props.scheduling.shiftView === SHIFT_VIEWS.EMPLOYEES) {
                    const {ID: employeeId} = newResource.extendedProps.item;

                    const roles = this.props.scheduling.schedule.employees
                      .filter((i) => i.ID === employeeId)
                      .map((i) => {
                        return {
                          ROLE_ID: i.ROLE_ID,
                          EMPLOYEE_ROLE_ID: i.EMPLOYEE_ROLE_ID,
                        };
                      });

                    const idx = roles.findIndex((item) => item.ROLE_ID === eventRoleId);

                    if (idx !== -1) {
                      const {item} = event.extendedProps;

                      item.DATE_START = moment(event.start).valueOf();
                      item.DATE_END = moment(event.end).valueOf();

                      item.FULL_NAME = newResource.extendedProps.item.FULL_NAME;
                      item.EMPLOYEE_ID = newResource.extendedProps.item.ID;
                      item.EMPLOYEE_ROLE_ID = roles[idx].EMPLOYEE_ROLE_ID;
                      item.DRAFT = 1;

                      const shiftPayload = {
                        EMPLOYEE_ID: item.EMPLOYEE_ID,
                        EMPLOYEE_ROLE_ID: item.EMPLOYEE_ROLE_ID,
                        ROLE_ID: roles[idx].ROLE_ID,
                        DATE_START: item.DATE_START,
                        DATE_END: item.DATE_END,
                        DRAFT: 1,
                        ACKNOWLEDGED: 0,
                      };
                      //
                      event.setExtendedProp("item", item);
                      event.setProp("title", newResource.extendedProps.item.FULL_NAME);
                      event.setExtendedProp("eventType", EVENT_TYPES.SHIFT);

                      this.props.setScheduler({
                        waitingForLoadEvent: {
                          id: event.extendedProps.item.ID,
                          type: event.extendedProps.eventType,
                        },
                        waitingForLoad: true,
                      });

                      try {
                        await request(
                          `scheduling/v2/shift/${event.extendedProps.item.ID}`,
                          "PATCH",
                          shiftPayload
                        );
                      } catch (err) {
                        console.log("Uh oh!", err);
                        revert();
                      }

                      this.clearWaitingForLoad();

                      return;
                    }
                  }
                }

                // Moving to an open shift
                if (parseInt(newResource.id) === RESOURCE_IDS.OPEN_SHIFT) {
                  const {item} = event.extendedProps;

                  item.DATE_START = moment(event.start).valueOf();
                  item.DATE_END = moment(event.end).valueOf();

                  item.FULL_NAME = newResource.extendedProps.item.FULL_NAME;
                  item.EMPLOYEE_ID = null;
                  item.EMPLOYEE_ROLE_ID = null;
                  item.DRAFT = 1;

                  const shiftPayload = {
                    EMPLOYEE_ID: null,
                    EMPLOYEE_ROLE_ID: null,
                    ROLE_ID: event.extendedProps.item.ROLE_ID,
                    DATE_START: item.DATE_START,
                    DATE_END: item.DATE_END,
                    DRAFT: 1,
                  };

                  event.setExtendedProp("item", item);
                  event.setExtendedProp("eventType", EVENT_TYPES.OPEN_SHIFT);

                  event.setProp("title", `${oldResource.extendedProps.item.ROLE_NAME}`);
                  event.setProp("color", EVENT_COLORS.OPEN_SHIFT_DRAFT);
                  event.setProp("textColor", EVENT_TEXT_COLORS.OPEN_SHIFT_DRAFT);
                  event.setProp("borderColor", EVENT_BORDER_COLORS.OPEN_SHIFT_DRAFT);

                  this.props.setScheduler({
                    waitingForLoadEvent: {
                      id: event.extendedProps.item.ID,
                      type: event.extendedProps.eventType,
                    },
                    waitingForLoad: true,
                  });

                  request(`scheduling/v2/shift/${event.extendedProps.item.ID}`, "PATCH", shiftPayload)
                    .then(() => {})
                    .catch((err) => {
                      console.log("Uh oh!", err);
                      revert();
                    });

                  this.clearWaitingForLoad();

                  return;
                }

                // Drag a shift to some other resource who has the same role
                if (event.extendedProps.eventType === EVENT_TYPES.SHIFT) {
                  const {ROLE_ID: oldRole} = oldResource.extendedProps.item;
                  const {ROLE_ID: newRole} = newResource.extendedProps.item;

                  // roles are undefined in employee view
                  if (
                    this.props.scheduling.shiftView === SHIFT_VIEWS.GROUP &&
                    newRole &&
                    oldRole &&
                    oldRole === newRole
                  ) {
                    const {item} = event.extendedProps;

                    item.DATE_START = moment(event.start).valueOf();
                    item.DATE_END = moment(event.end).valueOf();

                    item.FULL_NAME = newResource.extendedProps.item.FULL_NAME;
                    item.EMPLOYEE_ID = newResource.extendedProps.item.ID;
                    item.EMPLOYEE_ROLE_ID = newResource.extendedProps.item.EMPLOYEE_ROLE_ID;
                    item.DRAFT = 1;

                    const shiftPayload = {
                      EMPLOYEE_ID: item.EMPLOYEE_ID,
                      EMPLOYEE_ROLE_ID: newResource.extendedProps.item.EMPLOYEE_ROLE_ID,
                      ROLE_ID: newRole,
                      DATE_START: item.DATE_START,
                      DATE_END: item.DATE_END,
                      DRAFT: 1,
                      ACKNOWLEDGED: 0,
                    };
                    //
                    event.setExtendedProp("item", item);
                    event.setProp("title", newResource.extendedProps.item.FULL_NAME);

                    this.props.setScheduler({
                      waitingForLoadEvent: {
                        id: event.extendedProps.item.ID,
                        type: event.extendedProps.eventType,
                      },
                      waitingForLoad: true,
                    });

                    try {
                      await request(
                        `scheduling/v2/shift/${event.extendedProps.item.ID}`,
                        "PATCH",
                        shiftPayload
                      );
                    } catch (err) {
                      console.log("HOUSTON (pronounced HOW-STAHN) we have a problem!", err);
                      revert();
                    }

                    this.clearWaitingForLoad();

                    return;
                  }

                  // do employee view check
                  if (this.props.scheduling.shiftView === SHIFT_VIEWS.EMPLOYEES) {
                    const {ID: employeeId} = newResource.extendedProps.item;
                    const {ROLE_ID: eventRole} = event.extendedProps.item;

                    const roles = this.props.scheduling.schedule.employees
                      .filter((i) => i.ID === employeeId)
                      .map((i) => {
                        return {
                          ROLE_ID: i.ROLE_ID,
                          EMPLOYEE_ROLE_ID: i.EMPLOYEE_ROLE_ID,
                        };
                      });

                    const idx = roles.findIndex((item) => item.ROLE_ID === eventRole);

                    // If this person can take on this role
                    if (idx !== -1) {
                      const {item} = event.extendedProps;

                      item.DATE_START = moment(event.start).valueOf();
                      item.DATE_END = moment(event.end).valueOf();

                      item.FULL_NAME = newResource.extendedProps.item.FULL_NAME;
                      item.EMPLOYEE_ID = newResource.extendedProps.item.ID;
                      item.EMPLOYEE_ROLE_ID = roles[idx].EMPLOYEE_ROLE_ID;
                      item.DRAFT = 1;

                      const shiftPayload = {
                        EMPLOYEE_ID: item.EMPLOYEE_ID,
                        EMPLOYEE_ROLE_ID: item.EMPLOYEE_ROLE_ID,
                        ROLE_ID: roles[idx].ROLE_ID,
                        DATE_START: item.DATE_START,
                        DATE_END: item.DATE_END,
                        DRAFT: 1,
                        ACKNOWLEDGED: 0,
                      };
                      //
                      event.setExtendedProp("item", item);
                      event.setProp("title", newResource.extendedProps.item.FULL_NAME);

                      try {
                        await request(
                          `scheduling/v2/shift/${event.extendedProps.item.ID}`,
                          "PATCH",
                          shiftPayload
                        );
                      } catch (err) {
                        console.log("Uh oh!", err);
                        revert();
                      }

                      this.clearWaitingForLoad();

                      return;
                    }
                  }
                }

                const eventRoleId = event.extendedProps.item.ROLE_ID;

                const foundRole = this.props.shop.roles.findIndex(
                  (_shopRole) => _shopRole.ID === eventRoleId
                );

                if (foundRole === -1) {
                  showErrorNotification(
                    "Error Assigning Shift",
                    "Attempting to assign a shift with an archived role. Please re-select the shift role, save the shift, and then try again."
                  );
                } else {
                  showErrorNotification(
                    "Error Assigning Shift",
                    "Please ensure that the target's role matches the role designated for this shift."
                  );
                }

                eventDropInfo.revert();
              }}
              selectable={true}
              select={(vals) => {
                const id = parseInt(vals.resource.id);

                const start = moment(vals.start);
                const end = moment(vals.end);
                const type = vals.view?.type;
                const resource = vals?.resource;
                if (id === RESOURCE_IDS.EVENT) {
                  this.handleEventEventCreation({
                    start,
                    end,
                    type,
                  });
                } else if (id === RESOURCE_IDS.OPEN_SHIFT) {
                  this.handleOpenShiftCreation({start, end});
                } else {
                  this.handleEventCreation({
                    start,
                    end,
                    resource,
                  });
                }
              }}
              nowIndicator={false}
              eventClick={this.handleEventClick}
              schedulerLicenseKey="0186321653-fcs-1742479464"
              resourceAreaHeaderContent=" "
              headerToolbar={false}
              eventTimeFormat={{
                hour: "numeric",
                minute: "2-digit",
                meridiem: true,
              }}
              selectConstraint={{
                startTime: "00:00",
                endTime: "24:00",
              }}
              views={{
                resourceTimelineDay: {
                  titleFormat: {
                    month: "short",
                    day: "numeric",
                    weekday: "short",
                  },
                },
                resourceTimelineWeek: {
                  slotDuration: {hours: 24},
                  slotLabelContent: this.formatSlotLabel,
                  slotLabelFormat: [{weekday: "short", day: "numeric"}],
                },
                customWeek: {
                  titleFormat: {
                    month: "short",
                    day: "numeric",
                  },
                  type: "resourceTimelineWeek",
                  duration: {weeks: 1},
                  slotDuration: {days: 1},
                  buttonText: "week",
                  eventContent: ({event, timeText}) => {
                    const subtitle =
                      event.title +
                      (event.extendedProps.description ? ` - ${event.extendedProps.description}` : "");

                    // If the event has a custom timeText, override the timeText that will be displayed
                    if (event.extendedProps.timeText) {
                      timeText = event.extendedProps.timeText;
                    }

                    if (event.extendedProps.eventType === EVENT_TYPES.PREFERRED) {
                      return <PreferredEvent event={event} timeString={timeText} allDay={event.allDay} />;
                    }

                    const {settings} = this.props.shop;
                    let duration = 0;

                    if (settings.SCHEDULER_DURATION_DISPLAY === "1") {
                      duration = moment(event.extendedProps.item.DATE_END).diff(
                        moment(event.extendedProps.item.DATE_START),
                        "hours"
                      );
                    }

                    return (
                      <SchedulerEvent
                        customEvent={event.extendedProps.customEvent}
                        description={event.extendedProps.description}
                        timeString={timeText}
                        subtitle={subtitle}
                        title={event.title}
                        allDay={event.allDay}
                        acknowledged={event.extendedProps.item.ACKNOWLEDGED}
                        confirmSetting={settings.SCHEDULER_SHIFT_CONFIRMATION}
                        duration={duration}
                        location={this.props.isToggledLocation && event.extendedProps.item.LOCATION_NAME}
                        shiftLabel={event.extendedProps.label}
                      />
                    );
                  },
                },
                dayGridMonth: {
                  dateClick: ({dateStr}) => {
                    this.props.setSchedulingStats({
                      currentView: "resourceTimelineDay",
                    });
                    this.handleCurrentViewChange("resourceTimelineDay");
                    this.calendarRef.current.getApi().gotoDate(dateStr);
                  },
                  selectable: false,
                },
              }}
              firstDay={SCHEDULER_FIRST_DAY ? firstDay : 1}
              resources={resources}
              resourceGroupField={resourceGroupField}
              resourceOrder={resourceOrder}
              resourceGroupLabelContent={(resourceGroupContext) => {
                const arr = resourceGroupContext.groupValue.split("_");

                if (arr.length === 0) {
                  return <b className="text-sm">Deleted Role</b>;
                }

                return <b className="text-sm">{arr[arr.length - 1]}</b>;
              }}
              events={this.formatEvents()}
              eventBorderColor="black"
              dayMaxEvents={5}
              eventMouseEnter={(mouseEnterInfo) => {
                const {event} = mouseEnterInfo;
                const {eventType} = event.extendedProps;

                if (eventType === EVENT_TYPES.PREFERRED) {
                  event.setExtendedProp("isVisible", true);
                }
              }}
              eventMouseLeave={(mouseLeaveInfo) => {
                const {event} = mouseLeaveInfo;
                const {eventType} = event.extendedProps;

                if (eventType === EVENT_TYPES.PREFERRED) {
                  event.setExtendedProp("isVisible", false);
                }
              }}
              resourceLabelContent={(resourceContext) => {
                const {role} = resourceContext.resource.extendedProps;

                if (role === "__Events") {
                  return isGroupView ? <div /> : <EventSchedulerResource />;
                }

                if (role === "__Open Shifts") {
                  return isGroupView ? <div /> : <OpenShiftSchedulerResource />;
                }

                return (
                  <SchedulerResource
                    isLoading={loadingStats}
                    shiftView={this.props.scheduling.shiftView}
                    employeeId={resourceContext.resource.extendedProps.item.ID}
                    title={resourceContext.fieldValue}
                    activeEmployee={
                      resourceContext.resource.extendedProps.item.ACTIVE === 1 ||
                      shiftView === SHIFT_VIEWS.ROLES
                    }
                    externalEmployee={
                      this.props.shop.location.ID !==
                        resourceContext.resource.extendedProps.item.PRIMARY_LOCATION_ID &&
                      shiftView !== SHIFT_VIEWS.ROLES
                    }
                    multipleRoleEmployee={
                      employees.filter((x) => resourceContext.resource.extendedProps.item.ID === x.ID)
                        .length > 1
                    }
                    hours={resourceContext.resource.extendedProps.item.shift_stats.hours}
                    cost={resourceContext.resource.extendedProps.item.shift_stats.cost}
                    preferredTotal={resourceContext.resource.extendedProps.item.REQUESTED_HOURS}
                    history={this.props.history}
                    employeeModal={this.employeeModal}
                    unavailability={unavailability}
                    timeOff={timeOff}
                    payViewPermission={payViewPermission}
                    showLaborCost={showLaborCost}
                  />
                );
              }}
              resourceAreaWidth={"15%"}
              height={"auto"}
              customButtons={{
                toggleShiftsOnly: {
                  text: this.props.scheduling.showShiftsOnly ? "Show All" : "Shifts Only",
                  click: this.handleToggleShiftOnly,
                },
              }}
              stickyFooterScrollbar={false}
              buttonText={{
                list: "agenda",
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

Scheduler.propTypes = {
  history: PropTypes.any.isRequired,
  initialDate: PropTypes.instanceOf(Date),
  timezone: PropTypes.string.isRequired,
};

export default setupReduxConnection(["scheduling", "management", "shop", "user", "toolbar"])(
  withRouter(Scheduler)
);

const removeDuplicates = function (arr) {
  let resArr = [];

  arr.forEach(function (item, index) {
    let i = arr.findIndex((x) => x.item.ID === item.item.ID);
    if (i === index) {
      resArr.push(item);
    }
  });

  return resArr;
};

const removeTimeOffDuplicates = function (arr) {
  let resArr = [];

  arr.forEach(function (item, index) {
    let i = arr.findIndex((x) => x.item.ID === item.item.ID && (!item.dayId || x.dayId === item.dayId));

    if (i === index) {
      resArr.push(item);
    }
  });

  return resArr;
};

function LightenDarkenColor(col, amt) {
  let usePound = false;
  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  let num = parseInt(col, 16);

  let r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const EVENT_TYPES = {
  SHIFT: "SHIFT",
  CROSS_LOCATION_SHIFT: "CROSS_LOCATION_SHIFT",
  TIME_OFF_REQUEST: "TIME_OFF_REQUEST",
  UNAVAILABILITY: "UNAVAILABILITY",
  PREFERRED: "PREFERRED",
  EVENT: "EVENT",
  OPEN_SHIFT: "OPEN_SHIFT",
};

const EVENT_COLORS = {
  SHIFT_DRAFT: "#d0d1fb",
  SHIFT: "rgb(99 102 241)",
  OPEN_SHIFT: "rgb(175,86,86)",
  OPEN_SHIFT_DRAFT: "rgb(187,115,115)",
  CROSS_SHIFT: "#919191",
  UNAVAILABILITY: "#f4f4f5",
  PREFERRED: "#f4f4f5",
  EVENT: "#e0e7ff",
  TIME_OFF: "#f78f8f",
};

const EVENT_TEXT_COLORS = {
  SHIFT_DRAFT: "#000000",
  SHIFT: "#FFFFFF",
  OPEN_SHIFT: "#FFFFFF",
  OPEN_SHIFT_DRAFT: "#000000",
  EVENT: "#4e46e5",
  UNAVAILABILITY: "#71717a",
  PREFERRED: "#71717a",
};

const EVENT_BORDER_COLORS = {
  SHIFT_DRAFT: "#7366FF",
  SHIFT: "#7366FF",
  OPEN_SHIFT: "rgb(175,86,86)",
  OPEN_SHIFT_DRAFT: "rgb(175,86,86)",
  CROSS_SHIFT: "#919191",
  UNAVAILABILITY: "#f4f4f5",
  PREFERRED: "#f4f4f5",
  EVENT: "#e0e7ff",
  TIME_OFF: "#f78f8f",
};

export const VIEWS = {
  DAY: "resourceTimelineDay",
  WEEK: "customWeek",
  MONTH: "dayGridMonth",
  AGENDA: "listWeek",
};

export const VIEWS_DATA = [
  {
    label: "Day",
    value: VIEWS.DAY,
  },
  {label: "Week", value: VIEWS.WEEK},
  {label: "Month", value: VIEWS.MONTH},
  {label: "Agenda", value: VIEWS.AGENDA},
];

export const SHIFT_VIEWS = {
  EMPLOYEES: "EMPLOYEE",
  GROUP: "ROLES",
  ROLES: "SCHEDULE_ROLE",
};

export const SHIFT_VIEWS_DATA = [
  {label: "Group View", value: SHIFT_VIEWS.GROUP},
  {
    label: "Role View",
    value: SHIFT_VIEWS.ROLES,
  },
  {label: "Employee View", value: SHIFT_VIEWS.EMPLOYEES},
];

export const SORT_BY = {
  EMPLOYEES: "EMPLOYEE",
  SHIFT: "SHIFT",
};

export const SORT_BY_DATA = [
  {label: "By Employee", value: SORT_BY.EMPLOYEES},
  {
    label: "By Shift",
    value: SORT_BY.SHIFT,
  },
];

export const RESOURCE_IDS = {
  EVENT: -1,
  OPEN_SHIFT: -2,
};

const MOBILE_BREAKPOINT = 640;

export const SORT_BY_ALPHABETICAL = {
  FIRST: 1,
  LAST: 2,
};
