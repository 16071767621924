import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {useEffect, useState} from "react";
import {SearchIcon} from "@heroicons/react/solid";

export const Box = ({children, className = "", onClick}) => {
  return (
    <div className={`flex ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const Row = ({children, className = "", onClick}) => {
  return (
    <div className={`flex flex-row ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

export const Col = ({children, className = ""}) => {
  return <div className={`flex flex-col ${className}`}>{children}</div>;
};

const HeaderText = ({text}) => (
  <div className="flex-1 pl-4 text-left text-gray-500 py-2 text-sm font-medium">{text}</div>
);

/**
 *
 * @param columns - list of objects, requires the label key
 * @returns {JSX.Element}
 * @constructor
 */
const Header = ({columns}) => (
  <Row className="w-full justify-between border-b bg-gray-50 border-gray-200">
    {columns.map(({label, headerClassName = ""}) => (
      <HeaderText text={label} className={headerClassName} />
    ))}
  </Row>
);

const LineText = ({text, isLast = false, children, key, onChange}) => (
  <div
    key={key}
    className={`flex items-center justify-between flex-1 pl-4 text-left py-2 text-sm ${
      !isLast ? "border-r border-gray-300" : ""
    }`}
  >
    {onChange ? <input type="text" value={text} onChange={onChange} /> : <span>{text}</span>}
    {children && <div className="flex gap-2">{children}</div>}
  </div>
);

/**
 *
 * @param columns - list of objects with fields key, format, and label
 * @param line - the line of data to use
 * @param buttons - list of buttons to show at end of each row
 * @param onClick - action to take when row is clicked
 * @param className - additional styles
 * @returns {JSX.Element}
 * @constructor
 */
const Line = ({columns, line, className = "", onClick, buttons = []}) => (
  <Row className={"w-full border-b " + className} onClick={onClick}>
    {columns.map(({key, format = (it) => it, formatRow, onChange}, idx) => (
      <LineText
        key={key}
        text={formatRow ? formatRow(line) : format(line[key])}
        isLast={idx === columns.length - 1}
        onChange={onChange ? (value) => onChange(line, key, value) : undefined}
      >
        {idx === columns.length - 1 &&
          buttons?.map((button, buttonIdx) => (
            <button
              key={buttonIdx}
              onClick={() => button.onClick(line)}
              className="px-4 text-indigo-600 hover:text-indigo-900 text-sm font-medium"
            >
              {button.label}
            </button>
          ))}
      </LineText>
    ))}
  </Row>
);

export const ReadOnlyTable = ({columns, data, onRowClick, className = "", buttons = []}) => {
  return (
    <Box className={"bg-white " + className}>
      <Col className="w-full border border-gray-200 rounded">
        <Header columns={columns} />
        {data.map((dataLine) => (
          <Line
            columns={columns}
            line={dataLine}
            onClick={() => (onRowClick ? onRowClick(dataLine) : null)}
            className={onRowClick && "cursor-pointer"}
            buttons={buttons}
          />
        ))}
      </Col>
    </Box>
  );
};
