import {Button, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import LoadingSpinner from "../../../../../components/loading-spinner";
import {showErrorNotification} from "../../../../../utils/notification-helper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PdfViewer} from "../../../../../components/pdf/pdf-viewer";
import {Row} from "../../../../../v2/components/shared";
import {BackButton} from "../BackButton";
import {useEmployeeDocument} from "../../../../../hooks/team/onboarding/onboarding-hub-hooks";
import {parseFetchedFields} from "../documents/documents-helper";
import {useFormik} from "formik";
import * as Yup from "yup";
import {ONBOARDING_FIELD_TYPES as types} from "../../../../../utils/constants";
import {saveDrawableFieldsToPdf} from "../../../../hub/onboarding/federal/fillable-pdf-helpers";
import {useReviewDocument} from "../../../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {DOCUMENT_ERROR} from "../error-messages";

export const DocumentReviewPage = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const document = useMemo(() => location.state?.document, [location.state]);

  const {isLoading, error, data} = useEmployeeDocument(document.DOCUMENT_ID, document.DOCUMENT_ASSIGNMENT_ID);

  const [reviewerFields, setReviewerFields] = useState([]);

  useEffect(() => {
    if (data?.fields?.FIELDS) {
      setReviewerFields(data.fields.FIELDS.filter((field) => field.IS_REVIEWER === 1));
    }
  }, [data]);

  const [fetchedFields, setFetchedFields] = useState({});

  const [validationSchema, setValidationSchema] = useState(null);

  const getFieldsInitialValues = () => {
    return reviewerFields.reduce((acc, field) => {
      acc[field.ID] = field.FIELD_TYPE === types.CHECKBOX ? false : "";
      return acc;
    }, {});
  };

  const getFieldsValidation = () => {
    return reviewerFields.reduce((acc, field) => {
      acc[field.ID] =
        field.FIELD_TYPE === types.CHECKBOX ? Yup.boolean().optional() : Yup.string().required();

      return acc;
    }, {});
  };

  useEffect(() => {
    if (!data) return;

    const initialValues = getFieldsInitialValues();

    const schema = getFieldsValidation();

    setFetchedFields(initialValues);

    setValidationSchema(Yup.object().shape(schema));
  }, [reviewerFields, data]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: fetchedFields,
    validationSchema: validationSchema || Yup.object().shape({}),
    onSubmit: (values) => handleSubmit(values),
    validateOnChange: false,
  });

  const {mutate: reviewDocument, isLoading: reviewLoading} = useReviewDocument();

  const handleRejection = () => {
    reviewDocument({
      id: document.DOCUMENT_ASSIGNMENT_ID,
      payload: {
        IS_APPROVED: false,
      },
    });

    navigateToReviewTab();
  };

  const handleSubmit = async (values) => {
    try {
      const pdfBytes = await saveDrawableFieldsToPdf(data?.loadedPdf, reviewerFields, values, true);

      reviewDocument({
        id: document.DOCUMENT_ASSIGNMENT_ID,
        payload: {
          FILE: pdfBytes,
          IS_APPROVED: true,
        },
      });
    } catch (error) {
      showErrorNotification(DOCUMENT_ERROR, "Document couldn't be reviewed. Please try again.");
    } finally {
      navigateToReviewTab();
    }
  };

  const displayErrorMessage = () => {
    const isError = Object.keys(formik.touched).some((touchedKey) =>
      Object.keys(formik.errors).includes(touchedKey)
    );

    return (
      <>
        {isError && (
          <div className="text-sm text-red-500 mt-1">
            * The fields highlighted in red are required and must be filled in to submit document
          </div>
        )}
      </>
    );
  };

  const displayInstructions = () => {
    return (
      <div
        className={
          "flex flex-col flex-grow px-4 py-5 sm:px-6 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-15 rounded-md"
        }
      >
        <p className={"block text-sm font-semibold text-gray-700"}>Reviewer Instructions</p>

        <p className={"mt-4 block text-sm font-medium text-gray-700"}>
          1. Complete the reviewer fields, or click "Reject" to send a new blank document back to the employee
          for them to fill out.
        </p>

        <p className={"mt-4 block text-sm font-medium text-gray-700"}>
          2. After all fields are completed, click "Approve" to mark the document as finalized.
        </p>
      </div>
    );
  };

  const navigateToReviewTab = () => {
    navigate("/onboarding/dashboard?tab=Pending+Review");
  };

  const handlePdfDisplay = () => {
    if (isLoading) {
      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      showErrorNotification(
        "Document Error",
        "An error occurred while trying to load this document. Please refresh to try again."
      );

      return (
        <div className={"flex min-h-[50vh] items-center justify-center"}>
          <FontAwesomeIcon icon="far fa-circle-exclamation" className={"mr-2 text-red-600"} />
          <span className={"text-red-600"}>Unable to load document</span>
        </div>
      );
    }

    if (data) {
      const formattedFields = reviewerFields.map((fetchedField) => parseFetchedFields(fetchedField)) ?? [];

      return (
        <form onSubmit={formik.handleSubmit} className={"flex items-stretch justify-between space-x-4"}>
          <div className={"w-3/4"}>
            <PdfViewer
              url={data.signedUrl}
              type={data.fields?.EDIT_TYPE}
              fields={formattedFields}
              userView={true}
              formik={formik}
            />
          </div>

          <div className={"flex flex-col w-1/4 gap-y-2"}>
            {displayInstructions()}

            <Row className={"mt-2 flex flex-row w-full space-x-1 "}>
              <BackButton
                className={"flex-1 justify-center"}
                color={"red"}
                label={"Reject"}
                goBack={() => handleRejection()}
                disabled={formik.isSubmitting || reviewLoading}
              />

              <Button
                className={"flex-1 justify-center"}
                type={"submit"}
                label={"Approve"}
                disabled={formik.isSubmitting || reviewLoading}
              />
            </Row>

            <BackButton goBack={navigateToReviewTab} />

            {displayErrorMessage()}
          </div>
        </form>
      );
    }
  };

  return (
    <Card
      label={`${document.DOCUMENT_NAME ?? "Document"} Review`}
      noHeaderBorder={true}
      className={" py-6 px-4"}
    >
      {handlePdfDisplay()}
    </Card>
  );
};
