import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Loading, PageHeadings, Table, Filter} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import moment from "moment-timezone";

const LOG_TYPES = {
  0: "Inventory Rule",
  1: "Bulk Edit",
  2: "Kitchen Update",
  3: "Product Expiry",
  4: "Product Sold",
};

class InventoriesLogPage extends Component {
  state = {
    isLoading: true,
    products: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let products = await request("inventory/products/tracked", "GET", {});

    this.setState({isLoading: false, products});
  }

  render() {
    const {isLoading, products} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Inventory Log"
          description="Track inventory changes over time with a log of each inventory update"
        />

        <Filter
          className="mt-4"
          defaultFilters={[]}
          data={[
            {
              id: "type",
              label: "Log Type",
              options: [
                {
                  id: 0,
                  label: "Inventory Rule",
                  value: 0,
                },
                {
                  id: 1,
                  label: "Bulk Edit",
                  value: 1,
                },
                {
                  id: 2,
                  label: "Kitchen Update",
                  value: 2,
                },
                {
                  id: 3,
                  label: "Product Expired",
                  value: 3,
                },
                {
                  id: 4,
                  label: "Product Sold",
                  value: 4,
                },
              ],
              onFilter: (options, data) => {
                if (options.length === 0) {
                  return data;
                }

                console.log("DOGS", data, options);

                return data.filter((item) => options.includes(item.TYPE));
              },
            },
          ]}
        >
          {(filters, search) => {
            return (
              <Table
                pagination
                search={search}
                filters={filters}
                route="inventory/log"
                actionButtons={[
                  {
                    label: "View Inventory",
                    onClick: (item) => this.props.router.navigate("/inventory/" + item.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "PRODUCT_NAME",
                    label: "Product",
                  },
                  {
                    value: "TYPE",
                    label: "Type",
                    format: (val) => LOG_TYPES[val],
                  },
                  {
                    value: "DATE_CREATED",
                    label: "Date Created",
                    format: (val) => moment(val).format("M/D/YY h:mma"),
                  },
                  {
                    value: "DATE_EXPIRED",
                    label: "Date Expires",
                    format: (val) => (val ? moment(val).format("M/D/YY h:mma") : "No expiration"),
                  },
                  {
                    width: 1,
                    value: "STOCK",
                    label: "Stock",
                    format: (val, row) => val + " (" + row.QUANTITY + ")",
                  },
                ]}
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(InventoriesLogPage));
