import {Document, Page, pdfjs} from "react-pdf";
import React, {useEffect, useState} from "react";
import {DraggableField} from "./draggable-fields";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EDIT_TYPES} from "../../pages/team/onboarding/functional/documents/documents-helper";
import {FillableFields} from "./fillable-fields";
import {showErrorNotification} from "../../utils/notification-helper";
import {TransformComponent, TransformWrapper, useControls} from "react-zoom-pan-pinch";

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts/`,
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const PdfViewer = ({
  url,
  fields,
  removeField = () => {},
  addField = () => {},
  type,
  setCurrentPage = () => {},
  userView = false,
  formik = null,
}) => {
  const [numPages, setNumPages] = useState(0);

  const [currentHeight, setCurrentHeight] = useState(0);

  const [page, setPage] = useState(1);

  const [currentPageFields, setCurrentPageFields] = useState([]);

  const [disablePan, setDisablePan] = useState(false);

  useEffect(() => {
    setCurrentPage(page);
  }, [page]);

  useEffect(() => {
    if (type === EDIT_TYPES.FIELDS) {
      const filteredFields = fields.filter((field) => field.PAGE === page);

      setCurrentPageFields(filteredFields);
    } else {
      setCurrentPageFields([]);
    }
  }, [fields, page, type]);

  const displayPageCount = () => {
    return `${page} / ${numPages}`;
  };

  const incrementPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const decrementPage = () => {
    setPage((prevPage) => prevPage - 1);
  };

  const onDocumentLoadSuccess = ({numPages: pdfNumPages}) => {
    setNumPages(pdfNumPages);

    setCurrentHeight(window.innerHeight);
  };

  const onDocumentLoadError = (error) => {
    showErrorNotification(
      "Document Error",
      "An error occurred while loading the document. Please refresh to try again."
    );
  };

  const navigatePageButton = (disabled, onClick, icon) => {
    return (
      <button
        type={"button"}
        onClick={onClick}
        disabled={disabled}
        className={classNames(
          "border px-3 py-2 rounded-md",
          disabled
            ? "bg-gray-500 text-gray-100 border-gray-500"
            : "bg-indigo-500 text-white border-indigo-500"
        )}
      >
        <FontAwesomeIcon icon={icon} />
      </button>
    );
  };

  const displayFields = () => {
    return (
      <>
        {currentPageFields?.map((field, index) => (
          <DraggableField
            page={page}
            field={field}
            removeField={removeField}
            addField={addField}
            setDisablePan={setDisablePan}
          />
        ))}
      </>
    );
  };

  const displayUserFields = () => {
    return (
      <>
        {currentPageFields?.map((field, index) => (
          <FillableFields formik={formik} field={field} setDisablePan={setDisablePan} />
        ))}
      </>
    );
  };

  return (
    <>
      <div style={{minHeight: `${currentHeight}px`}}>
        <TransformWrapper
          initialScale={1}
          initialPositionX={200}
          initialPositionY={100}
          panning={{disabled: disablePan, excluded: ["input"]}}
        >
          {({zoomIn, zoomOut, resetTransform, ...rest}) => (
            <>
              <Controls />

              <TransformComponent>
                <Document
                  options={options}
                  file={url}
                  onLoadError={onDocumentLoadError}
                  onLoadSuccess={onDocumentLoadSuccess}
                  className={"border border-gray-300 flex items-center justify-center"}
                >
                  <div style={{position: "relative"}}>
                    <Page key={`page_${page}`} pageNumber={page} />

                    {userView ? displayUserFields() : displayFields()}
                  </div>
                </Document>
              </TransformComponent>

              <div className={"flex flex-row justify-center items-center mt-4 space-x-6"}>
                {navigatePageButton(numPages === 0 || page === 1, decrementPage, "fa-solid fa-left")}

                <span>{displayPageCount()}</span>

                {navigatePageButton(numPages === 0 || numPages === page, incrementPage, "fa-solid fa-right")}
              </div>
            </>
          )}
        </TransformWrapper>
      </div>
    </>
  );
};

const Controls = () => {
  const {zoomIn, zoomOut, resetTransform} = useControls();

  const handleZoomIn = () => {
    zoomIn(0.25);
  };

  const handleZoomOut = () => {
    zoomOut(0.25);
  };

  const handleReset = () => {
    resetTransform();
  };

  return (
    <div className="tools mb-2 space-x-2">
      <ControlButton onClick={handleZoomIn} label={"Zoom In"} icon={"fal fa-search-plus"} />

      <ControlButton onClick={handleZoomOut} label={"Zoom Out"} icon={"fal fa-search-minus"} />

      <ControlButton onClick={handleReset} label={"Reset"} icon={"fal fa-sync"} />
    </div>
  );
};

const ControlButton = ({onClick, label, icon}) => {
  return (
    <button
      type={"button"}
      onClick={onClick}
      className={
        "inline-flex bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-25 rounded-md hover:bg-indigo-500 hover:bg-opacity-20 cursor-pointer text-xs font-light items-center justify-center p-2"
      }
    >
      {label}

      <FontAwesomeIcon className={"ml-1"} icon={icon} />
    </button>
  );
};
