import {Card, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {ReadOnlyTable} from "../../../../../../v2/components/shared";
import {COLUMNS, SUBTABLE_COLUMNS} from "../dashboard-helper";
import {useState} from "react";
import {useIncompletePackets} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {ConfirmationModal} from "../../../../../../modals/general/confirmation-modal";
import {
  useSendEmail,
  useSignedUrl,
  useUnassignPacket,
} from "../../../../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {getFilterData} from "../../table-helpers";
import {useEmployeeDocumentSignedUrl} from "../../../../../../hooks/team/onboarding/onboarding-hub-hooks";

export const PendingEmployeeTab = () => {
  const {isLoading, error, data} = useIncompletePackets();

  const {openDocument} = useSignedUrl();

  const {openDocument: openCompletedDocument} = useEmployeeDocumentSignedUrl();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [confirmationModalLabel, setConfirmationModalLabel] = useState("");

  const [selectedPacket, setSelectedPacket] = useState(null);

  const {mutate: unassignPacket, isLoading: mutationLoading} = useUnassignPacket();

  const {mutate: sendEmail, isLoading: emailMutationLoading} = useSendEmail();

  const handleUnassignPacket = () => {
    if (selectedPacket) {
      unassignPacket(selectedPacket.ID);
    }
  };

  const handleResendEmail = () => {
    if (selectedPacket) {
      sendEmail({
        EMPLOYEE_EMAIL: selectedPacket.EMPLOYEE_EMAIL,
        EMPLOYEE_NAME: selectedPacket.EMPLOYEE_NAME,
        PACKET_ASSIGNMENT_ID: selectedPacket.ID,
      });
    }
  };

  const getConfirmationMessage = () => {
    if (confirmationModalLabel === "Unassign") {
      return undefined;
    }

    if (selectedPacket?.EMPLOYEE_EMAIL) {
      return `Are you sure you want to resend the link to ${selectedPacket?.EMPLOYEE_EMAIL}?`;
    }

    return undefined;
  };

  const handleViewDocument = (document) => {
    if (!document.ASSIGNMENT_URL) {
      return openDocument(document.ID);
    }

    openCompletedDocument(document.ASSIGNMENT_URL);
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleModalClose={() => {
          setIsConfirmationModalOpen(false);
          setSelectedPacket(null);
        }}
        modalLabel={`${confirmationModalLabel} Confirmation`}
        tooltip={
          confirmationModalLabel === "Unassign"
            ? "Unassigning this packet will detach it from the specified employee and unassign all associated company documents included in the packet"
            : "The assigned employee will receive a reminder email with the link to the existing packet"
        }
        confirmationAction={confirmationModalLabel === "Unassign" ? handleUnassignPacket : handleResendEmail}
        confirmationMessage={getConfirmationMessage()}
        isLoading={mutationLoading || emailMutationLoading}
      />

      <Card
        label={"Packets Awaiting Employee Action"}
        description={
          "The following packets have been assigned, but not yet completed by the onboarding employee."
        }
      >
        <Filter defaultFilters={[]} data={getFilterData(data)}>
          {(filters, _) => (
            <Table
              sortable
              columns={COLUMNS}
              data={getTableData(isLoading, error, data)}
              pagination={true}
              filters={filters}
              actionButtons={[
                {
                  label: "Resend Link",
                  onClick: (row) => {
                    setSelectedPacket(row);
                    setConfirmationModalLabel("Resend Link");
                    setIsConfirmationModalOpen(true);
                  },
                },
                {
                  label: "Unassign",
                  onClick: (row) => {
                    setSelectedPacket(row);
                    setConfirmationModalLabel("Unassign");
                    setIsConfirmationModalOpen(true);
                  },
                },
              ]}
              expandable={(packet) => (
                <>
                  <ReadOnlyTable
                    className={"m-2"}
                    columns={SUBTABLE_COLUMNS}
                    data={packet.DOCUMENTS}
                    buttons={[
                      {
                        label: "View",
                        onClick: (document) => handleViewDocument(document),
                      },
                    ]}
                  />
                </>
              )}
            />
          )}
        </Filter>
      </Card>
    </>
  );
};
