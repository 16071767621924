import {OnboardingHeadingLabel} from "../onboarding-heading-label";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";

function OnboardingPacketsHeading({handleCreatePacket}) {
  return (
    <PageHeadings
      label={<OnboardingHeadingLabel label={"Company Packets"} />}
      description={
        "Create new packets, manage existing ones, and stay organized by viewing, editing, or deleting packet contents"
      }
      buttons={[
        {
          label: "Create Packet",
          onClick: handleCreatePacket,
        },
      ]}
    />
  );
}

export default OnboardingPacketsHeading;
