import * as Yup from "yup";
import {I9_VALIDATION_TYPES} from "./i9-fill-pdf-helper";

// Clearing Fields
export const clearDocumentInfo = (setFieldValue, type) => {
  setFieldValue("documentType", type);
  setFieldValue("issuingAuth", "");
  setFieldValue("documentNumber", "");
  setFieldValue("expirationDate", "");
  setFieldValue("documentTitle", "");
};

export const clearAdditionalInfo = (setFieldValue, type) => {
  setFieldValue("additionalDocTitle", "");
  setFieldValue("additionalAuth", "");
  setFieldValue("additionalDocNum", "");
  setFieldValue("additionalExDate", "");
  setFieldValue("additionalDocType", type);
};

// Validation Helpers
const stringValidation = (label) =>
  Yup.string()
    .required(`${label} is required`)
    .trim()
    .min(1, `Minimum length is 1`)
    .label(label)
    .typeError(`${label} is required`);

const optionalStringValidation = (label) => Yup.string().optional();

// I-9 Part 1 Initial Values
export const employeeInfoInitial = {
  firstName: null,
  middleInitial: null,
  lastName: null,
  otherLastNames: null,
  address: null,
  aptNumber: null,
  cityOrTown: null,
  state: null,
  zip: null,
};

export const sensitiveInfoInitial = {
  dateOfBirth: null,
  ssn: null,
  email: null,
  phone: null,
};

export const attestationInitial = {
  validationType: null,
  documentType: null,
  documentNumber: null,
  issuingCountry: null,
  expirationDate: null,
  uscisANumber: null,
  listA: null,
  listB: null,
  listC: null,
};

// I-9 Part 2 Initial Values
export const verificationInitial = {
  documentTitle: "",
  documentType: "",
  issuingAuth: "",
  documentNumber: "",
  expirationDate: "",
  signature: "",
  employerLastName: "",
  employerFirstName: "",
  employerTitle: "",
  businessName: "",
  businessAddress: "",
  businessCity: "",
  businessState: "",
  businessPostalCode: "",
};

export const additionalInitial = {
  additionalDocTitle: "",
  additionalDocType: "",
  additionalAuth: "",
  additionalDocNum: "",
  additionalExDate: "",
};

// Part 1 Validation Schemas
export const employeeInfoSchema = Yup.object().shape({
  firstName: Yup.string().trim().required().label("First Name").typeError("Please enter your first name"),
  lastName: Yup.string().trim().required().label("Last Name").typeError("Please enter your last name"),
  middleInitial: Yup.string().trim().nullable().optional(),
  otherLastName: Yup.string().trim().nullable().optional(),
  address: Yup.string().trim().required().label("Address").typeError("Please enter your address"),
  aptNumber: Yup.string().trim().nullable().optional(),
  cityOrTown: Yup.string().trim().required().label("City").typeError("Please enter your city"),
  state: Yup.string().trim().required().label("State").typeError("Please enter your state"),
  zip: Yup.string()
    .trim()
    .required()
    .label("Zip")
    .matches(/^\d{5}(-\d{4})?$/, "Invalid ZIP code format")
    .typeError("Please enter your zip address"),
});

export const sensitiveEmployeeInfoSchema = Yup.object().shape({
  dateOfBirth: Yup.string()
    .trim()
    .required()
    .label("Date of Birth")
    .matches(/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/, "Date must be in MM/DD/YYYY format")
    .test("isValidDate", "Invalid date", (value) => {
      if (!value) return false; // Required
      const [month, day, year] = value.split("/").map(Number);
      const date = new Date(year, month - 1, day);
      return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    })
    .test("isPastDate", "Date cannot be in the future", (value) => {
      if (!value) return false;
      return new Date(value) < new Date();
    })
    .typeError("Please enter your date of birth"),
  ssn: Yup.string()
    .trim()
    .required()
    .length(11, "SSN must be 9 digits")
    .label("SSN")
    .typeError("Please enter your social security number"),
  email: Yup.string()
    .trim()
    .required()
    .email("Please enter a valid email")
    .label("Email")
    .typeError("Please enter your email"),
  phone: Yup.string()
    .trim()
    .required()
    .length(12, "Phone number must be 10 digits")
    .label("Phone")
    .typeError("Please enter your phone"),
});

export const attestationSchema = Yup.object().shape({
  validationType: Yup.string()
    .required()
    .label("Citizenship status")
    .typeError("Please select your citizenship status"),
  documentType: Yup.string().when("validationType", {
    is: I9_VALIDATION_TYPES.NONCITIZEN,
    then: (schema) =>
      schema
        .required("Document type is required")
        .label("Document type")
        .typeError("Please select your document type"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  documentNumber: Yup.string().when("validationType", {
    is: I9_VALIDATION_TYPES.NONCITIZEN,
    then: (schema) =>
      schema
        .required("Document number is required")
        .label("Document number")
        .typeError("Please enter your document number"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  expirationDate: Yup.string().optional().nullable(),
  uscisANumber: Yup.string().when("validationType", {
    is: I9_VALIDATION_TYPES.PERMANENT_RESIDENT,
    then: (schema) =>
      schema
        .required("USCIS A-Number is required")
        .label("USCIS A-Number date")
        .typeError("Please enter your USCIS A-Number"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  listA: Yup.string()
    .nullable()
    .test("listA-condition", "Select an option from List A", function (value) {
      const {listB, listC} = this.parent;
      return !(listB === null && listC === null && !value);
    }),

  listB: Yup.string()
    .nullable()
    .test("listB-condition", "Select an option from List B", function (value) {
      const {listA} = this.parent;
      return !(listA === null && !value);
    }),

  listC: Yup.string()
    .nullable()
    .test("listC-condition", "Select an option from List C", function (value) {
      const {listA} = this.parent;
      return !(listA === null && !value);
    }),
});

// Part 2 Validation Schema
export const verificationSchema = {
  documentTitle: stringValidation("Document title"),
  documentType: optionalStringValidation("Document type"),
  issuingAuth: stringValidation("Issuing authority"),
  documentNumber: optionalStringValidation("Document number"),
  expirationDate: optionalStringValidation("Expiration date"),
  signature: stringValidation("Signature"),
  employerLastName: stringValidation("Employer last name"),
  employerFirstName: stringValidation("Employer first name"),
  employerTitle: stringValidation("Employer title"),
  businessName: stringValidation("Business name"),
  businessAddress: stringValidation("Business address"),
  businessCity: stringValidation("Business city"),
  businessState: stringValidation("Business state"),
  businessPostalCode: stringValidation("Business postal code"),
  employeeStartDate: stringValidation("Employee start date"),
};

export const additionalSchema = {
  additionalDocTitle: stringValidation("Document title"),
  additionalDocType: optionalStringValidation("Document type"),
  additionalAuth: stringValidation("Issuing authority"),
  additionalDocNum: optionalStringValidation("Document number"),
  additionalExDate: optionalStringValidation("Expiration date"),
};
