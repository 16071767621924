import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Card,
  Filter,
  Loading,
  PageHeadings,
  Tab,
  Table,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";
import ChecklistModal from "../../../modals/operations/checklists/checklist-modal";
import ChecklistTaskModal from "../../../modals/operations/checklists/checklist-task-modal";
import {updateStateDelegator} from "../../../utils/util";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {showSuccessNotification} from "../../../utils/notification-helper";
import ChecklistRuleModal from "../../../modals/operations/checklists/checklist-rule-modal";

export const PIN_ENTRY_TYPES = {
  BY_ITEM: "BY_ITEM",
  BY_LIST: "BY_LIST",
};

export const PIN_ENTRY_TYPE_TRANSLATIONS = {
  [PIN_ENTRY_TYPES.BY_ITEM]: "Require pin when completing each item",
  [PIN_ENTRY_TYPES.BY_LIST]: "Require pin when closing the checklist",
};

function fetchChecklistType(item) {
  if (item.ISO_WEEK !== null) {
    return "Every " + moment().startOf("isoWeek").add(item.ISO_WEEK, "days").format("dddd");
  }

  if (item.ISO_BI_WEEK !== null) {
    return "Every other " + moment().startOf("isoWeek").add(item.ISO_BI_WEEK, "days").format("dddd");
  }

  if (item.ISO_MONTH !== null) {
    return "Every month on the " + moment().startOf("month").add(item.ISO_MONTH, "days").format("Do");
  }

  if (item.ISO_DATE !== null) {
    return "Once on " + moment(item.ISO_DATE).format("ddd MMM Do");
  }

  return "Daily";
}

class ChecklistPage extends Component {
  state = {
    checklist: null,
    tasks: null,
    rules: null,
    instances: null,
    dependencies: null,
    roles: null,
    locations: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    Promise.all([
      request("checklists/" + id, "GET", null),
      request("checklists/" + id + "/items", "GET"),
      request("checklists/" + id + "/rules", "GET"),
      request("checklists/" + id + "/instances", "GET"),
      request("checklists/" + id + "/dependencies", "GET"),
      request("checklists/" + id + "/roles", "GET"),
    ]).then(([checklist, tasks, rules, instances, dependencies, roles]) => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);
      instances.sort((a, b) => b.DATE_START_BY - a.DATE_START_BY);
      this.setState({tasks, rules, instances, checklist, dependencies, roles});
    });
  }

  createInstance() {
    let {checklist} = this.state;

    showLoadingConfirmAlert(
      "Send Copy to POS",
      "Do you want to send another copy of this checklist instance to the POS?"
    ).then(async (close) => {
      await request("checklists/" + checklist.ID + "/instances", "POST", {});

      close();

      showSuccessNotification("Checklist Created", "You can view it on the point of sale");
    });
  }

  async archiveChecklist() {
    let {checklist} = this.state;

    showLoadingConfirmAlert("Delete Checklist", "Are you sure you want to delete this checklist?").then(
      async (close) => {
        await request("checklists/" + checklist.ID, "DELETE", {});

        this.props.router.navigate("/checklists");

        close();

        showSuccessNotification("Checklist Deleted", "We have deleted the checklist");
      }
    );
  }

  saveSequence() {
    const {tasks, checklist} = this.state;

    request("checklists/" + checklist.ID + "/item", "PATCH", {
      ITEMS: tasks.map((item) => ({
        SEQ: item.SEQ,
        ID: item.ID,
      })),
    }).then(() => {
      tasks.sort((a, b) => a.SEQ - b.SEQ);
    });
  }

  render() {
    const {checklist, tasks, rules, instances, dependencies, roles} = this.state;
    const {locations} = this.props.shop;

    if (checklist === null) {
      return <Loading />;
    }

    tasks?.sort((a, b) => a.SEQ - b.SEQ);

    return (
      <div>
        <PageHeadings
          label={checklist.NAME}
          buttons={[
            {
              label: "Send to POS",
              onClick: () => this.createInstance(),
            },
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Checklist",
                      onClick: () => this.archiveChecklist(),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Operations", url: "/"},
            {label: "Checklists Page", url: "/checklists"},
          ]}
        />

        <ChecklistModal
          updateState={(checklist, roles) => this.setState({checklist, roles})}
          ref={(e) => (this.checklistModal = e)}
        />

        <ChecklistTaskModal
          checklist={checklist}
          ref={(e) => (this.taskModal = e)}
          addState={(task) => this.setState({tasks: [...tasks, task]})}
          updateState={(id, line) => {
            this.setState({
              tasks: updateStateDelegator(tasks, id, line),
            });
          }}
        />

        <ChecklistRuleModal
          checklist={checklist}
          ref={(e) => (this.ruleModal = e)}
          addState={(rule) => this.setState({rules: [...rules, rule]})}
          updateState={(id, rule) => {
            this.setState({
              rules: updateStateDelegator(rules, id, rule),
            });
          }}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () => {
                this.checklistModal.open(checklist);
              },
            },
          ]}
          label="Checklist Information"
          description="Modify the details of this checklist"
          data={[
            {label: "Name", value: checklist.NAME},
            {label: "Active", value: checklist.ENABLED ? "Yes" : "No"},
            {
              type: "bool",
              label: "Require Employee Pin",
              value: checklist.REQUIRE_PIN,
            },
            {
              label: "Pin Entry",
              value: PIN_ENTRY_TYPE_TRANSLATIONS[checklist.PIN_TYPE],
            },
            {
              label: "Target Roles",
              value: roles.length > 0 ? roles.map((item) => item.NAME).join(", ") : "None",
              tooltip: "Select which employee roles get notified about this checklist when clocking in.",
            },
            {
              type: "bool",
              label: "Validate Role on Pin Entry",
              tooltip: "Only employees with the target roles will be able to complete this checklist.",
              value: checklist.VALIDATE_ROLE,
            },
            {label: "Duration (minutes)", value: checklist.DURATION},
            {
              label: "Locations",
              value: checklist.LOCATIONS.map((it) => {
                return it.LOCATION_NAME;
              }).join(", "),
            },
          ]}
        />

        <Tab
          className="mt-2"
          data={[
            {label: "Tasks", id: "tasks"},
            {label: "Rules", id: "rules"},
            {label: "Instances", id: "instances"},
          ]}
        >
          {(id) => {
            if (id === "instances") {
              return (
                <Card
                  label="Checklist Instances"
                  description="View the individual instances of this checklist that have been created"
                >
                  <Filter
                    data={[
                      {
                        id: "LOCATION_ID",
                        label: "Location",
                        onFilter: (options, data) => {
                          return data.filter((item) => {
                            return options.includes(item.LOCATION_ID);
                          });
                        },
                        options: this.props.shop.locations.map((item) => {
                          return {id: item.ID, value: item.ID, label: item.NAME};
                        }),
                      },
                    ]}
                  >
                    {(filters) => {
                      return (
                        <Table
                          pagination
                          filters={filters}
                          rowsPerPage
                          data={instances}
                          columns={[
                            {
                              value: "LOCATION_ID",
                              label: "Location",
                              format: (value, row) => {
                                const {NAME} = locations.find(
                                  (location) => location.ID === row.LOCATION_ID
                                ) || {
                                  NAME: "No Location",
                                };
                                return `${NAME}`;
                              },
                            },
                            {
                              value: "DATE_START_BY",
                              label: "Start By",
                              format: (value) => moment(value).format("h:mma ddd MMM Do YYYY"),
                            },
                            {
                              value: "DATE_START",
                              label: "Date Started",
                              format: (value) =>
                                value ? moment(value).format("h:mma ddd MMM Do YYYY") : "Not started",
                            },
                            {
                              value: "DATE_END_BY",
                              label: "Complete By",
                              format: (value) =>
                                value ? moment(value).format("h:mma ddd MMM Do YYYY") : "No target end",
                            },
                            {
                              value: "DATE_END",
                              label: "Date Completed",
                              format: (value) =>
                                value ? moment(value).format("h:mma ddd MMM Do YYYY") : "Not completed",
                            },
                            {
                              value: "DATE_START_BY",
                              label: "Checklist Day",
                              format: (value) => moment(value).format("ddd MMM Do YYYY"),
                            },
                          ]}
                        />
                      );
                    }}
                  </Filter>
                </Card>
              );
            }

            if (id === "rules") {
              return (
                <Card
                  label="Checklist Rules"
                  description="Edit the frequency at which this checklist is created."
                  tooltip={{
                    data: "At least one checklist rule must be present for the checklist to be created.",
                  }}
                  button={{
                    label: "Add Rule",
                    onClick: () => this.ruleModal.open(null),
                  }}
                >
                  <Table
                    data={rules}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.ruleModal.open(row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                        format: (_, item) => moment(item.RESET_TIME, "HH:mm").format("hh:mm a"),
                      },
                      {
                        value: "TYPE",
                        label: "Type",
                        format: (_, row) => fetchChecklistType(row),
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "tasks") {
              const seq = tasks ? Math.max(...tasks.map((item) => item.SEQ), 0) + 1 : 1;

              return (
                <Card
                  label="Checklist Tasks"
                  description="Edit individual task items for this checklist"
                  button={{
                    label: "Add Task",
                    onClick: () => this.taskModal.open(null, seq),
                  }}
                >
                  <Table
                    draggable
                    data={tasks}
                    onDrag={() => this.saveSequence()}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.taskModal.open(row, seq),
                      },
                    ]}
                    columns={[
                      {
                        width: 1,
                        value: "NAME",
                        label: "Name",
                      },

                      {
                        value: "OPTIONS",
                        label: "Options",
                        format: (options, _) => {
                          if (!options) {
                            return "None";
                          }

                          return options.map((item) => item.NAME).join(", ");
                        },
                      },
                      {
                        value: "OPTIONAL",
                        label: "Optional",
                        format: (optional) => {
                          return optional ? "Yes" : "No";
                        },
                      },
                      {
                        value: "ASK_FOR_NOTES",
                        label: "Ask for Notes",
                        format: (askForNotes) => {
                          return askForNotes ? "Yes" : "No";
                        },
                      },
                    ]}
                  />
                </Card>
              );
            }
            return <div>Yo</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(ChecklistPage));
