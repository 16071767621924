import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";
import {Loading, PageHeadings, TwoColumnList, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {withTranslation} from "react-i18next";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import DiscountDetailsModal from "../../../modals/sales/discounts/discount-details-modal";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import ProductsTable from "../../../tables/sales/products-table";
import ProductSelectModal from "../../../modals/sales/discounts/product-select-modal";
import DangerBadge from "../../../components/badges/danger-badge";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import RuleBasedDiscountModal, {
  TRIGGER_SCOPES,
} from "../../../modals/sales/discounts/rule-based-discount-modal";

class DiscountPage extends Component {
  state = {discount: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    request("partner/discount/" + id, "GET", null).then(async (discount) => {
      console.log(discount);
      this.setState({discount});
    });
  }

  removeDiscount() {
    const {discount} = this.state;

    showLoadingConfirmAlert("Delete Discount", "Are you sure you want to delete this discount?").then(
      async (close) => {
        await request("partner/discount/" + discount.ID, "DELETE", {});

        close();

        this.props.router.navigate("/discounts");
      }
    );
  }

  renderCategoryTable() {
    const {discount} = this.state;

    if (discount?.TRIGGER?.CATEGORIES?.length > 0) {
      console.log(discount.TRIGGER.CATEGORIES);
      return (
        <Card label="Categories" description="Categories attached to this discount">
          <Table
            pagination
            data={discount.TRIGGER.CATEGORIES}
            columns={[
              {
                value: "NAME",
                label: "Name",
              },
            ]}
            actionButtons={[
              {
                label: "View",
                onClick: (row) => this.props.router.navigate("/category/" + row.UNIQUE_ID),
              },
            ]}
          />
        </Card>
      );
    }
  }

  renderProductsTable() {
    const {discount} = this.state;

    if (discount?.TRIGGER?.PRODUCTS?.length > 0) {
      return (
        <Card label="Products" description="Products attached to this discount">
          <Table
            pagination
            data={discount.TRIGGER.PRODUCTS}
            columns={[
              {
                value: "NAME",
                label: "Name",
              },
            ]}
            actionButtons={[
              {
                label: "View",
                onClick: (row) => this.props.router.navigate("/product/" + row.UNIQUE_ID),
              },
            ]}
          />
        </Card>
      );
    }
  }

  render() {
    const {discount} = this.state;

    if (discount === null) {
      return <Loading />;
    }

    const data = [
      {label: "Name", value: discount.NAME},
      {
        label: "Amount",
        value: discount.TYPE === 0 ? `$${toDollars(discount.CONTENT)}` : `${discount.CONTENT}%`,
      },
    ];

    // TODO add other types here later
    if (discount.TRIGGER) {
      data.push({
        label: "Scope",
        value: TRIGGER_SCOPES[discount.TRIGGER.SCOPE].label,
        tooltip: TRIGGER_SCOPES[discount.TRIGGER.SCOPE],
      });

      data.push({
        label: "Trigger Type",
        value: discount.TRIGGER.TRIGGER_TYPE === 0 ? "Quantity" : "Dollar Amount",
        tooltip: {
          data: "Whether this discount will check for a minimum quantity or dollar amount of the selected items required for this discount to be applied.",
        },
      });

      data.push({
        label: "Trigger Amount",
        value:
          discount.TRIGGER.TRIGGER_TYPE === 1
            ? `$${toDollars(discount.TRIGGER.TRIGGER_AMOUNT)}`
            : discount.TRIGGER.TRIGGER_AMOUNT,
        tooltip: {
          data: "The minimum quantity or dollar amount of the selected items required for this discount to be applied.",
        },
      });

      data.push({
        label: "Enabled",
        value: discount.TRIGGER.ENABLED ? "Yes" : "No",
        tooltip: {
          data: "If enabled, this discount will be automatically applied to the order based on the trigger condition and the discount information.",
        },
      });
    }

    return (
      <div>
        <DiscountDetailsModal
          ref={(e) => (this.discountModal = e)}
          updateState={(e) => this.setState(e)}
          onDelete={() => this.props.router.navigate("/discounts")}
        />

        <ProductSelectModal
          ref={(e) => (this.productSelectModal = e)}
          exclude={discount?.PRODUCTS?.map((prod) => prod.PRODUCT_ID || prod.ID)}
          discount={discount}
          updateState={() => this.syncState()}
        />

        <RuleBasedDiscountModal
          ref={(e) => (this.ruleBasedDiscountModal = e)}
          updateState={() => {
            this.syncState();
          }}
        />

        <PageHeadings
          label={
            discount.ARCHIVED ? (
              <div className={"flex flex-row items-center"}>
                <div>{discount.NAME}</div>
                <DangerBadge className="ml-2 mt-2 h-1/2">Archived</DangerBadge>
              </div>
            ) : (
              discount.NAME
            )
          }
          logo={getObjectImage(discount, "LOGO", "appicon.png")}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [{label: "Delete", onClick: () => this.removeDiscount()}],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Sales", url: "/"},
            {label: "Discounts Page", url: "/discounts"},
          ]}
        />

        <TwoColumnList
          buttons={[
            {
              label: "Edit Information",
              onClick: () =>
                discount.TRIGGER
                  ? this.ruleBasedDiscountModal.open(discount)
                  : this.discountModal.open(discount, discount?.PRODUCT ?? 0),
            },
          ]}
          label="Discount Details"
          hint={discount.PRODUCT ? "Product Discount" : "Ticket Discount"}
          tooltip={{
            data: [
              {
                label: "Ticket Discount",
                data:
                  "These will be available on the Register Screen on the Fees and Discounts page on the POS. They " +
                  "will be applied as a discount on the entire ticket.",
              },
              {
                label: "Product Discount",
                data:
                  "To make these available for use on a product, navigate to the product's page on the Partner " +
                  "Dashboard and add the discount to the product via the Discounts tab. Then the discount will be " +
                  "available on the product's page on the POS. When selected on the POS, the discount will be " +
                  "applied as a discount for that item, not the entire ticket.",
              },
              {
                label: "Rule-Based Discount",
                data:
                  "If enabled, these discounts will be automatically applied to the order based on the trigger condition" +
                  " and the discount information.",
              },
            ],
          }}
          description={`Information about this custom ${discount.PRODUCT ? "product" : "ticket"} discount`}
          data={data}
        />

        {!!discount.PRODUCT && (
          <Card
            label="Products"
            description="The products this discount is attached to"
            button={{
              label: "Add Product",
              onClick: () => this.productSelectModal.open(),
            }}
          >
            <ProductsTable
              pagination
              data={discount.PRODUCTS ?? discount.TRIGGER.PRODUCTS}
              updatePricing={() => this.syncState()}
              updateProduct={(serverProduct) => {
                const productIndex = discount.PRODUCTS.findIndex((item) => {
                  return item.ID === serverProduct.ID;
                });

                if (productIndex !== -1) {
                  discount.PRODUCTS.splice(productIndex, 1, {
                    ...discount.PRODUCTS[productIndex],
                    ...serverProduct,
                  });
                }

                this.setState({discount});
              }}
              updateInventory={({product, inventory}) => {
                const productIndex = discount.PRODUCTS.findIndex((item) => {
                  return item.ID === product.ID;
                });

                if (productIndex !== -1) {
                  discount.PRODUCTS.splice(productIndex, 1, {
                    ...discount.PRODUCTS[productIndex],
                    INVENTORY: inventory.QUANTITY,
                  });
                }

                this.setState({discount});
              }}
            />
          </Card>
        )}

        {this.renderCategoryTable()}
        {this.renderProductsTable()}
      </div>
    );
  }
}

export default withTranslation()(withRouter(DiscountPage));
