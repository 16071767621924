import React, {useState} from "react";
import {ONBOARDING_FORM_FIELDS} from "../../../utils/constants";
import {randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

export const DocumentFields = ({updateFields, isReviewer = false}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      key={isReviewer ? "reviewer-fields" : "employee-fields"}
      className={"mt-4 p-4 bg-white border border-gray-200 rounded-md hover:bg-gray-50 cursor-pointer"}
      onClick={() => setExpanded((prevState) => !prevState)}
    >
      <span className={"block text-sm font-medium text-gray-700"}>
        {isReviewer ? `Reviewer` : `Employee`} Form Fields
      </span>

      <span className={"text-sm text-gray-500 mb-2"}>
        Click to expand and add fillable {isReviewer ? `reviewer` : `employee`} fields to your document
      </span>

      {expanded && (
        <div className={"grid grid-cols-2 gap-4 mt-2"}>
          {ONBOARDING_FORM_FIELDS.map((field, index) => (
            <button
              key={index + "_" + randomString(24)}
              type="button"
              onClick={(e) => {
                e.stopPropagation();
                updateFields({...field, IS_REVIEWER: isReviewer});
              }}
              className={
                (isReviewer
                  ? "border-green-700 text-green-700 hover:bg-green-700 "
                  : "border-indigo-500 text-indigo-500 hover:bg-indigo-500 ") +
                "text-sm text-center border bg-white rounded-md px-3 py-2 hover:text-white transition-colors ease-in-out duration-200 w-full"
              }
            >
              {field.LABEL} {field.ICON}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
