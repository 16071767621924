import {request} from "../../request";
import {PACKET_STATUS} from "../../../pages/team/onboarding/functional/packets/packets-helper";
import {CUSTOM_DOCUMENT_STATUS} from "../../../pages/team/onboarding/functional/documents/documents-helper";

export const CustomDocumentRequests = {
  create: async (params) => {
    return request("onboarding/documents/custom/configure", "POST", params);
  },
  update: async (params) => {
    return request("onboarding/documents/custom/configure", "PATCH", params);
  },
  archive: async (id) => {
    return request(`onboarding/documents/custom/${id}/archive`, "PATCH", {});
  },
  fetch: async () => {
    return request("onboarding/documents/custom", "GET");
  },
  fetchArchived: async () => {
    return request("onboarding/documents/custom/" + CUSTOM_DOCUMENT_STATUS.ARCHIVED, "GET");
  },
  fetchEdits: async (documentId) => {
    return request(`onboarding/documents/custom/${documentId}/edits`, "GET");
  },
};

export const DocumentRequests = {
  fetchMetadata: async (documentId) => {
    return request("onboarding/documents/metadata/" + documentId, "GET");
  },
};

export const DefaultDocumentRequests = {
  fetchActive: async () => {
    return request("onboarding/documents/default/", "GET");
  },
};

export const PacketRequests = {
  fetchActive: async () => {
    return request("onboarding/packets", "GET");
  },
  create: async (params) => {
    return request("onboarding/packets/", "POST", params);
  },
  delete: async (id) => {
    return request("onboarding/packets/" + id, "DELETE");
  },
  update: async (params) => {
    return request("onboarding/packets/", "PATCH", params);
  },
};

export const PacketAssignments = {
  create: async (params) => {
    return request("onboarding/packet/assignments/", "POST", params);
  },
  fetchIncomplete: async () => {
    return request("onboarding/packet/assignments/status/" + PACKET_STATUS.INCOMPLETE, "GET");
  },
  fetchInReview: async () => {
    return request("onboarding/packet/assignments/status/" + PACKET_STATUS.PENDING_REVIEW, "GET");
  },
  fetchCompleted: async () => {
    return request("onboarding/packet/assignments/status/" + PACKET_STATUS.COMPLETED, "GET");
  },
  unassign: async (id) => {
    return request("onboarding/packet/assignments/" + id, "DELETE");
  },
  getCount: async () => {
    return request("onboarding/packet/assignments/count", "GET");
  },
  sendEmail: async (params) => {
    return request("onboarding/packet/assignments/email", "POST", params);
  },
  approve: async (params) => {
    return request("onboarding/packet/assignments/approve", "PATCH", params);
  },
};

export const DocumentAssignments = {
  fetchInReview: async () => {
    return request("onboarding/documents/assignments/status/review", "GET");
  },
  updateAfterReview: async (documentAssignmentUID, params) => {
    return request("onboarding/documents/assignments/review/" + documentAssignmentUID, "PATCH", params);
  },
};
