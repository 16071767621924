import {TIMEFRAMES} from "../features/reporting/reports/reporting-constants";
import moment from "moment-timezone";

const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function boolToString(bool) {
  if (bool) {
    return "yes";
  }

  return "no";
}

export function statusToNaturalText(status) {
  return status
    .replaceAll("_", " ")
    .split(" ")
    .map((word) => word.capitalize())
    .join(" ");
}

export function groupBy(array, key) {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function combineArraysIfExist(arr1, arr2) {
  if (arr1 && arr2) {
    return arr1.concat(arr2);
  } else if (arr1) {
    return arr1;
  } else if (arr2) {
    return arr2;
  } else {
    return [];
  }
}

export function timeFrameToLabel(entry, startEpoch, endEpoch, timeFrame) {
  if (timeFrame === TIMEFRAMES.SUMMARY) {
    return moment(startEpoch).startOf("day").valueOf() !== moment(endEpoch).startOf("day").valueOf()
      ? `${moment(startEpoch).format("M/D")} - ${moment(endEpoch).format("M/D")}`
      : moment(endEpoch).format("M/D");
  }
  let month;

  switch (timeFrame) {
    case TIMEFRAMES.DAILY:
      if (typeof entry?.DAY === "string") {
        month = parseInt(entry?.DAY.slice(5, 7));
        let date = entry?.DAY.slice(8, 10).replace(/^0+/, "");
        return month + "/" + date;
      }
      return "";
    case TIMEFRAMES.WEEKLY:
      if (entry?.WEEK) {
        return getDateFromYearWeek(entry.WEEK);
      }
      return "";

    case TIMEFRAMES.MONTHLY:
      if (typeof entry?.MONTH === "string") {
        month = entry?.MONTH.slice(0, 2);
        return moment(month, "M").format("MMMM");
      }
      return "";
  }
}

const getDateFromYearWeek = (yearWeek) => {
  // 202119 - 2021 week 19
  let year = yearWeek.toString().substring(0, 4);
  let weekNumber = yearWeek.toString().substring(4);

  let startOfWeek = moment()
    .day("Monday")
    .year(year)
    .isoWeek(weekNumber)
    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
    .format("M/D");

  let endOfWeek = moment()
    .day("Sunday")
    .year(year)
    .isoWeek(weekNumber)
    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
    .format("M/D");

  return startOfWeek + " - " + endOfWeek;
};

export function updateStateDelegator(state, id, element) {
  const stateIndex = state.findIndex((item) => {
    return item.ID === id;
  });

  if (stateIndex !== -1) {
    if (element) {
      state.splice(stateIndex, 1, element);
    } else {
      state.splice(stateIndex, 1);
    }
  }

  return state;
}

export function rgbToHex(rgb) {
  rgb = rgb.split(",");

  return rgb.reduce((accum, item) => `${accum}${parseInt(item.trim()).toString(16).toUpperCase()}`, "");
}

export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  let red = parseInt(result[1], 16);
  let green = parseInt(result[2], 16);
  let blue = parseInt(result[3], 16);

  return `${red},${green},${blue}`;
}

export function darken(hex, factor = 0.5) {
  const rgb = hexToRgb(hex);

  const rgbDarkened = rgb.split(",").map((item) => parseInt(Math.round(item * factor)));

  const ret = rgbToHex(`${rgbDarkened[0]}, ${rgbDarkened[1]}, ${rgbDarkened[2]}`);

  return ret;
}

export function getAllHolidays() {
  let holidays = {
    2022: {
      newYears: "01-01",
      mlk: "01-18",
      presidents: "02-21",
      memorialDay: "05-30",
      juneteenth: "06-19",
      july4th: "07-04",
      laborDay: "09-05",
      columbus: "10-10",
      veterans: "11-11",
      thanksgiving: "11-24",
      christmas: "12-25",
    },
    2023: {
      newYears: "01-01",
      mlk: "01-16",
      presidents: "02-20",
      memorialDay: "05-29",
      juneteenth: "06-19",
      july4th: "07-04",
      laborDay: "09-04",
      columbus: "10-09",
      veterans: "11-11",
      thanksgiving: "11-23",
    },
    2024: {
      newYears: "01-01",
      mlk: "01-15",
      presidents: "02-19",
      memorialDay: "05-27",
      juneteenth: "06-19",
      july4th: "07-04",
      laborDay: "09-02",
      columbus: "10-14",
      veterans: "11-11",
      thanksgiving: "11-28",
      christmas: "12-25",
    },
  };

  return Object.keys(holidays).reduce((arr, year) => {
    arr.push(...Object.values(holidays[year]).map((date) => `${date}-${year}`));

    return arr;
  }, []);
}

export function parseName(name) {
  if (!name) {
    return null;
  }

  const splitName = name.split(" ");

  const firstName = !!splitName.length > 0 ? splitName[0] : null;

  const middleInitial = splitName.length > 2 ? splitName[1].substring(0, 1) : "";

  const lastName = splitName.length > 1 ? (splitName.length === 2 ? splitName[1] : splitName[2]) : null;

  return {
    firstName,
    middleInitial,
    lastName,
  };
}

export function dayToIsoDay(day) {
  if (parseInt(day) === 0) {
    return 7;
  }
  return parseInt(day);
}

export function isoDayToDay(day) {
  if (parseInt(day) === 7) {
    return 0;
  }

  return parseInt(day);
}

export function companyNameToSubdomain(name) {
  if (!name) {
    return "";
  }

  return name
    .replaceAll("'", "")
    .replaceAll('"', "")
    .replaceAll(/[.]/g, "")
    .replaceAll("!", "")
    .replaceAll(" & ", " ")
    .replaceAll("&", "")
    .replaceAll(" - ", " ")
    .replaceAll(" ", "-")
    .trim()
    .toLowerCase();
}

export function fetchProductAmount(product, selections, quantity = 1) {
  const usedFixedCustomizations = new Set();
  const variant = selections.find(function (item) {
    return (
      item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.VARIANT ||
      item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
    );
  });

  const priceSelection = selections.find(
    (selection) => selection.TYPE !== CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
  );

  const price = variant ? variant.AMOUNT : priceSelection ? 0 : product.PRICE;
  const modifier = variant && variant.MODIFIER ? variant.MODIFIER : 1;
  const {amount} = calculatePrice(product, price);

  return (
    selections.reduce(function (accum, item) {
      let optionAmount = item.AMOUNT;

      if (item.AMOUNT_TAX === null) {
        optionAmount = calculatePrice(product, item.AMOUNT).amount;
      }

      if (item.MODIFIER_TYPE && item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.MULTI_SELECT_FIXED) {
        if (usedFixedCustomizations.has(item.ID)) {
          return accum;
        }

        usedFixedCustomizations.add(item.ID);
        return accum + Math.round(optionAmount * modifier);
      }

      if (
        item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.VARIANT ||
        item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.AUTOMATIC_VARIANT
      ) {
        return accum;
      }

      if (item.MODIFIER_TYPE === CUSTOMIZATION_TYPES.DISCOUNT) {
        return accum + optionAmount;
      }

      return accum + Math.round(optionAmount * modifier);
    }, amount) * (quantity ?? 1)
  );
}

export function calculatePrice(product, base) {
  const {TAX_DISABLED: disabled, TAX_RATES: rates = []} = product;

  let tax = 0;
  let amount = base;
  let lines = [];

  rates.sort((a, b) => b.IS_INCLUSIVE - a.IS_INCLUSIVE);

  for (let rate of rates) {
    const ratePayload = {
      PRODUCT_TAX_ID: null,
      TAX_ID: rate.ID,
      AMOUNT: 0,
    };

    if (rate.PRODUCT) {
      ratePayload.PRODUCT_TAX_ID = rate.PRODUCT.ID;
    }

    if (rate.IS_INCLUSIVE) {
      const percent = rate.RATE / 100 + 1;

      const rateAmount = Math.round(base / percent);
      const rateTax = base - rateAmount;

      amount -= rateTax;
      tax += rateTax;

      ratePayload.AMOUNT = rateTax;
    } else {
      const rateTax = Math.round((rate.RATE / 100) * base);

      tax += rateTax;

      ratePayload.AMOUNT = rateTax;
    }

    lines.push(ratePayload);
  }

  return {amount, tax, total: amount + tax, lines};
}

export function spliceObjectArrayObjects(obj) {
  return Object.keys(obj).reduce((dict, item) => {
    const value = obj[item];

    if (typeof value === "object" || Array.isArray(value)) {
      return dict;
    }

    dict[item] = value;

    return dict;
  }, {});
}

export function parseObjectFields(obj, fields) {
  return fields.reduce((dict, field) => {
    dict[field] = obj[field];
    return dict;
  }, {});
}

const CUSTOMIZATION_TYPES = {
  SINGLE_SELECT: 0,
  QUANTITY: 1,
  MULTI_SELECT: 2,
  MULTI_SELECT_QUANTITY: 3,
  MULTI_SELECT_FIXED: 4,
  VARIANT: 5,
  DISCOUNT: 69,
};

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function upsertArray(arr, element) {
  const elementIndex = arr.findIndex((item) => {
    return item.ID === element.ID;
  });

  if (elementIndex !== -1) {
    arr.splice(elementIndex, 1, element);
  } else {
    arr.push(element);
  }

  return arr;
}

export function spliceArray(arr, element) {
  const elementIndex = arr.findIndex((item) => {
    return item.ID === element.ID;
  });

  if (elementIndex !== -1) {
    arr.splice(elementIndex, 1);
  }

  return arr;
}

export function formatByteString(bytes) {
  const kilobytes = bytes / 1024;

  return kilobytes.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
}

export function objectDot(obj, param) {
  let paramSplit = param.split(".");
  let objLevel = obj;

  for (let level of paramSplit) {
    if (typeof objLevel[level] === "undefined") return null;

    objLevel = objLevel[level];
  }

  return objLevel;
}

export function isValidPhone(raw) {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parse(raw, "US"));
  } catch (e) {
    return false;
  }
}

export function formatNumberCommas(number) {
  if (number === null || number === undefined) {
    return "";
  }

  const parts = number.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

export function upsert(items, item, key = "ID") {
  const clonedItems = [...items];

  const index = clonedItems.findIndex((_item) => {
    return _item[key] === item[key];
  });

  if (index === -1) {
    return [item, ...clonedItems];
  }

  clonedItems.splice(index, 1, item);
  return clonedItems;
}

export function sortArrayByKey(arr = [], key = "ID", desc = true) {
  if (!!desc) {
    return arr.sort((a, b) => b[key] - a[key]);
  } else {
    return arr.sort((a, b) => a[key] - b[key]);
  }
}

export function isSupplyChainWhitelisted() {
  return true;
}

export function positiveModulo(n, m) {
  return ((n % m) + m) % m;
}

export function readableDate(date) {
  return moment(date).format("dddd, MMMM Do");
}

export function parseIdArray(arr, key = "ID") {
  return (arr ?? []).map((item) => item[key]);
}

export function isEmpty(object) {
  return object === null || object === "" || object === undefined;
}
