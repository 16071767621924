import React from "react";
import {Page, Text, View, Document, StyleSheet} from "@react-pdf/renderer";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {PAYMENT_TYPE_TO_STRING} from "../../utils/ticket-constants";

// Create styles
const styles = StyleSheet.create({
  section: {
    margin: 20,
    padding: 20,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  totalRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  tableRow: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  itemRow: {
    width: 500,
  },
  items: {
    marginTop: 3,
    marginLeft: 20,
  },
  qtyRow: {
    width: 100,
  },
  priceRow: {
    width: 100,
  },
  amountSummary: {
    marginLeft: 10,
  },
  paymentBlock: {
    marginTop: 7,
  },
  paymentAmount: {
    marginLeft: 10,
  },
});

// Create Document Component
const TicketPDF = (props) => {
  function renderHeader() {
    let {LOCATION_NAME, PAYMENT_INTENT} = props.ticket;
    let title = "Order Details";

    if (PAYMENT_INTENT.AMOUNT_REFUNDED > 0) {
      title = title.concat(" - Refund Issued");
    }

    return (
      <View style={styles.row}>
        <Text>{title}</Text>

        <Text>{LOCATION_NAME}</Text>
      </View>
    );
  }

  function renderCustomerInfo() {
    let nameStr = "N/A",
      phoneStr = "N/A",
      deliveryStr = "N/A",
      patronNotesStr = "N/A",
      customerNameStr = "N/A",
      orderNotesStr = "N/A",
      deliveryNotesStr = "N/A";

    let {NAME, PHONE, DELIVERY, PATRON, ACCOUNT, NOTES} = props.ticket;
    if (NAME) {
      nameStr = NAME;
    }
    if (PHONE) {
      phoneStr = PHONE;
    }

    if (DELIVERY) {
      let {ADDRESS} = DELIVERY;
      deliveryNotesStr = ADDRESS.NOTES ? ADDRESS.NOTES : "N/A";
      let {CITY, LINE_ONE, LINE_TWO, STATE, ZIPCODE} = ADDRESS;

      LINE_TWO = LINE_TWO ? LINE_TWO : "";
      deliveryStr = LINE_ONE + " " + LINE_TWO + " " + CITY + ", " + STATE + " " + ZIPCODE;
    } else {
      deliveryStr = "N/A";
    }

    if (PATRON) {
      let {NOTES} = PATRON;
      patronNotesStr = NOTES ? NOTES : "N/A";
    }

    if (ACCOUNT) {
      customerNameStr = ACCOUNT.FULL_NAME;
    }

    if (NOTES) {
      orderNotesStr = NOTES;
    }

    return (
      <View>
        <Text>Order Name: {nameStr}</Text>

        <View>
          <Text style={{color: "red"}}>Customer Name: {customerNameStr}</Text>
        </View>
        <Text>Address: {deliveryStr}</Text>

        <Text>Phone Number: {phoneStr}</Text>

        <Text>Patron Notes: {patronNotesStr}</Text>

        <Text>Order Notes: {orderNotesStr}</Text>

        <Text>Delivery Notes: {deliveryNotesStr}</Text>
      </View>
    );
  }

  function renderMiddle() {
    let {DATE_CREATED, DATE_COMPLETED, DATE_SCHEDULED, PAYMENT_INTENT} = props.ticket;
    let {AMOUNT, AMOUNT_TAX, TOTAL} = PAYMENT_INTENT;

    return (
      <View style={styles.row}>
        <View>
          <Text>Date Ordered</Text>

          <Text>{DATE_CREATED ? moment(DATE_CREATED).format("hh:mm A") : "Incomplete"}</Text>

          <Text>{DATE_CREATED ? moment(DATE_CREATED).format("MMM DD, YYYY") : ""}</Text>
        </View>

        <View>
          <Text>Date Scheduled</Text>

          <Text>{DATE_SCHEDULED ? moment(DATE_SCHEDULED).format("hh:mm A") : "Incomplete"}</Text>

          <Text>{DATE_SCHEDULED ? moment(DATE_SCHEDULED).format("MMM DD, YYYY") : ""}</Text>
        </View>

        <View>
          <Text>Date Completed</Text>

          <Text>{DATE_COMPLETED ? moment(DATE_COMPLETED).format("hh:mm A") : "Incomplete"}</Text>

          <Text>{DATE_COMPLETED ? moment(DATE_COMPLETED).format("MMM DD, YYYY") : ""}</Text>
        </View>

        <View>
          <View style={styles.totalRow}>
            <Text>Subtotal</Text>

            <Text style={styles.amountSummary}>{toDollars(AMOUNT, true)}</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>Taxes</Text>

            <Text>{toDollars(AMOUNT_TAX, true)}</Text>
          </View>

          <View style={styles.totalRow}>
            <Text>Total</Text>

            <Text>{toDollars(TOTAL, true)}</Text>
          </View>
        </View>
      </View>
    );
  }

  function renderPayment() {
    let {PAYMENTS} = props.ticket.PAYMENT_INTENT;

    return (
      <View>
        <Text>Payment Methods</Text>
        {PAYMENTS.map((item) => {
          let {TYPE, AMOUNT, AMOUNT_REFUNDED} = item;

          return (
            <View style={styles.paymentBlock}>
              <View>
                <Text>Method: {PAYMENT_TYPE_TO_STRING[TYPE]}</Text>
              </View>

              <View>
                <Text style={styles.paymentAmount}>Amount Paid: {toDollars(AMOUNT, true)}</Text>
              </View>

              {AMOUNT_REFUNDED > 0 && (
                <View>
                  <Text style={styles.paymentAmount}>
                    Amount Refunded: {toDollars(AMOUNT_REFUNDED, true)}
                  </Text>
                </View>
              )}
            </View>
          );
        })}
      </View>
    );
  }

  function renderItems(secondHalf = false) {
    let {ITEMS} = props.ticket;

    // ITEMS = ITEMS.slice(0, 1);

    // if(secondHalf){
    //   ITEMS = ITEMS.slice(7, ITEMS.length)
    // } else {
    //   ITEMS = ITEMS.slice(0, 7)
    // }
    //
    // console.log(ITEMS.length)

    return (
      <View>
        <View style={styles.tableRow}>
          <Text style={styles.itemRow}>Items</Text>
          <Text style={styles.qtyRow}>Quantity</Text>
          <Text style={styles.priceRow}>Price</Text>
        </View>

        <View>
          {ITEMS.map((item) => {
            let {NAME, QUANTITY, AMOUNT, AMOUNT_REFUNDED, SELECTIONS} = item;
            let modifierStr = "";
            for (let selection of SELECTIONS) {
              modifierStr += selection.OPTION_NAME + ", ";
            }
            if (modifierStr.length > 1) {
              modifierStr = modifierStr.substring(0, modifierStr.length - 2);
            }

            let itemNameTitle = NAME;
            if (AMOUNT_REFUNDED >= AMOUNT) {
              itemNameTitle = itemNameTitle.concat(" (Refunded)");
            }

            return (
              <View style={styles.tableRow}>
                <View style={styles.itemRow}>
                  <Text>{itemNameTitle}</Text>
                  <Text style={styles.items}>{modifierStr}</Text>
                </View>

                <Text style={styles.qtyRow}>{QUANTITY}</Text>
                <Text style={styles.priceRow}>{toDollars(AMOUNT, true)}</Text>
              </View>
            );
          })}
        </View>
      </View>
    );
  }

  function renderOrderNumber() {
    let {TICKET_NUMBER, ITEMS} = props.ticket;

    return (
      <View style={styles.row}>
        <View>
          <Text>Order Number</Text>
          <Text>{TICKET_NUMBER}</Text>
        </View>
      </View>
    );
  }

  let maxItemsOnFirstPage = 8;
  let {ITEMS} = props.ticket;

  return (
    <Document>
      <Page style={styles.page} wrap={true}>
        <View style={styles.section}>
          {renderHeader()}

          <View style={{marginTop: 30}} />
          {renderCustomerInfo()}

          <View style={{marginTop: 15}} />
          {renderOrderNumber()}

          <View style={{marginTop: 15}} />
          {renderMiddle()}

          <View style={{marginTop: 30}} />
          {renderPayment()}
        </View>

        <View style={styles.section}>{renderItems()}</View>
      </Page>
    </Document>
  );
};

export default TicketPDF;
