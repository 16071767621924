import React, {Component} from "react";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {
  doLocationsHaveDoubleOvertime,
  TIME_CARD_COLUMNS,
} from "../../features/reporting/reports/reporting-constants";
import TimeCardSummaryExpansionComponent from "../../features/reporting/reports/time-card-summary-expansion-component";
import {setupReduxConnection} from "../../redux";
import {withRouter} from "../../utils/navigation";
import {TIP_CALCULATION_METHOD_TYPES} from "../../utils/settings-constants";
import RadioDropdown from "../../components/form-elements/radio-dropdown";
import {classNames, minutesToHours, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {request} from "../../utils/request";
import DangerBadge from "../../components/badges/danger-badge";
import {convertColumnsToCSVHeaders, convertToCSVData, getDateString} from "../../utils/reporting-helper";
import moment from "moment-timezone";
import {differenceInHours} from "../../utils/time-helper";

class TimeCardsReportPage extends Component {
  state = {
    selectedTipCalculationMethod: null,
    groupBy: null,
    lastGeneratedCustomParams: {},
    breakOptions: null,
  };

  componentDidMount() {
    this.setState({
      selectedTipCalculationMethod: this.props.shop.settings.TIP_CALCULATION_METHOD,
      groupBy: GROUP_BY_MODES.EMPLOYEE.value,
    });

    this.syncState();
  }

  async syncState() {
    const serverBreakOptions = await request("team/break-option/company", "GET");

    this.setState({
      breakOptions: serverBreakOptions,
    });
  }

  refresh = async () => {
    await this.fixedColumnReport.onGenerate(true);
  };

  convertDataToRows = (reportData) => {
    let {lastGeneratedCustomParams, breakOptions} = this.state;
    let {selectedTipCalculationMethod} = this.state;
    let {EMPLOYEE_TIMESHEET_BREAKDOWN, TOTALS, SHOW_BREAKS} = reportData;
    let {SHOW_BREAKS_OUT_OF_COMPLIANCE} = this.props.shop.settings;

    reportData = EMPLOYEE_TIMESHEET_BREAKDOWN.map((entry) => {
      let label = entry.FULL_NAME;

      if (SHOW_BREAKS_OUT_OF_COMPLIANCE && entry.BREAKS_OUT_OF_COMPLIANCE) {
        label = <DangerBadge className={"text-sm font-normal"}>{entry.FULL_NAME}</DangerBadge>;
      }

      return {
        label,
        sortableName: entry.FULL_NAME,
        numbers: {
          AMOUNT_TOTAL_HOURS: (entry.PAY_TOTALS.AMOUNT_TOTAL_MINUTES / 60).toFixed(2),
          AMOUNT_REGULAR_HOURS: (entry.PAY_TOTALS.AMOUNT_REGULAR_MINUTES / 60).toFixed(2),
          AMOUNT_REGULAR_GROSS: entry.PAY_TOTALS.AMOUNT_REGULAR_GROSS,
          AMOUNT_OVERTIME_GROSS: entry.PAY_TOTALS.AMOUNT_OVERTIME_GROSS,
          AMOUNT_OVERTIME_HOURS:
            entry.PAY_TOTALS.AMOUNT_OVERTIME_MINUTES > 0
              ? (entry.PAY_TOTALS.AMOUNT_OVERTIME_MINUTES / 60).toFixed(2)
              : "0.00",
          AMOUNT_DOUBLE_OVERTIME_HOURS:
            entry.PAY_TOTALS.AMOUNT_DOUBLE_OVERTIME_MINUTES > 0
              ? (entry.PAY_TOTALS.AMOUNT_DOUBLE_OVERTIME_MINUTES / 60).toFixed(2)
              : "0.00",
          AMOUNT_DOUBLE_OVERTIME_GROSS: Math.round(entry.PAY_TOTALS.AMOUNT_DOUBLE_OVERTIME_GROSS),
          AMOUNT_HOURS_SCHEDULED: (entry.PAY_TOTALS.AMOUNT_MINUTES_SCHEDULED / 60).toFixed(2),
          AMOUNT_PAYED: entry.PAY_TOTALS.AMOUNT_PAYED,
          AMOUNT_TIPS: entry.PAY_TOTALS.AMOUNT_TIPS,
          AVERAGE_WAGE: entry.PAY_TOTALS.AVERAGE_WAGE,
          AMOUNT_STANDARD_PAY: entry.PAY_TOTALS.AMOUNT_STANDARD_PAY,
          ...entry,
        },
        expandCell: (row) => (
          <TimeCardSummaryExpansionComponent
            hideTips={selectedTipCalculationMethod !== TIP_CALCULATION_METHODS.TRANSACTION.value}
            row={row.numbers}
            groupedByRole={lastGeneratedCustomParams?.groupBy === GROUP_BY_MODES.ROLE.value}
            breakOptions={breakOptions}
            refresh={this.refresh}
          />
        ),
      };
    });

    reportData.sort((a, b) => a.sortableName.localeCompare(b.sortableName));

    reportData.push({
      label: "Total",
      numbers: {
        AMOUNT_TOTAL_HOURS: (TOTALS.AMOUNT_TOTAL_MINUTES / 60).toFixed(2),
        AMOUNT_REGULAR_HOURS: (TOTALS.AMOUNT_REGULAR_MINUTES / 60).toFixed(2),
        AMOUNT_OVERTIME_HOURS: TOTALS.AMOUNT_OVERTIME_MINUTES
          ? (TOTALS.AMOUNT_OVERTIME_MINUTES / 60).toFixed(2)
          : "0.00",
        AMOUNT_DOUBLE_OVERTIME_HOURS: TOTALS.AMOUNT_DOUBLE_OVERTIME_MINUTES
          ? (TOTALS.AMOUNT_DOUBLE_OVERTIME_MINUTES / 60).toFixed(2)
          : "0.00",
        AMOUNT_HOURS_SCHEDULED: (TOTALS.AMOUNT_MINUTES_SCHEDULED / 60).toFixed(2),
        AMOUNT_REGULAR_GROSS: TOTALS.AMOUNT_REGULAR_GROSS,
        AMOUNT_OVERTIME_GROSS: TOTALS.AMOUNT_OVERTIME_GROSS,
        AMOUNT_DOUBLE_OVERTIME_GROSS: TOTALS.AMOUNT_DOUBLE_OVERTIME_GROSS,
        AMOUNT_PAYED: TOTALS.AMOUNT_PAYED,
        AMOUNT_TIPS: TOTALS.AMOUNT_TIPS,
        AVERAGE_WAGE: null,
        AMOUNT_STANDARD_PAY: TOTALS.AMOUNT_STANDARD_PAY,
      },
      footer: true,
    });

    return reportData;
  };

  convertDataToTimesheetRows = (reportData) => {
    let {ALL_TIMESHEETS} = reportData;

    let rows = [];

    for (let timesheet of ALL_TIMESHEETS) {
      let {BREAKS} = timesheet;

      let mainEntry = {
        label: timesheet.FULL_NAME,
        numbers: {
          ROLE_NAME: timesheet.ROLE_NAME,
          LOCATION_NAME: timesheet.TIMESHEET_LOCATION_NAME,
          DATE_START: timesheet.DATE_START,
          DATE_END: timesheet.DATE_END,
          DATE_START_TIME: timesheet.DATE_START,
          DATE_END_TIME: timesheet.DATE_END,
          AMOUNT_REGULAR_MINUTES: timesheet.AMOUNT_REGULAR_MINUTES,
          AMOUNT_REGULAR_GROSS: timesheet.AMOUNT_REGULAR_GROSS,
          AMOUNT_OVERTIME_MINUTES: timesheet.AMOUNT_OVERTIME_MINUTES,
          AMOUNT_OVERTIME_GROSS: timesheet.AMOUNT_OVERTIME_GROSS,
          AMOUNT_DOUBLE_OVERTIME_MINUTES: timesheet.AMOUNT_DOUBLE_OVERTIME_MINUTES,
          AMOUNT_DOUBLE_OVERTIME_GROSS: timesheet.AMOUNT_DOUBLE_OVERTIME_GROSS,
          AMOUNT_HOURS_SCHEDULED: differenceInHours(timesheet.SHIFT_DATE_START, timesheet.SHIFT_DATE_END),
          AMOUNT_TOTAL_HOURS: timesheet.AMOUNT_TOTAL_HOURS,
          AMOUNT_MINUTES: timesheet.AMOUNT_MINUTES,
          AMOUNT_STANDARD_PAY:
            timesheet.AMOUNT_REGULAR_GROSS +
            timesheet.AMOUNT_OVERTIME_GROSS +
            timesheet.AMOUNT_DOUBLE_OVERTIME_GROSS,
          AMOUNT_TIPS: timesheet.AMOUNT_TIPS,
        },
      };

      if (BREAKS?.length > 0) {
        mainEntry.numbers = {
          ...mainEntry.numbers,
          ...this.getBreakEntryProperties(BREAKS[0]),
        };
      }

      rows.push(mainEntry);

      for (let i = 1; i < BREAKS?.length; i++) {
        rows.push({numbers: this.getBreakEntryProperties(BREAKS[i])});
      }
    }

    return rows;
  };

  getBreakEntryProperties(breakEntry) {
    let {DATE_START, DATE_END, IS_PAID} = breakEntry;

    let breakMinutes = (DATE_END - DATE_START) / 60000;

    return {
      BREAK_DATE_START: DATE_START,
      BREAK_DATE_END: DATE_END,
      BREAK_START_TIME: DATE_START,
      BREAK_END_TIME: DATE_END,
      PAID_AMOUNT_MINUTES: IS_PAID ? breakMinutes : 0,
      UNPAID_AMOUNT_MINUTES: IS_PAID ? 0 : breakMinutes,
    };
  }

  getColumns() {
    let {TIME_CARDS_CALCULATE_OVERTIME} = this.props.shop.settings;
    let {lastGeneratedCustomParams} = this.state;
    const {location, payrollGroup} = this.props.shop;

    let columns = [...TIME_CARD_COLUMNS];

    let showOvertime = TIME_CARDS_CALCULATE_OVERTIME === "1";
    const showDoubleOvertime = doLocationsHaveDoubleOvertime(location, payrollGroup);

    if (!showOvertime) {
      columns.unshift({
        label: "Pay Earned",
        columnSelector: "AMOUNT_TOTAL_HOURS",
        format: (value, row) => {
          if (value === "0.00") {
            return <div className="text-gray-300">0.00 hrs</div>;
          }

          return (
            <div className="text-right">
              <div className="text-medium">{value} hrs</div>

              <div className="text-gray-500">{toDollars(row.AMOUNT_PAYED, true)}</div>
            </div>
          );
        },
      });
    }

    if (showOvertime) {
      columns.unshift(
        ...[
          {
            label: "Regular",
            tooltip:
              "The value under this column represents the total amount of pay the corresponding employee earned from shifts worked during the selected time period, not including any overtime pay.",
            columnSelector: "AMOUNT_REGULAR_HOURS",
            format: (value, row) => {
              if (value === "0.00") {
                return <div className="text-gray-300">0.00 hrs</div>;
              }

              return (
                <div className="text-right">
                  <div className="text-medium">{value} hrs</div>

                  <div className="text-gray-500">{toDollars(row.AMOUNT_REGULAR_GROSS, true)}</div>
                </div>
              );
            },
          },
          {
            label: "Overtime",
            columnSelector: "AMOUNT_OVERTIME_HOURS",
            tooltip:
              "The value under this column represents the total amount of pay the corresponding employee earned from any overtime hours worked during the selected time period.",
            format: (value, row) => {
              return (
                <div className={classNames("text-right", value === "0.00" && "text-gray-300")}>
                  <div className="text-medium">{value} hrs</div>

                  <div className={classNames(value !== "0.00" && "text-gray-500")}>
                    {toDollars(row.AMOUNT_OVERTIME_GROSS, true)}
                  </div>
                </div>
              );
            },
          },
          {
            label: "Total",
            columnSelector: "AMOUNT_STANDARD_PAY",
            format: (value, row) => {
              return (
                <div className="font-medium text-right">
                  <div className="text-medium">{row.AMOUNT_TOTAL_HOURS} hrs</div>

                  <div className="text-gray-500">{toDollars(value, true)}</div>
                </div>
              );
            },
          },
        ]
      );
    }

    if (showOvertime && showDoubleOvertime) {
      columns.splice(2, 0, {
        label: "Double Overtime",
        columnSelector: "AMOUNT_DOUBLE_OVERTIME_HOURS",
        format: (value, row) => {
          return (
            <div className={classNames("text-right", value === "0.00" && "text-gray-300")}>
              <div className="text-medium">{value} hrs</div>

              <div className={classNames(value !== "0.00" && "text-gray-500")}>
                {toDollars(row.AMOUNT_DOUBLE_OVERTIME_GROSS, true)}
              </div>
            </div>
          );
        },
      });
    }

    if (lastGeneratedCustomParams?.groupBy === GROUP_BY_MODES.ROLE.value) {
      columns.splice(columns.length - 2, 2);
    }

    return columns;
  }

  onGenerate = () => {
    let {selectedTipCalculationMethod, groupBy} = this.state;

    this.setState({
      lastGeneratedCustomParams: {
        selectedTipCalculationMethod,
        groupBy,
      },
    });
  };

  renderTooltip() {
    let {TIP_CALCULATION_METHOD} = this.props.shop.settings;

    return (
      <div>
        <div>Tip Calculation Method:</div>
        <div>{TIP_CALCULATION_METHOD_TYPES[TIP_CALCULATION_METHOD]}</div>
        <div className="mt-2">{TIP_CALCULATION_METHOD_DESCRIPTIONS[TIP_CALCULATION_METHOD]}</div>
      </div>
    );
  }

  render() {
    let {TIP_CALCULATION_METHOD, TIME_CARDS_CALCULATE_OVERTIME} = this.props.shop.settings;
    let {selectedTipCalculationMethod, groupBy} = this.state;
    let {startEpoch, endEpoch} = this.props.reporting;

    let showOvertime = TIME_CARDS_CALCULATE_OVERTIME === "1";

    let fileDateString = getDateString(startEpoch, endEpoch);
    let columns = this.getColumns();

    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Time Cards Report"} />
        </div>

        <FixedColumnReport
          ref={(e) => (this.fixedColumnReport = e)}
          filenamePrefix="time-cards"
          endpoint="report/timesheets"
          constantColumns={columns}
          convertDataToRows={this.convertDataToRows}
          expandable={true}
          locationPicker={true}
          customParams={{
            TIP_CALCULATION_METHOD: selectedTipCalculationMethod,
            GROUP_BY: groupBy,
          }}
          onGenerate={this.onGenerate}
          csvColumns={showOvertime ? csvColumnsOvertime : csvColumnsNoOvertime}
          customFields={
            <div className={"flex-row flex space-x-3"}>
              <RadioDropdown
                className={"-mt-5"}
                label={"Group By"}
                defaultValue={GROUP_BY_MODES.EMPLOYEE.value}
                name="groupBy"
                data={Object.values(GROUP_BY_MODES)}
                onChange={(val) => {
                  this.setState({groupBy: val});
                }}
              />

              <RadioDropdown
                className={"-mt-5"}
                label={"Split Tips"}
                defaultValue={TIP_CALCULATION_METHOD}
                name="timeFrame"
                data={Object.values(TIP_CALCULATION_METHODS)}
                onChange={(val) => {
                  this.setState({selectedTipCalculationMethod: val});
                }}
              />
            </div>
          }
          customCSVLink={[
            {
              label: "Labor Cost Export",
              filename: `labor-cost_${fileDateString}.csv`,
              dataConvertFunction: (reportData) => {
                let columns = showOvertime ? csvColumnsOvertime : csvColumnsNoOvertime;

                let rows = this.convertDataToRows(reportData);

                let csvHeaders = convertColumnsToCSVHeaders(columns, "");
                let csvData = convertToCSVData(columns, rows, "sortableName");

                return {csvHeaders, csvData};
              },
            },
            {
              label: "Time Cards Export",
              filename: `time-cards_${fileDateString}.csv`,
              dataConvertFunction: (reportData) => {
                let rows = this.convertDataToTimesheetRows(reportData);

                let csvHeaders = convertColumnsToCSVHeaders(csvTimeCardsColumns, "");
                let csvData = convertToCSVData(csvTimeCardsColumns, rows);

                return {csvHeaders: csvHeaders, csvData};
              },
            },
          ]}
        />
      </div>
    );
  }
}

const GROUP_BY_MODES = {
  EMPLOYEE: {label: "Employee", value: "EMPLOYEE"},
  ROLE: {label: "Role", value: "ROLE"},
};

const TIP_CALCULATION_METHODS = {
  TRANSACTION: {
    label: "by Transaction",
    value: "TRANSACTION",
  },
  DAILY: {
    label: "by Day",
    value: "DAILY",
  },
  WEEKLY: {
    label: "by Week",
    value: "WEEKLY",
  },
  PAY_PERIOD: {
    label: "by Period",
    value: "PAY_PERIOD",
  },
};

const TIP_CALCULATION_METHOD_DESCRIPTIONS = {
  PAY_PERIOD: "Tips are split evenly by tip enabled workers across the dates of this report",
  TRANSACTION: "Tips are split evenly between tip enabled workers at time of transaction",
};

const csvTimeCardsColumns = [
  {
    label: "Role",
    columnSelector: "ROLE_NAME",
  },
  {
    label: "Location",
    columnSelector: "LOCATION_NAME",
  },
  {
    label: "Hours Scheduled",
    columnSelector: "AMOUNT_HOURS_SCHEDULED",
  },
  {
    label: "Clock-In Date",
    columnSelector: "DATE_START",
    csvFormat: (value, row) => {
      return moment(value).format("MM/DD/YY");
    },
  },
  {
    label: "Clock-In Time",
    columnSelector: "DATE_START_TIME",
    csvFormat: (value, row) => {
      return moment(value).format("hh:mm A");
    },
  },
  {
    label: "Clock-Out Date",
    columnSelector: "DATE_END",
    csvFormat: (value, row) => {
      return moment(value).format("MM/DD/YY");
    },
  },
  {
    label: "Clock-Out Time",
    columnSelector: "DATE_END_TIME",
    csvFormat: (value, row) => {
      return moment(value).format("hh:mm A");
    },
  },
  {
    label: "Break Start Date",
    columnSelector: "BREAK_DATE_START",
    csvFormat: (value, row) => {
      return moment(value).format("MM/DD/YY");
    },
  },
  {
    label: "Break Start Time",
    columnSelector: "BREAK_START_TIME",
    csvFormat: (value, row) => {
      return moment(value).format("hh:mm A");
    },
  },
  {
    label: "Break End Date",
    columnSelector: "BREAK_DATE_END",
    csvFormat: (value, row) => {
      return moment(value).format("MM/DD/YY");
    },
  },
  {
    label: "Break End Time",
    columnSelector: "BREAK_END_TIME",
    csvFormat: (value, row) => {
      return moment(value).format("hh:mm A");
    },
  },
  {
    label: "Break End Date",
    columnSelector: "UNPAID_AMOUNT_MINUTES",
  },
  {
    label: "Paid Break",
    columnSelector: "PAID_AMOUNT_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(value);
    },
  },
  {
    label: "Unpaid Break",
    columnSelector: "UNPAID_AMOUNT_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(value);
    },
  },
  {
    label: "Regular Hours",
    columnSelector: "AMOUNT_REGULAR_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(row.AMOUNT_REGULAR_MINUTES);
    },
  },
  {
    label: "Overtime Hours",
    columnSelector: "AMOUNT_OVERTIME_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(row.AMOUNT_OVERTIME_MINUTES);
    },
  },
  {
    label: "Double Overtime Hours",
    columnSelector: "AMOUNT_DOUBLE_OVERTIME_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(row.AMOUNT_OVERTIME_MINUTES);
    },
  },
  {
    label: "Total Paid Hours",
    columnSelector: "AMOUNT_MINUTES",
    csvFormat: (value, row) => {
      return minutesToHours(row.AMOUNT_MINUTES);
    },
  },
  {
    label: "Regular Pay",
    columnSelector: "AMOUNT_REGULAR_GROSS",
    dollarAmount: true,
  },
  {
    label: "Overtime Pay",
    columnSelector: "AMOUNT_OVERTIME_GROSS",
    dollarAmount: true,
  },
  {
    label: "Double Overtime Pay",
    columnSelector: "AMOUNT_DOUBLE_OVERTIME_GROSS",
    dollarAmount: true,
  },
  {
    label: "Total Pay",
    columnSelector: "AMOUNT_STANDARD_PAY",
    dollarAmount: true,
  },
  {
    label: "Tips",
    columnSelector: "AMOUNT_TIPS",
    dollarAmount: true,
  },
];

const csvColumnsNoOvertime = [
  {
    label: "Total Hours",
    columnSelector: "AMOUNT_TOTAL_HOURS",
  },
  {
    label: "Total Pay",
    columnSelector: "AMOUNT_PAYED",
    dollarAmount: true,
  },
  {
    label: "Hours Scheduled",
    columnSelector: "AMOUNT_HOURS_SCHEDULED",
  },
  {
    label: "Tips Earned",
    columnSelector: "AMOUNT_TIPS",
    dollarAmount: true,
  },
  {
    label: "Average Wage",
    columnSelector: "AVERAGE_WAGE",
    dollarAmount: true,
  },
];

const csvColumnsOvertime = [
  {
    label: "Regular Hours",
    columnSelector: "AMOUNT_REGULAR_HOURS",
  },
  {
    label: "Regular Pay",
    columnSelector: "AMOUNT_REGULAR_GROSS",
    dollarAmount: true,
  },
  {
    label: "Overtime Hours",
    columnSelector: "AMOUNT_OVERTIME_HOURS",
  },
  {
    label: "Overtime Pay",
    columnSelector: "AMOUNT_OVERTIME_GROSS",
    dollarAmount: true,
  },
  {
    label: "Total Hours",
    columnSelector: "AMOUNT_TOTAL_HOURS",
  },
  {
    label: "Total Pay",
    columnSelector: "AMOUNT_STANDARD_PAY",
    dollarAmount: true,
  },
  {
    label: "Hours Scheduled",
    columnSelector: "AMOUNT_HOURS_SCHEDULED",
  },
  {
    label: "Tips Earned",
    columnSelector: "AMOUNT_TIPS",
    dollarAmount: true,
  },
  {
    label: "Average Wage",
    columnSelector: "AVERAGE_WAGE",
    dollarAmount: true,
  },
];

export default setupReduxConnection(["shop", "reporting"])(withRouter(TimeCardsReportPage));
