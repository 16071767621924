import React, {Component} from "react";
import ComboBox from "../../../../components/combobox";
import PropTypes from "prop-types";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NumberFormat from "react-number-format";

class InvoiceLineItemsForm extends Component {
  render() {
    let {products} = this.props;
    const {lines: linesValue} = this.props.options.values;

    return (
      <div>
        <button
          className={
            "flex w-28 ml-3 my-2 text-right text-sm font-bold text-indigo-500 hover:text-indigo-600 transition-all"
          }
          onClick={() => this.props.onAddLineItem()}
          type="button"
        >
          Add line item +
        </button>

        <table className="border-b w-full">
          <thead className="table-header-group">
            <tr className="bg-gray-200 text-sm font-semibold">
              <td id="name" className="pl-3 py-2 border-t border-b border-neutral-border">
                Name
              </td>

              <td id="price" className="pl-3 py-2 border border-neutral-border">
                Price
              </td>

              <td id="tax" className="pl-3 py-2 border border-neutral-border">
                Tax
              </td>

              <td id="quantity" className="pl-3 py-2 border border-neutral-border">
                Quantity
              </td>

              <td id="total" className="pl-3 py-2 border border-neutral-border">
                Total
              </td>

              <td className="py-2 border-t border-b border-neutral-border" />
            </tr>
          </thead>

          <tbody className="w-full">
            {linesValue
              .filter((line) => line.TYPE !== "TIP")
              .sort((a, b) => {
                return (a.product?.CART_SEQ || 0) - (b.product?.CART_SEQ || 0);
              })
              .map((line, index) => {
                const productIndex = products.findIndex((product) => product.ID === line.OBJECT_ID);

                const product = productIndex !== -1 ? products[productIndex] : null;

                if (product) {
                  products.splice(productIndex, 1);
                  products.unshift(product);
                }

                return (
                  <tr className="border-t">
                    <td headers="name" className="flex flex-row">
                      <ComboBox
                        key={"box_" + line.ID}
                        fixed
                        compact
                        extraHeight={0}
                        createLabel="Create One Time Item"
                        onCreate={(query) => {
                          return this.props.onCreateLineItem(query, line);
                        }}
                        onChange={(_, item) => {
                          return this.props.onChangeLineItem(item, line);
                        }}
                        data={products.map((item) => {
                          return {
                            label: item.NAME,
                            id: item.ID || item.NAME,
                            item,
                          };
                        })}
                        value={line.OBJECT_ID || line.NAME}
                        ignoreMargin
                        style={{
                          marginTop: 0,
                          minWidth: 100,
                          flex: 1,
                          borderWidth: 0,
                        }}
                        placeholder="Input a product"
                      />

                      <button
                        key={"submit_" + line.ID}
                        className={
                          "w-28 text-sm font-bold border-0 text-indigo-500 hover:text-indigo-600 transition-all"
                        }
                        onClick={async () => this.props.onCustomize(line)}
                        type={"button"}
                      >
                        Customize
                      </button>
                    </td>

                    <td
                      headers="price"
                      className="border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        type="number"
                        step={0.01}
                        disabled={!(product && product.ONE_TIME)}
                        name={"price"}
                        autoComplete="off"
                        key={"prod_" + line?.ID + product?.ID}
                        value={product && !product.ONE_TIME ? line?.PRICE : undefined}
                        data-event-off="mouseleave"
                        data-event="mouseenter click"
                        defaultValue={line?.PRICE ?? "0.00"}
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500"
                        onChange={(e) => {
                          this.props.onChangePrice(index, e.target.value);
                        }}
                      />
                    </td>

                    <td
                      headers="tax"
                      className="border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        id={"tax_" + line.ID}
                        type="text"
                        defaultValue={0}
                        value={line.PRICE_TAX}
                        disabled
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500"
                        placeholder="$0.00"
                      />
                    </td>

                    <td
                      headers="quantity"
                      className="border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        type="text"
                        id={"quantity_" + line.ID}
                        data-event-off="mouseleave"
                        data-event="mouseenter click"
                        defaultValue={line.QUANTITY || 1}
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500 "
                        onBlur={(e) => {
                          return this.props.onChangeQuantity(e, index);
                        }}
                        placeholder="Quantity"
                      />
                    </td>

                    <td
                      headers="total"
                      className=" border-l border-r-0 border-t-0 border-b-0 border-neutral-border"
                    >
                      <input
                        id={"total_" + line.ID}
                        type="text"
                        value={toDollars(
                          decimalToDollars(line.AMOUNT) + decimalToDollars(line.AMOUNT_TAX),
                          true
                        )}
                        disabled
                        className="w-full text-sm focus:ring-2 border-0 focus:ring-indigo-500"
                        placeholder="$0.00"
                      />
                    </td>

                    <td className="justify-center  border-l border-r-0 border-t-0 border-b-0 border-neutral-border hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500">
                      <button
                        type="button"
                        className="w-full flex justify-center items-center text-indigo-500 hover:text-indigo-600  px-4 py-2 bg-white text-base font-medium  sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => {
                          return this.props.onDeleteLineItem(index);
                        }}
                      >
                        <FontAwesomeIcon icon="trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
}

InvoiceLineItemsForm.propTypes = {
  options: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
  onAddLineItem: PropTypes.func.isRequired,
  onCreateLineItem: PropTypes.func.isRequired,
  onChangeLineItem: PropTypes.func.isRequired,
  onCustomize: PropTypes.func.isRequired,
  onChangeQuantity: PropTypes.func.isRequired,
  onChangePrice: PropTypes.func.isRequired,
  onDeleteLineItem: PropTypes.func.isRequired,
};

export default InvoiceLineItemsForm;
