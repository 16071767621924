import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import {withRouter} from "../../utils/navigation";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";

class EmployeeTable extends Component {
  render() {
    const {data, className} = this.props;

    return (
      <Table
        {...this.props}
        data={data}
        className={className}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "View",
            onClick: (row) => this.props.router.navigate("/employee/" + row.UNIQUE_ID),
          },
        ]}
        columns={[
          {
            minWidth: 250,
            value: "FULL_NAME",
            label: "Name",
            format: (value, row) => {
              return (
                <div className="flex items-center">
                  <div className="h-10 w-10 flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src={getObjectImage(
                        row,
                        "LOGO",
                        "https://dripos-assets.s3.amazonaws.com/default-product-specials.jpg"
                      )}
                      alt=""
                    />
                  </div>

                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">{row.FULL_NAME}</div>

                    <div className="text-sm text-gray-500">
                      {row.ROLES?.map((item) => item.ROLE_NAME).join(", ")}
                    </div>
                  </div>
                </div>
              );
            },
          },
          {
            width: 1,
            value: "EMAIL",
            label: "Email",
            format: (value) => {
              return getFormattedPhone(value);
            },
          },
          {
            width: 1,
            value: "PHONE",
            label: "Phone",
            format: (value) => {
              return getFormattedPhone(value);
            },
          },
          {
            width: 1,
            value: "LOCATION_NAME",
            label: "Location",
            format: (value) => {
              return value;
            },
          },
          {
            width: 1,
            value: "DATE_STARTED",
            label: "Date Started",
            mobile: "lg:table-cell",
            format: (value) => {
              return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
            },
          },
        ]}
      />
    );
  }
}

EmployeeTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default withRouter(EmployeeTable);
