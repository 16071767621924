import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class RedemptionsPage extends Component {
  state = {redeems: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let redeems = await request("partner/redeems", "GET");

    this.setState({redeems});
  }

  render() {
    const {redeems} = this.state;

    if (redeems === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings label="Item Redemptions" description="View every item that was redeemed with loyalty" />

        <Table
          pagination
          data={redeems}
          className="mt-4"
          actionButtons={[
            {
              label: "View Patron",
              onClick: (row) => this.props.router.navigate("/patron/" + row.PATRON_ID),
            },
          ]}
          columns={[
            {
              value: "FULL_NAME",
              label: "Customer Name",
            },
            {
              value: "PRODUCT_NAME",
              label: "Product",
            },
            {
              width: 1,
              value: "PRICE",
              label: "Cost",
              format: (cost) => "$" + toDollars(cost),
            },
            {
              width: 1,
              value: "DATE_REDEEMED",
              label: "Date Redeemed",
              format: (value, _) => {
                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default withRouter(RedemptionsPage);
