import React, {useState, useEffect, useRef, Fragment} from "react";
import {Menu, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/solid";
import {classNames} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FunctionalCheckbox from "./functional-checkbox";
import {parseIdArray} from "../../utils/util";

const FunctionalFilter = ({
  initialValues = [],
  data,
  plural,
  initialText,
  noMl,
  selectAll,
  onChange,
  className,
}) => {
  const getLabel = () => {
    let label = "";
    if (values.length === 0) {
      label = `No ${plural}`;
    } else if (values.length === data.length) {
      label = `All ${plural}`;
    } else if (values.length === 1) {
      let selectedObj = data.find((item) => item.value === values[0]);
      label = selectedObj?.name || initialText;
    } else {
      label = `${values.length} ${plural}`;
    }
    return label;
  };

  const [values, setValues] = useState(initialValues);
  const [allChecked, setAllChecked] = useState(true);

  return (
    <Menu as="div" className={classNames(`relative inline-block text-left ${noMl ? "" : "ml-5"}`, className)}>
      <div className={className}>
        <Menu.Button className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50">
          {getLabel()}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        unmount={false}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          unmount={false}
          className="origin-top-right absolute text-sm w-64 left-0 mt-2 shadow-lg rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-10"
        >
          <div className="p-2">
            {selectAll && (
              <>
                <div className="mx-1 my-1">
                  <FunctionalCheckbox
                    initialValue={true}
                    label="Select All"
                    checked={allChecked}
                    onChange={() => {
                      const updatedValues = allChecked ? [] : parseIdArray(data, "value");
                      setValues(updatedValues);
                      setAllChecked((allChecked) => !allChecked);
                      onChange(updatedValues);
                    }}
                  />
                </div>

                <div className="my-2 h-0.5 w-fill bg-gray-300"></div>
              </>
            )}

            {data.map((item) => (
              <div key={item.value} className="mx-1 my-1">
                <FunctionalCheckbox
                  initialValue={true}
                  label={item.name}
                  checked={(selectAll && allChecked) || values.includes(item.value)}
                  onChange={() => {
                    if (allChecked) setAllChecked((allChecked) => !allChecked);
                    let selectedIndex = values.findIndex((currItem) => currItem === item.value);

                    if (selectedIndex === -1) {
                      values.push(item.value);
                    } else {
                      values.splice(selectedIndex, 1);
                    }

                    setValues(values);
                    onChange(values);
                  }}
                />
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default FunctionalFilter;
