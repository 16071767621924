import {useTranslation} from "react-i18next";
import FormRow from "../../../../../../components/form-row";
import {FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";
import FormStateSelect from "../../../../../../components/form-state-select";

export const EmployeeInfoStep = ({formik, w4 = false}) => {
  const {t, i18n} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-info-page." + path;
  };

  return (
    <form className={"flex flex-col gap-y-4 my-8"}>
      <FormRow>
        <FormInput name={"firstName"} label={t(getI18NextKey("firstName.label"))} flex options={formik} />

        <FormInput
          name={"middleInitial"}
          label={t(getI18NextKey("middleInitial.label"))}
          options={formik}
          flex
        />

        <FormInput name={"lastName"} label={t(getI18NextKey("lastName.label"))} flex options={formik} />
      </FormRow>

      <FormInput
        name={"otherLastNames"}
        label={t(getI18NextKey("otherLastNamesUsed.label"))}
        options={formik}
      />

      <FormInput name={"address"} label={t(getI18NextKey("address.label"))} options={formik} />

      <FormInput name={"aptNumber"} label={t(getI18NextKey("aptNumber.label"))} options={formik} />

      <FormRow>
        <FormInput name={"cityOrTown"} label={t(getI18NextKey("city.label"))} flex options={formik} />

        <FormStateSelect name={"state"} label={t(getI18NextKey("state.label"))} flex options={formik} />

        <FormInput name={"zip"} label={t(getI18NextKey("zip.label"))} flex options={formik} />
      </FormRow>
    </form>
  );
};
