import React, {Component} from "react";
import {request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {
  Button,
  Card,
  Loading,
  PageHeadings,
  Tab,
  TwoColumnList,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import CustomerModal from "../../../modals/operations/invoices/contact-modal";
import InvoicesTable from "../../../tables/operations/invoices/invoices-table";
import ContactPaymentModal from "../../../modals/operations/invoices/contact-payment-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCcAmex, faCcDiscover, faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import moment from "moment-timezone";
import {PlusIcon} from "@heroicons/react/solid";
import {faBuildingColumns} from "@fortawesome/pro-solid-svg-icons";

function BrandFontIcon(brand) {
  if (brand === "amex") {
    return faCcAmex;
  } else if (brand === "visa") {
    return faCcVisa;
  } else if (brand === "mastercard") {
    return faCcMastercard;
  } else if (brand === "discover") {
    return faCcDiscover;
  }
}

class ContactPage extends Component {
  state = {customer: null, methods: null, invoices: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let customer = await request("contacts/" + id, "GET", null);

    customer?.INVOICES?.reverse();

    this.setState({
      customer,
      invoices: customer.INVOICES,
      methods: customer.PAYMENT_METHODS,
    });
  }

  deleteContact() {
    const {customer} = this.state;

    showLoadingConfirmAlert("Delete Contact", "Are you sure you want to delete this contact?").then(
      async (close) => {
        await request("contacts/" + customer.ID, "DELETE", {});

        close();

        this.props.router.navigate("/contacts");
      }
    );
  }

  detachPayment(payment) {
    const {customer, methods} = this.state;

    let name = "Payment Method";
    if (payment.card) {
      name = payment.card.brand.capitalize() + " Card";
    } else if (payment.us_bank_account) {
      name = payment.us_bank_account.bank_name;
    }

    showLoadingConfirmAlert("Detach " + name, "Are you sure you want to delete this payment method?").then(
      async (close) => {
        await request("contacts/" + customer.ID + "/method/" + payment.id, "DELETE", {});

        close();

        const index = methods.findIndex((item) => {
          return item.id === payment.id;
        });

        if (index !== -1) {
          methods.splice(index, 1);
        }

        this.setState({methods});
      }
    );
  }

  render() {
    const {customer, invoices, methods} = this.state;

    if (customer === null) {
      return <Loading />;
    }

    return (
      <div>
        <ContactPaymentModal
          addState={(card) => this.setState({methods: [...methods, card]})}
          ref={(e) => (this.paymentModal = e)}
        />

        <PageHeadings
          label={customer.FIRST_NAME + " " + customer.LAST_NAME}
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Delete Customer",
                      onClick: this.deleteContact.bind(this),
                    },
                  ],
                },
              ],
            },
          ]}
          breadcrumbs={[
            {label: "Invoices", url: "/invoices"},
            {label: "Customer List", url: "/contacts"},
          ]}
        />

        <CustomerModal
          updateState={(customer) => this.setState({customer})}
          ref={(e) => (this.customerModal = e)}
        />

        <TwoColumnList
          label="Contact Details"
          description="Details about the contact and the information about them"
          data={[
            {
              label: "Name",
              value: customer.FIRST_NAME + " " + customer.LAST_NAME,
            },
            {
              label: "Company",
              value: customer.COMPANY,
            },
            {
              label: "Phone",
              value: customer.PHONE,
              type: "phone",
            },
            {
              label: "Email",
              value: customer.EMAIL,
            },
            {
              label: "Notes",
              value: customer.NOTES,
              span: true,
            },
          ]}
          buttons={[
            {
              label: "Edit Information",
              onClick: () => this.customerModal.open(customer),
            },
          ]}
        />

        <Tab
          data={[
            {id: "invoices", label: "Invoices"},
            {id: "methods", label: "Payment Methods"},
          ]}
        >
          {(id) => {
            if (id === "invoices") {
              return (
                <Card
                  key={id}
                  label="Invoices"
                  button={{
                    label: "Add Invoice",
                    onClick: () => this.props.router.navigate("/invoice?contact=" + customer.UNIQUE_ID),
                  }}
                >
                  <InvoicesTable data={invoices} />
                </Card>
              );
            }

            if (id === "methods") {
              return (
                <Card
                  key={id}
                  label="Payment Methods"
                  description="The payment methods used for recurring and auto paying invoices"
                  button={{
                    label: "Add Method",
                    onClick: () => {
                      this.paymentModal.open(customer);
                    },
                  }}
                >
                  <div className="divide-y divide-gray-200">
                    {methods?.length > 0 ? (
                      methods.map((method) => {
                        const {card, us_bank_account, created} = method;

                        if (us_bank_account) {
                          console.log(method);

                          return (
                            <div className="bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                              <h4 className="sr-only">{us_bank_account.bank_name.capitalize()}</h4>

                              <div className="sm:flex sm:items-center">
                                <FontAwesomeIcon
                                  className="h-7 w-auto text-indigo-600"
                                  icon={faBuildingColumns}
                                  size="2x"
                                />

                                <div className="mt-3 sm:mt-0 sm:ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    Ending with {us_bank_account.last4}
                                  </div>
                                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                    <div>Account Type {us_bank_account.account_type}</div>
                                    <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                                      &middot;
                                    </span>

                                    <div className="mt-1 sm:mt-0">
                                      Last updated on {moment(created * 1000).format("DD MMM YYYY")}
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                                <Button
                                  onClick={() => this.detachPayment(method)}
                                  label="Remove"
                                  type="gray"
                                />
                              </div>
                            </div>
                          );
                        }

                        return (
                          <div className="bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
                            <h4 className="sr-only">{card.brand.capitalize()}</h4>

                            <div className="sm:flex sm:items-center">
                              {card && (
                                <FontAwesomeIcon
                                  className="h-7 w-auto text-indigo-600"
                                  icon={BrandFontIcon(card.brand)}
                                  size="2x"
                                />
                              )}

                              <div className="mt-3 sm:mt-0 sm:ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  Ending with {card.last4}
                                </div>
                                <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                                  <div>
                                    Expires {("" + card.exp_month).padStart(2, "0")}/
                                    {("" + card.exp_year).slice(2)}
                                  </div>
                                  <span className="hidden sm:mx-2 sm:inline" aria-hidden="true">
                                    &middot;
                                  </span>

                                  <div className="mt-1 sm:mt-0">
                                    Last updated on {moment(created * 1000).format("DD MMM YYYY")}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                              <Button onClick={() => this.detachPayment(method)} label="Remove" type="gray" />
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="py-6 text-center">
                        <h3 className="text-sm font-medium text-gray-900">No Payment Methods</h3>

                        <p className="mt-1 text-sm text-gray-500">
                          Get started by creating adding a new method.
                        </p>

                        <div className="mt-6">
                          <button
                            type="button"
                            onClick={() => this.paymentModal.open(customer)}
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                            New Payment Method
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Card>
              );
            }

            return <div>hi</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(ContactPage);
