import React, {Component} from "react";
import {FormBoolean, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import PropTypes from "prop-types";
import * as Yup from "yup";
import {
  decimalToDollars,
  randomString,
  toDollars,
} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import moment from "moment-timezone";
import RolesDropdown from "../../dropdowns/team/roles-dropdown";
import {showLoadingConfirmAlert} from "../../utils/alert-helper";
import FormDateTimeSelect from "../../components/form-date-time-select";

class PayRateModal extends Component {
  state = {rate: null, employee: null};

  open(rate = null, employee = null) {
    this.setState({rate, employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async saveRate({name, type, amount, start, end, backdate, backdateDate, setDefault}) {
    const {role, soft, employee} = this.props;
    const {rate} = this.state;

    const payRatePayload = {
      NAME: name,
      TYPE: type === "-1" ? null : type,
      AMOUNT: decimalToDollars(amount),
      CONTENT: null,
      ROLE_ID: role ? role.ID : null,
      BACKDATE: parseInt(backdate),
      BACKDATE_DATE: backdateDate,
    };

    if (employee) {
      payRatePayload.ROLE_ID = rate.ROLE_ID ? rate.ROLE_ID : rate.ROLE_ID_ALT;
    }

    if (type === "TIME_RANGE") {
      const startOfDay = moment().startOf("day");

      const dateStart = moment(start, "hh:mm").diff(startOfDay, "minutes");
      const dateEnd = moment(end, "hh:mm").diff(startOfDay, "minutes");

      payRatePayload.CONTENT = Math.abs(dateStart) + "|" + Math.abs(dateEnd);
    }

    if (soft) {
      this.props.updateState(rate.ID, payRatePayload);
      return this.modal.close();
    }

    let serverPayRate = await request("pay/rates/" + rate.ID, "PATCH", payRatePayload);

    const serverEmployeeRate = serverPayRate?.EMPLOYEES.find(({EMPLOYEE_ID}) => EMPLOYEE_ID === employee.ID);

    if (parseInt(setDefault) && serverEmployeeRate) {
      await request("pay/rates/employee/" + employee.ID + "/default", "POST", {
        EMPLOYEE_RATE_ID: serverEmployeeRate.ID,
      });
    }

    this.props.updateState(serverPayRate);
    this.modal.close();
  }

  async createRate({name, type, amount, start, end, role: roleInput, backdate, backdateDate, setDefault}) {
    const {role, soft, employee} = this.props;

    const payRatePayload = {
      NAME: name,
      TYPE: type === "-1" ? null : type,
      AMOUNT: decimalToDollars(amount),
      CONTENT: null,
      ROLE_ID: role ? role.ID : roleInput,
      EMPLOYEE_ID: employee ? employee.ID : null,
      ADD_TO_ROLE: !employee,
      BACKDATE: parseInt(backdate),
      BACKDATE_DATE: backdateDate,
    };

    if (type === "TIME_RANGE") {
      const startOfDay = moment().startOf("day");

      const dateStart = moment(start, "hh:mm").diff(startOfDay, "minutes");
      const dateEnd = moment(end, "hh:mm").diff(startOfDay, "minutes");

      payRatePayload.CONTENT = Math.abs(dateStart) + "|" + Math.abs(dateEnd);
    }

    if (soft) {
      this.props.addState({ID: randomString(12), ...payRatePayload});
      return this.modal.close();
    }

    let serverPayRate = await request("pay/rates", "POST", payRatePayload);

    const serverEmployeeRate =
      employee && serverPayRate?.EMPLOYEES?.find(({EMPLOYEE_ID}) => EMPLOYEE_ID === employee.ID);

    if (parseInt(setDefault) && serverEmployeeRate) {
      await request("pay/rates/employee/" + employee.ID + "/default", "POST", {
        EMPLOYEE_RATE_ID: serverEmployeeRate.ID,
      });
    }

    this.props.addState(serverPayRate);
    this.modal.close();
  }

  async deleteRate() {
    const {rate} = this.state;
    const {soft, rates = []} = this.props;

    const cleanedRoleName = rate.ROLE_NAME ? rate.ROLE_NAME + " role." : "role.";

    const confirmMessage =
      rates.length > 1
        ? "Are you sure you want to delete this pay rate? Performing this action will affect all employees who have the " +
          cleanedRoleName
        : "There are no other pay rates attached to this role or employee. Please confirm that you want to delete this pay rate. Performing this action will affect all employees who have the " +
          cleanedRoleName;

    showLoadingConfirmAlert("Delete Rate", confirmMessage)
      .then(async (close) => {
        if (soft) {
          this.props.updateState(rate.ID);
          return this.modal.close();
        }

        await request("pay/rates/" + rate.ID, "DELETE", {});

        close();

        this.props.updateState(rate.ID);
        this.modal.close();
      })
      .catch((err) => {
        this.modal.close();
      });
  }

  renderContent(formikOptions) {
    const {type} = formikOptions.values;

    if (type === "TIME_RANGE") {
      return (
        <div>
          <FormInput type="time" name="start" label="Range Start" options={formikOptions} />

          <FormInput name="end" type="time" label="Range End" options={formikOptions} />
        </div>
      );
    }

    return <div />;
  }

  render() {
    const {rate, employee} = this.state;

    const {showPTO} = this.props;

    const initialValues = {
      name: rate?.NAME ?? "",
      amount: rate ? toDollars(rate.AMOUNT) : "",
      type: rate?.TYPE ?? -1,
      start: "",
      end: "",
      role: null,
      backdate: "0",
      backdateDate: null,
      setDefault: rate?.IS_DEFAULT,
    };

    if (rate && rate.TYPE === "TIME_RANGE") {
      const [start, end] = rate.CONTENT.split("|");

      initialValues.end = moment().startOf("day").add(parseInt(end), "minutes").format("hh:mm");
      initialValues.start = moment().startOf("day").add(parseInt(start), "minutes").format("hh:mm");
    }

    return (
      <Modal
        tooltip={{
          data: "You can add different pay rates to roles that can be assigned to employees with that role",
        }}
        buttonLabel="Save"
        label={rate ? "Edit Pay Rate" : "Add Pay Rate"}
        formikOnClick={() => this.formikRef}
        deleteOnClick={this.deleteRate.bind(this)}
        deleteLabel={rate && "Delete"}
        deleteOnRight={true}
        ref={(e) => (this.modal = e)}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          innerRef={(e) => (this.formikRef = e)}
          onSubmit={rate ? this.saveRate.bind(this) : this.createRate.bind(this)}
          validationSchema={Yup.lazy(({type, backdate}) => {
            const defaultSchema = {
              name: Yup.string().required("Pay rate name is required"),
              amount: Yup.string().required("Pay rate amount is required"),
            };

            if (type === "TIME_RANGE") {
              defaultSchema.start = Yup.string().required("Time range start is required");
              defaultSchema.end = Yup.string().required("Time range end is required");
            }

            if (employee && !rate) {
              defaultSchema.role = Yup.string().nullable().required("Role is required.");
            }

            if (backdate === "1") {
              defaultSchema.backdateDate = Yup.number()
                .typeError("Please enter a date")
                .required("Please enter a date");
            }

            return Yup.object(defaultSchema);
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            const {backdate, type, amount, start, end} = values;
            const {CONTENT: content} = rate ?? {};

            let showBackDate = false;

            if (!rate) {
              showBackDate = true;
            }

            if (rate) {
              if (rate?.TYPE !== type) {
                showBackDate = true;
              } else if (toDollars(rate?.AMOUNT) !== toDollars(amount * 100)) {
                showBackDate = true;
              } else {
                if (type === "TIME_RANGE") {
                  const [initialStart, initialEnd] = (content ?? "").split("|");

                  if (initialStart && initialEnd) {
                    const startOfDay = moment().startOf("day");
                    const startDiff = moment(start, "hh:mm").diff(startOfDay, "minutes");
                    const endDiff = moment(end, "hh:mm").diff(startOfDay, "minutes");

                    showBackDate = startDiff !== parseInt(initialStart) || endDiff !== parseInt(initialEnd);
                  }
                }
              }
            }

            return (
              <form onSubmit={handleSubmit}>
                {employee && !rate && (
                  <RolesDropdown
                    name="role"
                    tooltip={
                      "Even though this is an employee specific pay rate, a role still must be attached since employees can have many roles."
                    }
                    options={formikOptions}
                    ref={(e) => (this.roleRef = e)}
                    includeOnly={employee.ROLES.map((item) => item.ROLE_ID)}
                  />
                )}

                <FormInput
                  label="Rate name"
                  name="name"
                  placeholder="Senior Barista Rate"
                  options={formikOptions}
                />

                <FormSelect
                  tooltip={{
                    data: [
                      {
                        label: "Types",
                        data: "There two different types of rates: hourly and time range.",
                      },
                      {
                        label: "Hourly",
                        data: "Employees with roles of this rate type will be paid by the hour.",
                      },
                      {
                        label: "Time Range",
                        data: "This rate will only be applied to employees during the specified times.",
                      },
                    ],
                  }}
                  label="Rate type"
                  name="type"
                  options={formikOptions}
                  data={[
                    {label: "Hourly", value: "-1"}, // this will get replaced with null
                    {label: "Time Range", value: "TIME_RANGE"},
                  ]}
                />

                <FormInput label="Pay Amount" name="amount" placeholder={"15.50"} options={formikOptions} />

                {this.renderContent(formikOptions)}

                {showBackDate && (
                  <FormBoolean
                    label="Backdate Pay"
                    name="backdate"
                    options={formikOptions}
                    tooltip={
                      "Recalculates existing time card pay based on pay rate changes, starting from a specified date"
                    }
                  />
                )}

                {showBackDate && backdate === "1" && (
                  <FormDateTimeSelect
                    label={"Backdate Start Date"}
                    name="backdateDate"
                    hideTime
                    flex
                    options={formikOptions}
                  />
                )}

                {showPTO && (
                  <FormBoolean label="Make Default PTO Rate" name="setDefault" options={formikOptions} />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PayRateModal.propTypes = {
  addState: PropTypes.func,
  updateState: PropTypes.func,

  role: PropTypes.object,

  soft: PropTypes.bool,

  rates: PropTypes.array.isRequired,
};

export default PayRateModal;
