import React, {Component} from "react";
import {TwoColumnList} from "@frostbyte-technologies/frostbyte-tailwind";
import {setupReduxConnection} from "../../../redux";
import TimeCardAutoApprovalModal from "../../../modals/shop/shop-settings/time-card-auto-approval-modal";
import OvertimeTabModal from "../../../modals/shop/shop-settings/overtime-modal";
import {DAYS} from "@frostbyte-technologies/frostbyte-core/dist/helpers/location-helper";
import TimeCardApproveAllModal from "../../../modals/shop/shop-settings/time-card-approve-all-modal";
import BreaksSettingsModal from "../../../modals/shop/shop-settings/breaks-settings-modal";
import TimeCardNotificationModal from "../../../modals/shop/shop-settings/time-card-notification-modal";
import TimeOffRequestNoticeModal from "../../../modals/shop/shop-settings/time-off-request-notice";

class TimeCardAutoApprovalTab extends Component {
  render() {
    const {STATE, PAYROLL_GROUP_ID} = this.props.shop.location;

    const {payrollGroup} = this.props.shop;

    const isLivePayroll = PAYROLL_GROUP_ID && payrollGroup.STATUS === "LIVE";

    const {
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_BEFORE_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_IN_AFTER_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_BEFORE_BUFFER,
      TIMESHEET_AUTO_APPROVE_CLOCK_OUT_AFTER_BUFFER,
      TIMESHEET_AUTO_APPROVE,
      OVERTIME_WORKWEEK_START_ISODAY,
      TIME_CARDS_CALCULATE_OVERTIME,
      TIME_CARD_NO_PAY_EARNED,
      SHOW_BREAKS_OUT_OF_COMPLIANCE,
      FLAG_BREAK_BEFORE,
      FLAG_BREAK_AFTER,
      TIME_CLOCK_NOTIFICATION_THRESHOLD,
      TIME_OFF_REQUEST_NOTICE,
    } = this.props.shop.settings;

    let overtimeSettings = [];

    if (TIME_CARDS_CALCULATE_OVERTIME === "1") {
      overtimeSettings.push(
        ...[
          {
            label: "Workweek Start",
            value:
              DAYS[OVERTIME_WORKWEEK_START_ISODAY - 1].charAt(0) +
              DAYS[OVERTIME_WORKWEEK_START_ISODAY - 1].toLowerCase().substring(1),
            tooltip: {
              data: "First day of workweek in overtime calculations.",
            },
          },
        ]
      );
    }

    let autoApprovalColumns = [
      {
        label: "Enable Time Card Auto-Approval",
        value: TIMESHEET_AUTO_APPROVE,
        type: "bool",
      },
    ];

    if (TIMESHEET_AUTO_APPROVE === "1") {
      autoApprovalColumns = autoApprovalColumns.concat([
        {
          label: "Clock-In Before Shift Start",
          value: `${TIMESHEET_AUTO_APPROVE_CLOCK_IN_BEFORE_BUFFER} Minutes`,
        },
        {
          label: "Clock-In After Shift Start",
          value: `${TIMESHEET_AUTO_APPROVE_CLOCK_IN_AFTER_BUFFER} Minutes`,
        },
        {
          label: "Clock-Out Before Shift End",
          value: `${TIMESHEET_AUTO_APPROVE_CLOCK_OUT_BEFORE_BUFFER} Minutes`,
        },
        {
          label: "Clock-Out After Shift End",
          value: `${TIMESHEET_AUTO_APPROVE_CLOCK_OUT_AFTER_BUFFER} Minutes`,
        },
      ]);
    }

    return (
      <div>
        <TimeCardApproveAllModal ref={(e) => (this.timeCardApproveAllModal = e)} />

        <TimeCardAutoApprovalModal ref={(e) => (this.timeCardAutoApprovalModal = e)} />

        <OvertimeTabModal ref={(e) => (this.overtimeTabModal = e)} />

        <BreaksSettingsModal ref={(e) => (this.breaksSettingsModal = e)} />

        <TimeOffRequestNoticeModal ref={(e) => (this.timeOffRequestNoticeModal = e)} />

        <TimeCardNotificationModal ref={(e) => (this.timeCardNotificationModal = e)} />

        {!isLivePayroll && (
          <TwoColumnList
            label="Overtime"
            description="Overtime is displayed on Time Cards Report and Time Cards page"
            data={[
              {
                label: "Calculate Overtime with Dripos",
                value: TIME_CARDS_CALCULATE_OVERTIME,
                type: "bool",
              },
              ...overtimeSettings,
            ]}
            buttons={[
              {
                label: "Edit",
                onClick: () => this.overtimeTabModal.open(),
              },
            ]}
          />
        )}

        <TwoColumnList
          label="Time Card Auto-Approval"
          description="Allowed minute differences from clock in/out to shift start/end for time cards to be auto-approved"
          data={autoApprovalColumns}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.timeCardAutoApprovalModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Time Off Request Notice"
          description="Minimum number of days required before the start date of a time off request"
          data={[
            {
              label: "Number of Days",
              value: TIME_OFF_REQUEST_NOTICE ?? 0,
              type: "number",
              tooltip:
                "This setting prevents employees from requesting time off within a specified number of days. However, you can override this restriction by submitting time off on their behalf.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.timeOffRequestNoticeModal.open(),
            },
          ]}
        />

        <TwoColumnList
          label="Approve All Settings"
          description="Adjust which time cards are approved on approve all"
          data={[
            {
              label: "Approve no pay time cards",
              value: TIME_CARD_NO_PAY_EARNED,
              type: "bool",
              tooltip:
                "If enabled, time cards with no amount paid will be approved when approving all in the approval center.",
            },
          ]}
          buttons={[
            {
              label: "Edit",
              onClick: () => this.timeCardApproveAllModal.open(),
            },
          ]}
        />

        {STATE === "NY" && (
          <TwoColumnList
            label={"Breaks Settings"}
            data={[
              {
                label: "Mark Time Cards as Out of Compliance",
                value: SHOW_BREAKS_OUT_OF_COMPLIANCE,
                type: "bool",
                tooltip: `If enabled, the Time Cards Report will show which time cards are not in compliance with ${STATE}'s Unpaid Breaks laws`,
              },
              {
                label: "Flag Time Card if Break is Under",
                value: `${FLAG_BREAK_BEFORE ? FLAG_BREAK_BEFORE + " Minutes" : "-"}`,
                tooltip:
                  "Flag time card if break is short by a certain amount of minutes, leave empty to disable",
              },
              {
                label: "Flag Time Card if Break is Over",
                value: `${FLAG_BREAK_AFTER ? FLAG_BREAK_AFTER + " Minutes" : "-"}`,
                tooltip:
                  "Flag time card if break is over by a certain amount of minutes, leave empty to disable",
              },
            ]}
            buttons={[
              {
                label: "Edit",
                onClick: () => this.breaksSettingsModal.open(),
              },
            ]}
          />
        )}
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(TimeCardAutoApprovalTab);
