import {parseIdDict, randomString, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import {Button, FormInput, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {CUSTOMIZATION_TYPES} from "@frostbyte-technologies/frostbyte-tickets/dist/constants/product-constants";
import {Formik} from "formik";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import LoadingSpinner from "../../../../components/loading-spinner";
import IngredientDropdown from "../../../../dropdowns/operations/recipes/ingredient-dropdown";
import UnitDropdown from "../../../../dropdowns/operations/recipes/unit-dropdown";
import {setProduct, updateProducts} from "../../../../redux/menu";
import {showConfirmAlert} from "../../../../utils/alert-helper";
import {showErrorNotification} from "../../../../utils/notification-helper";
import {RecipeRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";
import {deepClone} from "../../../../utils/util";
import {Box, ReadOnlyTable, Row} from "../../../../v2/components/shared";

const OptionButton = ({option, setValue, enabled}) => {
  const {ID, NAME} = option;
  return (
    <Button
      type={enabled ? "button" : "gray"}
      className="mr-4 mt-2 w-32 h-16 items-center"
      label={NAME}
      onClick={() => setValue(ID)}
    />
  );
};

const recipeColumns = (unitDict) => [
  {
    key: "VARIANT_OPTION",
    label: "Variant Name",
    format: (it) => it?.NAME ?? "All Variants",
  },
  {key: "OPTION", label: "Modifier Option", format: (it) => it?.NAME ?? "All Options"},
  {
    key: "NAME",
    label: "Ingredient",
  },
  {
    key: "RECIPE_COST",
    label: "Quantity",
    formatRow: (row) => `${row.RECIPE_COST} ${unitDict[row.UNIT_ID]?.NAME}`,
  },
];

export const RemoveRecipeView = ({unitDict, product, className = "", setNewRecipes, newRecipes}) => {
  const dispatch = useDispatch();

  // Removing the columns we don't need if there are no variants and no modifiers
  const rColumns =
    product.CUSTOMIZATIONS.length === 0
      ? recipeColumns(unitDict).slice(2)
      : product.CUSTOMIZATIONS.filter((it) => it.TYPE === 5).length === 0
      ? recipeColumns(unitDict).slice(1)
      : recipeColumns(unitDict);

  const columns = [
    ...rColumns,
    {
      key: "Actions",
      formatRow: (row) => {
        return row.PRODUCT_ID && row.ID ? (
          <div
            className="text-blue-600 cursor-pointer"
            onClick={() => {
              newRecipes.find((it) => it.RANDOM_ID === row.RANDOM_ID).API_TYPE = "REMOVE";
              product.RECIPES.find((it) => it.RANDOM_ID === row.RANDOM_ID).API_TYPE = "REMOVE";
              dispatch(setProduct(product));
              setNewRecipes(newRecipes);
            }}
          >
            Remove {row.NAME}
          </div>
        ) : (
          <div> Modifier Recipe </div>
        );
      },
    },
  ];

  return (
    <ReadOnlyTable
      className={className}
      columns={columns}
      data={product.RECIPES.filter((r) => r.API_TYPE !== "REMOVE")}
    />
  );
};

const AddIngredientForm = ({
  selectedOptionId,
  activeOption,
  selectedVariantId,
  activeVariant,
  product,
  unitDict,
  setNewRecipes,
  newRecipes,
}) => {
  const [ingredient, setIngredient] = useState({});
  const dispatch = useDispatch();

  return (
    <Formik
      validationSchema={Yup.object().shape({
        ingredient: Yup.string().nullable().required("Please select an ingredient"),
        quantity: Yup.number().positive().nullable().required("Please enter a number greater than 0"),
        unit: Yup.string().nullable().required("Please select a unit"),
      })}
      initialValues={{
        ingredient: null,
        quantity: null,
        unit: null,
      }}
    >
      {(formikOptions) => {
        const {values, setFieldValue} = formikOptions;

        return (
          <Row>
            <IngredientDropdown
              name="ingredient"
              placeholder="Select an Ingredient"
              options={formikOptions}
              onChangeSoft={({ingredient}) => {
                setIngredient(ingredient);
                setFieldValue("unit", ingredient.UNIT_ID);
              }}
              className="pr-1.5"
            />

            <FormInput placeholder="Quantity" name="quantity" options={formikOptions} className="px-1.5" />

            <UnitDropdown
              name="unit"
              placeholder="Unit of Measurement"
              ingredient={ingredient}
              options={formikOptions}
              unit={ingredient?.UNIT_ID}
              className="pl-1.5 pr-3"
            />

            {ingredient && values.unit && (
              <Button
                label="Add"
                className="w-32 h-9.5 my-3"
                onClick={() => {
                  let quantity = parseFloat(values.quantity);
                  if (values.unit !== ingredient.UNIT_ID) {
                    const unit = unitDict[values.unit];
                    const conversion = unit.CONVERSIONS.find(
                      (conversion) => conversion.TO_UNIT === ingredient.UNIT_ID
                    );
                    quantity *= conversion?.RATE;
                  }
                  const RANDOM_ID = randomString(24);

                  newRecipes.unshift({
                    RANDOM_ID,
                    API_TYPE: "ADDITION",
                    RECIPE_COST: quantity,
                    INGREDIENT_ID: ingredient?.ID,
                    OPTION_ID: selectedOptionId,
                    VARIANT_OPTION_ID: selectedVariantId,
                    PRODUCT_ID: product.ID,
                    QUANTITY: quantity,
                  });

                  const newRenderableRecipe = {
                    ...ingredient,
                    RANDOM_ID,
                    QUANTITY: quantity,
                    RECIPE_COST: quantity,
                    OPTION_ID: selectedOptionId,
                    VARIANT_OPTION_ID: selectedVariantId,
                    PRODUCT_ID: product.ID,
                    OPTION: activeOption,
                    VARIANT_OPTION: activeVariant,
                  };

                  product.RECIPES.unshift(newRenderableRecipe);

                  setNewRecipes(newRecipes);
                  dispatch(setProduct(product));
                }}
              />
            )}
          </Row>
        );
      }}
    </Formik>
  );
};

function safeParseInt(value, radix = 10) {
  const parsed = parseInt(value, radix);
  return isNaN(parsed) ? null : parsed;
}

async function saveRecipes(product, newRecipes, dispatch) {
  console.log(product, newRecipes);
  return RecipeRequests.attachRecipe(product.ID, {
    RECIPES: newRecipes
      .filter((item) => item.API_TYPE !== "REMOVE" && item.PRODUCT_ID === product.ID)
      .map((item) => {
        if (item.API_TYPE === "ADDITION") {
          item.ID = null;
        }
        delete item.API_TYPE;
        return item;
      }),
  })
    .then(() => dispatch(updateProducts()))
    .catch((err) => {
      showErrorNotification("Error Saving Recipes", "We had an error saving your recipes. Please try again.");
    });
}

const ProductRecipePage = ({closeParent}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const productId = parseInt(searchParams.get("productId")),
    selectedOptionId = safeParseInt(searchParams.get("selectedOptionId")),
    selectedCustomizationId = safeParseInt(searchParams.get("selectedCustomizationId")),
    selectedVariantId = safeParseInt(searchParams.get("selectedVariantId"));

  const supply = useSelector((state) => state.supply); // Replace 'user' with the correct slice
  const menu = useSelector((state) => state.menu); // Replace 'user' with the correct slice
  const currentActiveId = menu.activeProduct?.ID;
  if (currentActiveId !== productId) {
    const activeProduct = deepClone(menu.products.find((it) => it.ID === productId));
    dispatch(setProduct(activeProduct));
  }
  const product = menu.activeProduct;

  const [newRecipes, setNewRecipes] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    if (product) {
      setNewRecipes(
        product.RECIPES.map((recipe) => {
          const RANDOM_ID = randomString(24);
          recipe.RANDOM_ID = RANDOM_ID;
          return {
            RANDOM_ID,
            API_TYPE: "ADDITION",
            RECIPE_COST: recipe.RECIPE_COST,
            INGREDIENT_ID: recipe.ID,
            OPTION_ID: recipe.OPTION_ID ?? null,
            VARIANT_OPTION_ID: recipe.VARIANT_OPTION_ID ?? null,
            PRODUCT_ID: recipe.PRODUCT_ID ?? null,
            QUANTITY: recipe.RECIPE_COST,
          };
        })
      );
    }
  }, [product]);

  if (!product) {
    return <LoadingSpinner />;
  }

  const setVariant = (variantId) => {
    if (variantId === selectedVariantId) {
      searchParams.delete("selectedVariantId");
    } else {
      searchParams.set("selectedVariantId", variantId);
    }
    setSearchParams(searchParams);
  };
  const setCustomization = (customizationId) => {
    if (customizationId === selectedCustomizationId) {
      searchParams.delete("selectedCustomizationId");
      searchParams.delete("selectedOptionId");
    } else {
      searchParams.set("selectedCustomizationId", customizationId);
    }
    setSearchParams(searchParams);
  };
  const setOption = (optionId) => {
    if (optionId === selectedOptionId) {
      searchParams.delete("selectedOptionId");
    } else {
      searchParams.set("selectedOptionId", optionId);
    }
    setSearchParams(searchParams);
  };

  const variant = product.CUSTOMIZATIONS?.find((it) => it.TYPE === CUSTOMIZATION_TYPES.VARIANT);
  const modifiers = product.CUSTOMIZATIONS?.filter((it) => it.TYPE !== CUSTOMIZATION_TYPES.VARIANT);
  const currentModifierGroup = modifiers?.find((it) => it.ID === selectedCustomizationId);

  const activeVariant = variant?.OPTIONS?.find((it) => it.ID === selectedVariantId);
  const activeOption = currentModifierGroup?.OPTIONS?.find((it) => it.ID === selectedOptionId);

  const unitDict = parseIdDict(supply.units ?? []);

  const selectedVariantRecipes = product.RECIPES.filter(
    (it) => it.VARIANT_OPTION_ID === selectedVariantId && it.OPTION_ID === null
  );
  const selectedOptionRecipes = product.RECIPES.filter(
    (it) => it.VARIANT_OPTION_ID === selectedVariantId && it.OPTION_ID && it.OPTION_ID === selectedOptionId
  );
  const recipeCostSum = (sum, recipe) => sum + recipe.COST_PER_UNIT * recipe.RECIPE_COST;
  const defaultBaseCost = selectedVariantRecipes.reduce(recipeCostSum, 0);
  const defaultModifierCost = selectedOptionRecipes.reduce(recipeCostSum, 0);

  const onClick = async () => {
    try {
      await showConfirmAlert("Leave without saving recipe?", "Changes you made may not be saved.");
      closeParent();
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <>
      <Row className={"justify-between items-center"}>
        <PageHeadings label={product.NAME} description="Edit your product's recipe" />
        <Row className="space-x-2">
          {hasChanges && (
            <Button
              label={"Save Recipes"}
              autoLoad={true}
              onClick={() => {
                setLoading(true);
                saveRecipes(product, newRecipes, dispatch)
                  .then(() => closeParent())
                  .finally(() => setLoading(false));
              }}
            />
          )}
          <Button type="delete" label="Close" onClick={onClick} />
        </Row>
      </Row>

      <div className="text-lg font-semibold mt-2 pt-2">
        Default Base Cost: ${toDollars(defaultBaseCost)} | Default Modifier Cost: $
        {toDollars(defaultModifierCost)}
      </div>
      <div className="text-sm text-gray-500">(Based on default cost of ingredients)</div>

      {variant && <div className="text-lg font-semibold mt-2 pt-2">Variant</div>}
      {variant && (
        <Row className="mt-2">
          {variant &&
            variant.OPTIONS.map((option) => {
              return (
                <OptionButton
                  option={option}
                  setValue={setVariant}
                  enabled={selectedVariantId === option.ID}
                />
              );
            })}
        </Row>
      )}

      {modifiers.length > 0 && <div className="text-lg font-semibold mt-2 pt-2">Modifier</div>}
      <Row className="mt-2">
        {modifiers.map((customization) => {
          return (
            <OptionButton
              option={customization}
              setValue={setCustomization}
              enabled={selectedCustomizationId === customization.ID}
            />
          );
        })}
      </Row>

      {currentModifierGroup && currentModifierGroup.OPTIONS.length > 0 && (
        <div className="text-lg font-semibold mt-2 pt-2">Options</div>
      )}
      <Box className="mt-2 flex-wrap">
        {currentModifierGroup &&
          currentModifierGroup.OPTIONS.map((option) => {
            return (
              <OptionButton option={option} setValue={setOption} enabled={selectedOptionId === option.ID} />
            );
          })}
      </Box>

      <div class="border-t border-gray-300 my-4"></div>

      <div className="text-lg font-semibold mt-2">Add Recipe Ingredient</div>
      <AddIngredientForm
        selectedOptionId={selectedOptionId}
        activeOption={activeOption}
        selectedVariantId={selectedVariantId}
        activeVariant={activeVariant}
        product={product}
        unitDict={unitDict}
        setNewRecipes={(recipes) => {
          setNewRecipes(recipes);
          setHasChanges(true);
        }}
        newRecipes={newRecipes}
      />

      <div class="border-t border-gray-300 my-4"></div>

      <div className="text-lg font-semibold mt-2">All Product Recipes</div>
      <RemoveRecipeView
        className="mt-4"
        product={product}
        unitDict={unitDict}
        setNewRecipes={(recipes) => {
          setNewRecipes(recipes);
          setHasChanges(true);
        }}
        newRecipes={newRecipes}
      />
    </>
  );
};

export default ProductRecipePage;
