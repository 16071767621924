import {Card, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {DOCUMENT_FED_CATEGORY, DOCUMENT_TYPE, formatDate} from "../../documents/documents-helper";
import {COLUMNS, SUBTABLE_COLUMNS} from "../dashboard-helper";
import {ReadOnlyTable} from "../../../../../../v2/components/shared";
import {
  useDocumentsInReview,
  usePacketsInReview,
} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {useEmployeeDocumentSignedUrl} from "../../../../../../hooks/team/onboarding/onboarding-hub-hooks";
import {useApprovePacket} from "../../../../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {getFilterData} from "../../table-helpers";

export const PendingReviewTab = () => {
  return (
    <>
      <DocumentsPendingReview />

      <PacketsPendingApproval />
    </>
  );
};

const DocumentsPendingReview = () => {
  const {isLoading, error, data} = useDocumentsInReview();

  const navigate = useNavigate();
  const location = useLocation();

  const COLUMNS = [
    {
      value: "EMPLOYEE_NAME",
      label: "Employee Name",
    },
    {
      value: "LOCATION_NAME",
      label: "Location",
    },
    {
      value: "DOCUMENT_NAME",
      label: "Document Name",
    },
    {
      value: "PACKET_NAME",
      label: "Packet Name",
    },
    {
      value: "DUE_DATE",
      label: "Due Date",
      format: (value) => formatDate(value),
    },
  ];

  const handleFederalReview = (document) => {
    const isI9 = document.DOCUMENT_CATEGORY === DOCUMENT_FED_CATEGORY.I9;
    const isW4 = document.DOCUMENT_CATEGORY === DOCUMENT_FED_CATEGORY.W4;

    if (isI9) {
      navigate("/onboarding/dashboard/review-document/I9", {
        state: {from: location.pathname, document: document},
      });
      return;
    }

    if (isW4) {
      navigate("/onboarding/dashboard/review-document/W4", {
        state: {from: location.pathname, document: document},
      });
    }
  };

  const handleReview = (document) => {
    const isFederal = document.DOCUMENT_TYPE === DOCUMENT_TYPE.FEDERAL;

    if (isFederal) {
      handleFederalReview(document);

      return;
    }

    navigate("/onboarding/dashboard/review-document", {
      state: {from: location.pathname, document: document},
    });
  };

  return (
    <>
      <Card
        label="Documents Needing Review"
        description={"The following default and custom documents require action from a reviewer."}
        className={"mt-6"}
      >
        <Filter defaultFilters={[]} data={getFilterData(data)}>
          {(filters, _) => (
            <Table
              sortable
              data={getTableData(isLoading, error, data)}
              isLoading={isLoading}
              pagination={true}
              filters={filters}
              actionButtons={[
                {
                  label: "Review",
                  onClick: (row) => handleReview(row),
                },
              ]}
              columns={COLUMNS}
            />
          )}
        </Filter>
      </Card>
    </>
  );
};

const PacketsPendingApproval = () => {
  const {isLoading, error, data} = usePacketsInReview();

  const {openDocument} = useEmployeeDocumentSignedUrl();

  const handleViewDocument = async (document) => {
    openDocument(document.ASSIGNMENT_URL ?? document.URL);
  };

  const account = useSelector((state) => state.user.account);

  const {mutate: approvePacket, isLoading: approvalLoading} = useApprovePacket();

  const handlePacketApproval = async (packet) => {
    if (!approvalLoading) {
      approvePacket({
        PACKET_ASS_UID: packet.PACKET_ASSIGNMENT_UID,
        REVIEWED_BY_ACCOUNT_ID: account.ID,
      });
    }
  };

  return (
    <Card
      label="Packets Needing Approval"
      description={
        "The following packets have been completed by the employee and require approval before they can be marked as completed."
      }
      className={"mt-6"}
    >
      <Filter defaultFilters={[]} data={getFilterData(data)}>
        {(filters, _) => (
          <Table
            sortable
            data={getTableData(isLoading, error, data)}
            isLoading={isLoading}
            pagination={true}
            filters={filters}
            actionButtons={[
              {
                onClick: (row) => handlePacketApproval(row),
                format: () => (
                  <div className="relative hover:bg-green-700 hover:bg-opacity-15 hover:rounded-full p-2 inline-block">
                    <FontAwesomeIcon className="text-green-700 w-5 h-5" icon={["fas", "check"]} />
                  </div>
                ),
              },
            ]}
            columns={COLUMNS}
            expandable={(packet) => (
              <ReadOnlyTable
                className={"m-2"}
                columns={SUBTABLE_COLUMNS}
                data={packet.DOCUMENTS}
                buttons={[
                  {
                    label: "View",
                    onClick: (document) => handleViewDocument(document),
                  },
                ]}
              />
            )}
          />
        )}
      </Filter>
    </Card>
  );
};
