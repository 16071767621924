import React, {Component} from "react";
import {Modal, FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import {formatDatesOff} from "../../pages/team/approvals/tabs/time-off-tab";
import moment from "moment-timezone";
import {request} from "../../utils/request";
import {TIME_OFF_REQUEST_STATUSES} from "../../utils/team-constants";
import WarningBadge from "../../components/badges/warning-badge";
import DangerBadge from "../../components/badges/danger-badge";
import SuccessBadge from "../../components/badges/success-badge";
import {getTimeOffDescription} from "../../utils/scheduler-helper";

class TimeOffModal extends Component {
  state = {request: null, reviewerNotes: ""};

  open(request) {
    this.setState({request}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  blessRequest(isAccepted) {
    let {request, reviewerNotes} = this.state;

    this.props.blessRequest(request.ID, isAccepted, reviewerNotes);
    this.modal.close();
  }

  async archiveRequest() {
    let {request: serverRequest} = this.state;

    await request("timeoff/requests/" + serverRequest.ID, "DELETE", {});

    if (this.props.archiveRequest) {
      this.props.archiveRequest(serverRequest);
    }

    this.modal.close();
  }

  renderHeader() {
    let {request} = this.state;

    return (
      <div className="flex flex-row">
        <div>{`${request?.EMPLOYEE_NAME}'s Request`}</div>

        <div className="ml-10">
          {request?.STATUS === TIME_OFF_REQUEST_STATUSES.PENDING.CODE && <WarningBadge>Pending</WarningBadge>}

          {request?.STATUS === TIME_OFF_REQUEST_STATUSES.ACCEPTED.CODE && (
            <SuccessBadge>Approved</SuccessBadge>
          )}

          {request?.STATUS === TIME_OFF_REQUEST_STATUSES.DENIED.CODE && <DangerBadge>Denied</DangerBadge>}
        </div>
      </div>
    );
  }

  render() {
    let {request} = this.state;

    let actionableRequest = request?.STATUS === TIME_OFF_REQUEST_STATUSES.PENDING.CODE;

    let reviewerResponse =
      request?.STATUS === TIME_OFF_REQUEST_STATUSES.ACCEPTED.CODE ||
      request?.STATUS === TIME_OFF_REQUEST_STATUSES.DENIED.CODE;

    return (
      <Modal
        ref={(e) => (this.modal = e)}
        label={this.renderHeader()}
        buttonLabel={actionableRequest && "Approve"}
        buttonOnClick={() => this.blessRequest(true)}
        deleteLabel={request?.STATUS === "PENDING" ? "Reject" : "Delete"}
        deleteOnClick={() =>
          request?.STATUS === "PENDING" ? this.blessRequest(false) : this.archiveRequest()
        }
      >
        <div className="text-sm">
          <div>
            <div className="block text-sm font-medium text-gray-700">Policy Name</div>

            <div>
              <span className="font-medium">{request?.POLICY_NAME}</span>
            </div>
          </div>

          {request?.CATEGORY_NAME && (
            <div className="mt-3">
              <div className="block text-sm font-medium text-gray-700">Request Category</div>

              <div className="font-medium">{request.CATEGORY_NAME}</div>
            </div>
          )}

          <div className="mt-3">
            <div className="block text-sm font-medium text-gray-700">Dates Off</div>

            <div>
              <span className="font-medium">{request && formatDatesOff(request)}</span> -{" "}
              <span className="font-medium">{request?.AMOUNT} hours</span>
            </div>
          </div>

          <div className="mt-3">
            <div className="block text-sm font-medium text-gray-700">By Day</div>

            <div>
              {request?.DAYS.map((item) => {
                return (
                  <div>
                    <span className="font-medium">{item.CONTENT}</span> -{" "}
                    <span className="font-medium">{getTimeOffDescription(item)}</span>
                  </div>
                );
              })}
            </div>
          </div>

          <div>
            <div className="block text-sm font-medium text-gray-700 mt-3">Requested On</div>
            <div>{moment(request?.DATE_CREATED).format("ddd, MMM D")}</div>
          </div>

          {request?.NOTES && (
            <div>
              <div className="block text-sm font-medium text-gray-700 mt-3">Notes</div>

              <div>{request?.NOTES}</div>
            </div>
          )}

          {actionableRequest && (
            <div>
              <div className="h-0.5 w-full my-10 bg-gray-300 mt-3" />

              <FormTextArea
                className="mt-3"
                label="Reviewer's Notes"
                placeholder="Example: Unfortunately, I cannot approve this request because..."
                onChange={(e) => this.setState({reviewerNotes: e.target.value})}
              />
            </div>
          )}

          {reviewerResponse && (
            <div>
              <div className="block text-sm font-medium text-gray-700 mt-3">Reviewed By</div>

              <div>{request?.REVIEWER_NAME ?? "Auto Reviewed"}</div>

              {request?.REVIEWER_NOTES && (
                <div>
                  <div className="block text-sm font-medium text-gray-700 mt-3">Reviewer Notes</div>

                  <div>{request?.REVIEWER_NOTES}</div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export default TimeOffModal;
