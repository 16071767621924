export const I9_TEXT_FIELDS = {
  EMPLOYEE_SIGNATURE: "employee_signature",
  EMPLOYEE_SIGNATURE_DATE: "employee_signature_date",
  LIST_A_DOCUMENT_1_TITLE: "list_a_document_1_title",
  LIST_A_DOCUMENT_1_ISSUING_AUTHORITY: "list_a_document_1_issuing_authority",
  LIST_A_DOCUMENT_1_NUMBER: "list_a_document_1_number",
  LIST_A_DOCUMENT_1_EXPIRATION_DATE: "list_a_document_1_expiration_date",
  LIST_A_DOCUMENT_3_EXPIRATION_DATE: "list_a_document_3_expiration_date",
  LIST_A_DOCUMENT_3_NUMBER: "list_a_document_3_number",
  LIST_A_DOCUMENT_3_ISSUING_AUTHORITY: "list_a_document_3_issuing_authority",
  LIST_A_DOCUMENT_3_TITLE: "list_a_document_3_title",
  LIST_A_DOCUMENT_2_EXPIRATION_DATE: "list_a_document_2_expiration_date",
  LIST_A_DOCUMENT_2_NUMBER: "list_a_document_2_number",
  LIST_A_DOCUMENT_2_ISSUING_AUTHORITY: "list_a_document_2_issuing_authority",
  LIST_A_DOCUMENT_2_TITLE: "list_a_document_2_title",
  LIST_B_DOCUMENT_EXPIRATION_DATE: "list_b_document_expiration_date",
  LIST_B_DOCUMENT_NUMBER: "list_b_document_number",
  LIST_B_DOCUMENT_ISSUING_AUTHORITY: "list_b_document_issuing_authority",
  LIST_B_DOCUMENT_TITLE: "list_b_document_title",
  LIST_C_DOCUMENT_EXPIRATION_DATE: "list_c_document_expiration_date",
  LIST_C_DOCUMENT_NUMBER: "list_c_document_number",
  LIST_C_DOCUMENT_ISSUING_AUTHORITY: "list_c_document_issuing_authority",
  LIST_C_DOCUMENT_TITLE: "list_c_document_title",
  SECTION_2_ADDITIONAL_INFORMATION: "section_2_additional_information",
  EMPLOYER_LAST_NAME: "employer_last_name",
  EMPLOYER_FIRST_NAME: "employer_first_name",
  EMPLOYER_TITLE: "employer_title",
  EMPLOYER_SIGNATURE_DATE: "employer_signature_date",
  EMPLOYER_SIGNATURE: "employer_signature",
  EMPLOYER_ADDRESS: "employer_address",
  EMPLOYER_CITY: "employer_city",
  EMPLOYER_STATE: "employer_state",
  PREPARER_OR_TRANSLATOR_1_SIGNATURE: "preparer_or_translator_1_signature",
  PREPARER_OR_TRANSLATOR_2_SIGNATURE: "preparer_or_translator_2_signature",
  PREPARER_OR_TRANSLATOR_3_SIGNATURE: "preparer_or_translator_3_signature",
  PREPARER_OR_TRANSLATOR_4_SIGNATURE: "preparer_or_translator_4_signature",
  PREPARER_OR_TRANSLATOR_1_FIRST_NAME: "preparer_or_translator_1_first_name",
  PREPARER_OR_TRANSLATOR_1_LAST_NAME: "preparer_or_translator_1_last_name",
  PREPARER_OR_TRANSLATOR_1_ADDRESS: "preparer_or_translator_1_address",
  PREPARER_OR_TRANSLATOR_1_CITY: "preparer_or_translator_1_city",
  PREPARER_OR_TRANSLATOR_1_STATE: "preparer_or_translator_1_state",
  PREPARER_OR_TRANSLATOR_1_POSTAL_CODE: "preparer_or_translator_1_postal_code",
  PREPARER_OR_TRANSLATOR_1_SIGNATURE_DATE: "preparer_or_translator_1_signature_date",
  PREPARER_OR_TRANSLATOR_2_SIGNATURE_DATE: "preparer_or_translator_2_signature_date",
  PREPARER_OR_TRANSLATOR_2_LAST_NAME: "preparer_or_translator_2_last_name",
  PREPARER_OR_TRANSLATOR_2_FIRST_NAME: "preparer_or_translator_2_first_name",
  PREPARER_OR_TRANSLATOR_2_ADDRESS: "preparer_or_translator_2_address",
  PREPARER_OR_TRANSLATOR_2_CITY: "preparer_or_translator_2_city",
  PREPARER_OR_TRANSLATOR_2_STATE: "preparer_or_translator_2_state",
  PREPARER_OR_TRANSLATOR_2_POSTAL_CODE: "preparer_or_translator_2_postal_code",
  PREPARER_OR_TRANSLATOR_3_SIGNATURE_DATE: "preparer_or_translator_3_signature_date",
  PREPARER_OR_TRANSLATOR_3_LAST_NAME: "preparer_or_translator_3_last_name",
  PREPARER_OR_TRANSLATOR_3_FIRST_NAME: "preparer_or_translator_3_first_name",
  PREPARER_OR_TRANSLATOR_3_ADDRESS: "preparer_or_translator_3_address",
  PREPARER_OR_TRANSLATOR_3_CITY: "preparer_or_translator_3_city",
  PREPARER_OR_TRANSLATOR_3_STATE: "preparer_or_translator_3_state",
  PREPARER_OR_TRANSLATOR_3_POSTAL_CODE: "preparer_or_translator_3_postal_code",
  PREPARER_OR_TRANSLATOR_4_SIGNATURE_DATE: "preparer_or_translator_4_signature_date",
  PREPARER_OR_TRANSLATOR_4_LAST_NAME: "preparer_or_translator_4_last_name",
  PREPARER_OR_TRANSLATOR_4_FIRST_NAME: "preparer_or_translator_4_first_name",
  PREPARER_OR_TRANSLATOR_4_ADDRESS: "preparer_or_translator_4_address",
  PREPARER_OR_TRANSLATOR_4_CITY: "preparer_or_translator_4_city",
  PREPARER_OR_TRANSLATOR_4_STATE: "preparer_or_translator_4_state",
  PREPARER_OR_TRANSLATOR_4_POSTAL_CODE: "preparer_or_translator_4_postal_code",
  EMPLOYEE_OTHER_LAST_NAMES_USED: "employee_other_last_names_used",
  EMPLOYEE_MIDDLE_INITIAL: "employee_middle_initial",
  EMPLOYEE_FIRST_NAME: "employee_first_name",
  EMPLOYEE_LAST_NAME: "employee_last_name",
  EMPLOYEE_ADDRESS: "employee_address",
  EMPLOYEE_APT_NUMBER: "employee_apt_number",
  EMPLOYEE_CITY: "employee_city",
  EMPLOYEE_STATE: "employee_state",
  EMPLOYEE_POSTAL_CODE: "employee_postal_code",
  EMPLOYEE_DATE_OF_BIRTH: "employee_date_of_birth",
  EMPLOYEE_SSN: "employee_ssn",
  EMPLOYEE_EMAIL: "employee_email",
  EMPLOYEE_PHONE_NUMBER: "employee_phone_number",
  EMPLOYER_BUSINESS_NAME: "employer_business_name",
  EMPLOYER_POSTAL_CODE: "employer_postal_code",
  SUPPLEMENT_B_EMPLOYEE_FIRST_NAME: "supplement_b_employee_first_name",
  SUPPLEMENT_B_EMPLOYEE_LAST_NAME: "supplement_b_employee_last_name",
  SUPPLEMENT_B_EMPLOYEE_MIDDLE_INITIAL: "supplement_b_employee_middle_initial",
  SUPPLEMENT_A_EMPLOYEE_LAST_NAME: "supplement_a_employee_last_name",
  SUPPLEMENT_A_EMPLOYEE_FIRST_NAME: "supplement_a_employee_first_name",
  SUPPLEMENT_A_EMPLOYEE_MIDDLE_INITIAL: "supplement_a_employee_middle_initial",
  SUPPLEMENT_B_ADDITIONAL_INFORMATION_3: "supplement_b_additional_information_3",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_DATE_3: "supplement_b_employer_signature_date_3",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_3: "supplement_b_employer_signature_3",
  SUPPLEMENT_B_EMPLOYER_NAME_3: "supplement_b_employer_name_3",
  SUPPLEMENT_B_DOCUMENT_EXPIRATION_DATE_3: "supplement_b_document_expiration_date_3",
  SUPPLEMENT_B_DOCUMENT_NUMBER_3: "supplement_b_document_number_3",
  SUPPLEMENT_B_DOCUMENT_TITLE_3: "supplement_b_document_title_3",
  SUPPLEMENT_B_ADDITIONAL_INFORMATION_2: "supplement_b_additional_information_2",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_DATE_2: "supplement_b_employer_signature_date_2",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_2: "supplement_b_employer_signature_2",
  SUPPLEMENT_B_EMPLOYER_NAME_2: "supplement_b_employer_name_2",
  SUPPLEMENT_B_DOCUMENT_EXPIRATION_DATE_2: "supplement_b_document_expiration_date_2",
  SUPPLEMENT_B_DOCUMENT_NUMBER_2: "supplement_b_document_number_2",
  SUPPLEMENT_B_DOCUMENT_TITLE_2: "supplement_b_document_title_2",
  SUPPLEMENT_B_ADDITIONAL_INFORMATION_1: "supplement_b_additional_information_1",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_DATE_1: "supplement_b_employer_signature_date_1",
  SUPPLEMENT_B_EMPLOYER_SIGNATURE_1: "supplement_b_employer_signature_1",
  SUPPLEMENT_B_EMPLOYER_NAME_1: "supplement_b_employer_name_1",
  SUPPLEMENT_B_DOCUMENT_EXPIRATION_DATE_1: "supplement_b_document_expiration_date_1",
  SUPPLEMENT_B_DOCUMENT_NUMBER_1: "supplement_b_document_number_1",
  SUPPLEMENT_B_DOCUMENT_TITLE_1: "supplement_b_document_title_1",
  SUPPLEMENT_B_EMPLOYEE_DATE_OF_REHIRE_1: "supplement_b_employee_date_of_rehire_1",
  SUPPLEMENT_B_EMPLOYEE_NEW_LAST_NAME_1: "supplement_b_employee_new_last_name_1",
  SUPPLEMENT_B_EMPLOYEE_NEW_FIRST_NAME_1: "supplement_b_employee_new_first_name_1",
  SUPPLEMENT_B_EMPLOYEE_NEW_MIDDLE_INITIAL_1: "supplement_b_employee_new_middle_initial_1",
  SUPPLEMENT_B_EMPLOYEE_DATE_OF_REHIRE_2: "supplement_b_employee_date_of_rehire_2",
  SUPPLEMENT_B_EMPLOYEE_NEW_LAST_NAME_2: "supplement_b_employee_new_last_name_2",
  SUPPLEMENT_B_EMPLOYEE_NEW_FIRST_NAME_2: "supplement_b_employee_new_first_name_2",
  SUPPLEMENT_B_EMPLOYEE_NEW_MIDDLE_INITIAL_2: "supplement_b_employee_new_middle_initial_2",
  SUPPLEMENT_B_EMPLOYEE_DATE_OF_REHIRE_3: "supplement_b_employee_date_of_rehire_3",
  SUPPLEMENT_B_EMPLOYEE_NEW_LAST_NAME_3: "supplement_b_employee_new_last_name_3",
  SUPPLEMENT_B_EMPLOYEE_NEW_FIRST_NAME_3: "supplement_b_employee_new_first_name_3",
  SUPPLEMENT_B_EMPLOYEE_NEW_MIDDLE_INITIAL_3: "supplement_b_employee_new_middle_initial_3",
  BOX_4_EXPIRATION_DATE: "box_4_expiration_date",
  BOX_3_USCIS_OR_A_NUMBER: "box_3_uscis_or_a_number",
  BOX_4_USCIS_OR_A_NUMBER: "box_4_uscis_or_a_number",
  BOX_4_I94_ADMISSION_NUMBER: "box_4_i94_admission_number",
  BOX_4_FOREIGN_PASSPORT_NUMBER: "box_4_foreign_passport_number",
  BOX_4_COUNTRY_OF_ISSUANCE: "box_4_country_of_issuance",
  EMPLOYEE_FIRST_DAY_OF_EMPLOYMENT: "employee_first_day_of_employment",
  PREPARER_OR_TRANSLATOR_1_MIDDLE_INITIAL: "preparer_or_translator_1_middle_initial",
  PREPARER_OR_TRANSLATOR_2_MIDDLE_INITIAL: "preparer_or_translator_2_middle_initial",
  PREPARER_OR_TRANSLATOR_3_MIDDLE_INITIAL: "preparer_or_translator_3_middle_initial",
  PREPARER_OR_TRANSLATOR_4_MIDDLE_INITIAL: "preparer_or_translator_4_middle_initial",
};

export const I9_CHECKBOX_FIELDS = {
  NONCITIZEN_NATIONAL: "noncitizen_national",
  PERMANENT_RESIDENT: "permanent_resident",
  NONCITIZEN: "noncitizen",
  SECTION_2_USED_ALTERNATIVE_PROCEDURE: "section_2_used_alternative_procedure",
  CITIZEN: "citizen",
  SUPPLEMENT_B_USED_ALTERNATIVE_PROCEDURE_2: "supplement_b_used_alternative_procedure_2",
  SUPPLEMENT_B_USED_ALTERNATIVE_PROCEDURE_1: "supplement_b_used_alternative_procedure_1",
  SUPPLEMENT_B_USED_ALTERNATIVE_PROCEDURE_3: "supplement_b_used_alternative_procedure_3",
};
