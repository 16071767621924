import React, {Component} from "react";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import {Card, Table, Loading} from "@frostbyte-technologies/frostbyte-tailwind";

class SuccessTrackerPage extends Component {
  state = {
    tickets: [],
    locations: [],

    isLoading: true,
  };

  componentDidMount() {
    this.loadData();

    this.resetInterval = setInterval(() => {
      this.loadData();
    }, 1000 * 60);
  }

  componentWillUnmount() {
    clearInterval(this.resetInterval);
  }

  async loadData() {
    request("notion/success", "GET", {
      DATE_START: moment().subtract(30, "days").valueOf(),
      DATE_END: moment().valueOf(),
    }).then(({tickets, locations}) => {
      this.setState({isLoading: false, locations, tickets});
    });
  }

  render() {
    const {tickets, locations, isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    const locationDict = locations.reduce((dict, item) => {
      dict[item.id] = item;

      return dict;
    }, {});

    const openTickets = tickets.filter((item) => {
      console.log(item);

      return item.properties.Status.status?.name === "Open";
    });

    const closedTickets = tickets.filter((item) => {
      return item.properties.Status.status?.name === "Closed";
    });

    const unhealthyLocations = locations.filter((item) => {
      return item.properties.Health.select?.name !== "Passive";
    });

    const timeToReply =
      closedTickets.reduce((accum, item) => {
        const open = item.properties["Time Originated"].date.start;
        const reply = item.properties["Time Responded"].date.start;

        return accum + moment(reply).valueOf() - moment(open).valueOf();
      }, 0) / (closedTickets.length || 1);

    let lower = 0;
    const timeToClose =
      closedTickets.reduce((accum, item) => {
        const open = item.properties["Time Originated"].date.start;
        const reply = item.properties["Time Resolved"].date?.start;

        if (reply === null) {
          return accum;
        }

        lower += 1;
        return accum + moment(reply).valueOf() - moment(open).valueOf();
      }, 0) / (lower || 1);

    tickets.sort((a, b) => {
      return (
        moment(a.properties["Time Originated"].date.start).valueOf() -
        moment(b.properties["Time Originated"].date.start).valueOf()
      );
    });

    return (
      <div className="p-6">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">General Stats</h3>

          <dl className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-4">
            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Tickets Created</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{tickets.length}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Open Tickets</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">{openTickets.length}</dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Time to Reply Avg</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {parseFloat(timeToReply / (1000 * 60)).toFixed(2)} mins
              </dd>
            </div>

            <div className="px-4 py-2 bg-white shadow rounded-lg overflow-hidden sm:px-6 sm:py-2">
              <dt className="text-sm font-medium text-gray-500 truncate">Time to Close Avg</dt>

              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {parseFloat(timeToClose / (1000 * 60)).toFixed(2)} mins
              </dd>
            </div>
          </dl>
        </div>

        <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Open Tickets</h3>

        <Card className="mt-2">
          <Table
            data={openTickets}
            columns={[
              {
                width: 1,
                value: "agent",
                label: "Agent",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return item.properties["Helped by"].created_by.name;
                },
              },
              {
                width: 1,
                value: "stage",
                label: "Stage",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return item.properties.Stage?.select?.name;
                },
              },
              {
                width: 1,
                value: "opened",
                label: "Date Opened",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return moment(item.properties["Time Originated"].date.start).format("hh:mma M/D/YY");
                },
              },
              {
                value: "name",
                label: "Ticket Name",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return item.properties.Ticket.title[0].plain_text;
                },
              },
              {
                width: 1,
                value: "location",
                label: "Location Name",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  const relations = item.properties.Location.relation;

                  if (relations.length === 0) {
                    return "Location not found";
                  }

                  const locationNames = item.properties.Location.relation.map(({id}) => {
                    const location = locationDict[id];

                    if (location) {
                      return location.properties.Location.title[0].plain_text;
                    }

                    return "Oof";
                  });

                  return locationNames.join(", ");
                },
              },
            ]}
          />
        </Card>

        <h3 className="mt-4 text-lg leading-6 font-medium text-gray-900">Unhealthy Customer</h3>

        <Card className="mt-2">
          <Table
            data={unhealthyLocations}
            columns={[
              {
                value: "name",
                label: "Location Name",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return item.properties.Location.title[0].plain_text;
                },
              },
              {
                width: 1,
                value: "status",
                label: "Health Status",
                className: "font-medium text-gray-900",
                format: (_, item) => {
                  return item.properties.Health.select.name;
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default SuccessTrackerPage;
