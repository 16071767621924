import {Component} from "react";
import FormDropZone from "./form-drop-zone";

class EmployeeFormDropZone extends Component {
  render() {
    const {options, name, onChange, onChangeSoft, endpoint, label, type} = this.props;

    return (
      <FormDropZone
        options={options}
        type={type || "PDF"}
        endpoint={endpoint ?? "employees/aws/document/"}
        name={name}
        label={label ?? "Upload a File"}
        onChange={onChange}
        onChangeSoft={onChangeSoft}
      />
    );
  }
}

export default EmployeeFormDropZone;
