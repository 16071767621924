import PageLayout from "../page/page-layout";
import SideNav from "../../components/side-nav";
import {Outlet} from "react-router-dom";
import React from "react";

export const HubOnboardingSideNav = () => {
  return (
    <div className="p-2 md:p-6 height-screen">
      <PageLayout className="mt-0">
        <div className="lg:col-start-1 lg:col-span-2">
          <SideNav
            title={"Onboarding"}
            permissions={[]}
            navigation={[
              {
                live: true,
                id: "employee-onboarding",
                name: "Dashboard",
                href: "/hub/employee-onboarding",
                permissions: [],
                children: [
                  {
                    live: true,
                    name: "Documents",
                    description: "View all your onboarding documents",
                    href: "/hub/employee-onboarding/documents",
                    paths: ["/hub/employee-onboarding/documents"],
                    permissions: [],
                  },
                ],
              },
            ]}
          />
        </div>

        <div className="lg:col-start-3 lg:col-span-10">
          <Outlet />
        </div>
      </PageLayout>
    </div>
  );
};
