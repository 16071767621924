export const W4_FIELDS = {
  FIRST_NAME: "first_name",
  LAST_NAME: "last_name",
  ADDRESS: "address",
  CITY_STATE_ZIP: "city_state_zip",
  SSN: "ssn",
  FILING_STATUS_SINGLE: "filing_status_single",
  FILING_STATUS_MARRIED: "filing_status_married",
  FILING_STATUS_HEAD_OF_HOUSEHOLD: "filing_status_head_of_household",
  HAS_TWO_JOBS: "has_two_jobs",
  DEPENDENTS: "dependents",
  OTHER_INCOME: "other_income",
  DEDUCTIONS: "deductions",
  ADDITIONAL_AMOUNT: "additional_amount",
  COMPANY_NAME: "company_name",
  COMPANY_ADDRESS: "company_address",
  EMPLOYEE_START_DATE: "employee_start_date",
  COMPANY_FEDERAL_EIN: "company_federal_ein",
  COMPANY_CITY_STATE_ZIP: "company_city_state_zip",
  EMPLOYEE_SIGNATURE_DATE: "employee_signature_date",
  EXEMPT: "exempt",
  EMPLOYEE_SIGNATURE: "employee_signature",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_01_0: "topmostSubform[0].Page3[0].f3_01[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_02_0: "topmostSubform[0].Page3[0].f3_02[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_03_0: "topmostSubform[0].Page3[0].f3_03[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_04_0: "topmostSubform[0].Page3[0].f3_04[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_05_0: "topmostSubform[0].Page3[0].f3_05[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_06_0: "topmostSubform[0].Page3[0].f3_06[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_07_0: "topmostSubform[0].Page3[0].f3_07[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_08_0: "topmostSubform[0].Page3[0].f3_08[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_09_0: "topmostSubform[0].Page3[0].f3_09[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_10_0: "topmostSubform[0].Page3[0].f3_10[0]",
  TOPMOSTSUBFORM_0_PAGE3_0_F3_11_0: "topmostSubform[0].Page3[0].f3_11[0]",
};
