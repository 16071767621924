import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import EmployeeFormDropZone from "../../../components/employee-form-drop-zone";
import {EmployeeRequests} from "../../../utils/request-helpers/employees/employee-requests";
import {showErrorNotification} from "../../../utils/notification-helper";

class EmployeeFileModal extends Component {
  state = {file: null, employee: null, fileUploaded: null};

  open(employee, file = null) {
    this.setState({file, employee}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    // submit thing to database
    const {filename, fileUpload, folder} = values;
    const {url} = fileUpload;
    const {employee, file} = this.state;
    const {syncState} = this.props;

    if (!file) {
      await EmployeeRequests.createDocument({
        EMPLOYEE_ID: employee.ID,
        URL: url,
        DOCUMENT_NAME: filename,
        FOLDER_ID: folder,
      });
    }

    if (file) {
      await EmployeeRequests.updateDocument({
        UNIQUE_ID: file.UNIQUE_ID,
        DOCUMENT_NAME: filename,
        FOLDER_ID: folder,
      });
    }

    syncState();

    this.modal.close();
  };

  close() {
    this.modal.close();
  }

  archivePdf(file) {
    const {syncState} = this.props;
    EmployeeRequests.archiveDocument(file.UNIQUE_ID)
      .then(() => {
        syncState();
        this.modal.close();
      })
      .catch((e) => {
        showErrorNotification("Document Error", `Unable to delete ${file.DOCUMENT_NAME}. Please try again.`);
      });
  }

  render() {
    const {file, fileUploaded} = this.state;
    const {folders} = this.props;

    return (
      <Modal
        tooltip={{
          data: `${file ? "Update a file for the employee" : "Add a new file for this employee"}`,
        }}
        buttonLabel={"Save"}
        buttonDisabled={!file && !fileUploaded}
        label={file ? "Edit File" : "Add File"}
        ref={(e) => (this.modal = e)}
        deleteOnClick={() => this.archivePdf(file)}
        formikOnClick={() => this.formikRef}
        deleteLabel={file ? "Archive" : null}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            fileUpload: file
              ? {
                  filename: file?.DOCUMENT_NAME,
                  url: file?.URL,
                }
              : null,
            filename: file?.DOCUMENT_NAME,
            folder: file?.FOLDER_ID,
          }}
          validationSchema={Yup.object().shape({
            fileUpload: Yup.object()
              .shape({
                filename: Yup.string().nullable().required("The file name is required."),
                url: Yup.string().nullable().required("The file url is required."),
              })
              .nullable()
              .required("Please select a file."),
            filename: Yup.string()
              .required("Please enter a valid file name")
              .matches(
                /^[a-zA-Z0-9 _-]+$/,
                "File name can only contain letters, numbers, spaces, underscores, and hyphens, with no special characters or periods"
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit, values, setFieldValue} = formikOptions;
            const {fileUpload} = values;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput options={formikOptions} label="File Name" name="filename" />

                <FormSelect
                  options={formikOptions}
                  label="Folder"
                  name="folder"
                  hint={"Optional"}
                  data={folders?.map(({ID, NAME}) => ({label: NAME, value: ID} ?? []))}
                />

                {!fileUpload && (
                  <EmployeeFormDropZone
                    name={"fileUpload"}
                    onChangeSoft={(file) => {
                      setFieldValue("filename", file.name);
                      setFieldValue("fileUpload", file.fileDetails);
                      this.setState({fileUploaded: file});
                    }}
                  />
                )}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeFileModal.propTypes = {
  onSave: PropTypes.func,
};

export default EmployeeFileModal;
