import React, {Component} from "react";
import {Document, Page, pdfjs} from "react-pdf";
import {PDFDocument, StandardFonts} from "pdf-lib";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {classNames, randomString} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SignatureModal from "../modals/team/employee/onboarding/signature-modal";
import {request} from "../utils/request";
import {Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import moment from "moment-timezone";
import SignatureField from "./pdf-editor/signature-field";
import TextField from "./pdf-editor/text-field";
import DropdownField from "./pdf-editor/dropdown-field";
import {cloneDeep} from "lodash";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfEditor extends Component {
  state = {numPages: null, page: 1, document: null, fields: null, error: null};

  async componentDidMount() {
    const {ID, instanceId} = this.props;
    let document = await request("company-documents/file/" + ID, "GET");

    const fields = await request(
      "company-documents/fields/" + document.ID + "/instance/" + instanceId,
      "GET"
    );

    const {toPrefill, toUserFill} = this._filterFields(fields);

    try {
      document = await this.prefillFields(document, toPrefill);
      this.setState({document, fields: toUserFill});
    } catch (e) {
      this.setState({
        error: "Failed to load your document. Please re-upload it and try again.",
      });
    }
  }

  async verifyComplete() {
    const {fields, document} = this.state;
    if (fields.some((item) => !item.VALUE && item.REQUIRED)) {
      return false;
    }

    const documentCopy = cloneDeep(document);
    const returnDoc = await this.writeFieldsToDocument(documentCopy);

    return {document: returnDoc, fields};
  }

  _filterFields(fields) {
    const toPrefill = [];
    const toUserFill = [];

    fields.forEach((field) => {
      if (["NAME", "COMPANY", "PHONE", "EMAIL", "DATE_SIGNED"].includes(field.TYPE)) {
        toPrefill.push(field);
      } else {
        toUserFill.push(field);
      }
    });

    return {toPrefill, toUserFill};
  }

  _getFieldValue(field, employee) {
    const {TYPE} = field;

    if (TYPE === "EMAIL") {
      return employee.EMAIL;
    } else if (TYPE === "PHONE") {
      return getFormattedPhone(employee.PHONE);
    } else if (TYPE === "DATE_SIGNED") {
      return moment().format("M/D/YYYY");
    } else if (TYPE === "NAME") {
      return employee.FULL_NAME;
    } else if (TYPE === "COMPANY") {
      return employee.LOCATION_NAME;
    }
  }

  async prefillFields(document, fields) {
    if (!fields) {
      return document;
    }

    const {employee} = this.props;

    let editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    fields.forEach((field) => {
      const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];
      const toWrite = this._getFieldValue(field, employee);

      page.drawText(toWrite, {
        x: field.X_COORDINATE,
        y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT / 2 - 6,
        width: field.WIDTH,
        height: field.HEIGHT,
        size: 12,
      });
    });

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  onDocumentLoad(params) {
    const {numPages} = params;

    this.setState((prevState) => {
      if (prevState.numPages !== numPages) {
        return {numPages, page: 1};
      }
    });
  }

  async writeFieldsToDocument(document) {
    const {fields} = this.state;

    let editablePDF = await PDFDocument.load(new Uint8Array(document.DATA.data).buffer);

    for (const field of fields) {
      editablePDF = await this.writeFieldToDocument(field, editablePDF);
    }

    const pdfBytes = await editablePDF.save();

    return {
      ...document,
      DATA: {
        type: "Buffer",
        data: pdfBytes,
      },
    };
  }

  async writeFieldToDocument(field, editablePDF) {
    if (field.TYPE === "SIGNATURE" || field.TYPE === "INITIALS") {
      return this.writeSignature(field, editablePDF);
    } else if (field.TYPE === "TEXTBOX") {
      return this.writeText(field, editablePDF);
    } else if (field.TYPE === "DROPDOWN") {
      return this.writeText(field, editablePDF, true);
    }
  }

  formatText(text, charsPerRow) {
    const regexString = "/.{" + charsPerRow + "}/g";
    return text.replace(regexString, "$&\n");
  }

  async writeText(field, editablePDF, dropdown = false) {
    const timesRomanFont = await editablePDF.embedFont(StandardFonts.TimesRoman);

    const rawText = dropdown ? field.VALUE.label : field.VALUE;

    const width = timesRomanFont.widthOfTextAtSize("a", 12);
    const charsPerRow = Math.floor(field.WIDTH / width);

    const textToWrite = this.formatText(rawText, charsPerRow);
    const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    page.drawText(textToWrite, {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE,
      width: field.WIDTH,
      height: field.HEIGHT,
      size: 12,
      font: timesRomanFont,
    });

    return editablePDF;
  }

  async writeSignature(field, editablePDF) {
    if (!field.VALUE) {
      return editablePDF;
    }

    const imageBytes = await fetch(field.VALUE).then((data) => data.arrayBuffer());

    const pngImage = await editablePDF.embedPng(imageBytes);
    const page = editablePDF.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    page.drawImage(pngImage, {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT,
      width: field.WIDTH,
      height: field.HEIGHT,
    });

    return editablePDF;
  }

  renderFields() {
    const {page, fields} = this.state;

    if (!fields) {
      return;
    }

    return fields.filter((field) => !!field && field.PAGE === page).map((field) => this.renderField(field));
  }

  renderField(field) {
    switch (field.TYPE) {
      case "SIGNATURE":
        return this.renderSignatureField(field);
      case "INITIALS":
        return this.renderInitialsField(field);
      case "TEXTBOX":
        return this.renderTextField(field);
      case "DROPDOWN":
        return this.renderDropdownField(field);
    }
  }

  renderSignatureField(field) {
    return (
      <SignatureField
        field={field}
        selected={false}
        key={field.UNIQUE_ID}
        message={"Click here to sign"}
        onClick={() => this.onSignatureClick(field)}
      />
    );
  }

  renderInitialsField(field) {
    return (
      <SignatureField
        field={field}
        selected={false}
        key={field.UNIQUE_ID}
        message={"Click here to initial"}
        onClick={() => this.onSignatureClick(field)}
      />
    );
  }

  async onSignatureClick(field) {
    field.VALUE = await this.signatureModal.open();

    this.setState((prevState) => {
      const {fields} = prevState;
      const fieldIndex = fields.find((_field) => _field.UNIQUE_ID === field.UNIQUE_ID);

      fields[fieldIndex] = field;

      return {fields};
    });
  }

  updateFields(field) {
    this.setState((prevState) => {
      const {fields} = prevState;
      const fieldIndex = fields.find((_field) => _field.UNIQUE_ID === field.UNIQUE_ID);

      fields[fieldIndex] = field;
      return {fields};
    });
  }

  renderDropdownField(field) {
    return <DropdownField field={field} value={field.VALUE} onChange={() => this.updateFields(field)} />;
  }

  renderTextField(field) {
    return <TextField field={field} value={field.VALUE?.value} />;
  }

  render() {
    const {extraStyle, updatePage, employee} = this.props;
    const {numPages, page, document, error} = this.state;

    if (!document && !error) {
      return <Loading />;
    }

    return (
      <>
        <SignatureModal
          key={randomString(24)}
          ref={(e) => (this.signatureModal = e)}
          document={document}
          employee={employee}
        />

        <div className={"relative w-full flex flex-col justify-center align-middle items-center"}>
          <div className={"h-[32rem] relative overflow-y-scroll shadow " + extraStyle}>
            {error ? (
              <div className="m-4">{error}</div>
            ) : (
              <Document file={document.DATA} onLoadSuccess={(params) => this.onDocumentLoad(params)}>
                <Page pageNumber={page} />
                {this.renderFields()}
              </Document>
            )}
          </div>

          <div className={"flex flex-row justify-center mt-4 space-x-6"}>
            <button
              className={classNames(
                "border  px-3 py-2 rounded-md",
                page === 1
                  ? "bg-gray-500 text-gray-100 border-gray-500"
                  : "bg-indigo-500 text-white border-indigo-500"
              )}
              onClick={() => {
                const {page} = this.state;
                updatePage && updatePage(Math.max(page - 1, 1));

                this.setState((prevState) => {
                  return {page: Math.max(prevState.page - 1, 1)};
                });
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-left" />
            </button>

            <div className={"align-baseline"}>
              {page} / {numPages}
            </div>

            <button
              className={classNames(
                "border  px-3 py-2 rounded-md",
                page === numPages
                  ? "bg-gray-500 text-gray-100 border-gray-500"
                  : "bg-indigo-500 text-white border-indigo-500"
              )}
              onClick={() => {
                const {page} = this.state;
                updatePage && updatePage(Math.min(page + 1, numPages));

                this.setState((prevState) => {
                  return {page: Math.min(prevState.page + 1, numPages)};
                });
              }}
            >
              <FontAwesomeIcon icon="fa-solid fa-right" />
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default PdfEditor;
