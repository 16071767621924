import * as Yup from "yup";

export const stringValidation = (label, length = null, lengthError = null) => {
  let schema = Yup.string()
    .trim()
    .required(`${label} is required`)
    .label(label)
    .typeError(`${label} is required`);

  if (length) {
    schema = schema.length(length, lengthError || `Must be exactly ${length} characters`);
  }

  return schema;
};

// W-4 Initial Values
export const employeeInfoInitial = {
  firstName: null,
  lastName: null,
  address: null,
  cityOrTown: null,
  state: null,
  zip: null,
  ssn: null,
};

export const taxWithholdingInitial = {
  withholdingExemption: null,
  filingStatus: null,
  exactlyTwoJobs: null,
  dependentsClaim: null,
  otherIncome: null,
  deductions: null,
  additionalWithholding: null,
};

// W-4 Verification Initial Values
export const verificationInitial = {
  employerEIN: "",
  employerCity: "",
  employerState: "",
  employerZip: "",
  employerName: "",
  employerAddress: "",
};

// W-4 Validation Schemas
export const taxWithholdingSchema = Yup.object().shape({
  withholdingExemption: Yup.boolean()
    .required("Withholding exemption is required")
    .label("Withholding Exemption")
    .typeError("Please select withholding exemption status"),
  filingStatus: Yup.string().when("withholdingExemption", {
    is: false,
    then: (schema) =>
      schema
        .required("Filing status is required")
        .label("Filing Status")
        .typeError("Please select filing status"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  exactlyTwoJobs: Yup.boolean().when("withholdingExemption", {
    is: false,
    then: (schema) =>
      schema
        .required("Exactly two jobs is required")
        .label("Exactly Two Jobs")
        .typeError("Please select number of jobs"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  dependentsClaim: Yup.number().when("withholdingExemption", {
    is: false,
    then: (schema) =>
      schema
        .required("Dependents claim amount is required")
        .label("Dependents Claim Amount")
        .typeError("Please fill in dependents claim amount"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  otherIncome: Yup.number().nullable().optional(),
  deductions: Yup.number().nullable().optional(),
  additionalWithholding: Yup.number().nullable().optional(),
});

export const employeeInfoSchema = Yup.object().shape({
  firstName: stringValidation("First name"),
  lastName: stringValidation("Last name"),
  address: stringValidation("Address"),
  cityOrTown: stringValidation("City"),
  state: stringValidation("State"),
  zip: stringValidation("Postal code").matches(/^\d{5}(-\d{4})?$/, "Invalid ZIP code format"),
  ssn: stringValidation("SSN", 11, "SSN must be 9 digits"),
});

export const languageSchema = Yup.object({
  language: Yup.string().required().typeError("Please select a language to continue"),
});

export const verificationSchema = Yup.object().shape({
  employerName: stringValidation("Employer name"),
  employerAddress: stringValidation("Employer address"),
  employeeStartDate: stringValidation("Employee start date"),
  employerEIN: stringValidation("Employer EIN", 10, "Employer EIN must be 9 digits"),
  employerCity: stringValidation("Employer city"),
  employerState: stringValidation("Employer state"),
  employerZip: stringValidation("Employer zip").matches(/^\d{5}(-\d{4})?$/, "Invalid ZIP code format"),
});
