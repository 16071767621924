import {FormTextArea} from "@frostbyte-technologies/frostbyte-tailwind";
import React from "react";

export const DocumentAcknowledge = ({label, name, options}) => {
  return (
    <div className={"mt-4 p-4 bg-white border border-gray-200 rounded-md"}>
      <FormTextArea
        label={label}
        name={name}
        options={options}
        className={"!mt-0 "}
        labelClassName={"mb-2"}
      />
    </div>
  );
};
