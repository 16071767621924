import {Tooltip} from "@frostbyte-technologies/frostbyte-tailwind";
import {FunctionalFormCheckbox} from "./functional-form-checkbox";
import React from "react";

export const MultiCheckbox = ({
  options,
  onChange,
  formatLabel,
  name,
  data,
  title,
  tooltip,
  getSelectedValue,
  noDataMessage = "No data to display",
}) => {
  return (
    <div className="mt-4">
      <div className="flex flex-1 gap-x-2 items-center">
        <span className="block text-sm font-medium text-gray-700">{title}</span>

        {tooltip && <Tooltip data={tooltip} place="right" />}
      </div>

      <div
        className={
          "p-1 bg-white max-h-72 overflow-y-auto border focus:ring-primary-border focus:border-primary-border border-neutral-border block w-full focus:outline-none sm:text-sm rounded-md shadow-sm"
        }
      >
        {data?.length ? (
          data.map((item) => (
            <div key={item.ID} className="flex flex-1 gap-x-2 items-center py-1 px-2">
              <FunctionalFormCheckbox
                customOnChange={() => onChange(item)}
                ignoreMargin
                name={name}
                tooltip={tooltip}
                options={options}
                selectedValue={getSelectedValue(item)}
              />
              <span>{formatLabel(item)}</span>
            </div>
          ))
        ) : (
          <div className="py-1 px-2 text-neutral-text">{noDataMessage}</div>
        )}
      </div>
    </div>
  );
};
