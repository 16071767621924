import {showErrorNotification} from "../../../../utils/notification-helper";
import {PDFDocument, StandardFonts, rgb} from "pdf-lib";
import * as fontkit from "@btielen/pdf-lib-fontkit";
import {OnboardingHubRequests} from "../../../../utils/request-helpers/onboarding/onboarding-hub-requests";
import {ONBOARDING_FIELD_TYPES as types} from "../../../../utils/constants";

export const getFieldValue = (form, fieldName) => {
  const field = form.getTextField(fieldName);
  return field ? field.getText() : null;
};

export const setSignature = (page, form, fieldName, value, font) => {
  const field = form.getTextField(fieldName);

  const widgets = field.acroField.getWidgets();

  if (widgets.length > 0) {
    const fieldRect = widgets[0].getRectangle();

    const x = fieldRect.x;
    const y = fieldRect.y;

    page.drawText(value, {
      x: x,
      y: y,
      font: font,
      size: 12,
    });
  }
};

export const setFieldValue = (form, fieldName, value, font = null) => {
  const field = form.getTextField(fieldName);
  if (field) {
    field.setText(value);
  }

  if (font) {
    field.updateAppearances(font);
  }
};

export const setCheckboxFieldValue = (form, fieldName, isChecked) => {
  const checkbox = form.getCheckBox(fieldName);

  if (checkbox) {
    if (isChecked) {
      checkbox.check();
    } else {
      checkbox.uncheck();
    }
  }
};

const fetchCursiveFont = async () => {
  return fetch("/fonts/DancingScript-Regular.ttf").then((res) => res.arrayBuffer());
};

export const loadPdf = async (signedUrl) => {
  const response = await fetch(signedUrl);

  if (!response.ok) {
    showErrorNotification(
      "Submission Error",
      "An error occurred while trying to save form fields. Please try again."
    );

    return null;
  }

  const arrayBuffer = await response.arrayBuffer();

  return PDFDocument.load(arrayBuffer);
};

export const loadPDFDocument = async (documentAssignmentId) => {
  const signedUrl = await OnboardingHubRequests.fetchSignedUrl(documentAssignmentId);

  try {
    const pdfDoc = await loadPdf(signedUrl);

    const cursiveFont = await fetchCursiveFont();

    pdfDoc.registerFontkit(fontkit);

    const embeddedFont = await pdfDoc.embedFont(cursiveFont, {subset: true});

    const form = pdfDoc.getForm();

    return {pdf: pdfDoc, form: form, font: embeddedFont};
  } catch (error) {
    showErrorNotification(
      "Submission Error",
      "An error occurred while trying to save form fields. Please try again."
    );

    return null;
  }
};

export const downloadPdf = async (pdf, name) => {
  const pdfBytes = await pdf.save();

  const pdfBlob = new Blob([pdfBytes], {type: "application/pdf"});

  const link = document.createElement("a");

  link.href = URL.createObjectURL(pdfBlob);

  link.download = `${name}.pdf`;

  link.click();
};

export const saveFormsToPdf = async (pdf, isComplete = false) => {
  if (isComplete) {
    const form = pdf.getForm();

    form.flatten();
  }

  return pdf.save();
};

const embedFonts = async (pdf) => {
  const fetchedCursive = await fetchCursiveFont();

  pdf.registerFontkit(fontkit);

  const [cursiveFont, helveticaFont] = await Promise.all([
    pdf.embedFont(fetchedCursive, {subset: true}),
    pdf.embedFont(StandardFonts.Helvetica),
  ]);

  return {cursiveFont, helveticaFont};
};

const getDynamicFont = (field, fonts, text) => {
  const fieldWidth = field.WIDTH;

  const fieldHeight = field.HEIGHT;

  const baseFontSize = 16;

  const charWidth = fonts.helveticaFont.widthOfTextAtSize("x", baseFontSize);

  const textWidth = charWidth * text.length;

  const scaleFactorWidth = fieldWidth / textWidth;

  const dynamicFontSizeWidth = baseFontSize * scaleFactorWidth;

  const textHeight = baseFontSize * 1.2;

  const scaleFactorHeight = fieldHeight / textHeight;

  const dynamicFontSizeHeight = baseFontSize * scaleFactorHeight;

  return Math.min(dynamicFontSizeWidth, dynamicFontSizeHeight);
};

export const saveDrawableFieldsToPdf = async (pdf, fields, values, isComplete) => {
  const fonts = await embedFonts(pdf);

  fields?.forEach((field) => {
    const fieldValue = values[field.ID];

    if (fieldValue === undefined || fieldValue === null) {
      return;
    }

    const page = pdf.getPages()[field.PAGE ? field.PAGE - 1 : 0];

    const options = {
      x: field.X_COORDINATE,
      y: page.getHeight() - field.Y_COORDINATE - field.HEIGHT + 3,
      width: field.WIDTH,
      height: field.HEIGHT,
      size: 12,
      font:
        field.FIELD_TYPE === types.SIGNATURE || field.FIELD_TYPE === types.INITIALS
          ? fonts.cursiveFont
          : fonts.helveticaFont,
      color: rgb(0, 0, 0),
      maxWidth: field.WIDTH,
      wordBreaks: [" "],
    };

    if (field.FIELD_TYPE === types.CHECKBOX) {
      if (values[field.ID]) {
        const checkmark = "x";

        const dynamicFontSize = getDynamicFont(field, fonts, checkmark);

        page.drawText(checkmark, {
          ...options,
          size: dynamicFontSize,
        });
      }
    } else {
      const dynamicFontSize = getDynamicFont(field, fonts, fieldValue);

      page.drawText(fieldValue, {...options, size: dynamicFontSize});
    }
  });

  return saveFormsToPdf(pdf, isComplete);
};
