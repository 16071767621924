import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import {showErrorNotification, showSuccessNotification} from "../../../utils/notification-helper";
import {CompanyFolders} from "../../../utils/request-helpers/employees/employee-requests";

class EmployeeFolderModal extends Component {
  state = {employee: null};

  open() {
    this.modal.open();
  }

  handleSubmit = async (values) => {
    const {employee} = this.props;

    if (!employee?.ID) {
      showErrorNotification("", "Missing employee information. Please refresh and try again.");

      this.modal.stopLoading();

      this.modal.close();
    }
    const {name} = values;

    try {
      const payload = {NAME: name};

      await CompanyFolders.create(payload);

      showSuccessNotification("", "Successfully created folder.");

      if (this.props.updateState) {
        this.props.updateState();
      }
    } catch (err) {
      showErrorNotification("", "Error creating folder. Please refresh and try again.");
    } finally {
      this.modal.stopLoading();

      this.modal.close();
    }
  };

  close() {
    this.modal.close();
  }

  render() {
    return (
      <Modal
        buttonLabel={"Create"}
        label={"Create Folder"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
        closeLabel={"Close"}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().trim().required("Folder name is required"),
          })}
        >
          {(formikOptions) => {
            return (
              <>
                <FormInput options={formikOptions} name={"name"} label={"Folder Name"} />
              </>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

EmployeeFolderModal.propTypes = {
  employee: PropTypes.object.isRequired,
};

export default EmployeeFolderModal;
