import {FormElement} from "@frostbyte-technologies/frostbyte-tailwind";

export const FunctionalFormCheckbox = ({name, options, selectedValue, customOnChange, ...props}) => {
  const isChecked = (value) => {
    if (Array.isArray(value)) {
      return value.some((item) => JSON.stringify(item) === JSON.stringify(selectedValue));
    }

    return value;
  };

  const handleChange = (value, onChange, options) => {
    if (customOnChange && typeof customOnChange === "function") {
      customOnChange();
      return;
    }

    if (options) {
      options.setFieldValue(name, !value);
    } else {
      onChange(!value);
    }
  };

  return (
    <FormElement name={name} options={options} value={selectedValue} {...props} ignoreShadow>
      {(value, error, onChange) => {
        return (
          <div className="form-check">
            <input
              className="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-purple-600 transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
              checked={isChecked(value)}
              onChange={() => handleChange(value, onChange)}
            />
          </div>
        );
      }}
    </FormElement>
  );
};
