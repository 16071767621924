import React, {Component} from "react";
import {Modal, FormSelect, FormBoolean, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../utils/request";
import {getCurrentPayrun} from "../../utils/payroll-helper";
import {Formik} from "formik";
import LoadingSpinner from "../../components/loading-spinner";
import {showConfirmAlert, showErrorAlert, showSuccessAlert} from "../../utils/alert-helper";
import {showSuccessNotification} from "../../utils/notification-helper";

class PayrollSuccessModal extends Component {
  state = {currentPayrun: null, location: null};

  async open(locationId) {
    this.setState({location: null}, () => this.slide.open());

    const location = await request("admin/dashboard/location/" + locationId, "GET", null);

    // let currentPayrun = await getCurrentPayrun();
    this.setState({location});
  }

  saveSchedule = async ({TYPE, FIRST_PERIOD_END, PAYDAY}) => {
    let {ID} = this.state.location.location;

    request("admin/pay-schedule/" + ID, "POST", {
      TYPE,
      FIRST_PERIOD_END,
      PAYDAY,
    })
      .then(async (res) => {
        this.slide.close();

        await showSuccessAlert("Schedule Saved");
      })
      .catch((err) => {
        showErrorAlert("Error Saving Schedule");
      });
  };

  render() {
    let whitelisted;
    let {location} = this.state;

    if (location) {
      whitelisted = location.settings.PAYROLL_WHITELISTED;
    }

    return (
      <Modal
        ref={(e) => (this.slide = e)}
        label={location?.location.NAME ?? " "}
        buttonOnClick={this.handleClick}
        disableButtonLoading={true}
      >
        {!location && <LoadingSpinner />}

        {location && (
          <Formik
            enableReinitialize={true}
            initialValues={{
              whitelisted,
            }}
            onSubmit={this.saveSchedule}
            innerRef={(e) => (this.formikRef = e)}
          >
            {(formikOptions, handleSubmit) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div>
                    <FormSelect
                      label="Pay Schedule"
                      name="TYPE"
                      data={[
                        {
                          label: "Biweekly",
                          value: "BIWEEKLY",
                        },
                        {label: "Weekly", value: "WEEKLY"},
                      ]}
                      options={formikOptions}
                    />

                    <FormInput
                      label="First Period End (Format: YYYY-MM-DD)"
                      name="FIRST_PERIOD_END"
                      options={formikOptions}
                    />

                    <FormInput
                      label="First Payday (Format: YYYY-MM-DD)"
                      name="PAYDAY"
                      options={formikOptions}
                    />

                    <div className="flex flex-1">
                      <div
                        onClick={() => this.formikRef.submitForm()}
                        className="mt-4 flex-1 cursor-pointer justify-center text-center inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700"
                      >
                        Save New Pay Schedule
                      </div>
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        )}
      </Modal>
    );
  }
}

export default PayrollSuccessModal;
