import React, {Component} from "react";
import {FormInput, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {request} from "../../utils/request";
import {setupReduxConnection} from "../../redux";
import * as Yup from "yup";

class OtherPayoutSettingsModal extends Component {
  state = {stripeAccount: null};

  open(stripeAccount) {
    this.setState({stripeAccount}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  handleSubmit = async (values) => {
    let {statementDescriptor, payoutDescriptor} = values;

    let payload = {
      statement_descriptor: statementDescriptor,
      payout_descriptor: payoutDescriptor,
    };

    const settingsPayload = {
      PAYOUT_STATEMENT_DESCRIPTOR: payoutDescriptor,
    };

    await request("stripe/connect/update", "POST", payload);
    await request("partner/settings/v2", "PATCH", {SETTINGS: settingsPayload});

    let settings = await request("settings/shop", "GET");
    this.props.updateShop({settings});
    await this.props.refresh();
    this.modal.close();
  };

  render() {
    const {stripeAccount} = this.state;
    const {PAYOUT_STATEMENT_DESCRIPTOR} = this.props.shop.settings;

    return (
      <Modal
        buttonLabel={"Save"}
        label={"Other Payout Settings"}
        ref={(e) => (this.modal = e)}
        formikOnClick={() => this.formikRef}
      >
        <Formik
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
          enableReinitialize
          initialValues={{
            statementDescriptor: stripeAccount?.settings?.payments?.statement_descriptor,
            payoutDescriptor: PAYOUT_STATEMENT_DESCRIPTOR,
          }}
          validationSchema={Yup.object().shape({
            statementDescriptor: Yup.string()
              .test("null", "Payment descriptor required", (val) => !isEmpty(val))
              .test(
                "description-length-test",
                "Payment descriptor can be at most 22 characters",
                (val) => val && val.length <= 22
              ),
            payoutDescriptor: Yup.string()
              .test("null", "Payout descriptor required", (val) => !isEmpty(val))
              .test(
                "description-length-test",
                "Payout descriptor can be at most 20 characters",
                (val) => val && val.length <= 20
              ),
          })}
        >
          {(formikOptions) => {
            const {handleSubmit} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="Payment Statement Descriptor"
                  name="statementDescriptor"
                  maxLength={22}
                  options={formikOptions}
                />

                <FormInput
                  label="Payout Statement Descriptor"
                  name="payoutDescriptor"
                  maxLength={20}
                  tooltip={{
                    data: "The description that will appear on your bank statement next to the payout. Some banks will display this information incorrectly or not at all.",
                  }}
                  options={formikOptions}
                />
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default setupReduxConnection(["shop"])(OtherPayoutSettingsModal);

export function isEmpty(object) {
  return object === null || object === "" || object === undefined;
}
