import React, {Component} from "react";
import {Card, Filter, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import PropTypes from "prop-types";
import {formatShiftString, openShiftStatusToBadge} from "../../../../utils/team-helper";
import {OPEN_SHIFT_APPROVAL_TYPES} from "../../../../utils/team-constants";
import {setupReduxConnection} from "../../../../redux";
import {CheckIcon, XIcon} from "@heroicons/react/solid";
import OpenShiftApprovalModal from "../../../../modals/team/open-shift-approval-modal";
import {renderNoPermission, requestApprovalError} from "../../../../utils/approval-center-helper";
import {OpenShifts} from "../../../../utils/request-helpers/scheduling/scheduling-requests";

class OpenShiftApprovalTab extends Component {
  handleShiftAssignedRejection = (requestId) => {
    let {openShifts, update} = this.props;

    const idx = openShifts.findIndex((item) => item.ID === requestId);

    if (idx !== -1) {
      openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.denied.value;
    }

    update(openShifts);
  };

  handleErrors = (isAccepted, requestId, error) => {
    if (error.error === "SHIFT_ASSIGNED") {
      return isAccepted
        ? requestApprovalError(
            "Shift has already been assigned. You can still mark request as denied to remove it from the pending page."
          )
        : this.handleShiftAssignedRejection(requestId);
    }

    if (error.error === "EMPLOYEE_ROLE_NOT_FOUND") {
      return requestApprovalError("The employee lacks the necessary role permissions for this shift.");
    }

    return requestApprovalError("An error occurred while trying to process your request. Please try again.");
  };

  approveRequest = async (requestId, isAccepted, notes = null) => {
    let {openShifts, update} = this.props;

    try {
      const payload = {STATUS: isAccepted, NOTES: notes};

      await OpenShifts.update(payload, requestId);

      const idx = openShifts.findIndex((item) => item.ID === requestId);

      if (isAccepted) {
        openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.approved.value;
      } else if (idx !== -1) {
        openShifts[idx].STATUS = OPEN_SHIFT_APPROVAL_TYPES.denied.value;
      }

      update(openShifts);
    } catch (err) {
      this.handleErrors(isAccepted, requestId, err);
    }
  };

  render() {
    const {openShifts, hasPermissions} = this.props;
    let {locations} = this.props;
    const {location} = this.props.shop;

    if (!hasPermissions) {
      return renderNoPermission();
    }

    return (
      <Card label="Open Shifts" description="Requests from employees to pick up open shifts">
        <OpenShiftApprovalModal
          ref={(e) => (this.OpenShiftApprovalModal = e)}
          approveRequest={this.approveRequest}
        />

        <Filter
          className="mt-0"
          defaultFilters={[
            {
              filter: "status",
              label: "Pending",
              id: OPEN_SHIFT_APPROVAL_TYPES.pending.value,
            },
            {filter: "location", id: location.ID},
          ]}
          data={[
            {
              id: "location",
              label: "Location",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.SHIFT_LOCATION_ID);
                }),
              options: locations ?? [],
            },
            {
              id: "status",
              label: "Status",
              onFilter: (filter, data) =>
                data.filter((item) => {
                  return filter.includes(item.STATUS);
                }),
              options: [
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.pending.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.pending.label,
                },
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.approved.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.approved.label,
                },
                {
                  id: OPEN_SHIFT_APPROVAL_TYPES.denied.value,
                  label: OPEN_SHIFT_APPROVAL_TYPES.denied.label,
                },
              ],
            },
          ]}
        >
          {(filters) => (
            <Table
              pagination
              data={openShifts}
              filters={filters}
              actionButtons={[
                {
                  label: "View Details",
                  onClick: (row) => this.OpenShiftApprovalModal.open(row),
                },
              ]}
              columns={[
                {
                  value: "NAME",
                  label: "Employee Name",
                  width: 2,
                  format: (value, row) => {
                    return (
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img
                            src={getObjectImage(row, "TRADER_LOGO", "appicon.png")}
                            className="h-10 w-10 rounded-full"
                            alt=""
                          />
                        </div>

                        <div className="ml-4 text-sm font-medium text-gray-900">{row.FULL_NAME}</div>
                      </div>
                    );
                  },
                },
                {
                  value: "SHIFT_DATE",
                  label: "Shift",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div className="text-sm text-gray-500">
                        {formatShiftString(row.DATE_START, row.DATE_END)}
                      </div>
                    );
                  },
                },
                {
                  value: "ROLE_NAME",
                  label: "Role",
                  width: 1,
                  format: (value, row) => {
                    return <div className="text-sm text-gray-500">{row.ROLE_NAME}</div>;
                  },
                },
                {
                  value: "STATUS",
                  label: "Status",
                  width: 1,
                  format: (value) => {
                    return openShiftStatusToBadge(value);
                  },
                },
                {
                  value: "STATUS",
                  width: 1,
                  format: (value, row) => {
                    return (
                      <div>
                        {row.STATUS === OPEN_SHIFT_APPROVAL_TYPES.pending.value && (
                          <div className="flex flex-row">
                            <div
                              className="text-green-600 hover:text-green-900 cursor-pointer font-medium mr-7"
                              onClick={async () => {
                                await this.approveRequest(row.ID, true);
                              }}
                            >
                              <CheckIcon className="h-6 w-6" />
                            </div>

                            <div
                              className="text-red-600 hover:text-red-900 cursor-pointer font-medium"
                              onClick={() => this.approveRequest(row.ID, false)}
                            >
                              <XIcon className="h-6 w-6" />
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  },
                },
              ]}
            />
          )}
        </Filter>
      </Card>
    );
  }
}

OpenShiftApprovalTab.propTypes = {
  openShifts: PropTypes.array.isRequired,
};

export default setupReduxConnection(["user"])(OpenShiftApprovalTab);
