import {Component} from "react";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";
import moment from "moment-timezone";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Calendar extends Component {
  state = {
    beginningOfCurrentMonth: moment().startOf("month"),
    days: [],
    firstSelectedIndex: null,
    secondSelectedIndex: null,
    startEpoch: null,
    endEpoch: null,
  };

  //updateObj with attributes of startEpoch, endEpoch
  onChange = (updateObj) => {
    this.setState(updateObj, this.props.onChange(updateObj));
  };

  componentDidMount() {
    let {startEpoch, endEpoch} = this.props.initialEpochs;

    if (!startEpoch) {
      startEpoch = moment().startOf("day").valueOf();
      endEpoch = startEpoch;
    }

    this.onChange({startEpoch, endEpoch});

    this.setState({
      beginningOfCurrentMonth: moment(startEpoch).startOf("month"),
      days: this.generateDaysOfNewMonth(startEpoch, endEpoch, moment(startEpoch).startOf("month").valueOf()),
    });
  }

  updateDays = (newStart, newEnd) => {
    let {days} = this.state;
    this.setState({days: this.updatedDays(newStart, newEnd, days)});
  };

  setEpochs(updateObj) {
    let {startEpoch, endEpoch} = updateObj;

    this.onChange(updateObj);

    let beginningOfCurrentMonth = moment(startEpoch).startOf("month");

    this.setState({
      beginningOfCurrentMonth,
      days: this.generateDaysOfNewMonth(startEpoch, endEpoch, beginningOfCurrentMonth.valueOf()),
    });
    // this.setState({days: this.updatedDays(startEpoch, endEpoch, days)});
  }

  goBackMonth = () => {
    let {beginningOfCurrentMonth} = this.state;
    let {startEpoch, endEpoch} = this.state;

    if (!(startEpoch || endEpoch)) {
      return;
    }

    beginningOfCurrentMonth.subtract(1, "month").startOf("month");

    this.setState({
      beginningOfCurrentMonth,
      days: this.generateDaysOfNewMonth(startEpoch, endEpoch, beginningOfCurrentMonth.valueOf()),
    });
  };

  goForwardMonth = () => {
    let {beginningOfCurrentMonth} = this.state;
    let {startEpoch, endEpoch} = this.state;

    if (!(startEpoch || endEpoch)) {
      return;
    }

    beginningOfCurrentMonth.add(1, "month").startOf("month");

    this.setState({
      beginningOfCurrentMonth,
      days: this.generateDaysOfNewMonth(startEpoch, endEpoch, beginningOfCurrentMonth.valueOf()),
    });
  };

  setYear = (year) => {
    let {startEpoch, endEpoch} = this.state;

    this.setState({
      beginningOfCurrentMonth: moment(startEpoch).set("year", year).startOf("month"),
      days: this.generateDaysOfNewMonth(
        startEpoch,
        endEpoch,
        moment(startEpoch).set("year", year).startOf("month").valueOf()
      ),
    });
  };

  generateDaysOfNewMonth = (startEpoch, endEpoch, monthStartEpoch) => {
    let monthStartMoment = moment(monthStartEpoch);

    let endOfMonth = monthStartMoment.clone().endOf("month");
    let days = [];

    let currentDayMoment = monthStartMoment.clone();
    while (
      endOfMonth.diff(currentDayMoment, "days") >= 0 &&
      currentDayMoment.format("MM") === endOfMonth.format("MM")
    ) {
      days.push({
        moment: currentDayMoment.clone(),
        date: currentDayMoment.format("DD/MM"),
        isCurrentMonth: true,
        isToday: currentDayMoment.format("DD/MM/YYYY") === moment().format("DD/MM/YYYY"),
      });
      currentDayMoment.add(1, "day");
    }

    let firstTrackedDay = days[0].moment.clone();

    while (firstTrackedDay.day() !== 0) {
      firstTrackedDay.subtract(1, "day");

      days.unshift({
        moment: firstTrackedDay.clone(),
        date: firstTrackedDay.format("DD/MM"),
      });
    }

    let lastTrackedDay = days[days.length - 1].moment.clone();

    while (days.length < 42) {
      lastTrackedDay.add(1, "day");
      days.push({
        moment: lastTrackedDay.clone(),
        date: lastTrackedDay.format("DD/MM"),
      });
    }

    return this.updatedDays(startEpoch, endEpoch, days);
  };

  updatedDays(firstSelectedEpoch, secondSelectedEpoch, days) {
    firstSelectedEpoch = moment(firstSelectedEpoch).startOf("day").valueOf();
    secondSelectedEpoch = moment(secondSelectedEpoch).startOf("day").valueOf();

    for (let day in days) {
      days[day].isSelected = false;
    }

    //If there's a selection, and a day selected is within this month
    if (firstSelectedEpoch) {
      let noIsSelected =
        secondSelectedEpoch < days[0].moment.valueOf() ||
        firstSelectedEpoch > days[days.length - 1].moment.valueOf();

      let firstIndex = days.findIndex((day) => {
        return day.moment.valueOf() === firstSelectedEpoch;
      });

      let secondIndex = days.findIndex((day) => {
        return day.moment.valueOf() === secondSelectedEpoch;
      });

      this.setState({
        firstSelectedIndex: firstIndex,
        secondSelectedIndex: secondIndex,
      });

      if (!noIsSelected) {
        firstIndex = firstIndex === -1 ? 0 : firstIndex;
        secondIndex = secondIndex === -1 ? days.length - 1 : secondIndex;

        while (firstIndex <= secondIndex) {
          days[firstIndex].isSelected = true;
          firstIndex++;
        }
      }
    }

    return days;
  }

  onClick = (dayIndex) => {
    let {singleDay} = this.props;
    let {startEpoch, endEpoch} = this.state;
    let {firstSelectedIndex, secondSelectedIndex, days} = this.state;

    if (singleDay) {
      for (let currentDayIndex in days) {
        days[currentDayIndex].isSelected = false;
      }

      days[dayIndex].isSelected = true;

      this.setState({
        firstSelectedIndex: dayIndex,
        secondSelectedIndex: dayIndex,
        days,
      });

      this.onChange({
        startEpoch: days[dayIndex].moment.valueOf(),
        endEpoch: days[dayIndex].moment.valueOf(),
      });
      return;
    }

    //Case 1: Nothing has been selected
    if (firstSelectedIndex == null) {
      days[dayIndex].isSelected = true;
      this.setState({
        firstSelectedIndex: dayIndex,
        secondSelectedIndex: dayIndex,
        days,
      });

      this.onChange({
        startEpoch: days[dayIndex].moment.valueOf(),
        endEpoch: days[dayIndex].moment.valueOf(),
      });

      //Case 2: The selection is before the first date
    } else if (days[dayIndex].moment.valueOf() < startEpoch) {
      for (let currentDayIndex in days) {
        days[currentDayIndex].isSelected = false;
      }

      days[dayIndex].isSelected = true;

      this.setState({
        firstSelectedIndex: dayIndex,
        secondSelectedIndex: dayIndex,
        days,
      });

      this.onChange({
        startEpoch: days[dayIndex].moment.valueOf(),
        endEpoch: days[dayIndex].moment.valueOf(),
      });

      //Case 3: The selection is at one day, so we extend it on second click
    } else if (startEpoch && endEpoch && firstSelectedIndex === secondSelectedIndex) {
      for (
        let currentIndex = firstSelectedIndex === -1 ? 0 : firstSelectedIndex;
        currentIndex <= dayIndex;
        currentIndex++
      ) {
        days[currentIndex].isSelected = true;
      }
      this.setState({
        secondSelectedIndex: dayIndex,
        days,
      });

      this.onChange({endEpoch: days[dayIndex].moment.valueOf()});

      //Case 4: A range is already selected, so we set the date to the selected one
    } else {
      for (let currentDayIndex in days) {
        days[currentDayIndex].isSelected = false;
      }

      days[dayIndex].isSelected = true;

      this.setState({
        firstSelectedIndex: dayIndex,
        secondSelectedIndex: dayIndex,
      });

      this.onChange({
        startEpoch: days[dayIndex].moment.valueOf(),
        endEpoch: days[dayIndex].moment.valueOf(),
      });
    }
  };

  render() {
    let {beginningOfCurrentMonth, days} = this.state;

    let title = beginningOfCurrentMonth.format("MMMM");

    let currentYear = moment().format("YYYY");
    let calendarYear = moment(beginningOfCurrentMonth).format("YYYY");

    return (
      <div className="w-72 p-4 pt-0 text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
        <div className="flex items-center text-gray-900">
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
          >
            <span className="sr-only">Previous month</span>

            <ChevronLeftIcon onClick={this.goBackMonth} className="h-5 w-5" aria-hidden="true" />
          </button>

          <div className="flex-auto font-semibold space-x-2">
            <span>{title}</span>

            <select
              id="location"
              name="location"
              className="mt-1 w-20 pl-1 pr-1 py-1 text-base font-semibold border-gray-300 sm:text-sm rounded-md"
              value={calendarYear}
              onChange={(e) => {
                this.setYear(e.target.value);
              }}
            >
              {[...Array(moment().year() - 1998).keys()].reverse().map((item, index) => {
                return <option key={index}>{parseInt(currentYear) + 1 - item}</option>;
              })}
            </select>
          </div>
          <button
            type="button"
            className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 select-dropdown"
          >
            <span className="sr-only">Next month</span>

            <ChevronRightIcon onClick={this.goForwardMonth} className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>

        <div className="mt-2 grid grid-cols-7 text-xs leading-6 text-gray-500">
          <div>S</div>
          <div>M</div>
          <div>T</div>
          <div>W</div>
          <div>T</div>
          <div>F</div>
          <div>S</div>
        </div>

        <div className="isolate mt-1 grid grid-cols-7 gap-px rounded-sm bg-gray-200 text-sm shadow ring-1 ring-gray-200">
          {days.map((day, dayIdx) => (
            <button
              key={day.date}
              type="button"
              className={classNames(
                "py-1.5 hover:bg-gray-100 focus:z-10",
                day.isCurrentMonth ? "bg-white" : "bg-gray-50",
                (day.isSelected || day.isToday) && "font-semibold",
                day.isSelected && "text-white",
                !day.isSelected && day.isCurrentMonth && !day.isToday && "text-gray-900",
                !day.isSelected && !day.isCurrentMonth && !day.isToday && "text-gray-400",
                day.isToday && !day.isSelected && "text-indigo-600",
                dayIdx === 0 && "rounded-tl-sm",
                dayIdx === 6 && "rounded-tr-sm",
                dayIdx === days.length - 7 && "rounded-bl-sm",
                dayIdx === days.length - 1 && "rounded-br-sm"
              )}
              onClick={() => {
                this.onClick(dayIdx);
              }}
            >
              <div
                className={classNames(
                  "mx-auto flex h-5 w-5 items-center justify-center rounded-sm",
                  day.isSelected && day.isToday && "bg-indigo-600",
                  day.isSelected && !day.isToday && "bg-gray-900"
                )}
              >
                {day.moment.format("D")}
              </div>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default Calendar;
