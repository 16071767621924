import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import {ExternalLink} from "react-feather";
import ScheduledTicketsLineItems from "./ScheduledTicketsLineItems";

class ScheduledTickets extends Component {
  render() {
    let {DATE, TICKETS} = this.props.day;

    return (
      <div className="p-7 px-16 py-16 pt-12">
        <div className="font-semibold pb-3">Tickets Scheduled for {DATE}</div>

        <Table
          columns={COLUMN_HEADERS}
          data={TICKETS.map((item) => ({...item}))}
          expandable={(row, index) => <ScheduledTicketsLineItems lineItems={row.LINE_ITEMS} />}
        />
      </div>
    );
  }
}

const COLUMN_HEADERS = [
  {
    label: "Ticket Number",
    value: "TICKET_NUMBER",
    sortable: true,
  },
  {
    label: "Name",
    value: "TICKET_NAME",
    sortable: true,
  },
  {
    label: "Date Scheduled",
    value: "TICKET_DATE_SCHEDULED",
    sortable: true,
    format: (value) => moment(value).format("h:mm A"),
  },
  {
    label: "Phone",
    value: "TICKET_PHONE",
    format: (value) => getFormattedPhone(value),
  },
  {
    label: "New Tab",
    value: "TICKET_UNIQUE_ID",
    format: (value) => (
      <div>
        <ExternalLink
          className="h-4 w-4 cursor-pointer"
          onClick={() => {
            window.open(`${window.location.origin}/ticket/${value}`, "_newtab");
          }}
        />
      </div>
    ),
    // cell: (row) => (
    //   <a
    //     href={"/ticket/orders/" + row.TICKET_UNIQUE_ID}
    //     target={"_blank"}
    //   >
    //     <Button color="primary">
    //       <ExternalLink size={14}/>
    //     </Button>
    //   </a>
  },
];
export default ScheduledTickets;
