import {DOCUMENT_TYPE, formatType} from "../documents-helper";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {useDefaultDocuments} from "../../../../../../hooks/team/onboarding/onboarding-hooks";
import {getTableData} from "../../onboarding-helper";
import {useSignedUrl} from "../../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

export default function DefaultDocumentsTab() {
  const COLUMNS = [
    {
      value: "NAME",
      label: "Name",
    },
    {
      value: "TYPE",
      label: "Type",
      format: (value) => formatType(value),
    },
  ];

  const {openDocument} = useSignedUrl();

  const handleViewDocument = (document) => {
    openDocument(document.ID);
  };

  const {isLoading, error, data} = useDefaultDocuments();

  return (
    <>
      <Card
        label={"Federal Documents"}
        description={
          "A collection of pre-configured federal documents with guided completion flows for employees."
        }
      >
        <Table
          classNames={"mt-8"}
          data={getTableData(
            isLoading,
            error,
            data?.filter((document) => document.TYPE === DOCUMENT_TYPE.FEDERAL)
          )}
          isLoading={isLoading}
          pagination={true}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => handleViewDocument(row),
            },
          ]}
          columns={COLUMNS}
        />
      </Card>
    </>
  );
}
