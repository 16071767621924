import {useTranslation} from "react-i18next";
import {useMemo, useRef} from "react";
import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import Wizard from "../../../../../components/wizard";
import {LanguageSelectStep} from "../language-select-step";
import {W4TaxWithholdingStep} from "./steps/w4-tax-withholding-step";
import * as Yup from "yup";
import {setW4FormFields} from "./w4-fill-pdf-helper";
import {W4EmployeeInfoStep} from "./steps/w4-employee-info-step";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {SignatureForm} from "../signature-form";
import {
  employeeInfoInitial,
  employeeInfoSchema,
  languageSchema,
  taxWithholdingInitial,
  taxWithholdingSchema,
} from "./w4-helper";
import {useSubmitDocument} from "../../../../../hooks/team/onboarding/submission-hooks";
import {handleSignatureValidation} from "../federal-form-helpers";

export const W4FormPage = () => {
  const {t} = useTranslation();

  const navigate = useNavigate();
  const location = useLocation();

  const packetAssUId = useMemo(() => location.state?.packetAssignmentUId, [location.state]);

  const {handleSubmit, reviewLoading} = useSubmitDocument(packetAssUId);

  const {DOCUMENT_ASS_ID} = useParams();

  const wizardRef = useRef(null);

  const getI18NextKey = (path) => {
    return "pages.onboarding.onboarding-landing-page." + path;
  };

  const getSignatureKeys = (path) => {
    return "pages.onboarding.w-4." + path;
  };

  const getW4Steps = () => {
    const steps = [];

    steps.push(
      {
        id: 0,
        name: t(getI18NextKey("select-language")),
        initialValues: {
          language: null,
        },
        yup: languageSchema,
        description: t(getI18NextKey("select-language-description")),
        render: (formik) => <LanguageSelectStep formik={formik} />,
      },
      {
        id: 1,
        name: t(getI18NextKey("employee-info-w4")),
        description: t(getI18NextKey("employee-info-w4-description")),
        initialValues: employeeInfoInitial,
        yup: employeeInfoSchema,
        render: (formik) => <W4EmployeeInfoStep formik={formik} />,
      },
      {
        id: 2,
        name: t(getI18NextKey("tax-withholding")),
        description: t(getI18NextKey("tax-withholding-description")),
        initialValues: taxWithholdingInitial,
        yup: taxWithholdingSchema,
        render: (formik) => <W4TaxWithholdingStep formik={formik} />,
      },
      {
        id: 3,
        name: t(getI18NextKey("employee-verification")),
        initialValues: {
          signature: null,
        },
        yup: Yup.object().shape({}),
        customValidation: (formik) => handleSignatureValidation(formik),
        render: (formik) => (
          <SignatureForm formik={formik} label={t(getSignatureKeys("attestation"))} className={"w-3/4"} />
        ),
      }
    );

    return steps;
  };

  const navigateBack = () => {
    navigate(location.state?.from ?? "/hub/employee-onboarding", {replace: true});
  };

  const handleW4Submit = async (values) => {
    await handleSubmit(DOCUMENT_ASS_ID, values, setW4FormFields, navigateBack);
  };

  return (
    <Card label={"Federal W-4"} noHeaderBorder={true} className={"py-6 px-4 items-center justify-center"}>
      <div className={"my-4 mx-6"}>
        <Wizard
          flexHeader
          className={"mx-20 h-screen"}
          ref={wizardRef}
          steps={getW4Steps()}
          onSubmit={(values) => handleW4Submit(values)}
          submitLabel={t(getI18NextKey("finish"))}
          onQuit={navigateBack}
        />
      </div>
    </Card>
  );
};
