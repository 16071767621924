import React, {Component} from "react";
import {Modal, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik, Form} from "formik";
import * as Yup from "yup";
import FormDateTimeSelect from "../../components/form-date-time-select";
import moment from "moment-timezone";

class PayScheduleSetupModal extends Component {
  open() {
    this.slide.open();
  }

  render() {
    return (
      <Modal ref={(e) => (this.slide = e)} label={`Pay Schedule Setup`} buttonLabel="Create">
        <Formik
          initialValues={{}}
          validationSchema={Yup.object().shape({
            firstPayday: Yup.number().required("Please select a first payday"),
            firstPeriodEnd: Yup.number().required("Please select a first period end"),
          })}
        >
          {(formikOptions) => {
            let {setFieldValue, handleSubmit, values} = formikOptions;
            this.setFieldValue = setFieldValue;

            let {firstPayday, firstPeriodEnd} = values;
            let deadline, period;
            if (firstPayday) {
              deadline = moment(firstPayday).subtract(3, "days").format("MM/DD/yyyy");
            }

            if (firstPeriodEnd) {
              period =
                moment(firstPeriodEnd).subtract(2, "weeks").format("MM/DD/yyyy") +
                " - " +
                moment(firstPeriodEnd).format("MM/DD/yyyy");
            }

            return (
              <div>
                <div className="text-sm font-bold">Paydays will occur every 2 weeks</div>

                <FormDateTimeSelect
                  name="firstPayday"
                  label="End of First Period"
                  buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                  options={formikOptions}
                  hideTime={true}
                />

                {deadline && (
                  <FormDateTimeSelect
                    name="deadline"
                    label="Deadline to Run Payroll"
                    buttonText={(epoch) => moment(epoch).format("M/D/YY")}
                    value={deadline}
                    options={formikOptions}
                    hideTime={true}
                  />
                )}
              </div>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

export default PayScheduleSetupModal;
