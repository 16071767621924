import React, {Component} from "react";
import {FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import FormDateTimeSelect from "../../../components/form-date-time-select";
import moment from "moment-timezone";
import * as Yup from "yup";

class ChecklistRuleModal extends Component {
  state = {rule: null};

  open(rule = null) {
    this.setState({rule}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.modal.open();
    });
  }

  async createRule({type, time, date, week, biWeek, month, quarter, year}) {
    const {checklist} = this.props;

    let rulePayload = {
      RESET_TIME: time,

      ISO_DATE: date,
      ISO_WEEK: week,
      ISO_BI_WEEK: biWeek,
      ISO_MONTH: month,
      ISO_QUARTER: quarter,
      ISO_YEAR: year,
    };

    // eslint-disable-next-line default-case
    switch (parseInt(type)) {
      case 1: {
        rulePayload.ISO_WEEK = parseInt(week);
        break;
      }
      case 6: {
        rulePayload.ISO_BI_WEEK = parseInt(biWeek);
        break;
      }
      case 2: {
        rulePayload.ISO_MONTH = moment.utc(month).date();
        break;
      }
      case 5: {
        rulePayload.ISO_DATE = moment.utc(date).format("MM/DD/YY");
        break;
      }
    }

    const serverRule = await request("checklists/" + checklist.ID + "/rules", "POST", rulePayload);

    this.props.addState(serverRule);
    this.modal.close();
  }

  async saveRule({type, time, date, week, biWeek, month}) {
    const {checklist} = this.props;
    const {rule} = this.state;

    let rulePayload = {
      RESET_TIME: time,

      ISO_DATE: null,
      ISO_WEEK: null,
      ISO_BI_WEEK: null,
      ISO_MONTH: null,
      ISO_QUARTER: null,
      ISO_YEAR: null,
    };

    // eslint-disable-next-line default-case
    switch (parseInt(type)) {
      case 1:
        rulePayload.ISO_WEEK = parseInt(week);
        break;
      case 6:
        rulePayload.ISO_BI_WEEK = parseInt(biWeek);
        break;
      case 2:
        rulePayload.ISO_MONTH = moment.utc(month).date();
        break;
      case 5:
        rulePayload.ISO_DATE = moment.utc(date).format("MM/DD/YY");
        break;
    }

    const serverRule = await request("checklists/" + checklist.ID + "/rules/" + rule.ID, "POST", rulePayload);

    this.props.updateState(serverRule.ID, serverRule);
    this.modal.close();
  }

  async deleteRule() {
    const {rule} = this.state;
    const {checklist} = this.props;

    const serverLine = await request("checklists/" + checklist.ID + "/rules/" + rule.ID, "DELETE", {});

    this.props.updateState(serverLine.ID);
    this.modal.close();
  }

  renderContent(options) {
    switch (parseInt(options.values.type)) {
      default:
        return <div />;
      case 1:
        return (
          <FormSelect
            options={options}
            label="Reset Day"
            name="week"
            data={[
              {value: "0", label: "Monday"},
              {value: "1", label: "Tuesday"},
              {value: "2", label: "Wednesday"},
              {value: "3", label: "Thursday"},
              {value: "4", label: "Friday"},
              {value: "5", label: "Saturday"},
              {value: "6", label: "Sunday"},
            ]}
          />
        );
      case 2:
        return (
          <FormDateTimeSelect
            buttonText={(epoch) => moment(epoch).format("Do")}
            label="Day of Month"
            options={options}
            name="month"
            hideTime
          />
        );
      case 5:
        return (
          <FormDateTimeSelect
            buttonText={(epoch) => moment(epoch).format("M/D/YY")}
            label="One Time Date"
            options={options}
            name="date"
            hideTime
          />
        );
      case 6:
        return (
          <FormSelect
            options={options}
            label="Reset Day (every other week)"
            name="biWeek"
            data={[
              {value: "0", label: "Monday"},
              {value: "1", label: "Tuesday"},
              {value: "2", label: "Wednesday"},
              {value: "3", label: "Thursday"},
              {value: "4", label: "Friday"},
              {value: "5", label: "Saturday"},
              {value: "6", label: "Sunday"},
            ]}
          />
        );
    }
  }

  render() {
    const {rule} = this.state;

    let initialValues = {
      type: "0",
      time: null,
      week: null,
      biWeek: null,
      month: null,
      quarter: null,
      year: null,
      date: null,
    };

    if (rule) {
      initialValues.time = rule.RESET_TIME;

      if (rule.ISO_WEEK !== null) {
        initialValues.type = "1";
        initialValues.week = rule.ISO_WEEK;
      }

      if (rule.ISO_BI_WEEK !== null) {
        initialValues.type = "6";
        initialValues.biWeek = rule.ISO_BI_WEEK;
      }

      if (rule.ISO_MONTH !== null) {
        initialValues.type = "2";
        initialValues.month = moment().startOf("month").add(rule.ISO_MONTH, "days").valueOf();
      }

      if (rule.ISO_DATE !== null) {
        initialValues.type = "5";
        initialValues.date = moment(rule.ISO_DATE).valueOf();
      }
    }

    return (
      <Modal
        deleteLabel={rule && "Delete"}
        deleteOnClick={this.deleteRule.bind(this)}
        buttonLabel={rule ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        label={rule ? "Edit Rule" : "Create Rule"}
      >
        <Formik
          enableReinitialize
          innerRef={(e) => (this.formikRef = e)}
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            type: Yup.string().nullable().required(),
            time: Yup.string().nullable().required(),
            week: Yup.string()
              .nullable()
              .test(
                "week-test",
                "Please select a day of the week.",
                (val, context) => context.parent.type !== "1" || (val && val.length > 0)
              ),
            biWeek: Yup.string()
              .nullable()
              .test(
                "biweek-test",
                "Please select a day of the week.",
                (val, context) => context.parent.type !== "6" || val
              ),
            month: Yup.string()
              .nullable()
              .test(
                "month-test",
                "Please select a day of the month.",
                (val, context) => context.parent.type !== "2" || val
              ),
            date: Yup.string()
              .nullable()
              .test(
                "date-test",
                "Please select a day.",
                (val, context) => context.parent.type !== "5" || val
              ),
          })}
          onSubmit={rule ? this.saveRule.bind(this) : this.createRule.bind(this)}
        >
          {(formikOptions) => {
            const {handleSubmit, setFieldValue} = formikOptions;

            return (
              <form onSubmit={handleSubmit}>
                <FormInput type="time" label="Time" name="time" options={formikOptions} />

                <FormSelect
                  name="type"
                  label="Type"
                  options={formikOptions}
                  onChangeSoft={(target) => {
                    setFieldValue("week", null);
                    setFieldValue("biWeek", null);
                    setFieldValue("month", null);
                    setFieldValue("quarter", null);
                    setFieldValue("year", null);
                    setFieldValue("date", null);

                    // eslint-disable-next-line default-case
                    switch (target.value) {
                      case "1":
                        return setFieldValue("week", "0");
                      case "6":
                        return setFieldValue("biWeek", "0");
                    }
                  }}
                  data={[
                    {value: "0", label: "Daily"},
                    {value: "1", label: "Weekly"},
                    {value: "6", label: "Bi-Weekly"},
                    {value: "2", label: "Monthly"},
                    // {value: "3", label: "Quarterly"},
                    // {value: "4", label: "Yearly"},
                    {value: "5", label: "One Time"},
                  ]}
                />

                {this.renderContent(formikOptions)}
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

ChecklistRuleModal.propTypes = {
  updateState: PropTypes.func.isRequired,
  addState: PropTypes.func.isRequired,
};

export default ChecklistRuleModal;
