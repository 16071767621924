import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Loading, PageHeadings, Table, Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import TagDetailsModal from "../../../modals/sales/tag-details-modal";

class TagsPage extends Component {
  state = {
    isLoading: true,
    tags: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    this.setState({isLoading: true});
    let tags = await request("tags", "GET");

    tags = tags.filter((item) => item.DATE_ARCHIVED === null);

    this.setState({isLoading: false, tags});
  }

  render() {
    const {isLoading, tags} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <TagDetailsModal
          ref={(e) => (this.tagModal = e)}
          updateState={(tag) => this.props.router.navigate("/tag/" + tag)}
        />

        <PageHeadings
          label="Tags"
          description="Create custom tags to be applied to products"
          button={{
            onClick: () => this.tagModal.open(),
            label: "Create Tag",
          }}
        />

        <Card
          label={"Tags"}
          tooltip={{
            data: "Tags can be applied to products which can be used to filter your reports",
          }}
        >
          <Table
            pagination
            ref={(e) => (this.tableRef = e)}
            data={tags}
            actionButtons={[
              {
                label: "View",
                onClick: (row) => this.props.router.navigate("/tag/" + row.ID),
              },
            ]}
            columns={[
              {
                value: "NAME",
                label: "Tag Name",
              },
              {
                value: "DATE_CREATED",
                label: "Created",
                mobile: "lg:table-cell",
                format: (value, _) => {
                  return moment(value).format("MM/DD/YY");
                },
              },
              {
                value: "DATE_UPDATED",
                label: "Updated",
                mobile: "lg:table-cell",
                format: (value) => {
                  return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
                },
              },
            ]}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(TagsPage));
