import React, {Component} from "react";
import {PageHeadings, Card, Table, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SendTextModal from "../../../modals/operations/chats/send-text-modal";
import {showConfirmAlert} from "../../../utils/alert-helper";

class ChatPage extends Component {
  state = {isLoading: true, chat: {}};

  async componentDidMount() {
    await this.syncState();
  }

  async syncState() {
    const {PHONE} = this.props.router.params;

    const chat = await request("support/chats/" + PHONE, "GET");

    this.setState({chat, isLoading: false});
  }

  addText = (text) => {
    let {chat} = this.state;

    chat.TEXTS.unshift(text);

    this.setState({chat});
  };

  closeChat = () => {
    showConfirmAlert("Are you sure you want to close this chat?").then(async () => {
      const {PHONE} = this.props.router.params;

      await request("support/text", "DELETE", {PHONE});

      let {chat} = this.state;
      chat.IS_OPEN = false;
      this.setState({chat});
    });
  };

  render() {
    const {PHONE} = this.props.router.params;
    let {NAME} = this.props.shop.location;
    let {chat, isLoading} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    let buttons = [
      {
        label: "Send Text",
        onClick: () => this.sendTextModal.open(PHONE),
      },
    ];

    if (chat?.IS_OPEN) {
      buttons.push({
        label: "Close Chat",
        onClick: () => this.closeChat(),
      });
    }

    if (chat?.PATRON) {
      buttons.push({
        label: "View Patron",
        onClick: () => this.props.router.navigate(`/patron/${chat.PATRON.UNIQUE_ID}`),
      });
    }

    return (
      <div>
        <PageHeadings label={"Chat"} />

        <SendTextModal addText={this.addText} ref={(e) => (this.sendTextModal = e)} />

        <Card
          label={
            <div className="flex flex-col">
              <div>{chat?.FULL_NAME}</div>
              <div className="text-gray-500 text-sm">{getFormattedPhone(PHONE)}</div>
            </div>
          }
          buttons={buttons}
        >
          <Table
            columns={[
              {
                label: "Sender",
                value: "sender",
              },
              {
                label: "Text Content",
                value: "content",
                wrap: true,
              },
              {
                label: "Date Sent",
                value: "created",
              },
            ]}
            data={chat?.TEXTS?.map((item, index) => ({
              item,
              id: index,
              sender: item.USER_TEXT ? chat.FULL_NAME : NAME,
              content: item.CONTENT,
              sent: item.DATE_CREATED,
              created: moment(item.DATE_CREATED).format("h:mma MM/DD/YY"),
            }))}
          />
        </Card>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(ChatPage));
