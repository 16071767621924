import {showErrorNotification} from "../../../../utils/notification-helper";
import {useTranslation} from "react-i18next";
import React from "react";

export const LanguageSelectStep = ({formik}) => {
  const {i18n} = useTranslation();

  const setLanguageValues = (languageCode) => {
    try {
      i18n.changeLanguage(languageCode);

      formik?.setFieldValue("language", languageCode);
    } catch (err) {
      showErrorNotification(
        "Error",
        "An error occurred while setting the language. Please refresh to try again."
      );
    }
  };

  return (
    <>
      <div className={"flex flex-col items-center justify-center space-y-2 m-8"}>
        <LanguageButton language={"English"} handleLanguageSelection={() => setLanguageValues("en")} />

        <LanguageButton language={"Español"} handleLanguageSelection={() => setLanguageValues("es")} />
      </div>

      {formik.touched["language"] && formik.errors["language"] && (
        <div className="text-sm text-red-500 mt-2 text-right">{formik.errors["language"]}</div>
      )}
    </>
  );
};

const LanguageButton = ({language, handleLanguageSelection}) => {
  return (
    <button
      type={"button"}
      onClick={handleLanguageSelection}
      className={`flex items-center justify-center text-center w-1/2 py-5 bg-white border border-gray-300 shadow-sm text-lg leading-4 font-medium rounded-md text-gray-700 hover:outline-none hover:bg-indigo-500 hover:text-white focus:outline-none focus:bg-indigo-500 focus:text-white transition-colors duration-200 ease-in-out 
        `}
    >
      {language}
    </button>
  );
};
