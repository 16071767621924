export const DOCUMENT_ERROR = "Document Error";

export const SUCCESS = "Success";

export const PACKET_ERROR = "Packet Error";

export const PACKET_ASSIGNMENT_ERROR = "Packet Assignment Error";

export const EMAIL_ERROR = "Email Error";

export const APPROVAL_ERROR = "Approval Error";

export const REVIEW_ERROR = "Review Error";

export const DOCUMENT_SUBMISSION_ERROR_MESSAGE = "Document couldn't be submitted. Please try again.";
