import React, {Component} from "react";
import {ISO_DAYS} from "../../../utils/constants";
import moment from "moment-timezone";
import {setupReduxConnection} from "../../../redux";
import {Formik} from "formik";
import FormTimeSelect from "../../../components/form-time-select";
import FormCheckbox from "../../../components/form-elements/form-checkbox";
import {getHours} from "../../../utils/hours-helper";
import {HOURS_TYPES} from "../../../utils/shop-constants";

class HoursRange extends Component {
  state = {
    entryCreated: false,
    storeOpen: STATUS.OPENED,
    toggleOpen: 0,
    deliveryEnabled: STATUS.DELIVERY_ENABLED,
    openTime: 0,
    closeTime: 0,
  };

  componentDidMount() {
    let {storeHours, selectedHours, hoursType, day} = this.props;

    let {open: storeOpen, storeOpenTime: openTime, storeCloseTime: closeTime} = getHours(storeHours, day);

    let toggleOpen = storeOpen;

    if (hoursType !== HOURS_TYPES.STORE) {
      let {open, storeOpenTime, storeCloseTime} = getHours(selectedHours, day, openTime, closeTime);

      toggleOpen = open;
      openTime = storeOpenTime;
      closeTime = storeCloseTime;
    }

    this.setState({
      storeOpen,
      toggleOpen,
      openTime,
      closeTime,
    });

    // let {isStoreHours, hours, deliveryHours, day} = this.props;
    // let entryCreated = false;
    //
    // if (deliveryHours && Object.keys(deliveryHours).length > 0) {
    //   entryCreated = true;
    //   this.setState({entryCreated});
    // }
    //
    // let {
    //   open: storeOpen,
    //   storeOpenTime: openTime,
    //   storeCloseTime: closeTime,
    // } = getHours(hours, day);
    //
    // let toggleOpen = storeOpen;
    //
    // if (!isStoreHours) {
    //   let {open, storeOpenTime, storeCloseTime} = getHours(
    //     deliveryHours,
    //     day,
    //     openTime,
    //     closeTime
    //   );
    //   toggleOpen = open;
    //   openTime = storeOpenTime;
    //   closeTime = storeCloseTime;
    // }
    //
    // this.setState({
    //   storeOpen,
    //   toggleOpen,
    //   openTime,
    //   closeTime,
    // });
  }

  getValue() {
    return this.handleSubmit(this.state);
  }

  handleSubmit = (values) => {
    let {day} = this.props;

    let {storeOpen, toggleOpen, openTime, closeTime} = this.state;

    if (openTime > closeTime) {
      closeTime += 1440;
    }

    let base = moment();

    if (moment().isDST() !== moment().subtract(1, "day").isDST()) {
      base.add(1, "day");
    }

    let toReturn = {
      [ISO_DAYS[day].toUpperCase() + "_OPEN"]: !toggleOpen
        ? "CLOSED"
        : base.clone().startOf("day").add(openTime, "minutes").valueOf(),
      [ISO_DAYS[day].toUpperCase() + "_CLOSE"]: !toggleOpen
        ? "CLOSED"
        : base.clone().startOf("day").add(closeTime, "minutes").valueOf(),
    };

    return toReturn;
  };

  render() {
    let {day, hoursType} = this.props;
    let {storeOpen, toggleOpen, openTime, closeTime} = this.state;

    let isStoreHours = hoursType === HOURS_TYPES.STORE;

    let closedText = isStoreHours && !storeOpen ? "Closed" : "Disabled";
    let {storeHours, selectedHours} = this.props;

    return (
      <div>
        <div className="flex flex-row items-center h-14">
          {((!isStoreHours && storeOpen) || isStoreHours) && (
            <FormCheckbox
              className="mt-0"
              value={toggleOpen}
              onChange={() => this.setState({toggleOpen: !toggleOpen})}
            />
          )}

          {!storeOpen && !isStoreHours && <div className="w-7"></div>}

          <div className="w-28">{ISO_DAYS[day]}</div>

          {((!isStoreHours && storeOpen) || isStoreHours) && toggleOpen && (
            <div className="flex flex-row items-center">
              <FormTimeSelect
                className={"mt-0"}
                value={openTime}
                onChange={(openTime) => this.setState({openTime})}
              />

              <div className="px-3">to</div>

              <FormTimeSelect
                className={"mt-0"}
                value={closeTime}
                onChange={(closeTime) => this.setState({closeTime})}
              />
            </div>
          )}

          {!((!isStoreHours && storeOpen) || isStoreHours) && (
            <div className="font-semibold text-red-600">Closed</div>
          )}

          {!isStoreHours && storeOpen && !toggleOpen && (
            <div className="font-semibold text-red-600">{closedText}</div>
          )}
        </div>
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(HoursRange);

const STATUS = {
  OPENED: "opened",
  CLOSED: "closed",
  DELIVERY_ENABLED: "enabled",
  DELIVERY_DISABLED: "disabled",
};
