import React, {Component} from "react";
import {
  clearItems,
  getBreakOptions,
  getExtras,
  hasNoPayTimeCard,
  getOffCycleDraft,
  saveOffCyclePayroll,
  showPayrollError,
} from "../../../../utils/payroll-helper";
import {Formik} from "formik";
import LoadingSpinner from "../../../../components/loading-spinner";
import FormCheckbox from "../../../../components/form-elements/form-checkbox";
import {showErrorAlert, showLoadingConfirmAlert} from "../../../../utils/alert-helper";
import PayrollRunTable from "../payroll-run-table";
import {request} from "../../../../utils/request";
import {setupReduxConnection} from "../../../../redux";
import {withRouter} from "../../../../utils/navigation";
import {RUN_MODES} from "../../../../utils/payroll-constants";
import {showSuccessNotification} from "../../../../utils/notification-helper";

class OffCycleChooseHoursComponent extends Component {
  state = {
    loading: true,
    unapprovedCards: [],
    payrollDraft: {},
    payrollForm: null,
    breakdown: null,
    includedEmployees: [],
    includeHours: false,
    employees: null,
    extras: null,
    checkPayrollId: null,
    dbaLocations: null,
  };

  componentDidMount() {
    this.refresh();
  }

  refresh() {
    request("team/timesheet-unapproved", "GET").then((unapprovedCards) => {
      this.setState({unapprovedCards});
    });

    getBreakOptions().then((breakOptions) => {
      this.setState({breakOptions});
    });

    getOffCycleDraft()
      .then((res) => {
        let {runMode} = this.props.payroll;

        let {
          PAYROLL_FORM,
          SALARIED_EMPLOYEES_BREAKDOWN,
          EMPLOYEE_TIMESHEET_BREAKDOWN,
          OTHER_EMPLOYEE_BREAKDOWN,
          SKIPPED_EMPLOYEE_NAMES,
          DBA_LOCATIONS,
          EMPLOYEES,
          EXTRAS,
          INCLUDED_EMPLOYEES,
          CHECK_PAYROLL_ID,
        } = res;

        let combinedBreakdown = [
          ...SALARIED_EMPLOYEES_BREAKDOWN,
          ...EMPLOYEE_TIMESHEET_BREAKDOWN,
          ...OTHER_EMPLOYEE_BREAKDOWN,
        ];

        if (INCLUDED_EMPLOYEES.length === 0) {
          INCLUDED_EMPLOYEES = combinedBreakdown.map((item) => {
            return item.ID;
          });
        }

        let employeeNamesStr = SKIPPED_EMPLOYEE_NAMES.reduce((str, item) => {
          return (str += item + ", ");
        }, "");
        employeeNamesStr = employeeNamesStr.substring(0, employeeNamesStr.length - 2);

        this.setState(
          {
            loading: false,
            payrollForm: PAYROLL_FORM,
            breakdown: combinedBreakdown,
            employees: EMPLOYEES,
            extras: EXTRAS,
            includedEmployees: INCLUDED_EMPLOYEES,
            includeHours: runMode !== "BONUS",
            checkPayrollId: CHECK_PAYROLL_ID,
            dbaLocations: DBA_LOCATIONS,
            payrollDraft: res,
          },
          async () => {
            if (SKIPPED_EMPLOYEE_NAMES.length > 0) {
              await showErrorAlert(
                "Employees Skipped",

                `${employeeNamesStr} ${
                  SKIPPED_EMPLOYEE_NAMES.length > 1 ? "are" : "is"
                } cannot be included in this payroll as they have not completed their onboarding process.`,

                "Ok"
              );
            }
          }
        );
      })
      .catch((e) => {
        showErrorAlert(
          "Error",
          "An error occurred. Please reach out to support at (781)-583-3699 for more information",
          "Ok"
        );

        this.props.router.navigate("/payroll");
      });
  }

  refreshExtras = async () => {
    let extras = await getExtras(RUN_MODES.OFF_CYCLE);
    this.setState({extras});

    return true;
  };

  async updateCard(card, finalBreakdown) {
    card.TIMESHEET_LOCATION_NAME = card.LOCATION_NAME;

    const employee = finalBreakdown?.find(({ID}) => ID === card.EMPLOYEE_ID);

    const index = employee?.TIMESHEETS?.findIndex(({ID}) => ID === card.ID);

    if (index !== -1) {
      employee.TIMESHEETS.splice(index, 1, card);
      await this.refresh();
      showSuccessNotification("Timecard Updated");
    }
  }

  handleClick = async () => {
    const {breakdown} = this.state;
    let {runMode} = this.props.payroll;

    const allTimeCards = [].concat(...breakdown.map((emp) => emp.TIMESHEETS ?? []));

    const canContinue = (await this.formikRef.submitForm()) ? true : false;

    this.props.updateNavState({
      metadata: {...this.props.navState.metaData, payrollDraft: this.state.payrollDraft},
    });

    if (runMode === RUN_MODES.OFF_CYCLE && hasNoPayTimeCard(allTimeCards)) {
      return showPayrollError(
        "Time Cards Without Pay Detected",
        "One or more time cards in this pay period have no pay attached. Employees with no pay time cards are marked in red. Are you sure you want to continue?",
        canContinue
      );
    }

    return canContinue;
  };

  handleSubmit = async (values) => {
    let {loading, includeHours, includedEmployees, extras} = this.state;
    let {runMode} = this.props.payroll;

    if (loading) {
      return false;
    }

    if (
      (includeHours && includedEmployees.length === 0) ||
      (!includeHours && Object.keys(extras).length === 1)
    ) {
      await showErrorAlert(
        "Empty Off-Cycle Payroll",
        "This payroll is currently empty. Please add employees' hours or bonus/reimbursements.",
        "Ok"
      );

      return false;
    }

    let {FORCE_SUPPLEMENTAL_WITHHOLDING, APPLY_BENEFITS, APPLY_POST_TAX_DEDUCTIONS} = values;

    let PAYROLL_FORM = {
      FORCE_SUPPLEMENTAL_WITHHOLDING,
      APPLY_BENEFITS,
      APPLY_POST_TAX_DEDUCTIONS,
    };

    let SELECTED_EMPLOYEES = [];
    if (includeHours) {
      SELECTED_EMPLOYEES = includedEmployees;
    }

    let payload = {PAYROLL_FORM, SELECTED_EMPLOYEES, RUN_MODE: runMode};

    this.setState({loading: true});

    await saveOffCyclePayroll(payload);
    return true;
  };

  renderNoHours() {
    let {checkPayrollId, extras, employees, dbaLocations} = this.state;

    let onboardedEmployees = employees.filter((employee) => employee.ACTIVE === 1 && employee.IS_ONBOARDED);

    return (
      <div style={{marginTop: 30}}>
        <div className={"text-md text-gray-700 font-semibold mb-3"}>All Fully Onboarded Employees</div>

        <PayrollRunTable
          hideEmployeeSelector={true}
          checkPayrollId={checkPayrollId}
          breakdown={onboardedEmployees}
          extras={extras}
          refreshExtras={this.refreshExtras}
          hideEarned={true}
          expandSummary={false}
          dbaLocations={dbaLocations}
        />
      </div>
    );
  }

  renderInclude() {
    return (
      <div className="mt-5">
        <div>
          {this.renderIncluded()}
          {this.renderNotIncluded()}
        </div>
      </div>
    );
  }

  async removeEmployeeFromIncluded(employeeID) {
    let {runMode} = this.props.payroll;

    await clearItems({RUN_MODE: runMode}, employeeID);
    await this.refreshExtras();
    this.setState({
      includedEmployees: this.state.includedEmployees.filter((emp) => emp !== employeeID),
    });
  }

  renderIncluded() {
    let {includedEmployees, checkPayrollId, extras, breakdown, dbaLocations, breakOptions} = this.state;
    let filteredBreakdown = breakdown.filter((breakdown) => includedEmployees.includes(breakdown.ID));

    return (
      <PayrollRunTable
        dbaLocations={dbaLocations}
        checkPayrollId={checkPayrollId}
        breakdown={filteredBreakdown}
        extras={extras}
        refreshExtras={this.refreshExtras}
        hideEarned={false}
        expandSummary={true}
        breakOptions={breakOptions}
        prefixColumns={[
          {
            width: 1,
            label: "",
            name: "",
            format: (value, row) => {
              if (row.PAY_SUMMARY) {
                return (
                  <button
                    type="button"
                    className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={async () => {
                      await this.removeEmployeeFromIncluded(row.ID);
                    }}
                  >
                    Remove
                  </button>
                );
              }
            },
          },
        ]}
        updateCard={(card) => this.updateCard(card, filteredBreakdown)}
      />
    );
  }

  addEmployeeToIncluded(employeeID) {
    this.setState((prevState) => ({
      includedEmployees: [...prevState.includedEmployees, employeeID],
    }));
  }

  renderNotIncluded() {
    let {includedEmployees, checkPayrollId, extras, breakdown, dbaLocations, breakOptions} = this.state;
    let filteredBreakdown = breakdown.filter((breakdown) => !includedEmployees.includes(breakdown.ID));

    return (
      <div className="mt-6">
        {filteredBreakdown.length > 0 && (
          <div>
            <div className={"text-md text-gray-700 font-semibold mb-1"}>Excluded Employee Hours</div>

            <PayrollRunTable
              hideHeader={true}
              dbaLocations={dbaLocations}
              checkPayrollId={checkPayrollId}
              breakdown={filteredBreakdown}
              extras={extras}
              refreshExtras={this.refreshExtras}
              hideEarned={false}
              expandSummary={true}
              hideExtraAdditions={true}
              breakOptions={breakOptions}
              prefixColumns={[
                {
                  width: 1,
                  label: "",
                  name: "",
                  format: (value, row) => {
                    return (
                      <button
                        type="button"
                        className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={async () => {
                          await this.addEmployeeToIncluded(row.ID);
                        }}
                      >
                        Add
                      </button>
                    );
                  },
                },
              ]}
              updateCard={(card) => this.updateCard(card, filteredBreakdown)}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    let {loading, breakdown, includeHours, payrollForm} = this.state;

    if (loading) {
      return <LoadingSpinner />;
    }

    let {FORCE_SUPPLEMENTAL_WITHHOLDING, APPLY_BENEFITS, APPLY_POST_TAX_DEDUCTIONS} = payrollForm;

    if (includeHours && (!breakdown || breakdown.length === 0)) {
      return (
        <div className="text-center py-48">
          <div className="text-lg font-bold">No Hours For Selected Date Range</div>

          <div className="text-md font-semibold text-gray-600">
            Please go back and select a date range containing timesheets
          </div>
        </div>
      );
    }

    return (
      <div className={"p-6"}>
        <Formik
          initialValues={{
            FORCE_SUPPLEMENTAL_WITHHOLDING,
            APPLY_BENEFITS,
            APPLY_POST_TAX_DEDUCTIONS,
          }}
          onSubmit={this.handleSubmit}
          innerRef={(e) => (this.formikRef = e)}
        >
          {(formikOptions) => {
            return (
              <div className="mb-4">
                <div className="flex">
                  <FormCheckbox name={"FORCE_SUPPLEMENTAL_WITHHOLDING"} options={formikOptions} />
                  <div className="mt-2.5 text-sm font-medium text-gray-700">
                    Tax all earnings as supplemental
                  </div>
                </div>

                <div className="flex">
                  <FormCheckbox name={"APPLY_BENEFITS"} options={formikOptions} />
                  <div className="mt-2.5 text-sm font-medium text-gray-700">
                    Apply benefit deductions and contributions to this payroll
                  </div>
                </div>

                <div className="flex">
                  <FormCheckbox name={"APPLY_POST_TAX_DEDUCTIONS"} options={formikOptions} />
                  <div className="mt-2.5 text-sm font-medium text-gray-700">
                    Apply post tax deductions to this payroll
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>

        {includeHours && this.renderInclude()}

        {!includeHours && this.renderNoHours()}
      </div>
    );
  }
}

export default setupReduxConnection(["payroll"])(withRouter(OffCycleChooseHoursComponent));
