import {useTranslation} from "react-i18next";
import {getDescription} from "../../federal-form-helpers";
import React from "react";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {FILING_STATUS} from "../w4-fill-pdf-helper";

export const W4TaxWithholdingStep = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.w4-employee." + path;
  };

  return (
    <>
      <div className={"text-sm font-medium text-gray-700 mt-6"}>
        {getDescription(t, getI18NextKey, false)}
      </div>

      <form className={"flex flex-col my-8 gap-y-4"}>
        <FormSelect
          name={"withholdingExemption"}
          labelClassName={"mb-1"}
          tooltip={{
            data: 'Select "No" unless you had no federal income tax liability for the previous tax year and you expect to have no federal income tax liability for the current tax year. See form for additional details.',
          }}
          label={t(getI18NextKey("exemptionWithholding.label"))}
          data={[
            {
              label: t(getI18NextKey("confirmations.yes")),
              value: true,
            },
            {
              label: t(getI18NextKey("confirmations.no")),
              value: false,
            },
          ]}
          options={formik}
        />

        {formik?.values?.withholdingExemption !== null && !formik?.values?.withholdingExemption && (
          <WitholdingForms formik={formik} />
        )}
      </form>
    </>
  );
};

const WitholdingForms = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.w4-employee." + path;
  };

  return (
    <div className={"flex flex-col gap-y-4 mt-4"}>
      <FormSelect
        labelClassName={"mb-1"}
        name={"filingStatus"}
        options={formik}
        tooltip={{
          data: t(getI18NextKey("w4-step-1.filingType.tooltip")),
        }}
        data={[
          {
            label: t(getI18NextKey("w4-step-1.single.label")),
            value: FILING_STATUS.SINGLE,
          },
          {
            label: t(getI18NextKey("w4-step-1.married.label")),
            value: FILING_STATUS.MARRIED,
          },
          {
            label: t(getI18NextKey("w4-step-1.head.label")),
            value: FILING_STATUS.HOUSEHOLD,
          },
        ]}
        label={t(getI18NextKey("w4-step-1.filingType.label"))}
      />

      <FormSelect
        labelClassName={"mb-1"}
        label={t(getI18NextKey("w4-step-2.twoJobsQuestion.question"))}
        name={"exactlyTwoJobs"}
        options={formik}
        tooltip={{
          data: t(getI18NextKey("w4-step-2.twoJobsQuestion.tooltip")),
        }}
        data={[
          {label: t(getI18NextKey("w4-step-2.twoJobsQuestion.yes")), value: true},
          {label: t(getI18NextKey("w4-step-2.twoJobsQuestion.no")), value: false},
        ]}
      />

      <FormInput
        labelClassName={"mb-1"}
        isDollars={true}
        label={t(getI18NextKey("w4-step-2.dependentsClaim.question"))}
        name={"dependentsClaim"}
        options={formik}
        tooltip={{
          data: t(getI18NextKey("w4-step-2.dependentsClaim.tooltip")),
        }}
      />

      <FormInput
        labelClassName={"mb-1"}
        isDollars={true}
        label={t(getI18NextKey("w4-step-2.expectedIncome.question"))}
        name={"otherIncome"}
        options={formik}
        tooltip={{
          data: t(getI18NextKey("w4-step-2.expectedIncome.tooltip")),
        }}
      />

      <FormInput
        labelClassName={"mb-1"}
        isDollars={true}
        label={t(getI18NextKey("w4-step-2.deductionsOptional.question"))}
        name={"deductions"}
        options={formik}
        tooltip={{
          data: t(getI18NextKey("w4-step-2.deductionsOptional.tooltip")),
        }}
      />

      <FormInput
        labelClassName={"mb-1"}
        isDollars={true}
        options={formik}
        label={t(getI18NextKey("w4-step-2.additionalWith.question"))}
        name={"additionalWithholding"}
        tooltip={{data: t(getI18NextKey("w4-step-2.additionalWith.tooltip"))}}
      />
    </div>
  );
};
