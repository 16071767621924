import React, {Component} from "react";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import SingleReportingTooltip from "../../../reporting/graphs/tooltips/single-reporting-tooltip";

class VendorGraph extends Component {
  state = {graphData: []};

  componentDidMount() {
    this.parseGraphData();
  }

  parseGraphData() {
    const {data: vendors} = this.props;

    const graphData = vendors.reduce((accum, item) => {
      const purchaseOrderOutstandingTotal = item.PURCHASE_ORDERS.reduce((accum, item) => {
        return item.RECEIVED_TOTAL === null ? accum + item.TOTAL : accum;
      }, 0);

      const purchaseOrderReceivedTotal = item.PURCHASE_ORDERS.reduce((accum, item) => {
        return accum + (item.RECEIVED_TOTAL ?? 0);
      }, 0);

      const purchaseOrderRequestedTotal = item.PURCHASE_ORDERS.reduce((accum, item) => {
        return item.RECEIVED_TOTAL !== null ? item.TOTAL + accum : accum;
      }, 0);

      accum.push({
        name: item.NAME,
        receivedTotal: purchaseOrderReceivedTotal,
        requestedTotal: purchaseOrderRequestedTotal,
        outstandingTotal: purchaseOrderOutstandingTotal,
      });

      return accum;
    }, []);

    this.setState({graphData});
  }

  yTickFormatter(val) {
    return toDollars(val, true);
  }

  render() {
    const {graphData = []} = this.state;

    return (
      <div className="bg-white rounded-md p-10 mt-4">
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={500}
            height={300}
            data={graphData}
            margin={{
              top: 30,
              right: 30,
              left: 30,
              bottom: 30,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />

            <XAxis
              dataKey={"name"}
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              axisLine={{stroke: "#cccccc"}}
              tickMargin={10}
              tickLine={{stroke: "#cccccc"}}
              interval={"preserveStartEnd"}
            />

            <YAxis
              tick={{fontSize: "0.75rem", fontWeight: "bold"}}
              tickMargin={10}
              tickFormatter={this.yTickFormatter}
              axisLine={{stroke: "#afadd9"}}
              tickLine={{stroke: "#cccccc"}}
            />

            <Tooltip
              content={
                <SingleReportingTooltip
                  formatLabel={(payload) => {
                    return payload[`name`];
                  }}
                />
              }
              rows={[
                {
                  label: "Total Outstanding PO Value",
                  formatValue: (payload) => {
                    return toDollars(payload["outstandingTotal"], true);
                  },
                },
                {
                  label: "Total Received PO Value",
                  formatValue: (payload) => {
                    return toDollars(payload["receivedTotal"], true);
                  },
                },
                {
                  label: "Total Requested PO Value",
                  formatValue: (payload) => {
                    return toDollars(payload["requestedTotal"], true);
                  },
                },
              ]}
            />

            <Bar dataKey="outstandingTotal" fill="#4e46e5" />

            <Bar dataKey="receivedTotal" fill="#7B76D5" />

            <Bar dataKey="requestedTotal" fill="#AFADD9" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default VendorGraph;
