import React, {Component} from "react";
import Wizard from "../../../components/wizard";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import InvoiceForm from "../../../forms/operations/invoices/invoice-form";
import InvoiceLinesForm from "../../../forms/operations/invoices/invoice-lines-form";
import InvoiceCard from "../../../features/operations/invoices/invoice-card";
import InvoiceLinesCard from "../../../features/operations/invoices/invoice-lines-card";
import {Tab, Card, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import InvoicePdfPreview from "../../../features/operations/invoices/previews/invoice-pdf-preview";
import InvoiceEmailPreview from "../../../features/operations/invoices/previews/invoice-email-preview";
import InvoiceRecipientsForm from "../../../forms/operations/invoices/invoice-recipients-form";
import InvoiceRemindersForm from "../../../forms/operations/invoices/invoice-reminders-form";
import ConfirmInvoiceModal from "../../../modals/operations/invoices/confirm-invoice-modal";
import RecurringInvoiceForm from "../../../forms/operations/invoices/recurring-invoice-form";
import RecurringInvoiceCard from "../../../features/operations/recurring-invoices/recurring-invoice-card";

class CreateInvoicePage extends Component {
  state = {
    isLoading: false,
    invoice: null,
    contact: null,
    due: null,
    invoiceNumber: 1,
  };

  componentDidMount() {
    const url = new URL(window.location.href);
    this.setState({isLoading: true});

    const promises = {
      duplicatePromise: Promise.resolve(),
      contactPromise: Promise.resolve(),
      invoiceNumberPromise: Promise.resolve(),
    };

    if (url.searchParams.has("duplicate")) {
      promises.duplicatePromise = request("invoices/" + url.searchParams.get("duplicate"), "GET");
    }

    if (url.searchParams.has("contact")) {
      promises.contactPromise = request("contacts/" + url.searchParams.get("contact"), "GET", null);
    }

    promises.invoiceNumberPromise = request("invoices/number", "GET");

    Promise.all(Object.values(promises)).then((data) => {
      this.setState({
        invoice: data[0],
        contact: data[1],
        invoiceNumber: data[2] ? ("" + data[2]).padStart(6, "0") : data[2],
        isLoading: false,
      });
    });
  }

  async submitInvoice(data) {
    this.wizardRef.fetchSubmitButton().stopLoading();

    return this.confirmModal.open(data);
  }

  renderRecurringForm(values, handleSubmit, setupRefs) {
    const {isRecurring} = this.props;
    if (isRecurring) {
      return (
        <Card label="Recurring Invoice Cadence" description="General information about the recurring invoice">
          <div className="px-6 pt-2 pb-4">
            <RecurringInvoiceForm
              ref={(e) => {
                setupRefs(e, "schedule");
              }}
              schedule={values?.schedule}
              handleSubmit={handleSubmit}
            />
          </div>
        </Card>
      );
    }
  }

  render() {
    const {isLoading, invoice, contact, due, invoiceNumber} = this.state;
    const {isRecurring} = this.props;
    const label = isRecurring ? "Recurring Invoice" : "Invoice";

    if (isLoading) {
      return <Loading />;
    }

    let initialValues = {invoice: {INVOICE_NUMBER: invoiceNumber}};

    if (invoice) {
      initialValues = {
        duplicate: invoice,
        schedule: {},
        invoice: {
          CONTACT: invoice.CONTACT,
          CONTACT_NAME: invoice.CONTACT.FIRST_NAME,
          CONTACT_ID: invoice.CONTACT_ID,

          COLLECTION_METHOD: invoice.COLLECTION_METHOD || "SEND_INVOICE",
          CUSTOMER_ID: invoice.CUSTOMER_ID,
          SOURCE_ID: invoice.SOURCE_ID,

          INVOICE_NUMBER: invoiceNumber,
          NOTES: invoice.NOTES ?? invoice.TICKET.NOTES,
        },
      };
    } else if (contact) {
      initialValues = {
        duplicate: invoice,
        invoice: {
          CONTACT: contact,
          CONTACT_NAME: contact.FIRST_NAME + " " + contact.LAST_NAME,
          INVOICE_NUMBER: invoiceNumber,
          CONTACT_ID: contact.ID,
        },
        schedule: {},
      };
    }

    return (
      <Wizard
        submitLabel={"Review " + label}
        ref={(e) => (this.wizardRef = e)}
        onSubmit={this.submitInvoice.bind(this)}
        onQuit={() => this.props.router.navigate("/invoices")}
        steps={[
          {
            id: 0,
            initialValues: initialValues,
            name: label + " Details",
            description: "General information about the " + label.toLowerCase(),
            render: ({values}, {handleSubmit, setupRefs}) => {
              return (
                <>
                  <Card
                    label={label + " Information"}
                    description={`
                      General information about the ${label.toLowerCase()} and primary recipient
                    `}
                  >
                    <div className="px-6 pt-2 pb-4">
                      <InvoiceForm
                        ref={(e) => {
                          setupRefs(e, "invoice");
                        }}
                        isRecurring={isRecurring}
                        onDueChange={(due) => this.setState({due})}
                        handleSubmit={handleSubmit}
                        invoice={values?.invoice}
                      />
                    </div>
                  </Card>

                  {this.renderRecurringForm(values, handleSubmit, setupRefs)}

                  <Card
                    label="Additional Recipients"
                    hint="Optional"
                    description="Who will additionally be sent reminders."
                  >
                    <div>
                      <InvoiceRecipientsForm
                        actionTextClassName="mr-4"
                        ref={(e) => setupRefs(e, "recipients")}
                        handleSubmit={handleSubmit}
                        recipients={invoice ? invoice.RECIPIENTS : values?.recipients}
                      />
                    </div>
                  </Card>

                  {due && (
                    <Card
                      label="Invoice Reminders"
                      description="The frequency of email reminders to the invoice recipients."
                    >
                      <div>
                        <InvoiceRemindersForm
                          due={due}
                          actionTextClassName="mr-4"
                          ref={(e) => setupRefs(e, "reminders")}
                          handleSubmit={handleSubmit}
                          reminders={values?.reminders}
                        />
                      </div>
                    </Card>
                  )}
                </>
              );
            },
          },
          {
            id: 1,
            initialValues: initialValues,
            name: label + " Line Items",
            description: "Select items to attach to the " + label.toLowerCase(),
            render: ({values}, {handleSubmit, setupRefs}) => {
              return (
                <>
                  <div className="hidden px-6 pt-2 pb-4">
                    <InvoiceForm
                      ref={(e) => {
                        setupRefs(e, "invoice");
                      }}
                      isRecurring={isRecurring}
                      onDueChange={(due) => this.setState({due})}
                      handleSubmit={handleSubmit}
                      invoice={values?.invoice}
                    />
                  </div>
                  <Card
                    label={label + " Lines"}
                    description="The items that will be attached to the invoice."
                  >
                    <div>
                      <InvoiceLinesForm
                        actionTextClassName="mr-4"
                        ref={(e) => {
                          setupRefs(e, "lines");
                        }}
                        duplicate={values?.duplicate}
                        handleSubmit={handleSubmit}
                        lines={values?.lines}
                        discounts={values?.discounts}
                        serviceFees={values?.serviceFees}
                      />
                    </div>
                  </Card>
                </>
              );
            },
          },
          {
            id: 2,
            name: label + " Summary",
            description: "Preview and confirm the details of the " + label.toLowerCase(),
            render: ({values}) => {
              const {invoice, schedule, lines, serviceFees, discounts} = values;

              return (
                <>
                  <ConfirmInvoiceModal isRecurring={isRecurring} ref={(e) => (this.confirmModal = e)} />

                  <Tab
                    data={[
                      {
                        id: "info",
                        label: "Confirm Information",
                      },
                      {
                        id: "pdf",
                        label: "Preview (PDF)",
                      },
                      {
                        id: "email",
                        label: "Preview (EMAIL)",
                      },
                    ]}
                  >
                    {(tab) => {
                      if (tab === "pdf") {
                        return <InvoicePdfPreview invoice={invoice} lines={lines} />;
                      }

                      if (tab === "email") {
                        return <InvoiceEmailPreview invoice={invoice} lines={lines} />;
                      }

                      if (tab === "info") {
                        return (
                          <div>
                            {isRecurring && (
                              <RecurringInvoiceCard
                                schedule={schedule}
                                handleEdit={() => this.wizardRef.setStep(0)}
                              />
                            )}

                            <InvoiceCard
                              isRecurring={isRecurring}
                              handleEdit={() => this.wizardRef.setStep(0)}
                              invoice={invoice}
                            />

                            <InvoiceLinesCard
                              handleEdit={() => this.wizardRef.setStep(1)}
                              invoice={invoice}
                              lines={lines}
                              serviceFees={serviceFees}
                              discounts={discounts}
                            />
                          </div>
                        );
                      }
                    }}
                  </Tab>
                </>
              );
            },
          },
        ]}
      />
    );
  }
}

export default withRouter(CreateInvoicePage);
