import HubPayrollSideNav from "../../layouts/side-navs/hub-payroll-side-nav";
import AccountPaystubsPage from "../../pages/hub/payroll/account-paystubs-page";
import PayrollDashboardPage from "../../pages/hub/payroll/payroll-dashboard-page";
import PayrollOnboardPage from "../../pages/hub/payroll/payroll-employee-onboard-page";
import AccountDocumentsPage from "../../pages/hub/payroll/account-tax-documents-page";
import {HubOnboardingSideNav} from "../../layouts/side-navs/hub-onboarding-side-nav";
import {OnboardingDashboardPage} from "../../pages/hub/onboarding/onboarding-dashboard-page";
import {EmployeePacketPage} from "../../pages/hub/onboarding/employee-packet-page";
import {I9Part1FormPage} from "../../pages/hub/onboarding/federal/i9-part-1/i9-part-1-form-page";
import {W4FormPage} from "../../pages/hub/onboarding/federal/w4/w4-form-page";
import {CustomDocumentPage} from "../../pages/hub/onboarding/custom-document-page";
import {InReviewDocumentPage} from "../../pages/hub/onboarding/in-review-document-page";
import {EmployeeDocumentsPage} from "../../pages/hub/onboarding/employee-documents-page";

export const HUB_PAGE_ROUTES = [
  {
    side: <HubPayrollSideNav />,
    permissions: [],
    routes: [
      {
        live: true,
        path: "/payroll",
        element: <PayrollDashboardPage />,
        permissions: [],
      },
      {
        live: true,
        path: "/payroll/paystubs",
        element: <AccountPaystubsPage />,
        permissions: [],
      },
      {
        live: true,
        path: "/payroll/tax-documents",
        element: <AccountDocumentsPage />,
      },
      {
        live: true,
        path: "/payroll/onboard/:UNIQUE_ID",
        element: <PayrollOnboardPage />,
        permissions: [],
      },
    ],
  },
  {
    side: <HubOnboardingSideNav />,
    permissions: [],
    routes: [
      {
        live: true,
        path: "/employee-onboarding",
        element: <OnboardingDashboardPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/packet/:PACKET_ASSIGNMENT_UNIQUE_ID",
        element: <EmployeePacketPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/packet/form/federal/I9/:DOCUMENT_ASS_ID",
        element: <I9Part1FormPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/packet/form/federal/W4/:DOCUMENT_ASS_ID",
        element: <W4FormPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/packet/form/:DOCUMENT_ASS_UID",
        element: <CustomDocumentPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/packet/form/review",
        element: <InReviewDocumentPage />,
      },
      {
        live: true,
        path: "/employee-onboarding/documents",
        element: <EmployeeDocumentsPage />,
      },
    ],
  },
];
