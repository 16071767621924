import {useEffect, useRef} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";

export const ConfirmationModal = ({
  isOpen,
  handleModalClose,
  modalLabel,
  tooltip,
  confirmationAction,
  confirmationMessage = "Are you sure you want to proceed?",
  isLoading = false,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.open();
    } else {
      modalRef.current.close();
    }
  }, [isOpen]);

  const onConfirmation = () => {
    confirmationAction();

    handleModalClose();
  };

  return (
    <Modal
      ref={modalRef}
      label={modalLabel}
      tooltip={{
        data: tooltip,
      }}
      buttonLabel={"Confirm"}
      buttonDisabled={isLoading}
      buttonOnClick={() => onConfirmation()}
      hideClose={true}
      deleteLabel={"Cancel"}
      onClose={() => handleModalClose()}
      deleteOnClick={() => handleModalClose()}
    >
      <span>{confirmationMessage}</span>
    </Modal>
  );
};
