import {useEffect, useRef, useState} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {fileUploadValidation} from "../../pages/team/onboarding/functional/documents/documents-helper";
import {EmployeeFileUploader} from "../../pages/team/onboarding/functional/documents/add-document-page";
import {EmployeeDocumentRequests} from "../../utils/request-helpers/onboarding/onboarding-hub-requests";
import {showErrorNotification} from "../../utils/notification-helper";

export const UploadDocumentModal = ({
  isOpen,
  handleModalClose,
  modalLabel,
  tooltip,
  buttonLabel,
  selectedEmployeeId,
}) => {
  const modalRef = useRef(null);

  const formikRef = useRef(null);

  const [file, setFile] = useState(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      formikRef.current && formikRef.current.resetForm();
      modalRef.current.open();
    } else {
      modalRef.current.close();
    }
  }, [isOpen]);

  const handleSubmit = async (values) => {
    const payload = {
      DOCUMENT_NAME: values.filename?.replace(/\.[^/.]+$/, "") ?? "",
      DOCUMENT_URL: values.fileUpload.url,
      EMPLOYEE_ID: selectedEmployeeId,
    };

    try {
      await EmployeeDocumentRequests.upload(payload);
    } catch (error) {
      console.error(error);
      showErrorNotification(
        "Document Error",
        "An error occurred while trying to upload document. Please try again."
      );
    }

    handleModalClose();
  };

  const handleFileDelete = (setFieldValue) => {
    setFieldValue("fileUpload", null);
    setFieldValue("filename", "");
    setFile(null);
  };

  return (
    <Modal
      large
      ref={modalRef}
      label={modalLabel}
      tooltip={{
        data: tooltip,
      }}
      buttonLabel={buttonLabel}
      formikOnClick={() => formikRef.current}
      hideClose={true}
      deleteLabel={"Cancel"}
      onClose={() => handleModalClose()}
      deleteOnClick={() => handleModalClose()}
    >
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          fileUpload: null,
          filename: "",
        }}
        validationSchema={fileUploadValidation}
        onSubmit={handleSubmit}
      >
        {(formikOptions) => {
          const {handleSubmit} = formikOptions;
          return (
            <form onSubmit={handleSubmit} className={"mb-4"}>
              <EmployeeFileUploader
                formik={formikOptions}
                setFile={(file) => setFile(file)}
                onDeleteFile={() => handleFileDelete(formikOptions.setFieldValue)}
                endpoint={"onboarding/documents/custom/upload/employees"}
              />
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};
