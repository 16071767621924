import React, {useMemo, useState} from "react";
import {Button, Card, FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {useFormik} from "formik";
import * as Yup from "yup";
import {SignatureForm} from "../../../../hub/onboarding/federal/signature-form";
import moment from "moment-timezone";
import {Row} from "../../../../../v2/components/shared";
import {BackButton} from "../BackButton";
import {useLocation, useNavigate} from "react-router-dom";
import FormStateSelect from "../../../../../components/form-state-select";
import {useI9ListA, useI9ListB, useI9ListC} from "../../../../../hooks/team/onboarding/i9-list-hooks";
import {DATE_MASK} from "./dashboard-helper";
import {
  additionalInitial,
  additionalSchema,
  clearAdditionalInfo,
  clearDocumentInfo,
  verificationInitial,
  verificationSchema,
} from "../../../../hub/onboarding/federal/i9-part-1/i9-helper";
import {useVerificationSubmit} from "../../../../../hooks/team/onboarding/submission-hooks";
import {setI9VerificationFields} from "../../../../hub/onboarding/federal/i9-part-1/i9-fill-pdf-helper";

export const I9Part2VerificationPage = () => {
  const [isListBC, setIsListBC] = useState(false);

  const listA = useI9ListA();

  const listB = useI9ListB();

  const listC = useI9ListC();

  const [additionalDocument, setAdditionalDocument] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const document = useMemo(() => location.state?.document, [location.state]);

  const navigateToReviewTab = () => {
    navigate("/onboarding/dashboard?tab=Pending+Review");
  };

  const {handleSubmit, reviewLoading} = useVerificationSubmit(document.DOCUMENT_ASSIGNMENT_ID);

  const formik = useFormik({
    initialValues: {
      ...verificationInitial,
      employeeStartDate: document?.EMPLOYEE_START_DATE
        ? moment(document?.EMPLOYEE_START_DATE).format("MM/DD/YYYY")
        : "",
      ...(isListBC && additionalInitial),
    },
    validationSchema: Yup.object().shape({
      ...verificationSchema,
      ...(isListBC && additionalSchema),
    }),
    onSubmit: (values) =>
      handleSubmit(document.DOCUMENT_ASSIGNMENT_ID, values, setI9VerificationFields, navigateToReviewTab),
  });

  const checkDocumentType = (value, setFieldValue, previousType) => {
    if (value.type === "listA") {
      if (isListBC) {
        setIsListBC(false);
        clearAdditionalInfo(setFieldValue, "listC");
      }

      clearDocumentInfo(setFieldValue, value.type);
    } else if (value.type === "listB") {
      if (previousType !== "listB") {
        setIsListBC(true);
        clearAdditionalInfo(setFieldValue, "listC");
        setAdditionalDocument(listC);
      }

      clearDocumentInfo(setFieldValue, value.type);
    } else if (value.type === "listC") {
      if (previousType !== "listC") {
        setIsListBC(true);
        clearAdditionalInfo(setFieldValue, "listB");
        setAdditionalDocument(listB);
      }

      clearDocumentInfo(setFieldValue, value.type);
    }
  };

  const renderDocumentRow = (fields, formikOptions) => (
    <div className="flex flex-row space-x-8">
      {fields.map(
        ({
          Component,
          name = `unique-key-${Math.random()}`,
          label,
          type,
          data,
          onChangeSoft = () => {},
          mask = null,
          placeholder,
        }) => (
          <Component
            key={name}
            className="flex-1 truncate"
            name={name}
            label={label}
            type={type}
            data={data}
            onChange={formikOptions.handleChange}
            options={formikOptions}
            onChangeSoft={onChangeSoft}
            {...(mask !== null && {mask})}
            placeholder={placeholder}
          />
        )
      )}
    </div>
  );

  const displayDocumentForms = (formikOptions) => {
    return (
      <>
        {renderDocumentRow(
          [
            {
              Component: FormSelect,
              name: "documentTitle",
              label: "Document Title",
              data: [...listA, ...listB, ...listC],
              onChangeSoft: (value) => {
                formikOptions.setFieldValue("documentTitle", value.label);
                checkDocumentType(value, formikOptions.setFieldValue, formikOptions.values.documentType);
              },
            },
            {Component: FormInput, name: "issuingAuth", label: "Issuing Authority", type: "text"},
          ],
          formikOptions
        )}

        {renderDocumentRow(
          [
            {Component: FormInput, name: "documentNumber", label: "Document Number (if any)", type: "text"},
            {
              Component: FormInput,
              name: "expirationDate",
              label: "Expiration Date (if any)",
              type: "text",
              mask: DATE_MASK,
              placeholder: "MM/DD/YYYY",
            },
          ],
          formikOptions
        )}

        {isListBC && (
          <>
            {renderDocumentRow(
              [
                {
                  Component: FormSelect,
                  name: "additionalDocTitle",
                  label: "Document Title",
                  data: additionalDocument,
                  onChangeSoft: (value) => {
                    formikOptions.setFieldValue("additionalDocTitle", value.label);
                  },
                },
                {Component: FormInput, name: "additionalAuth", label: "Issuing Authority", type: "text"},
              ],
              formikOptions
            )}

            {renderDocumentRow(
              [
                {
                  Component: FormInput,
                  name: "additionalDocNum",
                  label: "Document Number (if any)",
                  type: "text",
                },
                {
                  Component: FormInput,
                  name: "additionalExDate",
                  label: "Expiration Date (if any)",
                  type: "text",
                  mask: DATE_MASK,
                  placeholder: "MM/DD/YYYY",
                },
              ],
              formikOptions
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Card
      label={`I-9 Part 2 Verification for ${document?.EMPLOYEE_NAME}`}
      noHeaderBorder={true}
      className={" py-6"}
      tooltip={{
        data: "Verify the employee’s identity and work authorization by reviewing their original documents, ensuring they are not expired.",
      }}
      description={
        "Complete this section to verify the employee’s identity and work authorization by confirming their verification documents"
      }
    >
      <div className={"px-6"}>
        <form onSubmit={formik.handleSubmit}>
          <>{displayDocumentForms(formik)}</>

          <FormInput
            className={"mt-8"}
            name={"employeeStartDate"}
            label={"Employee's First Day"}
            mask={DATE_MASK}
            placeholder={"MM/DD/YYYY"}
            options={formik}
          />

          <div className="flex flex-row space-x-8">
            <FormInput
              className={"flex-1"}
              name={"employerFirstName"}
              label={"Employer's First Name"}
              options={formik}
            />

            <FormInput
              className={"flex-1"}
              name={"employerLastName"}
              label={"Employer's Last Name"}
              options={formik}
            />

            <FormInput
              className={"flex-1"}
              name={"employerTitle"}
              label={"Employer's Title"}
              options={formik}
            />
          </div>

          <FormInput name={"businessName"} label={"Employer's Business Name"} options={formik} />

          <FormInput name={"businessAddress"} label={"Employer's Business Address"} options={formik} />

          <div className="flex flex-row space-x-8">
            <FormInput name={"businessCity"} label={"Business City"} flex options={formik} />

            <FormStateSelect name={"businessState"} label={"Business State"} flex options={formik} />

            <FormInput name={"businessPostalCode"} label={"Business Zip Code"} flex options={formik} />
          </div>

          <SignatureForm
            formik={formik}
            subLabel={
              "Under penalties of perjury, I declare that this certificate, to the best of my knowledge and belief, is true, correct, and complete."
            }
          />

          <Row className={"mt-2 flex flex-row w-full space-x-1 "}>
            <BackButton className={"flex-1 py-4"} label="Back" goBack={navigateToReviewTab} />

            <Button
              className={"flex-1 justify-center py-4"}
              type={"submit"}
              label={"Verify"}
              disabled={formik.isSubmitting || reviewLoading}
            />
          </Row>
        </form>
      </div>
    </Card>
  );
};
