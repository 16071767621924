export const getTableData = (isLoading, error, data, sort = null) => {
  if (isLoading) {
    return null;
  }

  if (error) {
    return [];
  }

  return data;
};
