import React, {Component} from "react";
import FixedColumnReport from "../../features/reporting/reports/fixed-column-report";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import {CASH_FLOW_COLUMNS, CELL_TYPES} from "../../features/reporting/reports/reporting-constants";
import moment from "moment-timezone";
import CashFlowReportExpansionComponent from "../../features/reporting/reports/cash-flow-report-expansion-component";

class CashFlowReportPage extends Component {
  convertDataToRows = (reportData) => {
    const toReturn = [];
    const {storeBanks, totals} = reportData;

    for (const bank of storeBanks) {
      toReturn.push({
        label: moment(bank.DATE_OPEN).format("MMM DD, hh:mm A"),
        numbers: bank,
        rowType: CELL_TYPES.REGULAR,
        expandCell: () => (
          <div className="my-4 mx-40">
            <CashFlowReportExpansionComponent bank={bank} />
          </div>
        ),
      });
    }

    toReturn.push({
      label: "Totals",
      numbers: totals,
      style: [CELL_TYPES.BOLD],
    });

    return toReturn;
  };

  render() {
    return (
      <div>
        <div className="mb-5">
          <PageHeadings label={"Cash Flow Report"} />
        </div>

        <FixedColumnReport
          firstColLabel={"Open Time"}
          firstColTooltip="This column lists the time that the corresponding drawer was opened."
          filenamePrefix="cash-drawers"
          endpoint="report/cashflow"
          constantColumns={CASH_FLOW_COLUMNS}
          convertDataToRows={this.convertDataToRows}
          locationPicker={false}
          expandable={true}
        />
      </div>
    );
  }
}

export default CashFlowReportPage;
