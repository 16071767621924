import {useTranslation} from "react-i18next";
import React from "react";
import {FormInput, FormSelect} from "@frostbyte-technologies/frostbyte-tailwind";
import {getDescription} from "../../federal-form-helpers";
import {I9_VALIDATION_TYPES, NONCITIZEN_BOX_4_TYPES} from "../i9-fill-pdf-helper";
import {useI9ListA, useI9ListB, useI9ListC} from "../../../../../../hooks/team/onboarding/i9-list-hooks";
import {DATE_MASK} from "../../../../../team/onboarding/functional/dashboard/dashboard-helper";

export const EmployeeAttestationStep = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  return (
    <>
      <div className={"text-sm font-medium text-gray-700 mt-6 mb-8"}>
        {getDescription(t, getI18NextKey, true)}

        <p className={"mb-4"}>{t(getI18NextKey("penaltyPhrase.label"))}</p>
      </div>

      <form className={"w-3/4"}>
        <FormSelect
          name={"validationType"}
          labelClassName={"mb-2"}
          label={t(getI18NextKey("attestationDropboxPhrase.label"))}
          data={[
            {
              label: t(getI18NextKey("citizen.label")),
              value: I9_VALIDATION_TYPES.CITIZEN,
            },
            {
              label: t(getI18NextKey("noncitizenNational.label")),
              value: I9_VALIDATION_TYPES.NONCITIZEN_NATIONAL,
            },
            {
              label: t(getI18NextKey("lawfulPermanentResident.label")),
              value: I9_VALIDATION_TYPES.PERMANENT_RESIDENT,
            },
            {
              label: (
                <span className={"whitespace-normal break-words"}>
                  {t(getI18NextKey("alienAuthorizedToWork.label"))}
                </span>
              ),
              value: I9_VALIDATION_TYPES.NONCITIZEN,
            },
          ]}
          options={formik}
        />

        <CitizenshipStatusFollowup formik={formik} />

        <VerificationListOptions formik={formik} />
      </form>
    </>
  );
};

const CitizenshipStatusFollowup = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  const documentTypes = [
    {
      label: t(getI18NextKey("documentNumberOptions.uscisANumber")),
      value: NONCITIZEN_BOX_4_TYPES.USCIS_A_NUMBER,
    },
    {label: t(getI18NextKey("documentNumberOptions.formI94")), value: NONCITIZEN_BOX_4_TYPES.FORM_I94},
    {
      label: t(getI18NextKey("documentNumberOptions.foreignPassport")),
      value: NONCITIZEN_BOX_4_TYPES.FOREIGN_PASSPORT,
    },
  ];

  const type = formik?.values?.validationType ?? "";

  return (
    <div className={"text-sm font-medium text-gray-700 mt-6 mb-8"}>
      {type === I9_VALIDATION_TYPES.NONCITIZEN && (
        <>
          <FormInput
            onChange={formik.handleChange}
            maxLength={10}
            type={"text"}
            mask={DATE_MASK}
            placeholder={"MM/DD/YYYY"}
            name={"expirationDate"}
            label={t(getI18NextKey("citizenshipFollowup.expirationDate"))}
            labelClassName={"mb-1"}
          />

          <FormSelect
            data={documentTypes}
            label={t(getI18NextKey("citizenshipFollowup.documentType"))}
            name={"documentType"}
            labelClassName={"mb-1"}
            options={formik}
            className={"mt-4"}
          />

          <FormInput
            label={t(getI18NextKey("citizenshipFollowup.documentNumber"))}
            onChange={formik.handleChange}
            type={"text"}
            name={"documentNumber"}
          />

          {formik?.values?.documentType === "foreignPassport" && (
            <FormInput
              name={"issuingCountry"}
              label={"Issuing Country"}
              type={"text"}
              onChange={formik.handleChange}
              labelClassName={"mb-1"}
              className={"mt-4"}
            />
          )}
        </>
      )}

      {type === "PERMANENT_RESIDENT" && (
        <FormInput
          label={t(getI18NextKey("citizenshipFollowup.uscisANumber"))}
          onChange={formik.handleChange}
          type={"text"}
          name={"uscisANumber"}
        />
      )}
    </div>
  );
};

const VerificationListOptions = ({formik}) => {
  const {t} = useTranslation();

  const getI18NextKey = (path) => {
    return "pages.onboarding.landing-page-types.i9-part-1.employee-attestation-page." + path;
  };

  const listA = useI9ListA();

  const listB = useI9ListB();

  const listC = useI9ListC();

  return (
    <>
      <div className="flex flex-col gap-4 text-sm font-medium text-gray-700 mt-10 mb-2">
        {t(getI18NextKey("list.prompt"))}

        <FormSelect
          name={"listA"}
          labelClassName={"mb-1"}
          label={t(getI18NextKey("list.listA"))}
          data={listA}
          options={formik}
        />

        {t(getI18NextKey("list.or"))}

        <FormSelect
          name={"listB"}
          labelClassName={"mb-1"}
          label={t(getI18NextKey("list.listB"))}
          data={listB}
          options={formik}
        />

        <FormSelect
          name={"listC"}
          labelClassName={"mb-1"}
          label={t(getI18NextKey("list.listC"))}
          data={listC}
          options={formik}
        />
      </div>
    </>
  );
};
