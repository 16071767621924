import {useMutation} from "@tanstack/react-query";
import {
  CustomDocumentRequests,
  DocumentAssignments,
  DocumentRequests,
  PacketAssignments,
  PacketRequests,
} from "../../../utils/request-helpers/onboarding/onboarding-requests";
import {showErrorNotification} from "../../../utils/notification-helper";
import {useOnboardingMutation} from "../../use-mutation-wrapper";
import {
  APPROVAL_ERROR,
  DOCUMENT_ERROR,
  DOCUMENT_SUBMISSION_ERROR_MESSAGE,
  EMAIL_ERROR,
  PACKET_ASSIGNMENT_ERROR,
  PACKET_ERROR,
  SUCCESS,
} from "../../../pages/team/onboarding/functional/error-messages";
import {OnboardingHubRequests} from "../../../utils/request-helpers/onboarding/onboarding-hub-requests";

export const useSignedUrl = () => {
  const mutation = useMutation((documentId) => DocumentRequests.fetchMetadata(documentId), {
    onSuccess: (url) => {
      window.open(url, "_blank");
    },
    onError: (error) => {
      showErrorNotification("Document Error", "Unable to get the URL for this document. Please try again.");
    },
  });

  const openDocument = (documentId) => {
    mutation.mutate(documentId);
  };

  return {
    openDocument,
    isLoading: mutation.isLoading,
    error: mutation.error,
  };
};

export const useCreateDocument = () => {
  return useOnboardingMutation((payload) => CustomDocumentRequests.create(payload), {
    queryKeys: [["custom"]],
    successLabel: SUCCESS,
    successMessage: "Document created successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: "Document was not created. Please try again.",
  });
};

export const useUpdateDocument = () => {
  return useOnboardingMutation((payload) => CustomDocumentRequests.update(payload), {
    queryKeys: [["custom"]],
    successLabel: SUCCESS,
    successMessage: "Document updated successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: "Updates to the document haven't been saved. Please try again.",
  });
};

export const useArchiveDocument = () => {
  return useOnboardingMutation((documentId) => CustomDocumentRequests.archive(documentId), {
    queryKeys: [["custom"], ["archived"]],
    successLabel: SUCCESS,
    successMessage: "Document archived successfully",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: "Document couldn't be archived. Please try again.",
  });
};

export const useCreatePacket = () => {
  return useOnboardingMutation((payload) => PacketRequests.create(payload), {
    queryKeys: [["packets"]],
    successLabel: SUCCESS,
    successMessage: "Packet created successfully.",
    errorLabel: PACKET_ERROR,
    errorMessage: "Packet couldn't be created. Please try again.",
  });
};

export const useUpdatePacket = () => {
  return useOnboardingMutation((payload) => PacketRequests.update(payload), {
    queryKeys: [["packets"]],
    successLabel: SUCCESS,
    successMessage: "Packet updated successfully.",
    errorLabel: PACKET_ERROR,
    errorMessage: "Packet couldn't be updated. Please try again.",
  });
};

export const useDeletePacket = () => {
  return useOnboardingMutation((packetId) => PacketRequests.delete(packetId), {
    queryKeys: [["packets"]],
    successLabel: SUCCESS,
    successMessage: "Packet deleted successfully.",
    errorLabel: PACKET_ERROR,
    errorMessage: "Packet couldn't be deleted. Please try again.",
  });
};

export const useAssignPacket = () => {
  return useOnboardingMutation((payload) => PacketAssignments.create(payload), {
    queryKeys: [["dashboard-count"], ["incomplete"]],
    successLabel: SUCCESS,
    successMessage: "Packets were assigned, and onboarding emails have been sent to selected employees.",
    errorLabel: PACKET_ASSIGNMENT_ERROR,
    errorMessage: "Packet couldn't be assigned. Please try again.",
  });
};

export const useUnassignPacket = () => {
  return useOnboardingMutation((packetId) => PacketAssignments.unassign(packetId), {
    queryKeys: [["dashboard-count"], ["incomplete"]],
    successLabel: SUCCESS,
    successMessage: "Unassigned packet assignment successfully.",
    errorLabel: PACKET_ASSIGNMENT_ERROR,
    errorMessage: "Packet couldn't be unassigned. Please try again.",
  });
};

export const useApprovePacket = () => {
  return useOnboardingMutation((payload) => PacketAssignments.approve(payload), {
    queryKeys: [["dashboard-count"], ["packetsInReview"], ["approvedPackets"]],
    successLabel: SUCCESS,
    successMessage: "Packet approved successfully.",
    errorLabel: APPROVAL_ERROR,
    errorMessage: "Packet couldn't be approved. Please try again.",
  });
};

export const useSendEmail = () => {
  return useOnboardingMutation((payload) => PacketAssignments.sendEmail(payload), {
    successLabel: SUCCESS,
    successMessage: "Email was sent successfully.",
    errorLabel: EMAIL_ERROR,
    errorMessage: "Email was not sent. Please try again.",
  });
};

export const useReviewDocument = () => {
  return useOnboardingMutation(({id, payload}) => DocumentAssignments.updateAfterReview(id, payload), {
    queryKeys: [["dashboard-count"], ["incomplete"], ["documentsInReview"], ["packetsInReview"]],
    successLabel: SUCCESS,
    successMessage: "Document review was submitted successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: "Document couldn't be reviewed. Please try again.",
  });
};

export const useReviewHubDocument = (packetAssignmentUId) => {
  return useOnboardingMutation(({id, payload}) => DocumentAssignments.updateAfterReview(id, payload), {
    queryKeys: [["packet-documents", packetAssignmentUId], ["incomplete"]],
    successLabel: SUCCESS,
    successMessage: "Document review was submitted successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: "Document couldn't be reviewed. Please try again.",
  });
};

export const useSendForReview = (packetAssignmentUId) => {
  return useOnboardingMutation(({id, payload}) => OnboardingHubRequests.sendDocumentForReview(id, payload), {
    queryKeys: [["packet-documents", packetAssignmentUId], ["incomplete"]],
    successLabel: SUCCESS,
    successMessage: "Document was submitted successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: DOCUMENT_SUBMISSION_ERROR_MESSAGE,
  });
};

export const useAckDocument = (packetAssignmentUId) => {
  return useOnboardingMutation((id) => OnboardingHubRequests.acknowledgeDocument(id), {
    queryKeys: [["packet-documents", packetAssignmentUId]],
    successLabel: SUCCESS,
    successMessage: "Document acknowledgement submitted successfully.",
    errorLabel: DOCUMENT_ERROR,
    errorMessage: DOCUMENT_SUBMISSION_ERROR_MESSAGE,
  });
};

export const useRegenerateDocument = (packetAssignmentUId, documentUID) => {
  return useOnboardingMutation((id) => OnboardingHubRequests.regenerateDocument(id), {
    queryKeys: [
      ["packet-documents", packetAssignmentUId],
      ["employee-document", documentUID],
    ],
  });
};
