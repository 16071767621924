import {I9_CHECKBOX_FIELDS, I9_TEXT_FIELDS} from "./constants";
import {setCheckboxFieldValue, setFieldValue, setSignature} from "../fillable-pdf-helpers";
import moment from "moment-timezone";

// FORMIK FIELD NAMES
export const I9_FORM_FIELDS = {
  FIRST_NAME: "firstName",
  MIDDLE_INITIAL: "middleInitial",
  LAST_NAME: "lastName",
  OTHER_LAST_NAMES: "otherLastNames",
  ADDRESS: "address",
  APT_NUMBER: "aptNumber",
  CITY_OR_TOWN: "cityOrTown",
  STATE: "state",
  ZIP: "zip",
  DATE_OF_BIRTH: "dateOfBirth",
  SSN: "ssn",
  EMAIL: "email",
  PHONE: "phone",
  VALIDATION_TYPE: "validationType",
  EXPIRATION_DATE: "expirationDate",
  USCIS_A_NUMBER: "uscisANumber",
  LIST_A: "listA",
  LIST_B: "listB",
  LIST_C: "listC",
  SIGNATURE: "signature",
};

// I-9 PART 1 FIELDS
export const I9_VALIDATION_TYPES = {
  CITIZEN: "CITIZEN",
  NONCITIZEN: "NONCITIZEN",
  NONCITIZEN_NATIONAL: "NONCITIZEN_NATIONAL",
  PERMANENT_RESIDENT: "PERMANENT_RESIDENT",
};

export const I9_PART_1_VALIDATION_MAP = {
  [I9_VALIDATION_TYPES.CITIZEN]: I9_CHECKBOX_FIELDS.CITIZEN,
  [I9_VALIDATION_TYPES.NONCITIZEN]: I9_CHECKBOX_FIELDS.NONCITIZEN,
  [I9_VALIDATION_TYPES.NONCITIZEN_NATIONAL]: I9_CHECKBOX_FIELDS.NONCITIZEN_NATIONAL,
  [I9_VALIDATION_TYPES.PERMANENT_RESIDENT]: I9_CHECKBOX_FIELDS.PERMANENT_RESIDENT,
};

export const I9_PART_1_SSN_MAP = {
  [I9_FORM_FIELDS.SSN]: I9_TEXT_FIELDS.EMPLOYEE_SSN,
};

export const I9_PART_1_SIGNATURE_MAP = {
  [I9_FORM_FIELDS.SIGNATURE]: I9_TEXT_FIELDS.EMPLOYEE_SIGNATURE,
  signatureDate: I9_TEXT_FIELDS.EMPLOYEE_SIGNATURE_DATE,
};

export const I9_PART_1_FIELDS_MAP = {
  [I9_FORM_FIELDS.FIRST_NAME]: I9_TEXT_FIELDS.EMPLOYEE_FIRST_NAME,
  [I9_FORM_FIELDS.MIDDLE_INITIAL]: I9_TEXT_FIELDS.EMPLOYEE_MIDDLE_INITIAL,
  [I9_FORM_FIELDS.LAST_NAME]: I9_TEXT_FIELDS.EMPLOYEE_LAST_NAME,
  [I9_FORM_FIELDS.FIRST_NAME]: I9_TEXT_FIELDS.EMPLOYEE_FIRST_NAME,
  [I9_FORM_FIELDS.MIDDLE_INITIAL]: I9_TEXT_FIELDS.EMPLOYEE_MIDDLE_INITIAL,
  [I9_FORM_FIELDS.LAST_NAME]: I9_TEXT_FIELDS.EMPLOYEE_LAST_NAME,
  [I9_FORM_FIELDS.OTHER_LAST_NAMES]: I9_TEXT_FIELDS.EMPLOYEE_OTHER_LAST_NAMES_USED,
  [I9_FORM_FIELDS.ADDRESS]: I9_TEXT_FIELDS.EMPLOYEE_ADDRESS,
  [I9_FORM_FIELDS.APT_NUMBER]: I9_TEXT_FIELDS.EMPLOYEE_APT_NUMBER,
  [I9_FORM_FIELDS.CITY_OR_TOWN]: I9_TEXT_FIELDS.EMPLOYEE_CITY,
  [I9_FORM_FIELDS.STATE]: I9_TEXT_FIELDS.EMPLOYEE_STATE,
  [I9_FORM_FIELDS.ZIP]: I9_TEXT_FIELDS.EMPLOYEE_POSTAL_CODE,
  [I9_FORM_FIELDS.DATE_OF_BIRTH]: I9_TEXT_FIELDS.EMPLOYEE_DATE_OF_BIRTH,
  [I9_FORM_FIELDS.EMAIL]: I9_TEXT_FIELDS.EMPLOYEE_EMAIL,
  [I9_FORM_FIELDS.PHONE]: I9_TEXT_FIELDS.EMPLOYEE_PHONE_NUMBER,
  [I9_FORM_FIELDS.EXPIRATION_DATE]: I9_TEXT_FIELDS.BOX_4_EXPIRATION_DATE,
  [I9_FORM_FIELDS.USCIS_A_NUMBER]: I9_TEXT_FIELDS.BOX_3_USCIS_OR_A_NUMBER,
};

export const I9_PART_1_BOX_4_FIELDS = {
  BOX_4_USCIS_OR_A_NUMBER: "box_4_uscis_or_a_number",
  BOX_4_I94_ADMISSION_NUMBER: "box_4_i94_admission_number",
  BOX_4_FOREIGN_PASSPORT_NUMBER: "box_4_foreign_passport_number",
  BOX_4_COUNTRY_OF_ISSUANCE: "box_4_country_of_issuance",
};

export const NONCITIZEN_BOX_4_TYPES = {
  USCIS_A_NUMBER: "uscisANumber",
  FORM_I94: "formI94",
  FOREIGN_PASSPORT: "foreignPassport",
};

// I-9 PART 2 FIELDS
export const I9_PART_2_FIELDS = {
  EMPLOYER_LAST_NAME: "employer_last_name",
  EMPLOYER_FIRST_NAME: "employer_first_name",
  EMPLOYER_TITLE: "employer_title",
  EMPLOYER_SIGNATURE_DATE: "employer_signature_date",
  EMPLOYER_SIGNATURE: "employer_signature",
  EMPLOYER_ADDRESS: "employer_address",
  EMPLOYER_CITY: "employer_city",
  EMPLOYER_STATE: "employer_state",
  EMPLOYER_BUSINESS_NAME: "employer_business_name",
  EMPLOYER_POSTAL_CODE: "employer_postal_code",
  EMPLOYEE_FIRST_DAY_OF_EMPLOYMENT: "employee_first_day_of_employment",
};

export const I9_PART_2_LIST_A_FIELDS = {
  LIST_A_DOCUMENT_1_TITLE: "list_a_document_1_title",
  LIST_A_DOCUMENT_1_ISSUING_AUTHORITY: "list_a_document_1_issuing_authority",
  LIST_A_DOCUMENT_1_NUMBER: "list_a_document_1_number",
  LIST_A_DOCUMENT_1_EXPIRATION_DATE: "list_a_document_1_expiration_date",
};

export const I9_PART_2_LIST_B_FIELDS = {
  LIST_B_DOCUMENT_TITLE: "list_b_document_title",
  LIST_B_DOCUMENT_ISSUING_AUTHORITY: "list_b_document_issuing_authority",
  LIST_B_DOCUMENT_NUMBER: "list_b_document_number",
  LIST_B_DOCUMENT_EXPIRATION_DATE: "list_b_document_expiration_date",
};

export const I9_PART_2_LIST_C_FIELDS = {
  LIST_C_DOCUMENT_TITLE: "list_c_document_title",
  LIST_C_DOCUMENT_ISSUING_AUTHORITY: "list_c_document_issuing_authority",
  LIST_C_DOCUMENT_NUMBER: "list_c_document_number",
  LIST_C_DOCUMENT_EXPIRATION_DATE: "list_c_document_expiration_date",
};

export const I9_LIST_A_MAP = {
  documentTitle: I9_PART_2_LIST_A_FIELDS.LIST_A_DOCUMENT_1_TITLE,
  issuingAuth: I9_PART_2_LIST_A_FIELDS.LIST_A_DOCUMENT_1_ISSUING_AUTHORITY,
  documentNumber: I9_PART_2_LIST_A_FIELDS.LIST_A_DOCUMENT_1_NUMBER,
  expirationDate: I9_PART_2_LIST_A_FIELDS.LIST_A_DOCUMENT_1_EXPIRATION_DATE,
};

export const I9_LIST_B_MAP = {
  documentTitle: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_TITLE,
  issuingAuth: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_ISSUING_AUTHORITY,
  documentNumber: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_NUMBER,
  expirationDate: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_EXPIRATION_DATE,
  additionalDocTitle: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_TITLE,
  additionalAuth: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_ISSUING_AUTHORITY,
  additionalDocNum: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_NUMBER,
  additionalExDate: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_EXPIRATION_DATE,
};

export const I9_LIST_C_MAP = {
  documentTitle: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_TITLE,
  issuingAuth: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_ISSUING_AUTHORITY,
  documentNumber: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_NUMBER,
  expirationDate: I9_PART_2_LIST_C_FIELDS.LIST_C_DOCUMENT_EXPIRATION_DATE,
  additionalDocTitle: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_TITLE,
  additionalAuth: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_ISSUING_AUTHORITY,
  additionalDocNum: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_NUMBER,
  additionalExDate: I9_PART_2_LIST_B_FIELDS.LIST_B_DOCUMENT_EXPIRATION_DATE,
};

export const I9_PART_2_MAP = {
  signature: I9_PART_2_FIELDS.EMPLOYER_SIGNATURE,
  employerLastName: I9_PART_2_FIELDS.EMPLOYER_LAST_NAME,
  employerFirstName: I9_PART_2_FIELDS.EMPLOYER_FIRST_NAME,
  employerTitle: I9_PART_2_FIELDS.EMPLOYER_TITLE,
  businessName: I9_PART_2_FIELDS.EMPLOYER_BUSINESS_NAME,
  businessAddress: I9_PART_2_FIELDS.EMPLOYER_ADDRESS,
  businessCity: I9_PART_2_FIELDS.EMPLOYER_CITY,
  businessState: I9_PART_2_FIELDS.EMPLOYER_STATE,
  businessPostalCode: I9_PART_2_FIELDS.EMPLOYER_POSTAL_CODE,
  employeeStartDate: I9_PART_2_FIELDS.EMPLOYEE_FIRST_DAY_OF_EMPLOYMENT,
};

// I-9 Form Fill Helper Methods
const sanitizeSSN = (ssn) => {
  if (ssn) {
    return ssn.replace(/-/g, "");
  }
  return "";
};

const handleNoncitizenBox = (form, documentType, documentNumber, country) => {
  if (documentType === NONCITIZEN_BOX_4_TYPES.USCIS_A_NUMBER) {
    setFieldValue(form, I9_PART_1_BOX_4_FIELDS.BOX_4_USCIS_OR_A_NUMBER, documentNumber);
  } else if (documentType === NONCITIZEN_BOX_4_TYPES.FORM_I94) {
    setFieldValue(form, I9_PART_1_BOX_4_FIELDS.BOX_4_I94_ADMISSION_NUMBER, documentNumber);
  } else if (documentType === NONCITIZEN_BOX_4_TYPES.FOREIGN_PASSPORT) {
    setFieldValue(form, I9_PART_1_BOX_4_FIELDS.BOX_4_FOREIGN_PASSPORT_NUMBER, documentNumber);
    setFieldValue(form, I9_PART_1_BOX_4_FIELDS.BOX_4_COUNTRY_OF_ISSUANCE, country);
  }
};

const handleValidationTypes = (
  form,
  validationType,
  documentType = "",
  documentNumber = "",
  country = ""
) => {
  setCheckboxFieldValue(form, I9_PART_1_VALIDATION_MAP[validationType], true);

  if (validationType === I9_VALIDATION_TYPES.NONCITIZEN) {
    handleNoncitizenBox(form, documentType, documentNumber, country);
  }
};

const handleSignature = (form, signature, font) => {
  setFieldValue(form, I9_PART_1_SIGNATURE_MAP.signatureDate, moment().format("MM/DD/YYYY"));

  setFieldValue(form, I9_PART_1_SIGNATURE_MAP[I9_FORM_FIELDS.SIGNATURE], signature, font);
};

export const setI9FormFields = (values, form, font) => {
  Object.entries(I9_PART_1_FIELDS_MAP)?.forEach(([key, value]) => {
    if (values[key] !== null) {
      setFieldValue(form, value, values[key]);
    }
  });

  setFieldValue(form, I9_PART_1_SSN_MAP[I9_FORM_FIELDS.SSN], sanitizeSSN(values[I9_FORM_FIELDS.SSN]));

  handleSignature(form, values.signature, font);

  handleValidationTypes(
    form,
    values.validationType,
    values.documentType,
    values.documentNumber,
    values.issuingCountry
  );
};

const handleI9Part2Fields = (values, form, font, page) => {
  Object.entries(I9_PART_2_MAP).forEach(([key, value]) => {
    if (key === "signature") {
      setSignature(page, form, value, values[key], font);
      setFieldValue(form, I9_TEXT_FIELDS.EMPLOYER_SIGNATURE_DATE, moment().format("MM/DD/YYYY"));

      return;
    }

    setFieldValue(form, value, values[key]);
  });
};

const setListFields = (form, formikValues, listMap) => {
  Object.entries(listMap).forEach(([key, value]) => {
    if (formikValues[key]) {
      setFieldValue(form, value, formikValues[key]);
    }
  });
};

const handleI9Part2ListFields = (values, form) => {
  if (values.documentType === "listA") {
    setListFields(form, values, I9_LIST_A_MAP);
  } else if (values.documentType === "listB") {
    setListFields(form, values, I9_LIST_B_MAP);
  } else if (values.documentType === "listC") {
    setListFields(form, values, I9_LIST_C_MAP);
  }
};

export const setI9VerificationFields = (values, form, font, page) => {
  handleI9Part2Fields(values, form, font, page);

  handleI9Part2ListFields(values, form);
};
