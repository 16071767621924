import React, {Component} from "react";
import {getURL, isQE, request} from "../../../utils/request";
import {withRouter} from "../../../utils/navigation";

import {Loading, PageHeadings, Tab, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import CustomerModal from "../../../modals/operations/invoices/contact-modal";
import InvoiceCard from "../../../features/operations/invoices/invoice-card";
import InvoiceLinesCard from "../../../features/operations/invoices/invoice-lines-card";
import InvoiceModal from "../../../modals/operations/invoices/invoice-modal";
import InvoiceLinesModal from "../../../modals/operations/invoices/invoice-lines-modal";
import {saveAs} from "file-saver";
import PayInvoiceModal from "../../../modals/operations/invoices/pay-invoice-modal";
import InvoiceRecipientsCard from "../../../features/operations/invoices/invoice-recipients-card";
import SendInvoiceModal from "../../../modals/operations/invoices/send-invoice-modal";
import SendInvoiceQuoteModal from "../../../modals/operations/invoices/send-invoice-quote-modal";
import RemindInvoiceModal from "../../../modals/operations/invoices/remind-invoice-modal";
import RefundModal from "../../../modals/sales/tickets/refund-modal";
import UpdateInvoiceCheckModal from "../../../modals/operations/invoices/update-invoice-check-modal";
import InvoiceRemindersCard from "../../../features/operations/invoices/invoice-reminders-card";
import DangerBadge from "../../../components/badges/danger-badge";
import {showSuccessNotification} from "../../../utils/notification-helper";
import InvoiceRecipientModal from "../../../modals/operations/invoices/invoice-recipient-modal";
import {getLastFourPaymentString} from "../../../utils/payment-helper";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

import moment from "moment-timezone";
import {INVOICE_QUOTE_STATUSES} from "../../../utils/constants";

const PAYMENT_TYPES_DISPLAY = {
  CARD_NOT_PRESENT: "Card Not Present",
  CARD_PRESENT: "Card Present",
  CASH: "Cash",
  CHECK: "Check",
};

const INVOICE_LOG_TYPES = {
  DOWNLOAD_PDF: "DOWNLOAD_PDF",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  UPDATE_LINE_ITEMS: "UPDATE_LINE_ITEMS",
  ADD_RECIPIENT: "ADD_RECIPIENT",
  DELETE_RECIPIENT: "DELETE_RECIPIENT",
  INSERT_INVOICE_LINE_ITEMS: "INSERT_INVOICE_LINE_ITEMS",
  ARCHIVE_INVOICE: "ARCHIVE_INVOICE",
  PAYMENT_PROCESSING: "PAYMENT_PROCESSING",
  PAYMENT_SUCCEEDED: "PAYMENT_SUCCEEDED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  SEND_EMAIL: "SEND_EMAIL",
  PAY_CONTACT_METHOD: "PAY_CONTACT_METHOD",
  REFUND: "REFUND",
  SEND_PAID_EMAILS: "SEND_PAID_EMAILS",
};

class InvoicePage extends Component {
  state = {invoice: null, ticket: null};

  componentDidMount() {
    this.syncState();
  }

  async resyncPage() {
    this.setState({invoice: null, ticket: null}, () => {
      this.syncState();
    });
  }

  syncState = async () => {
    const {ID: id} = this.props.router.params;

    let invoice = await request("invoices/" + id, "GET", null);

    this.setState({invoice, ticket: invoice.TICKET});
  };

  async sendReminder() {
    const {ID: id} = this.props.router.params;

    await request("invoices/" + id + "/reminder", "POST", {});
  }

  removeCustomer() {
    const {customer} = this.state;

    showLoadingConfirmAlert("Delete Customer", "Are you sure you want to delete this customer?").then(
      async (close) => {
        await request("customers/" + customer.ID, "DELETE", {});

        close();

        this.props.router.navigate("/customers");
      }
    );
  }

  refundInvoice() {
    const {invoice, ticket} = this.state;
    this.refundModal.open(ticket, invoice);
  }

  deleteInvoice() {
    const {invoice} = this.state;

    showLoadingConfirmAlert("Delete Invoice", "Are you sure you want to delete this invoice?").then(
      async (close) => {
        await request("invoices/" + invoice.ID, "DELETE");

        close();

        showSuccessNotification(
          "Invoice Delete",
          "Invoice #" + invoice.INVOICE_NUMBER + " was successfully deleted."
        );

        this.props.router.navigate("/invoices");
      }
    );
  }

  fetchPaymentColumns(ticket) {
    const checkPayments = ticket.PAYMENT_INTENT.PAYMENTS.filter((payment) => payment.TYPE === "CHECK");

    const paymentColumns = [
      {
        value: "TYPE",
        label: "Payment Method",
        format: (val, row) => {
          return (PAYMENT_TYPES_DISPLAY[val] ?? val) + getLastFourPaymentString(row);
        },
      },

      {
        value: "AMOUNT",
        label: "Amount Paid",
        format: (val) => "$" + toDollars(val),
      },
      {
        value: "AMOUNT",
        label: "Amount Paid",
        format: (val) => "$" + toDollars(val),
      },
      {
        value: "AMOUNT_REFUNDED",
        label: "Amount Refunded",
        format: (val) => "$" + toDollars(val),
      },
    ];

    if (checkPayments.length > 0) {
      paymentColumns.splice(1, 0, {
        value: "CONTENT",
        label: "Check Number",
      });
    }

    return paymentColumns;
  }

  renderInvoiceAction(action) {
    switch (action.action) {
      case "DOWNLOAD_PDF":
        return "Pdf Downloaded";
      case "CREATE":
        return "Invoice Created";
      case "UPDATE":
        return "Invoice Updated";
      case "UPDATE_LINE_ITEMS":
        return "Line Items Updated";
      case "ADD_RECIPIENT":
        return `Recipient Added - ${action.body.EMAIL}`;
      case "DELETE_RECIPIENT":
        return "Recipient Deleted";
      case "INSERT_INVOICE_LINE_ITEMS":
        return "Line Items Inserted";
      case "ARCHIVE_INVOICE":
        return "Invoice Deleted";
      case "PAYMENT_PROCESSING":
        return "Payment Processing";
      case "PAYMENT_SUCCEEDED":
      case "PAY_CONTACT_METHOD":
        return "Payment Succeeded";
      case "PAYMENT_FAILED":
        return "Payment Failed";
      case "SEND_EMAIL":
        if (action.body.subject.includes("New Invoice")) {
          return `New Invoice Notification Delivered to ${action.body.recipient}`;
        } else if (action.body.subject.includes("Reminder")) {
          return `Invoice Reminder Delivered to ${action.body.recipient}`;
        }

        return `Email (Subject: ${action.body.subject}) Sent to ${action.body.recipient}`;
      case "REFUND":
        return "Invoice Refunded";
      case "SEND_PAID_EMAILS":
        return `Payment Notifications Sent`;
    }
  }

  renderQuoteResponseStatus(quote) {
    const {invoice} = this.state;

    if (invoice?.ACTIVE_QUOTE_ID === quote.ID && !quote.APPROVED) {
      return "Awaiting Changes";
    } else if (invoice?.ACTIVE_QUOTE_ID === quote.ID) {
      return "Active";
    } else {
      return "Outdated";
    }
  }

  render() {
    const {invoice, ticket} = this.state;

    if (invoice === null) {
      return <Loading />;
    }

    const invoiceTabs = [
      {id: "lines", label: "Invoice Lines"},
      {id: "recipients", label: "Additional Recipients"},
      {id: "activity", label: "Activity Log"},
    ];

    if (invoice.REMINDERS.length > 0) {
      invoiceTabs.push({id: "reminders", label: "Invoice Reminders"});
    }

    if (invoice.QUOTES.length > 0) {
      invoiceTabs.push({id: "quotes", label: "Quote Responses"});
    }

    if (invoice.TICKET.PAYMENT_INTENT.PAYMENTS.filter((payment) => payment.TYPE !== "INVOICE").length > 0) {
      invoiceTabs.push({id: "payments", label: "Payments"});
    }

    const dropdownActions = [
      {
        label: "Duplicate",
        onClick: () => {
          window.open("/invoice?duplicate=" + invoice.UNIQUE_ID, "_blank");
        },
      },
    ];

    if (invoice.STATUS === "DRAFT" || invoice.STATUS === "OPEN") {
      dropdownActions.push({
        label: "Delete",
        onClick: () => this.deleteInvoice(),
      });
    }

    const headerButtons = [
      {
        type: "dropdown",
        label: "Actions",
        sections: [
          {
            items: dropdownActions,
          },
          {
            items: [
              {
                label: "Download PDF",
                onClick: () =>
                  saveAs(getURL() + "invoices/" + invoice.UNIQUE_ID + "/pdf", invoice.INVOICE_NUMBER),
              },
              {
                label: "Copy Payment Link",
                onClick: () => {
                  navigator.clipboard.writeText(`https://pay.dripos.com/${invoice.UNIQUE_ID}`).then(() => {
                    showSuccessNotification("Invoice Payment Link Copied!");
                  });
                },
              },
              {
                label: "View Payment Page",
                onClick: () => {
                  if (isQE()) {
                    window.open("https://qpay.dripos.com/" + invoice.UNIQUE_ID, "_blank");
                  } else {
                    window.open("https://pay.dripos.com/" + invoice.UNIQUE_ID, "_blank");
                  }
                },
              },
            ],
          },
        ],
      },
    ];

    if (invoice.STATUS === "PAID") {
      headerButtons.splice(0, 0, {
        label: "View Receipt",
        onClick: () => {
          window.open("https://pay.dripos.com/receipt/" + invoice.UNIQUE_ID);
        },
      });

      let refundAmount = ticket?.PAYMENT_INTENT?.TOTAL - ticket?.PAYMENT_INTENT?.AMOUNT_REFUNDED;

      if (refundAmount > 0) {
        headerButtons.splice(0, 0, {
          label: "Refund Invoice",
          onClick: () => this.refundInvoice(),
        });
      }
    } else if (invoice.STATUS === "DRAFT" || INVOICE_QUOTE_STATUSES.includes(invoice.STATUS)) {
      headerButtons.splice(0, 0, {
        label: "Send Invoice",
        onClick: () => {
          this.sendModal.open(invoice);
        },
      });

      headerButtons.splice(0, 0, {
        label: "Resend Quote",
        onClick: () => {
          this.sendQuoteModal.open(invoice);
        },
      });
    } else {
      headerButtons.splice(0, 0, {
        label: "Pay Invoice",
        onClick: () => {
          setTimeout(() => this.payModal.open(invoice), 250);
        },
      });

      headerButtons.splice(0, 0, {
        label: "Send Reminder",
        onClick: () => {
          this.remindModal.open(invoice);
        },
      });
    }

    return (
      <div>
        <InvoiceModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.invoiceModal = e)}
        />

        <UpdateInvoiceCheckModal
          ref={(e) => (this.updateCheckModal = e)}
          resyncPage={() => this.resyncPage()}
        />

        <SendInvoiceModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.sendModal = e)}
        />

        <SendInvoiceQuoteModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.sendQuoteModal = e)}
        />

        <InvoiceLinesModal
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.invoiceLinesModal = e)}
        />

        <PayInvoiceModal
          resyncPage={() => this.resyncPage()}
          updateState={(invoice) => this.setState({invoice})}
          ref={(e) => (this.payModal = e)}
        />

        <RemindInvoiceModal ref={(e) => (this.remindModal = e)} />

        <RefundModal handleUpdate={() => this.resyncPage()} ref={(e) => (this.refundModal = e)} />

        <CustomerModal
          updateState={(customer) => this.setState({customer})}
          ref={(e) => (this.customerModal = e)}
        />

        <InvoiceRecipientModal
          ref={(e) => (this.recipientModal = e)}
          updateState={(invoice) => this.setState({invoice})}
        />

        <PageHeadings
          label={
            <div className={"flex flex-row "}>
              <div className={"mr-2"}>
                {invoice.CONTACT.FIRST_NAME + " " + invoice.CONTACT.LAST_NAME + " #" + invoice.INVOICE_NUMBER}
              </div>

              {invoice.STATUS === "REFUNDED" && <DangerBadge className={"my-1"}>Refunded</DangerBadge>}

              {invoice.DATE_ARCHIVED && <DangerBadge className={"my-1"}>Archived</DangerBadge>}
            </div>
          }
          buttons={headerButtons}
          breadcrumbs={[
            {label: "Invoicing", url: "/invoicing"},
            {label: "Invoice List", url: "/invoices"},
          ]}
        />

        <InvoiceCard
          invoice={invoice}
          hideEdit={invoice.STATUS === "PAID"}
          handleEdit={() => this.invoiceModal.open(invoice)}
        />

        <Tab data={invoiceTabs}>
          {(id) => {
            if (id === "lines") {
              return (
                <InvoiceLinesCard
                  ticket={invoice.TICKET}
                  lines={invoice.TICKET.ITEMS}
                  hideEdit={invoice.STATUS === "PAID"}
                  handleEdit={() => this.invoiceLinesModal.open(invoice)}
                />
              );
            }

            if (id === "recipients") {
              return (
                <InvoiceRecipientsCard
                  invoice={invoice}
                  handleEdit={() => this.recipientModal.open(invoice)}
                  updateState={(invoice) => this.setState({invoice})}
                />
              );
            }

            if (id === "reminders") {
              return (
                <InvoiceRemindersCard
                  hideEdit
                  invoice={invoice}
                  handleEdit={() => this.invoiceLinesModal.open(invoice)}
                />
              );
            }

            if (id === "activity") {
              return (
                <Table
                  className="mt-2"
                  data={invoice.LOGS.map((log) => ({...log, object: null}))}
                  columns={[
                    {
                      label: "Action",
                      value: "action",
                      format: (_, row) => this.renderInvoiceAction(row),
                    },
                    {
                      label: "Timestamp",
                      value: "dateCreated",
                      format: (val) => moment(val).format("MMM Do YYYY hh:mmA"),
                    },
                  ]}
                />
              );
            }

            if (id === "payments") {
              return (
                <Table
                  className="mt-2"
                  data={ticket.PAYMENT_INTENT.PAYMENTS.filter((payment) => payment.TYPE !== "INVOICE")}
                  columns={this.fetchPaymentColumns(ticket)}
                  actionButtons={
                    ticket.PAYMENT_INTENT.PAYMENTS.filter((payment) => payment.TYPE === "CHECK").length >
                      0 && [
                      {
                        label: "Edit",
                        onClick: (row) => this.updateCheckModal.open(row),
                      },
                    ]
                  }
                />
              );
            }

            if (id === "quotes") {
              return (
                <Table
                  className="mt-2"
                  data={invoice.QUOTES}
                  columns={[
                    {
                      label: "Response",
                      value: "APPROVED",
                      format: (val) => (val ? "Approved" : "Rejected"),
                    },
                    {
                      label: "Submit Date",
                      value: "DATE_UPDATED",
                      format: (val) => moment(val).format("hh:mm A - MMM D, YYYY"),
                    },
                    {
                      label: "Status",
                      value: "QUOTE",
                      format: (_, row) => this.renderQuoteResponseStatus(row),
                    },
                    {
                      label: "Notes",
                      value: "NOTES",
                    },
                  ]}
                />
              );
            }

            return <div></div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(InvoicePage);
