import React, {Component} from "react";
import {PageHeadings, Card, Table, Tab, Loading} from "@frostbyte-technologies/frostbyte-tailwind";
import {request} from "../../../../utils/request";
import moment from "moment-timezone";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import CreateBankTemplateModal from "../../../../modals/operations/cash/create-bank-template-modal";
import {withRouter} from "../../../../utils/navigation";
import StoreBanksTable from "../../../../tables/operations/store-banks-table";

class StoreBanksPage extends Component {
  state = {templates: []};

  componentDidMount() {
    this.syncState();
  }

  syncState() {
    request("storebanks/templates/", "GET").then((templates) => this.setState({templates}));
  }

  render() {
    const {templates} = this.state;

    if (!templates) {
      return <Loading />;
    }

    return (
      <div>
        <CreateBankTemplateModal
          ref={(e) => (this.templateModal = e)}
          updateTemplates={() => this.syncState()}
        />

        <PageHeadings
          label="Store Banks"
          description="View and edit cash flow statements for your business"
          breadcrumbs={[{label: "Cash Management", url: "/cash"}]}
        />

        <Tab
          data={[
            {
              id: "banks",
              label: "Banks",
            },
            {
              id: "templates",
              label: "Templates",
            },
          ]}
        >
          {(tab) => {
            return (
              <div>
                {tab === "banks" && (
                  <Card label="Store Banks">
                    <StoreBanksTable />
                  </Card>
                )}

                {tab === "templates" && (
                  <Card
                    label="Store Bank Templates"
                    button={{
                      label: "Create Template +",
                      onClick: () => this.templateModal.open(),
                    }}
                  >
                    <Table
                      pagination
                      data={templates}
                      columns={[
                        {
                          value: "NAME",
                          label: "Name",
                        },
                      ]}
                      actionButtons={[
                        {
                          label: "Edit",
                          onClick: (row) => this.props.router.navigate("/bank/template/" + row.UNIQUE_ID),
                        },
                      ]}
                    />
                  </Card>
                )}
              </div>
            );
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(StoreBanksPage);
