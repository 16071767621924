import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import Banner from "../../../../components/banner";
import {PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import LoadingSpinner from "../../../../components/loading-spinner";
import RecipeBuilder from "../../../../features/operations/supply-chain/recipe-builder/recipe-builder";
import IngredientCategoryModal from "../../../../modals/operations/supply-chain/recipes/ingredient-category-modal";
import IngredientModal from "../../../../modals/operations/supply-chain/recipes/ingredient-modal";
import QuickStockIngredientModal from "../../../../modals/operations/supply-chain/recipes/quick-stock-ingredient-modal";
import {getStore, setupReduxConnection} from "../../../../redux";
import {updateIngredients} from "../../../../redux/supply";
import IngredientsTable from "../../../../tables/operations/recipes/supply-chain-inventory-table";
import {withRouter} from "../../../../utils/navigation";
import {IngredientRequests} from "../../../../utils/request-helpers/supply-chain/supply-chain-requests";

const VIEW_TYPES = {TABLE: "TABLE", TREE: "TREE"};

class SupplyChainInventoryPage extends Component {
  state = {view: VIEW_TYPES.TABLE, ingredientUpdates: {}};

  componentWillUnmount() {
    clearInterval(this.intervalToCancel);
  }

  includeModals() {
    return (
      <>
        <IngredientModal ref={(e) => (this.ingredientModal = e)} />
        <IngredientCategoryModal ref={(e) => (this.ingredientCategoryModal = e)} />
        <QuickStockIngredientModal ref={(e) => (this.quickStockIngredientModal = e)} />
      </>
    );
  }

  fetchCreateIngredientButton() {
    return {
      label: "Create Ingredient",
      onClick: () => this.ingredientModal.open(),
    };
  }

  fetchSaveIngredientsButton() {
    return {
      label: "Update Ingredient Costs",
      onClick: () => {
        const {ingredientUpdates} = this.state;
        const requests = [];
        for (const entry of Object.entries(ingredientUpdates)) {
          const [id, ingredient] = entry;
          const cost = decimalToDollars(ingredient.COST);
          requests.push(IngredientRequests.updateIngredient(id, {COST_PER_UNIT: cost}));
        }
        Promise.all(requests).then(() => {
          getStore().dispatch(updateIngredients());
          this.setState({ingredientUpdates: {}});
        });
      },
    };
  }

  fetchIngredientGroupsButton(categories) {
    return {
      label: "View Ingredient Groups",
      onClick: () => this.ingredientCategoryModal.open(categories),
    };
  }

  fetchTableViewButton() {
    return {
      label: "Table",
      onClick: () => this.setState({view: VIEW_TYPES.TABLE}),
    };
  }

  fetchTreeViewButton() {
    return {
      label: "Tree",
      onClick: () => this.setState({view: VIEW_TYPES.TREE}),
    };
  }

  fetchPageHeadingButtons(categories) {
    return [this.fetchCreateIngredientButton(), this.fetchIngredientGroupsButton(categories)];
  }

  renderPageHeadings(categories) {
    const {ingredientUpdates} = this.state;
    const shouldSaveIngredients = Object.keys(ingredientUpdates).length > 0;
    const buttons = this.fetchPageHeadingButtons(categories);
    if (shouldSaveIngredients) {
      buttons.unshift(this.fetchSaveIngredientsButton());
    }
    return <PageHeadings label="Ingredients" description="Create and view ingredients." buttons={buttons} />;
  }

  fetchEditButton() {
    return {
      label: "Edit",
      onClick: (_ingredient) => this.ingredientModal.open(_ingredient),
    };
  }

  fetchViewButton() {
    return {
      label: "View",
      onClick: (row) => this.props.router.navigate("/recipes/ingredients/" + row.UNIQUE_ID),
    };
  }

  fetchQuickStockButton() {
    return {
      label: "Stock",
      onClick: (ingredient) => {
        return this.quickStockIngredientModal.open(ingredient);
      },
    };
  }

  fetchIngredientsTableActionButtons() {
    return [this.fetchQuickStockButton(), this.fetchEditButton(), this.fetchViewButton()];
  }

  renderTableView() {
    const {ingredients} = this.props.supply;
    const {ingredientUpdates} = this.state;

    for (const ingredient of ingredients) {
      ingredient.COST = ingredient.COST ?? toDollars(ingredient.COST_PER_UNIT);
    }

    const updateState = (newState) => {
      const {ingredientUpdates} = this.state;
      this.setState({ingredientUpdates: {...ingredientUpdates, ...newState}});
    };

    return (
      <IngredientsTable
        data={ingredients.map((it) => ({...it, COST: ingredientUpdates[it.ID]?.COST ?? it.COST}))}
        updateState={updateState}
        actionButtons={this.fetchIngredientsTableActionButtons()}
      />
    );
  }

  renderTreeView() {
    const {ingredients} = this.props.supply;
    return <RecipeBuilder ingredients={ingredients} />;
  }

  renderView() {
    const {view} = this.state;

    if (view === VIEW_TYPES.TABLE) {
      return this.renderTableView();
    }

    return this.renderTreeView();
  }

  renderBanner() {
    return (
      <Banner label="Supply Chain Units functionality update: By the end of May, the “Display Unit” and “Unit of Measurement” fields will be combined into a single “Unit” field. There is no action needed from you as we will be migrating your existing “Display Unit” data into the new “Unit” field. You’ll now be able to choose from any linked unit directly within a recipe to make ingredient setup simpler and more flexible when building recipes." />
    );
  }

  render() {
    const {ingredients, categories} = this.props.supply;

    if (!ingredients || !categories) {
      return <LoadingSpinner />;
    }

    return (
      <>
        {this.renderBanner()}
        {this.includeModals()}
        {this.renderPageHeadings(categories)}
        {this.renderView()}
      </>
    );
  }
}

export default setupReduxConnection(["shop", "supply"])(withRouter(SupplyChainInventoryPage));
