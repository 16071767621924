import OnboardingPacketsHeading from "./onboarding-packets-heading";
import {Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {usePackets} from "../../../../../hooks/team/onboarding/onboarding-hooks";
import {ReadOnlyTable} from "../../../../../v2/components/shared";
import {COLUMNS, SUBTABLE_COLUMNS} from "./packets-helper";
import {useLocation, useNavigate} from "react-router-dom";
import {getTableData} from "../onboarding-helper";
import {useState} from "react";
import {ConfirmationModal} from "../../../../../modals/general/confirmation-modal";
import {useDeletePacket, useSignedUrl} from "../../../../../hooks/team/onboarding/onboarding-mutate-hooks";

function OnboardingPackets() {
  const {isLoading, error, data} = usePackets();

  const navigate = useNavigate();

  const location = useLocation();

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const [selectedPacket, setSelectedPacket] = useState(null);

  const {openDocument} = useSignedUrl();

  const handleViewDocument = (document) => {
    openDocument(document.ID);
  };

  const handleCreatePacket = () => {
    navigate("/onboarding/manage-packet", {state: {from: location.pathname, packet: null}});
  };

  const handleEditPacket = (packet) => {
    navigate("/onboarding/manage-packet", {state: {from: location.pathname, packet: packet}});
  };

  const handleAssignPacket = (packet) => {
    navigate("/onboarding/assign-packet", {state: {from: location.pathname, selectedPacket: packet.ID}});
  };

  const {mutate: deletePacket, isLoading: mutationLoading} = useDeletePacket();

  const handleDelete = () => {
    deletePacket(selectedPacket.ID);
  };

  const handleDeletePacket = (packet) => {
    setSelectedPacket(packet);
    setIsConfirmationModalOpen(true);
  };

  return (
    <>
      <OnboardingPacketsHeading handleCreatePacket={handleCreatePacket} />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        handleModalClose={() => {
          setIsConfirmationModalOpen(false);
          setSelectedPacket(null);
        }}
        modalLabel={"Delete Packet Confirmation"}
        tooltip={
          "Deleting the packet will unassign it from employees, however, all documents from completed packets will still be visible in an employee's document section."
        }
        confirmationAction={handleDelete}
        isLoading={mutationLoading}
      />

      <Card
        label={"Packets"}
        description={
          "This is the list of packets that have been created for the company, and their associated documents."
        }
      >
        <Table
          sortable
          classNames={"mt-8"}
          columns={COLUMNS}
          data={getTableData(isLoading, error, data)}
          pagination={true}
          actionButtons={[
            {
              label: "Assign",
              onClick: (row) => handleAssignPacket(row),
            },
            {
              label: "Edit",
              onClick: (row) => handleEditPacket(row),
            },
            {
              label: "Delete",
              onClick: (row) => handleDeletePacket(row),
            },
          ]}
          expandable={(packet) => (
            <ReadOnlyTable
              className={"m-2"}
              columns={SUBTABLE_COLUMNS}
              data={packet.DOCUMENTS}
              buttons={[
                {
                  label: "View",
                  onClick: (document) => handleViewDocument(document),
                },
              ]}
            />
          )}
        />
      </Card>
    </>
  );
}

export default OnboardingPackets;
