export function isTimeOffPartialDay(daysArr) {
  for (const day of daysArr) {
    if (day.AMOUNT !== 8) {
      return true;
    }
  }

  return false;
}

export const getPolicyWorkedHours = (policyAmount) => {
  return Math.round(1 / policyAmount) < 1
    ? parseFloat((1 / policyAmount).toFixed(1))
    : Math.round(1 / policyAmount);
};
