import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";

export const HubHomePage = () => {
  const navigate = useNavigate();

  return (
    <div
      className={
        "flex flex-col md:flex-row w-full h-[85vh] items-center justify-evenly mt-6 px-12 gap-6 md:gap-8"
      }
    >
      <RouteSquare icon={"far fa-sack-dollar"} label={"Payroll"} onClick={() => navigate("/hub/payroll")} />

      <RouteSquare
        icon={"far fa-users"}
        label={"Onboarding"}
        onClick={() => navigate("/hub/employee-onboarding")}
      />
    </div>
  );
};

const RouteSquare = ({icon, label, onClick}) => {
  return (
    <div
      className={
        "flex flex-col gap-y-4 w-full md:w-2/5 h-1/2  items-center justify-center bg-indigo-500 rounded-3xl border hover:bg-indigo-700"
      }
      onClick={onClick}
    >
      <FontAwesomeIcon className={"text-white h-1/5"} icon={icon} />
      <p className={"text-white font-semibold text-3xl"}>{label}</p>
    </div>
  );
};
