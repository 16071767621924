import React, {Component} from "react";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import MemoDetailsModal from "../../../modals/team/memo-details-modal";
import {POLICY_TYPE} from "../../../utils/constants";
import {setupReduxConnection} from "../../../redux";

class PoliciesPage extends Component {
  state = {policies: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let policies = await request("timeoff/policies", "GET");

    this.setState({policies});
  }

  render() {
    const {policies} = this.state;
    const {DISABLE_TIME_OFF} = this.props.shop.settings;

    if (policies === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Time Off Policies"
          status={DISABLE_TIME_OFF === "1" && {label: "Disabled", type: "error"}}
          description="Create policies for employees to earn and request paid or unpaid time off"
          buttons={[
            {
              label: "Run all Policies",
              onClick: () => this.props.router.navigate("/policy/run"),
            },
            {
              label: "Create Policy",
              onClick: () => this.props.router.navigate("/policy"),
            },
          ]}
        />

        <Table
          pagination
          data={policies}
          className="mt-4"
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/policy/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "TYPE",
              label: "Type",
              format: (type) => POLICY_TYPE[type],
            },
            {
              value: "METHOD",
              label: "Accrual Method",
              format: (method) => method.capitalize(),
            },
            {
              value: "EMPLOYEES",
              label: "Enrolled Employees",
              format: (employees) => employees.length,
            },
            {
              label: "Date Created",
              value: "DATE_CREATED",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["shop"])(withRouter(PoliciesPage));
