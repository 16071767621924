import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {getFormattedPhone} from "@frostbyte-technologies/frostbyte-core/dist/helpers/phone-helper";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {classNames, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import InvoicesModal from "../../../modals/operations/invoices/invoices-modal";
import {withRouter} from "../../../utils/navigation";

class ContactsTable extends Component {
  render() {
    const {contacts, invoices} = this.props;

    const notPaid = invoices.filter((item) => item.STATUS !== "PAID" && item.STATUS !== "REFUNDED");

    const notDue = notPaid.filter((item) => {
      if (item.DATE_DUE === null) return true;

      return item.DATE_DUE > Date.now();
    });

    const lastThirty = notPaid.filter((item) => {
      if (item.DATE_DUE === null || item.DATE_DUE > Date.now()) return false;

      return item.DATE_DUE > moment().subtract(1, "month").valueOf();
    });

    const lastThirtyNinety = notPaid.filter((item) => {
      if (item.DATE_DUE === null || item.DATE_DUE > Date.now()) return false;

      return (
        item.DATE_DUE < moment().subtract(1, "month").valueOf() &&
        item.DATE_DUE > moment().subtract(3, "months").valueOf()
      );
    });

    const pastNinety = notPaid.filter((item) => {
      if (item.DATE_DUE === null || item.DATE_DUE > Date.now()) return false;

      return item.DATE_DUE < moment().subtract(3, "months").valueOf();
    });

    return (
      <>
        <InvoicesModal ref={(e) => (this.invoicesModal = e)} />

        <Table
          pagination
          data={contacts}
          ref={(e) => (this.tableRef = e)}
          actionButtons={[
            {
              label: "View",
              onClick: (row) => this.props.router.navigate("/contact/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              width: 1,
              value: "CONTACT_NAME",
              label: "Customer Name",
              format: (_, customer) => {
                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {customer.FIRST_NAME + " " + customer.LAST_NAME}
                    </div>

                    <div className="text-sm text-gray-500">{customer.EMAIL}</div>

                    <div className="text-sm text-gray-500">{getFormattedPhone(customer.PHONE)}</div>
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "EMAIL",
              label: "Not Due",
              format: (_, contact) => {
                const contactNotDue = notDue.filter((item) => {
                  return item.CONTACT.ID === contact.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      $
                      {toDollars(
                        contactNotDue.reduce((accum, item) => {
                          return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                        }, 0)
                      )}
                    </div>

                    <div
                      onClick={() => this.invoicesModal.open(contactNotDue)}
                      className={classNames(
                        "text-sm cursor-pointer",
                        contactNotDue.length === 0
                          ? "text-gray-500"
                          : "font-medium text-indigo-500 hover:text-indigo-600"
                      )}
                    >
                      {contactNotDue.length} Invoices
                    </div>
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "EMAIL",
              label: "1-30 days overdue",
              format: (_, contact) => {
                const contactNotDue = lastThirty.filter((item) => {
                  return item.CONTACT.ID === contact.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      $
                      {toDollars(
                        contactNotDue.reduce((accum, item) => {
                          return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                        }, 0)
                      )}
                    </div>

                    <div
                      onClick={() => this.invoicesModal.open(contactNotDue)}
                      className={classNames(
                        "text-sm cursor-pointer",
                        contactNotDue.length === 0
                          ? "text-gray-500"
                          : "font-medium text-indigo-500 hover:text-indigo-600"
                      )}
                    >
                      {contactNotDue.length} Invoices
                    </div>
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "EMAIL",
              label: "30-90 days overdue",
              format: (_, contact) => {
                const contactNotDue = lastThirtyNinety.filter((item) => {
                  return item.CONTACT.ID === contact.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      $
                      {toDollars(
                        contactNotDue.reduce((accum, item) => {
                          return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                        }, 0)
                      )}
                    </div>

                    <div
                      onClick={() => this.invoicesModal.open(contactNotDue)}
                      className={classNames(
                        "text-sm cursor-pointer",
                        contactNotDue.length === 0
                          ? "text-gray-500"
                          : "font-medium text-indigo-500 hover:text-indigo-600"
                      )}
                    >
                      {contactNotDue.length} Invoices
                    </div>
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "EMAIL",
              label: "90+ days overdue",
              format: (_, contact) => {
                const contactNotDue = pastNinety.filter((item) => {
                  return item.CONTACT.ID === contact.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      $
                      {toDollars(
                        contactNotDue.reduce((accum, item) => {
                          return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                        }, 0)
                      )}
                    </div>

                    <div
                      onClick={() => this.invoicesModal.open(contactNotDue)}
                      className={classNames(
                        "text-sm cursor-pointer",
                        contactNotDue.length === 0
                          ? "text-gray-500"
                          : "font-medium text-indigo-500 hover:text-indigo-600"
                      )}
                    >
                      {contactNotDue.length} Invoices
                    </div>
                  </div>
                );
              },
            },
            {
              width: 1,
              value: "EMAIL",
              label: "Total Outstanding",
              format: (_, contact) => {
                const contactNotDue = notPaid.filter((item) => {
                  return item.CONTACT.ID === contact.ID;
                });

                return (
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      $
                      {toDollars(
                        contactNotDue.reduce((accum, item) => {
                          return accum + item.TICKET.PAYMENT_INTENT.TOTAL;
                        }, 0)
                      )}
                    </div>

                    <div
                      onClick={() => this.invoicesModal.open(contactNotDue)}
                      className={classNames(
                        "text-sm cursor-pointer",
                        contactNotDue.length === 0
                          ? "text-gray-500"
                          : "font-medium text-indigo-500 hover:text-indigo-600"
                      )}
                    >
                      {contactNotDue.length} Invoices
                    </div>
                  </div>
                );
              },
            },
          ]}
          {...this.props}
        />
      </>
    );
  }
}

ContactsTable.propTypes = {
  contacts: PropTypes.array.isRequired,
  invoices: PropTypes.array.isRequired,
};

export default withRouter(ContactsTable);
