import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";

class EmployeeModal extends Component {
  state = {
    employeeId: null,
    empUnavailable: null,
    empPref: null,
    empTimeOff: null,
    name: null,
  };

  open(employeeId, unavailability = null, timeOff = null, title) {
    function onlyUnique(value, index, self) {
      return self.findIndex((item) => item.ID === value.ID) === index;
    }

    function dayOfWeekSort(a, b) {
      return moment(a.DATE_START).format("E") > moment(b.DATE_START).format("E");
    }

    const empUnavailable = unavailability
      ?.filter((item) => item.TYPE === 1 && item.EMPLOYEE_ID === employeeId)
      .filter(onlyUnique);
    const empPref = unavailability
      ?.filter((item) => item.TYPE === 0 && item.EMPLOYEE_ID === employeeId)
      .filter(onlyUnique);
    const empTimeOff = timeOff
      ?.filter((item) => item.EMPLOYEE_ID === employeeId && item.DATE_END > Date.now())
      .filter(onlyUnique);

    empUnavailable.sort(dayOfWeekSort);
    empPref.sort(dayOfWeekSort);

    this.setState({employeeId, empUnavailable, empPref, empTimeOff, name: title}, () => {
      this.formikRef && this.formikRef.resetForm();
      this.slide.open();
    });
  }

  close() {
    this.setState(
      {employeeId: null, empUnavailable: null, empPref: null, empTimeOff: null},
      this.slide.close()
    );
  }

  render() {
    const {employeeId, name, empUnavailable, empPref, empTimeOff} = this.state;
    const {navigateToEmployee} = this.props;

    return (
      <Modal
        label={`${name}'s Time`}
        buttonLabel={"Open Employee"}
        buttonOnClick={() => {
          this.slide.close();
          navigateToEmployee("/employee/" + employeeId);
        }}
        ref={(e) => (this.slide = e)}
      >
        <div className="text-lg font-medium text-gray-800">Work Preferences</div>

        {empPref && empPref.length !== 0 ? (
          empPref.map((item) => {
            return (
              <div key={item.ID} className="text-sm font-medium text-gray-600 pt-0.5">
                {`${moment(item.DATE_START).format("ddd hh:mma")} - ${moment(item.DATE_END).format(
                  "ddd hh:mma"
                )}`}
              </div>
            );
          })
        ) : (
          <div className="text-sm font-medium text-gray-600">None</div>
        )}

        <div className="text-lg font-medium text-gray-800 pt-4">Unavailability</div>

        {empUnavailable && empUnavailable.length !== 0 ? (
          empUnavailable.map((item) => {
            return (
              <div key={item.ID} className="text-sm font-medium text-gray-600 pt-0.5">
                {`${moment(item.DATE_START).format("ddd hh:mma")} - ${moment(item.DATE_END).format(
                  "ddd hh:mma"
                )}`}
              </div>
            );
          })
        ) : (
          <div className="text-sm font-medium text-gray-600">None</div>
        )}

        <div className="text-lg font-medium text-gray-800 pt-4">Upcoming Time Off</div>

        {empTimeOff && empTimeOff.length !== 0 ? (
          empTimeOff.map((item) => {
            return (
              <div key={item.ID} className="text-sm font-medium text-gray-600 pt-0.5">
                {`${moment(item.DATE_START).format("DD/MM/YY hh:mma")} - ${moment(item.DATE_END).format(
                  "DD/MM/YY hh:mma"
                )}`}
              </div>
            );
          })
        ) : (
          <div className="text-sm font-medium text-gray-600">None</div>
        )}
      </Modal>
    );
  }
}

export default EmployeeModal;
