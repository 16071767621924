import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Filter, Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import ChecklistModal from "../../../modals/operations/checklists/checklist-modal";

class ChecklistsPage extends Component {
  state = {
    isLoading: true,
    checklists: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    let checklists = [];
    try {
      checklists = await request("checklists", "GET", {});
    } catch (e) {
      console.log(e);
    }

    this.setState({isLoading: false, checklists});
  }

  render() {
    const {isLoading, checklists} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          label="Checklists"
          description="Create checklists of tasks for employees to follow during their shifts"
          button={{
            label: "Create Checklist",
            onClick: () => this.checklistModal.open(),
          }}
        />

        <ChecklistModal
          ref={(e) => (this.checklistModal = e)}
          addState={(checklist) => this.props.router.navigate("/checklist/" + checklist.UNIQUE_ID)}
        />

        <Filter
          searchable
          data={[
            {
              id: "LOCATIONS",
              label: "Location",
              onFilter: (options, data) => {
                return data.filter((item) => {
                  return item.LOCATIONS.some((location) => options.includes(location.LOCATION_ID));
                });
              },
              options: this.props.shop.locations.map((item) => {
                return {id: item.ID, value: item.ID, label: item.NAME};
              }),
            },
          ]}
        >
          {(filters, search) => {
            return (
              <Table
                pagination
                search={search}
                searchFields={["NAME"]}
                filters={filters}
                className="mt-4"
                data={checklists}
                ref={(e) => (this.tableRef = e)}
                actionButtons={[
                  {
                    label: "View",
                    onClick: (row) => this.props.router.navigate("/checklist/" + row.UNIQUE_ID),
                  },
                ]}
                columns={[
                  {
                    value: "NAME",
                    label: "Name",
                  },
                  {
                    value: "LOCATIONS",
                    label: "Locations",
                    format: (locations) => locations.map((item) => item.LOCATION_NAME).join(", "),
                  },
                  {
                    value: "ITEMS",
                    label: "Number of Tasks",
                    format: (tasks) => tasks.length,
                  },
                  {
                    width: 1,
                    label: "Created",
                    value: "DATE_CREATED",
                    format: (value, row) => {
                      return moment(value).format("MM/DD/YY");
                    },
                  },
                  {
                    width: 1,
                    label: "Updated",
                    value: "DATE_UPDATED",
                    format: (value, row) => {
                      if (!value) {
                        value = row.DATE_CREATED;
                      }

                      return moment(value).format("MM/DD/YY");
                    },
                  },
                ]}
              />
            );
          }}
        </Filter>
      </div>
    );
  }
}

export default setupReduxConnection(["menu", "shop"])(withRouter(ChecklistsPage));
