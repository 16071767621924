import React, {Component} from "react";
import {
  Tab,
  Card,
  Loading,
  PageHeadings,
  TwoColumnList,
  Table,
} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import {request} from "../../../utils/request";
import {getObjectImage} from "@frostbyte-technologies/frostbyte-core/dist/helpers/asset-helper";
import TaskModal from "../../../modals/marketing/tasks/task-modal";
import moment from "moment-timezone";
import PrizeModal from "../../../modals/marketing/tasks/prize-modal";
import {updateStateDelegator} from "../../../utils/util";

const TASK_TYPE = {
  TICKET: "Number of Orders",
  PRODUCT: "Product Orders",
  CATEGORY: "Category Orders",
};

class TaskPage extends Component {
  state = {task: null, prizes: null};

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const {ID: id} = this.props.router.params;

    let task = await request("rewards/tasks/" + id, "GET");

    this.setState({prizes: task.PRIZES, task});
  }

  render() {
    const {task, prizes} = this.state;

    if (task === null) {
      return <Loading />;
    }

    return (
      <div>
        <PageHeadings
          logo={getObjectImage(task, "LOGO", "appicon.png")}
          label={task.NAME}
          breadcrumbs={[
            {label: "Marketing", url: "/"},
            {label: "Loyalty Page", url: "/loyalty"},
            {label: "Tasks Page", url: "/tasks"},
          ]}
        />

        <TaskModal
          updateState={(task) => this.setState({task: {...task}})}
          ref={(e) => (this.taskModal = e)}
        />

        <PrizeModal
          task={task}
          ref={(e) => (this.prizeModal = e)}
          addState={(prize) => this.setState({prizes: [...prizes, prize]})}
          updateState={(id, prize) => {
            this.setState({
              prizes: updateStateDelegator(prizes, id, prize),
            });
          }}
        />

        <TwoColumnList
          label="Task Information"
          button={{
            label: "Edit Information",
            onClick: () => {
              this.taskModal.open(task);
            },
          }}
          data={[
            {
              label: "Name",
              value: task.NAME,
            },
            {
              label: "Task Range",
              value: task.DATE_START ? "Time Range" : "Anytime",
            },
            ...(task.DATE_START
              ? [
                  {label: "Date Start", value: moment(task.DATE_START).format("MM/DD/YY")},
                  {
                    label: "Date End",
                    value: moment(task.DATE_END).format("MM/DD/YY"),
                  },
                ]
              : []),
            {
              label: "Target Location",
              value: task.LOCATION_NAME ?? "Any Location",
            },
            {label: "Task Type", value: TASK_TYPE[task.TYPE]},
            {label: "Task Quantity", value: task.QUANTITY},
            ...(task.CATEGORY_NAME
              ? [
                  {
                    label: "Category Name",
                    value: task.CATEGORY_NAME,
                  },
                ]
              : []),
            ...(task.PRODUCT_NAME
              ? [
                  {
                    label: "Product Name",
                    value: task.PRODUCT_NAME,
                  },
                ]
              : []),
            {label: "Description", value: task.DESCRIPTION, span: true},
          ]}
        />

        <Tab
          data={[
            {id: "prizes", label: "Reward"},
            {id: "champions", label: "Champions"},
          ]}
        >
          {(id) => {
            if (id === "prizes") {
              return (
                <Card
                  label="Task Reward"
                  description="The rewards a patron who completes this task will receive"
                  buttons={[
                    {
                      label: "Add Prize",
                      onClick: () => this.prizeModal.open(),
                    },
                  ]}
                >
                  <Table
                    data={prizes}
                    actionButtons={[
                      {
                        label: "Edit",
                        onClick: (row) => this.prizeModal.open(row),
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Name",
                      },
                      {
                        value: "TYPE",
                        label: "Type",
                      },
                      {
                        value: "QUANTITY",
                        label: "quantity",
                      },
                    ]}
                  />
                </Card>
              );
            }

            if (id === "champions") {
              return (
                <Card label="Champions" description="Patrons who have completed this task">
                  <Table
                    data={[]}
                    actionButtons={[
                      {
                        label: "View Customer",
                      },
                    ]}
                    columns={[
                      {
                        value: "NAME",
                        label: "Customer Name",
                      },
                      {
                        value: "DATE_CREATED",
                        label: "Time Earned",
                      },
                    ]}
                  />
                </Card>
              );
            }

            return <div>test</div>;
          }}
        </Tab>
      </div>
    );
  }
}

export default withRouter(TaskPage);
