import {Card} from "@frostbyte-technologies/frostbyte-tailwind";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {usePacketDocuments} from "../../../hooks/team/onboarding/onboarding-hub-hooks";
import React from "react";
import LoadingSpinner from "../../../components/loading-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  DOCUMENT_FED_CATEGORY,
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
} from "../../team/onboarding/functional/documents/documents-helper";
import {ChevronRightIcon} from "@heroicons/react/solid";

export const EmployeePacketPage = () => {
  const {PACKET_ASSIGNMENT_UNIQUE_ID} = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const {isLoading, error, data: packets} = usePacketDocuments(PACKET_ASSIGNMENT_UNIQUE_ID);

  if (isLoading) {
    return (
      <div className={"flex justify-center items-center"}>
        <LoadingSpinner />
      </div>
    );
  }

  const displayStatusIcon = (status) => {
    switch (status) {
      case DOCUMENT_STATUS.PENDING_EMPLOYEE:
        return (
          <FontAwesomeIcon
            className={"text-white border border-indigo-500 rounded-full"}
            icon={"fa fa-circle"}
          />
        );
      case DOCUMENT_STATUS.REJECTED:
        return <FontAwesomeIcon className={"text-red-600"} icon={"far fa-circle-exclamation"} />;
      case DOCUMENT_STATUS.PENDING_REVIEW:
        return <FontAwesomeIcon className={"text-yellow-400"} icon={"fas fa-check-circle"} />;
      case DOCUMENT_STATUS.COMPLETED:
        return <FontAwesomeIcon className={"text-green-600"} icon={"fas fa-check-circle"} />;
      default:
        return <FontAwesomeIcon className={"text-gray-400"} icon={"fal fa-circle"} />;
    }
  };

  const handleFederalNavigation = (category, assignmentUId) => {
    if (category === DOCUMENT_FED_CATEGORY.I9) {
      navigate(`/hub/employee-onboarding/packet/form/federal/I9/${assignmentUId}`, {
        state: {from: location.pathname, packetAssignmentUId: PACKET_ASSIGNMENT_UNIQUE_ID},
      });
    } else if (category === DOCUMENT_FED_CATEGORY.W4) {
      navigate(`/hub/employee-onboarding/packet/form/federal/W4/${assignmentUId}`, {
        state: {from: location.pathname, packetAssignmentUId: PACKET_ASSIGNMENT_UNIQUE_ID},
      });
    }
  };

  const handleCustomNavigation = (name, uniqueId, assignmentUId) => {
    navigate(`/hub/employee-onboarding/packet/form/${assignmentUId}`, {
      state: {
        from: location.pathname,
        documentName: name,
        documentUID: uniqueId,
        packetAssignmentUId: PACKET_ASSIGNMENT_UNIQUE_ID,
      },
    });
  };

  const handleInReviewOrCompleted = (document, inReview = false) => {
    navigate(`/hub/employee-onboarding/packet/form/review`, {
      state: {
        from: location.pathname,
        name: document.DOCUMENT_NAME,
        type: document.DOCUMENT_TYPE,
        category: document.DOCUMENT_CATEGORY,
        documentUID: document.DOCUMENT_ID,
        packetAssignmentUId: PACKET_ASSIGNMENT_UNIQUE_ID,
        inReview: inReview,
        document: document,
      },
    });
  };

  const handleDocumentClick = (document) => {
    if (document.DOCUMENT_STATUS === DOCUMENT_STATUS.PENDING_REVIEW) {
      return handleInReviewOrCompleted(document, true);
    }

    if (document.DOCUMENT_STATUS === DOCUMENT_STATUS.COMPLETED) {
      return handleInReviewOrCompleted(document);
    }

    if (document.DOCUMENT_TYPE === DOCUMENT_TYPE.FEDERAL) {
      return handleFederalNavigation(document.DOCUMENT_CATEGORY, document.DOCUMENT_ASS_UID);
    }

    if (document.DOCUMENT_TYPE === DOCUMENT_TYPE.CUSTOM || document.DOCUMENT_TYPE === DOCUMENT_TYPE.STATE) {
      handleCustomNavigation(document.DOCUMENT_NAME, document.DOCUMENT_ID, document.DOCUMENT_ASS_UID);
    }
  };

  return (
    <Card
      label={packets?.PACKET_NAME ?? "Onboarding Packet"}
      description={
        "These documents must be completed as part of the onboarding packet. Once a document is completed, it will be automatically sent for review if needed. If the document is under review, it will be marked with a yellow check. Once the document is completed and approved, it will be marked with a green check."
      }
      className={"pb-8"}
    >
      <div className={"mt-8 mx-8"}>
        {packets?.assignments?.map((document, index) => (
          <div
            onClick={() => {
              handleDocumentClick(document);
            }}
            className={
              "mx-1 my-2 bg-indigo-500 bg-opacity-10 border border-indigo-500 border-opacity-25 rounded-md hover:bg-indigo-500 hover:bg-opacity-20 cursor-pointer m-1"
            }
            key={`${document.DOCUMENT_ID}-${index}`}
          >
            <div className={"flex items-center justify-between p-4"}>
              <div>
                {displayStatusIcon(document.DOCUMENT_STATUS)}

                <span className="text-sm font-medium text-indigo-600 truncate ml-4">
                  {document.DOCUMENT_NAME}
                </span>
              </div>

              <div className={"flex flex-row items-center font-semibold text-gray-600"}>
                {document.DOCUMENT_STATUS === DOCUMENT_STATUS.REJECTED ? (
                  <span className={"text-red-600"}>Resubmit</span>
                ) : (
                  <span>View</span>
                )}

                <ChevronRightIcon
                  className={`h-5 w-5 ${
                    document.DOCUMENT_STATUS === DOCUMENT_STATUS.REJECTED ? "text-red-600" : "text-gray-700"
                  }`}
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};
