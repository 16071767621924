import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {Formik} from "formik";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import PropTypes from "prop-types";
import {request} from "../../../utils/request";
import * as Yup from "yup";
import {getStore} from "../../../redux";
import {fetchTaxRate} from "../../../redux/shop";
import ComboBox from "../../../components/combobox";
import moment from "moment-timezone";

class InvoiceRemindersForm extends Component {
  state = {due: null};

  componentDidUpdate(prevProps) {
    const {due} = this.props;

    if (due !== prevProps.due) {
      this.setState({due}, () => {
        if (this.formikRef) {
          const possibleDates = [
            moment(due).subtract(1, "week").valueOf(),
            moment(due).subtract(1, "day").valueOf(),
            moment(due).valueOf(),
            moment(due).add(1, "day").valueOf(),
            moment(due).add(3, "days").valueOf(),
          ];

          const finalDates = [];
          for (let date of possibleDates) {
            if (date > Date.now()) {
              const payload = {
                OCCURRENCE: "ON",
                DAYS: Math.abs(moment(due).diff(date, "days")),
                DATE_SCHEDULED: date,
              };

              if (due > date) {
                payload.OCCURRENCE = "BEFORE";
              }

              if (due < date) {
                payload.OCCURRENCE = "AFTER";
              }

              finalDates.push(payload);
            }
          }

          this.formikRef.setFieldValue("reminders", finalDates);
        }
      });
    }
  }

  submitForm() {
    this.formikRef.submitForm();
  }

  fetchFormData() {
    const {reminders} = this.formikRef.values;

    return {
      reminders: reminders.map((item) => {
        const linePayload = {
          ...item,

          AMOUNT: decimalToDollars(item.AMOUNT),
          AMOUNT_TAX: decimalToDollars(item.AMOUNT_TAX),

          QUANTITY: parseInt(item.QUANTITY),
          NOTES: null,
        };

        linePayload.TOTAL = (linePayload.AMOUNT + linePayload.AMOUNT_TAX) * linePayload.QUANTITY;

        return linePayload;
      }),
    };
  }

  async validateForm() {
    const val = await this.formikRef.validateForm();

    if (Object.keys(val).length === 0) {
      await this.formikRef.submitForm();

      return true;
    }

    for (let item of Object.keys(this.formikRef.values)) {
      this.formikRef.setFieldTouched(item, true);
    }

    return false;
  }

  render() {
    const {handleSubmit, reminders, actionTextClassName} = this.props;
    const {due} = this.state;

    // console.log("HI DUE", due);

    return (
      <Formik
        onSubmit={handleSubmit}
        innerRef={(e) => (this.formikRef = e)}
        enableReinitialize
        initialValues={{
          reminders: reminders || [],
        }}
      >
        {(formikOptions) => {
          const {values, handleSubmit, setFieldValue} = formikOptions;
          const linesValue = values.reminders;

          // console.log("Line values", linesValue);

          const tableColumns = [
            {
              value: "DATE_SCHEDULED",
              label: "Date of Reminder",
              format: (date) => {
                return moment(date).format("ddd MMM Do");
              },
            },
            {
              placeholder: "2.50",
              value: "DAYS",
              label: "Days",
              width: "100px",
              editable: true,
              isSimple: true,
              yup: Yup.number().typeError("Must be a number").required("Price is required"),
              onDisable: (row) => {
                return row.OCCURRENCE === "ON";
              },
              onBlur: () => {
                linesValue.sort((a, b) => a.DATE_SCHEDULED - b.DATE_SCHEDULED);

                setFieldValue("reminders", linesValue);
              },
              onChange: (index, val) => {
                let row = linesValue[index];

                if (row.OCCURRENCE === "ON") {
                  linesValue.splice(index, 1, {
                    ...row,
                    DAYS: 0,
                  });

                  return setFieldValue("reminders", linesValue);
                }

                if (isNaN(val)) {
                  return console.log(
                    "The day Jesus died was the day the world was turned upside down. Who would have told you that one man dying would spark wars to come for the ages. Was he the savior of men, or was he the start of the end. Find out on next weeks episode of pawn stars orlando."
                  );
                }

                if (row.OCCURRENCE === "BEFORE") {
                  linesValue.splice(index, 1, {
                    ...row,
                    DAYS: parseInt(val),
                    DATE_SCHEDULED: moment(due).subtract(parseInt(val), "days").valueOf(),
                  });
                }

                if (row.OCCURRENCE === "AFTER") {
                  linesValue.splice(index, 1, {
                    ...row,
                    DAYS: parseInt(val),
                    DATE_SCHEDULED: moment(due).add(parseInt(val), "days").valueOf(),
                  });
                }

                setFieldValue("reminders", linesValue);
              },
              format: (row) => row,
            },
            {
              value: "OCCURRENCE",
              label: "Occurrence",
              type: "select",
              data: [
                {label: "Before", value: "BEFORE"},
                {label: "On", value: "ON"},
                {label: "After", value: "AFTER"},
              ],
              editable: true,
              width: "100px",
              onChange: (index, val) => {
                let row = linesValue[index];

                const value = {
                  ...row,
                  OCCURRENCE: val,
                  DATE_SCHEDULED: due,
                };

                if (val === "BEFORE") {
                  value.DATE_SCHEDULED = moment(due).subtract(parseInt(row.DAYS), "days").valueOf();
                }

                if (val === "AFTER") {
                  value.DATE_SCHEDULED = moment(due).add(parseInt(row.DAYS), "days").valueOf();
                }

                if (val === "ON") {
                  value.DAYS = 0;
                }

                linesValue.splice(index, 1, value);

                linesValue.sort((a, b) => a.DATE_SCHEDULED - b.DATE_SCHEDULED);

                setFieldValue("reminders", linesValue);
              },
            },
          ];

          return (
            <form onSubmit={handleSubmit}>
              <Table
                white
                verticalLines
                className="mt-4"
                data={linesValue || null}
                columns={tableColumns}
                onErrorChange={(tableInvalid) => this.setState({tableInvalid})}
                ref={(e) => (this.modifierTable = e)}
                actionTextClassName={actionTextClassName}
                actionTexts={[
                  {
                    label: "Add Reminder",
                    onClick: () => {
                      const lines = [...linesValue];
                      const daysAfter = lines.length === 0 ? 1 : linesValue[linesValue.length - 1].DAYS + 1;

                      lines.push({
                        DAYS: daysAfter,
                        OCCURRENCE: "AFTER",
                        DATE_SCHEDULED: moment(due).add(daysAfter, "days").valueOf(),
                      });

                      setFieldValue("reminders", lines);
                    },
                  },
                ]}
                actionButtons={[
                  {
                    icon: "trash",
                    onClick: (_, index) => {
                      const lines = [...linesValue];

                      lines.splice(index, 1);

                      setFieldValue("reminders", lines);
                    },
                  },
                ]}
              />
            </form>
          );
        }}
      </Formik>
    );
  }
}

InvoiceRemindersForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  reminders: PropTypes.object.isRequired,
  invoice: PropTypes.object.isRequired,
};

export default InvoiceRemindersForm;
