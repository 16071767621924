import {useFormik} from "formik";
import {Button, Card, FormInput} from "@frostbyte-technologies/frostbyte-tailwind";
import {useLocation, useNavigate} from "react-router-dom";
import React, {useMemo} from "react";
import {Row} from "../../../../../v2/components/shared";
import {BackButton} from "../BackButton";
import moment from "moment-timezone";
import {setW4VerificationFields} from "../../../../hub/onboarding/federal/w4/w4-fill-pdf-helper";
import {DATE_MASK, EIN_MASK} from "./dashboard-helper";
import {verificationInitial, verificationSchema} from "../../../../hub/onboarding/federal/w4/w4-helper";
import {useVerificationSubmit} from "../../../../../hooks/team/onboarding/submission-hooks";
import FormStateSelect from "../../../../../components/form-state-select";

export const W4VerificationPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const document = useMemo(() => location.state?.document, [location.state]);

  const {handleSubmit, reviewLoading} = useVerificationSubmit(document.DOCUMENT_ASSIGNMENT_ID);

  const formik = useFormik({
    initialValues: {
      ...verificationInitial,
      employeeStartDate: document?.EMPLOYEE_START_DATE
        ? moment(document?.EMPLOYEE_START_DATE).format("MM/DD/YYYY")
        : "",
    },
    validationSchema: verificationSchema,
    onSubmit: (values) =>
      handleSubmit(document.DOCUMENT_ASSIGNMENT_ID, values, setW4VerificationFields, navigateToReviewTab),
  });

  const navigateToReviewTab = () => {
    navigate("/onboarding/dashboard?tab=Pending+Review");
  };

  return (
    <Card
      label={`W-4 Verification for ${document?.EMPLOYEE_NAME}`}
      noHeaderBorder={true}
      className={"pt-4 pb-6"}
      tooltip={{
        data: "Verify the employee’s identity and work authorization by reviewing their original documents, ensuring they are not expired.",
      }}
      description={
        "Please review and complete the following section to verify the accuracy of the employee’s W-4 information."
      }
    >
      <form className={"px-6"} onSubmit={formik.handleSubmit}>
        <FormInput
          className={"mt-2"}
          name={"employeeStartDate"}
          label={"Employee's First Day"}
          onChange={formik.handleChange}
          mask={DATE_MASK}
          placeholder={"MM/DD/YYYY"}
          options={formik}
        />

        <FormInput
          className={"mt-4"}
          name={"employerEIN"}
          placeholder={"XX-XXXXXXX"}
          label={"Employer Identification Number (EIN)"}
          mask={EIN_MASK}
          onChange={formik.handleChange}
          options={formik}
        />

        <FormInput
          className={"mt-4"}
          name={"employerName"}
          label={"Employer Name"}
          onChange={formik.handleChange}
          options={formik}
        />

        <FormInput
          name={"employerAddress"}
          label={"Employer Address"}
          flex
          options={formik}
          onChange={formik.handleChange}
        />

        <div className="flex flex-row space-x-8">
          <FormInput name={"employerCity"} label={"Employer City"} flex options={formik} />

          <FormStateSelect name={"employerState"} label={"Employer State"} flex options={formik} />

          <FormInput name={"employerZip"} label={"Employer Zip Code"} flex options={formik} />
        </div>

        <Row className={"mt-8 flex flex-row w-full space-x-1"}>
          <BackButton className={"flex-1 py-4"} label="Back" goBack={navigateToReviewTab} />

          <Button
            className={"flex-1 justify-center py-4"}
            type={"submit"}
            label={"Verify"}
            disabled={formik.isSubmitting || reviewLoading}
          />
        </Row>
      </form>
    </Card>
  );
};
