import {formatDate, formatType} from "../documents/documents-helper";
import * as Yup from "yup";

export const packetValidation = Yup.object({
  packetName: Yup.string().trim().required("Packet name is required"),
  selectedDocuments: Yup.array()
    .min(1, "Please select at least one document")
    .required("Documents are required"),
});

export const PACKET_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  REJECTED: "REJECTED",
  PENDING_REVIEW: "PENDING_REVIEW",
  COMPLETED: "COMPLETED",
};
export const COLUMNS = [
  {
    value: "NAME",
    label: "Name",
  },
  {
    value: "VERSION",
    label: "Version",
  },
  {
    value: "DATE_CREATED",
    label: "Date Created",
    format: (value) => formatDate(value),
  },
  {
    value: "DATE_UPDATED",
    label: "Date Updated",
    format: (value) => formatDate(value),
  },
];

export const SUBTABLE_COLUMNS = [
  {
    key: "NAME",
    label: "Document Name",
  },
  {
    key: "TYPE",
    label: "Type",
    format: (value) => formatType(value),
  },
  {
    key: "VERSION",
    label: "Version",
  },
];
