import {FormBoolean, FormInput, FormSelect, Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {Component} from "react";
import {Formik} from "formik";
import {setupReduxConnection} from "../../../redux";
import {request} from "../../../utils/request";
import {decimalToDollars, toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import FormCategorySelect from "../../../components/form-category-select";
import FormProductSelect from "../../../components/form-product-select";
import * as Yup from "yup";
import {showLoadingConfirmAlert} from "../../../utils/alert-helper";
import {withRouter} from "../../../utils/navigation";

export const TRIGGER_SCOPE_TYPES = {
  PRODUCT: {
    id: 0,
    label: "Product",
  },

  CATEGORY: {
    id: 1,
    label: "Category",
  },

  ORDER: {
    id: 2,
    label: "Order",
  },
};

class RuleBasedDiscountModal extends Component {
  state = {discount: null};

  open(discount = null) {
    this.setState({discount}, () => {
      this.modal.open();
    });
  }

  removeDiscount() {
    const {discount} = this.state;

    showLoadingConfirmAlert("Delete Discount", "Are you sure you want to delete this discount?").then(
      async (close) => {
        await request("partner/discount/" + discount.ID, "DELETE", {});

        close();

        this.props.router.navigate("/discounts");
      }
    );
  }

  async handleAction({
    name,
    scope,
    triggerType,
    triggerAmount,
    discountAmount,
    discountType,
    products,
    categories,
    logo,
    enabled,
  }) {
    const {discount} = this.state;

    const payload = {
      NAME: name,
      TYPE: parseInt(discountType),
      CONTENT: parseInt(discountType) === 0 ? decimalToDollars(discountAmount) : discountAmount,
      LOGO: logo?.trim().length > 0 ? logo : "appicon.png",
      TRIGGER: {
        SCOPE: scope,
        TRIGGER_TYPE: triggerType,
        TRIGGER_AMOUNT: triggerType === 0 ? triggerAmount : decimalToDollars(triggerAmount),
        PRODUCTS: scope === TRIGGER_SCOPE_TYPES.PRODUCT.id ? products : [],
        CATEGORIES: scope === TRIGGER_SCOPE_TYPES.CATEGORY.id ? categories : [],
        ENABLED: enabled,
      },
    };

    let serverDiscount = null;
    if (discount) {
      serverDiscount = await request("partner/discount/" + discount.ID, "PUT", payload);
    } else {
      serverDiscount = await request("partner/discount", "POST", payload);
    }

    this.props.updateState({discount: {...serverDiscount}});
    this.modal.close();
  }

  handleDelete = async () => {
    const {ID} = this.state.discount;

    await request("loyalty/rules/redeem/" + ID, "DELETE", null);

    this.props.onChange && this.props.onChange();
    this.modal.close();
  };

  renderScopePicker(options, values) {
    if (values.scope === TRIGGER_SCOPE_TYPES.CATEGORY.id) {
      return (
        <FormCategorySelect
          label="Categories"
          name="categories"
          options={options}
          tooltip={{
            data: "Select which categories this rule should apply to.",
          }}
          multi
        />
      );
    }

    if (values.scope === TRIGGER_SCOPE_TYPES.PRODUCT.id) {
      return (
        <FormProductSelect
          label="Products"
          name="products"
          options={options}
          tooltip={{
            data: "Select which products this rule should apply to.",
          }}
          multi
        />
      );
    }

    return null;
  }

  fetchInitialValues(discount) {
    return {
      name: discount?.NAME,
      scope: discount?.TRIGGER?.SCOPE,
      triggerType: discount?.TRIGGER?.TRIGGER_TYPE,
      triggerAmount: discount?.TRIGGER
        ? discount?.TRIGGER?.TRIGGER_TYPE === 0
          ? discount?.TRIGGER?.TRIGGER_AMOUNT
          : toDollars(discount?.TRIGGER?.TRIGGER_AMOUNT)
        : null,
      discountAmount: parseInt(discount?.TYPE) === 0 ? toDollars(discount?.CONTENT) : discount?.CONTENT,
      discountType: discount?.TYPE,
      enabled: discount?.TRIGGER?.ENABLED ?? 1,
      logo: discount?.LOGO,
      categories: discount?.TRIGGER?.CATEGORIES
        ? discount.TRIGGER.CATEGORIES.map((cat) => cat.CATEGORY_ID || cat.ID)
        : [],
      products: discount?.TRIGGER?.PRODUCTS
        ? discount.TRIGGER.PRODUCTS.map((prod) => prod.PRODUCT_ID || prod.ID)
        : [],
    };
  }

  renderForm(formikOptions, values, handleSubmit) {
    return (
      <form onSubmit={handleSubmit}>
        <FormInput label="Name" name="name" options={formikOptions} placeholder={"BOGO Watermelons"} />

        <FormSelect
          label="Trigger Scope"
          name="scope"
          data={TRIGGER_SCOPES}
          options={formikOptions}
          tooltip={{
            data: TRIGGER_SCOPES,
          }}
        />

        {this.renderScopePicker(formikOptions, values)}

        <FormSelect
          label="Trigger Type"
          name="triggerType"
          data={TRIGGER_TYPES}
          options={formikOptions}
          tooltip={{
            data: TRIGGER_TYPES,
          }}
        />

        <FormInput
          label="Trigger Amount"
          name="triggerAmount"
          placeholder={values?.triggerType ? "5.00" : "2"}
          options={formikOptions}
        />

        <FormSelect
          name="discountType"
          label="Discount Type"
          options={formikOptions}
          data={[
            {label: "Flat", value: 0},
            {label: "Percentage", value: 1},
          ]}
        />

        <FormInput
          label="Discount Amount"
          name="discountAmount"
          placeholder={values?.type ? "75" : "5.00"}
          options={formikOptions}
        />

        <FormBoolean
          label="Enabled"
          name="enabled"
          options={formikOptions}
          tooltip={{
            data: "If enabled, this discount will be automatically applied to the order based on the trigger condition and the discount information.",
          }}
        />
      </form>
    );
  }

  render() {
    const {discount} = this.state;

    const schema = Yup.object({
      name: Yup.string().nullable().required("Rule Name is required."),
      scope: Yup.string().nullable().required("Scope is required."),
      triggerAmount: Yup.number()
        .nullable()
        .required("Trigger Amount is required.")
        .typeError("Trigger Amount must be a number.")
        .min(0.01, "Trigger Amount must be greater than 0."),
      triggerType: Yup.string().nullable().required("Trigger Type is required."),
      discountType: Yup.string().nullable().required("Discount Type is required."),
      discountAmount: Yup.number()
        .nullable()
        .required("Discount Amount is required.")
        .typeError("Discount Amount must be a number."),
      categories: Yup.array().nullable(),
      products: Yup.array().nullable(),
    });

    return (
      <Modal
        label="Rule-Based Discount"
        buttonLabel={discount ? "Save" : "Add"}
        formikOnClick={() => this.formikRef}
        ref={(e) => (this.modal = e)}
        deleteLabel={discount ? "Delete" : null}
        deleteOnClick={() => this.removeDiscount()}
      >
        <Formik
          onSubmit={this.handleAction.bind(this)}
          innerRef={(e) => (this.formikRef = e)}
          initialValues={this.fetchInitialValues(discount)}
          validationSchema={schema}
        >
          {(formikOptions) => {
            const {handleSubmit, values} = formikOptions;
            return this.renderForm(formikOptions, values, handleSubmit);
          }}
        </Formik>
      </Modal>
    );
  }
}

export const TRIGGER_SCOPES = [
  {
    label: "Product Discount",
    value: TRIGGER_SCOPE_TYPES.PRODUCT.id,
    data: "With this discount type, the rule will apply to specific products",
  },
  {
    label: "Category Discount",
    value: TRIGGER_SCOPE_TYPES.CATEGORY.id,
    data: "With this discount type, the rule will apply to specific categories",
  },
  {
    label: "Order Discount",
    value: TRIGGER_SCOPE_TYPES.ORDER.id,
    data: "With this discount type, the rule will apply to the entire order",
  },
];

const TRIGGER_TYPES = [
  {
    label: "Quantity",
    value: 0,
    data: "Select a minimum quantity for the rule to apply",
  },
  {
    label: "Dollar Amount",
    value: 1,
    data: "Select a minimum dollar amount for the rule to apply",
  },
];

export default setupReduxConnection(["shop"], null, {forwardRef: true})(withRouter(RuleBasedDiscountModal));
