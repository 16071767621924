import {Button, FormSelect, PageHeadings} from "@frostbyte-technologies/frostbyte-tailwind";
import React, {useMemo} from "react";
import {useFormik} from "formik";
import {useLocation, useNavigate} from "react-router-dom";
import {AddButton} from "../../../../../forms/team/onboarding/form-add-button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormDateTimeSelect from "../../../../../components/form-date-time-select";
import {usePackets} from "../../../../../hooks/team/onboarding/onboarding-hooks";
import {Row} from "../../../../../v2/components/shared";
import {BackButton} from "../BackButton";
import {assignPacketValidation} from "./dashboard-helper";
import MultiEmployeeSelect from "../../../../../components/multi-employee-select";
import {useSelector} from "react-redux";
import LoadingSpinner from "../../../../../components/loading-spinner";
import {useAssignPacket} from "../../../../../hooks/team/onboarding/onboarding-mutate-hooks";
import {formatDate} from "../documents/documents-helper";

export const AssignPacketPage = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const {isLoading, error, data} = usePackets();

  const sessionStorageKey = "assignPacketFormikValues";

  const account = useSelector((state) => state.user.account);

  const selectedPacket = useMemo(() => location.state?.selectedPacket, [location.state]);

  const {mutate: createPacket, isLoading: mutationLoading} = useAssignPacket();

  const initialValues = JSON.parse(sessionStorage.getItem(sessionStorageKey)) || {
    selectedPacket: selectedPacket ?? null,
    selectedEmployees: [],
    dueDate: null,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: assignPacketValidation,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const payload = {
      PACKET_ID: values.selectedPacket,
      EMPLOYEES: values.selectedEmployees,
      DUE_DATE: values.dueDate,
      ASSIGNED_BY_ACCOUNT_ID: account.ID,
    };

    createPacket(payload);

    goBackToDashboard();
  };

  const goBackToDashboard = () => {
    sessionStorage.removeItem(sessionStorageKey);

    navigate("/onboarding/dashboard");
  };

  const goToPacket = () => {
    const formikValues = JSON.stringify(formik.values);

    sessionStorage.setItem(sessionStorageKey, formikValues);

    navigate("/onboarding/manage-packet", {
      state: {from: location.pathname, origin: location.pathname, packet: null},
    });
  };

  const displayContent = () => {
    if (isLoading) {
      return (
        <div className={"flex min-h-[25vh] items-center justify-center"}>
          <LoadingSpinner />
        </div>
      );
    }

    if (error) {
      return (
        <div className={"flex min-h-[25vh] items-center justify-center"}>
          <FontAwesomeIcon icon="far fa-circle-exclamation" className={"mr-2 text-red-600"} />

          <span className={"text-red-600"}>Unable to load content</span>
        </div>
      );
    }

    if (data) {
      return (
        <>
          <FormSelect
            data={data?.map((packet) => ({label: packet.NAME, value: packet.ID})) ?? []}
            placeholder={
              data?.length ? "Click to select" : "No packets created yet. Please create a packet to continue."
            }
            tooltip={
              "Select the packet you would like to assign. You can create a packet below or from the Company Packets tab."
            }
            disabled={!data?.length}
            label="Select Packet"
            name="selectedPacket"
            options={formik}
          />

          <AddButton label={"Create Packet"} onClick={goToPacket}>
            <FontAwesomeIcon icon={"fal fa-plus-circle"} className={"ml-1 text-gray-700"} />
          </AddButton>

          <FormDateTimeSelect
            name="dueDate"
            closeOnClick={true}
            className={"mt-4"}
            clearable
            label={
              <>
                <span>Due Date</span>
                <span className="ml-1 font-light">(optional)</span>
              </>
            }
            options={formik}
            buttonText={(value) => formatDate(value)}
            hideTime={true}
          />

          <MultiEmployeeSelect
            name={"selectedEmployees"}
            options={formik}
            placeholder={"Click to select employees"}
            label={"Employees"}
            className={"mt-4"}
            isSearchable={true}
            tooltip={
              "Select one or more employees to assign this packet to. Once assigned, the employee will receive an emailed link telling them to complete their packet."
            }
          />
        </>
      );
    }
  };

  return (
    <>
      <PageHeadings
        className={"py-4"}
        label={"Assign Packet"}
        description={
          "Assign a packet to employees in your company. An onboarding link will be sent to their email on file, allowing them to access the documents in the packet, complete them, or take any required actions."
        }
      />

      <form onSubmit={formik.handleSubmit}>
        <div className="mt-4 bg-white px-6 pt-4 pb-8 border rounded-md border-gray-200">
          {displayContent()}
        </div>

        <Row className={"mt-4 justify-end"}>
          <BackButton goBack={goBackToDashboard} />

          <Button
            label={"Assign Packet"}
            type="submit"
            className="text-indigo-600 hover:text-white cursor-pointer"
            disabled={formik.isSubmitting || !data || mutationLoading}
          />
        </Row>
      </form>
    </>
  );
};
