import React from "react";

export const BackButton = ({goBack, label = "Back", className = "", color = "default", disabled = false}) => {
  const colorStyle =
    color === "red"
      ? "bg-red-600 text-white hover:bg-red-700 focus:ring-red-500"
      : "text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500";

  return (
    <button
      type={"button"}
      onClick={goBack}
      disabled={disabled}
      className={
        className +
        " px-6 mr-1 justify-center relative inline-flex items-center py-2 shadow-sm text-sm font-medium rounded-md border focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300 " +
        colorStyle
      }
    >
      {label}
    </button>
  );
};
