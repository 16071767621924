import React, {Component} from "react";
import {Modal} from "@frostbyte-technologies/frostbyte-tailwind";
import moment from "moment-timezone";

class PreferredModal extends Component {
  state = {item: {}};

  open(item) {
    this.setState({item}, () => {
      this.slide.open();
    });
  }

  render() {
    const {FULL_NAME, DATE_CREATED, DATE_START, DATE_END, NOTES} = this.state.item;

    return (
      <Modal label={`${FULL_NAME}'s Preference`} ref={(e) => (this.slide = e)}>
        <div className="text-sm font-medium text-gray-700">Created</div>
        <div>{moment(DATE_CREATED).format("MM/DD/YY, h:mmA")}</div>

        <div className="text-sm font-medium text-gray-700 pt-2">Start</div>
        <div>{moment(DATE_START).format("MM/DD/YY, h:mmA")}</div>

        <div className="text-sm font-medium text-gray-700 pt-2">End</div>
        <div>{moment(DATE_END).format("MM/DD/YY, h:mmA")}</div>

        <div className="mt-4 text-sm font-medium text-gray-700">Notes</div>
        <div>{NOTES ? NOTES : `No Notes`}</div>
      </Modal>
    );
  }
}

export default PreferredModal;
