import React, {Component} from "react";
import {PageHeadings, Card, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../../utils/navigation";
import {setupReduxConnection} from "../../../../redux";
import PurchaseTextCreditsModal from "../../../../modals/marketing/campaigns/purchase-text-credits-modal";
import CardAlert from "../../../../features/card-alert";
import {request} from "../../../../utils/request";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";
import LoadingSpinner from "../../../../components/loading-spinner";
import moment from "moment-timezone";

class CreditsPage extends Component {
  state = {
    reloads: null,
    balance: null,
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    const [reloads, balance] = await Promise.all([
      request("text/marketing/credit/history", "GET", null),
      request("text/marketing/credit/balance", "GET", null),
    ]);

    this.setState({reloads, balance});
  }

  render() {
    const {reloads, balance} = this.state;
    const {TEXT_MARKETING_PURCHASED_CREDITS, TEXT_MARKETING_AUTO_CREDITS, TEXT_MARKETING_AUTO_AMOUNT} =
      this.props.shop.companySettings;

    return (
      <div>
        <PurchaseTextCreditsModal
          ref={(e) => (this.creditModal = e)}
          onSubmit={() => {
            this.setState({reloads: null}, () => this.syncState());
          }}
        />

        <PageHeadings
          label="Text Marketing Credits"
          description="View your credit reload history "
          buttons={[
            {
              type: "dropdown",
              label: "Actions",
              sections: [
                {
                  items: [
                    {
                      label: "Purchase Credits",
                      onClick: () => this.creditModal.open(),
                    },
                  ],
                },
              ],
            },
          ]}
        />

        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-4 lg:grid-cols-4">
          <CardAlert
            label="Free Credits"
            icon="paper-plane"
            value={TEXT_MARKETING_AUTO_CREDITS}
            subtext={"free sends remaining this month"}
            hideView
          />

          <CardAlert
            label="Purchased Credits"
            icon="usd"
            value={TEXT_MARKETING_PURCHASED_CREDITS}
            subtext={"purchased sends remaining"}
            hideView
          />

          <CardAlert
            label="Monthly Reload"
            icon="refresh"
            value={TEXT_MARKETING_AUTO_AMOUNT}
            subtext={"free sends per month"}
            hideView
          />

          <CardAlert
            label="Current Balance"
            icon="dollar"
            value={balance === null ? <LoadingSpinner size={20} /> : toDollars(balance, true)}
            subtext={balance === null ? "" : "owed from purchased credits"}
            hideView
          />
        </div>

        <Card
          label="Reloads"
          description="View your reload history"
          tooltip={{
            data: [
              {
                label: "Auto Reloads",
                data:
                  "At the beginning of every month your text marketing credits will be refilled to " +
                  TEXT_MARKETING_AUTO_AMOUNT +
                  " credits.",
              },
              {
                label: "Purchased Reloads",
                data:
                  "In addition to the free monthly credits, you may purchase additional credits to use. Purchased" +
                  " credits never expire.",
              },
            ],
          }}
        >
          <Table
            pagination={true}
            columns={[
              {
                value: "AMOUNT",
                label: "Credit Amount",
                format: (credits) => credits + " credits",
              },
              {
                value: "TYPE",
                label: "Type",
              },
              {
                value: "RECONCILED",
                label: "Status",
                format: (reconciled, row) => (row.PRICE ? (reconciled ? "Paid" : "Unpaid") : "-"),
              },
              {
                value: "PRICE",
                label: "PRICE",
                format: (price) => (price ? toDollars(price, true) : "Free"),
              },
              {
                value: "DATE_RELOADED",
                label: "DATE RELOADED",
                type: "date",
                format: (date) => moment(date).format("M/D/YY  hh:mm A"),
              },
              {
                value: "DATE_RECONCILED",
                label: "DATE PAID",
                type: "date",
                format: (date) => (date ? moment(date).format("M/D/YY  hh:mm A") : "-"),
              },
            ]}
            data={reloads}
          />
        </Card>
      </div>
    );
  }
}
export default setupReduxConnection(["shop"])(withRouter(CreditsPage));
