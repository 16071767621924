import React, {Component} from "react";
import {Table} from "@frostbyte-technologies/frostbyte-tailwind";
import PropTypes from "prop-types";
import moment from "moment-timezone";
import {withRouter} from "../../utils/navigation";
import GrayBadge from "../../components/badges/gray-badge";

class RolesTable extends Component {
  render() {
    const {roles, defaultRoleId, className, onClick} = this.props;

    return (
      <Table
        {...this.props}
        data={roles}
        className={className}
        ref={(e) => (this.tableRef = e)}
        actionButtons={[
          {
            label: "Edit",
            onClick: (row) => {
              if (onClick) {
                return onClick(row);
              }

              this.props.router.navigate("/role/" + row.UNIQUE_ID);
            },
          },
        ]}
        columns={[
          {
            value: "NAME",
            label: "Name",
            width: 1,
          },
          {
            value: "DEFAULT_ROLE_BADGE",
            label: "",
            format: (value, row) => {
              return (
                <div>{row?.ID === defaultRoleId && <GrayBadge className="ml-1">Default Role</GrayBadge>}</div>
              );
            },
          },
          {
            value: "DATE_CREATED",
            label: "Created",
            mobile: "lg:table-cell",
            format: (value, row) => {
              return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
            },
          },
          {
            value: "DATE_UPDATED",
            label: "Updated",
            mobile: "lg:table-cell",
            format: (value) => {
              return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
            },
          },
        ]}
      />
    );
  }
}

RolesTable.propTypes = {
  roles: PropTypes.array.isRequired,
};

export default withRouter(RolesTable);
