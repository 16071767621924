import React, {Component} from "react";
import {setupReduxConnection} from "../../../redux";
import {Loading, PageHeadings, Table} from "@frostbyte-technologies/frostbyte-tailwind";
import {withRouter} from "../../../utils/navigation";
import moment from "moment-timezone";
import {request} from "../../../utils/request";
import FundraiserDetailsModal from "../../../modals/marketing/fundraiser-details-modal";
import {toDollars} from "@frostbyte-technologies/frostbyte-core/dist/utils/util";

class FundraisersPage extends Component {
  state = {
    isLoading: true,
    fundraisers: [],
  };

  componentDidMount() {
    this.syncState();
  }

  async syncState() {
    this.setState({isLoading: true});

    let fundraisers = await request("marketing/fundraisers", "GET");

    this.setState({isLoading: false, fundraisers});
  }

  render() {
    const {isLoading, fundraisers} = this.state;

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <FundraiserDetailsModal
          ref={(e) => {
            this.addModal = e;
          }}
          updateState={() => {
            this.syncState();
          }}
        />

        <div className={"pb-5"}>
          <PageHeadings
            label="Fundraisers"
            description="View all fundraising events and how much each raised"
            button={{
              label: "Add Fundraiser",
              onClick: () => {
                this.addModal.open();
              },
            }}
          />
        </div>

        <Table
          pagination
          data={fundraisers}
          ref={(e) => (this.tableRef = e)}
          onClick={(row) => {
            this.props.router.navigate("/fundraiser/" + row.UNIQUE_ID);
          }}
          actionButtons={[
            {
              label: "Edit",
              onClick: (row) => this.props.router.navigate("/fundraiser/" + row.UNIQUE_ID),
            },
          ]}
          columns={[
            {
              value: "NAME",
              label: "Name",
            },
            {
              value: "DATE_CREATED",
              label: "Created",
              mobile: "lg:table-cell",
              format: (value) => {
                return moment(value).format("MM/DD/YY");
              },
            },
            {
              value: "DATE_UPDATED",
              label: "Updated",
              mobile: "lg:table-cell",
              format: (value) => {
                return <div className="text-sm text-gray-500">{moment(value).format("MM/DD/YY")}</div>;
              },
            },
            {
              value: "AMOUNT_RAISED",
              label: "Amount Raised",
              mobile: "lg:table-cell",
              format: (value) => {
                return <div className="text-sm text-gray-500">${toDollars(value)}</div>;
              },
            },
          ]}
        />
      </div>
    );
  }
}

export default setupReduxConnection(["menu"])(withRouter(FundraisersPage));
