import {useQuery} from "@tanstack/react-query";

const THIRTY_MINS_IN_MS = 30 * (60 * 1000);
const SIXTY_MINS_IN_MS = 60 * (60 * 1000);

export const useReactQuery = (
  queryKey,
  queryFn,
  options = {},
  staleTime = THIRTY_MINS_IN_MS,
  cacheTime = SIXTY_MINS_IN_MS
) => {
  const defaultOptions = {
    staleTime: staleTime,
    cacheTime: cacheTime,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    ...options,
  };

  return useQuery({
    queryKey: queryKey,
    queryFn: queryFn,
    ...defaultOptions,
  });
};
